import { BehaviorSubject, Observable } from 'rxjs';

import { EmailVerificationStatus } from '../models/email-verification-status';
import { User } from '../models/user';
import { AuthService } from './auth.service';

export class EmailVerificationService {
  static deps = [
    AuthService
  ];

  user: User;

  private _emailVerifiedSubject = new BehaviorSubject<EmailVerificationStatus>(null);

  constructor(
    private _authService: AuthService
  ) {
    this.user = this._authService.getUser();

    if (this.user.email.includes('@hevodata.com')) {
      this._emailVerifiedSubject.next({
        verified: true,
        blocked: false
      });
    } else {
      this._emailVerifiedSubject.next({
        verified: this.user.emailVerified !== false,
        blocked: this.user.isEmailVerificationBlock
      });
    }
  }

  emailVerified$: Observable<EmailVerificationStatus> = this._emailVerifiedSubject.asObservable();

  resendVerificationLink(showLoading = false) {
    return this._authService.resendVerificationLink(showLoading);
  }
}
