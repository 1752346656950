import { Subject } from 'rxjs';
import { Workflow } from '../../models/workflow';

export class WorkflowUpdateService {
  private _update$ = new Subject<Workflow>();

  update$ = this._update$.asObservable();

  onUpdate(workflow: Workflow) {
    this._update$.next(workflow);
  }
}
