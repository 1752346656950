import { useHistory } from '@useHistory/useHistoryCompat';
import { SESSION_KEY } from '../core/constants';
import { AuthService } from '../core/services/auth.service';
import { CookieService } from '../core/services/cookie.service';
import { LocalStorageService } from '../core/services/local-storage.service';
import { store } from '../core/services/redux.service';
import { UserService } from '../core/services/user.service';
import { onUserLogout } from '../../actions/global';
import useService from '../../hooks/useService';
import AuthAPI from './AuthAPI';

export function useLogout() {
  const cookieService = useService(CookieService);
  const userService = useService(UserService);
  const authService = useService(AuthService);
  const localStorageService = useService(LocalStorageService);
  const history = useHistory();

  const logout = (handleNext = false, redirect = true) => {
    store.dispatch(onUserLogout());
    authService.beforeLogoutSubject.next();

    return AuthAPI.logout(handleNext, redirect).then(
      res => {
        afterLogout(handleNext, redirect);

        return res;
      },
      () => {
        forceRemoveToken();
        afterLogout(handleNext, redirect);

        return null;
      }
    );
  };

  const afterLogout = (handleNext = false, redirect = true) => {
    userService.clearUser();

    if (localStorageService.get(SESSION_KEY)) {
      localStorageService.remove(SESSION_KEY);
    }

    const searchParams = new URLSearchParams();

    if (handleNext) {
      searchParams.set('next', window.location.pathname + window.location.search);
    }

    if (!redirect || window.location.pathname.includes('login')) {
      authService.logoutSubject.next();
      return;
    }

    history.replace({
      pathname: '/login',
      search: searchParams.toString()
    });

    setTimeout(() => {
      authService.logoutSubject.next();
    }, 0);
  };

  const forceRemoveToken = () => {
    cookieService.delete('ht');
  };

  return {
    logout
  };
}
