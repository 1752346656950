import { getArrayFromSize } from '../../../../../legacy-utils/array';
import {
  GoogleAdsCustomerProfilesAPIResponse,
  GoogleAdsManagerProfilesAPIResponse
} from './models';

export const GOOGLE_ADS_ACCOUNTS_MOCK: Required<GoogleAdsManagerProfilesAPIResponse> = {
  success: true,
  count: 4,
  data: [
    {
      basic_info: {
        name: 'Mock Manager 1',
        id: '7447929974'
      },
      managing_clients: true,
      managed_customers_count: 50
    },
    {
      basic_info: {
        name: 'Mock Manager 2',
        id: '744792329974'
      },
      managing_clients: true,
      managed_customers_count: 50
    },
    {
      basic_info: {
        name: 'Mock Manager 3',
        id: '7'
      },
      managing_clients: false,
      managed_customers_count: 0
    },
    {
      basic_info: {
        name: 'Mock Manager 4',
        id: '744774'
      },
      managing_clients: true,
      managed_customers_count: 50
    }
  ]
};

export const GOOGLE_ADS_CUSTOMERS_MOCK: GoogleAdsCustomerProfilesAPIResponse = {
  success: true,
  count: 50,
  data: getArrayFromSize(50).map(num => ({ id: num, name: `Customer ${num}` }))
};

export enum GoogleAdsVersionEnum {
  v1 = 'v1',
  v2 = 'v2'
}
