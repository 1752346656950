import { BehaviorSubject, of, Subject, timer } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { AnsibleBoolean } from '../../../containers/core/models/ansible-config';
import { IntercomService } from '../../../containers/core/services/intercom.service';
import { PlanChatSupportService } from '../../../containers/core/services/plan-chat-support.service';
import { SettingsStorageService } from '../../../containers/core/services/settings-storage.service';
import { AskSupportTrigger } from './ask-support-trigger';
import { AskSupportData, SupportQuery } from './interface';


const ASK_SUPPORT_ON_ERROR_DELAY = 2000;
const ASK_SUPPORT_SHOWN = 'general.askSupportShown';

declare const __SHOW_ASK_SUPPORT_PROMPT__: AnsibleBoolean;

export class AskSupportDialogService {
  static deps = [
    SettingsStorageService,
    IntercomService,
    PlanChatSupportService
  ];

  private _stepErrors$ = new Subject<string>();
  askSupportDialogData$ = new BehaviorSubject<{
    data?: AskSupportData,
    open: boolean
  }>({ open: false });

  constructor(
    private _userSettingsService: SettingsStorageService,
    private _supportService: IntercomService,
    private _planChatSupportService: PlanChatSupportService
  ) {
  }

  show(askSupportData: AskSupportData) {
    this.askSupportDialogData$.next({ open: true, data: askSupportData });
    return of();
  }

  onClose(openSupport: boolean, askSupportData: AskSupportData) {
    this.askSupportDialogData$.next({ open: false, data: askSupportData });

    if (openSupport) {
      this._supportService.show(
        typeof askSupportData.supportQuery === 'string'
          ? askSupportData.supportQuery
          : askSupportData.supportQuery(askSupportData.payload)
      );
    }
  }

  onStepError(error: string) {
    this._stepErrors$.next(error);
  }

  askSupportOnFailure(concern: string, supportQuery: SupportQuery, maxErrors: number) {
    if (this.isAskSupportDisabled()) {
      return of();
    }

    let count = 0;

    return this._stepErrors$.pipe(
      switchMap((error) => {
        count++;

        if (count < maxErrors) {
          return of();
        }

        return timer(ASK_SUPPORT_ON_ERROR_DELAY).pipe(
          switchMap(() => {
            if (this._planChatSupportService.isLiveChatDisabled() || this._supportService.isLiveChatOpen()) {
              return of();
            }

            return this._askSupportOnce({
              concern,
              trigger: AskSupportTrigger.ERROR,
              payload: error,
              supportQuery
            });
          })
        );
      })
    );
  }

  private _askSupport(data: AskSupportData) {
    return this.show(data);
  }

  private _askSupportOnce(data: AskSupportData) {
    const askSupportShown = this._userSettingsService.getSettings(ASK_SUPPORT_SHOWN);

    if (askSupportShown) {
      return of();
    }

    this._userSettingsService.applySettings(ASK_SUPPORT_SHOWN, true);

    return this._askSupport(data);
  }

  isAskSupportDisabled() {
    return this.isAskSupportDisabledForTeam();
  }

  isAskSupportDisabledForTeam() {
    return typeof __SHOW_ASK_SUPPORT_PROMPT__ !== 'undefined' && __SHOW_ASK_SUPPORT_PROMPT__ === AnsibleBoolean.FALSE;
  }
}
