import { useCallback } from 'react';
import { RbacPermissions } from '../containers/core/models/user';
import useUserService from './services/useUserService';
import useService from './useService';
import { RbacService } from '../containers/core/services/rbac.service';

const useHasPermission = () => {
  const _rbacService = useService(RbacService);
  const { user, rbacPermissions, isInternalUser  } = useUserService();

  const hasPermission = useCallback(
    (permission: RbacPermissions) => _rbacService.canTakeAction(permission),
    [rbacPermissions]
  );

  const isOwnerOrHasPermission = (permission: RbacPermissions) =>
    _rbacService.isRbacEnabled()
      ? _rbacService.canTakeAction(permission)
      : user.isOwner;


  return {
    isRbacEnabled: _rbacService.isRbacEnabled(),
    hasPermission,
    isInternalUserOrHasPermission: (permission: RbacPermissions) => isOwnerOrHasPermission(permission) || isInternalUser(),
    isOwnerOrHasPermission
  };
};

export default useHasPermission;
