export function getDecodedStateObject(encodedState: string) {
  const decodedState = atob(encodedState);
  const decodedStateString = decodedState.split('|')[0];

  try {
    return JSON.parse(decodedStateString);
  } catch (e) {
    return null;
  }
}

export function getErrorRedirectURLFromEncodedState(encodedState: string) {
  const decodedStateObject = getDecodedStateObject(encodedState);

  if (decodedStateObject && decodedStateObject['error_path']) {
    return decodedStateObject['error_path'];
  } else {
    return '/login';
  }
}
