import React, { useEffect } from 'react';
import { Banner } from '../../../components/Banner';
import { BannerColorPalette } from '../../../components/Banner/interface';
import useAnalyticsTracker from '../../../hooks/useAnalyticsTracker';
import { HdLink } from '../../../components/UIElements';
import {
  TRACKER_EMAIL_VERIFICATION_BANNER_CLOSE,
  TRACKER_EMAIL_VERIFICATION_BANNER_OPEN,
  TRACKER_EMAIL_VERIFICATION_BANNER_RESEND_CLICK
} from './constants';
import useService from '../../../hooks/useService';
import { EmailVerificationService } from '../../core/services/email-verification.service';

export default function EmailVerifiedBanner({ close }) {
  const analyticsTracker = useAnalyticsTracker();
  const _emailVerificationService = useService(EmailVerificationService);

  useEffect(() => {
    analyticsTracker.eventTrack({
      action: TRACKER_EMAIL_VERIFICATION_BANNER_OPEN
    });
  }, [analyticsTracker]);

  const onClose = () => {
    analyticsTracker.eventTrack({
      action: TRACKER_EMAIL_VERIFICATION_BANNER_CLOSE
    });

    close();
  };

  const resendLink = () => {
    analyticsTracker.eventTrack({
      action: TRACKER_EMAIL_VERIFICATION_BANNER_RESEND_CLICK
    });

    _emailVerificationService.resendVerificationLink(true).subscribe();
  };

  return (
    <Banner
      color={BannerColorPalette.DEFERRED}
      dataId='email-verified-banner'
      open
      onClose={onClose}
      canClose
      icon='email-warning'
      Title={<span>Verify Your Email!</span>}
    >
      You haven't verified your email yet. Please check your inbox for verification link sent by
      Hevo. Didn't receive the link?{' '}
      <HdLink dataId='resend-verification-link' color='light' onClick={resendLink}>
        Resend Verification Link
      </HdLink>
    </Banner>
  );
}
