import { getExecutionPolicyFromRawData } from '../../scheduler/utils';
import { getMapFromObj } from '../../../legacy-utils/object';
import { Destination, DestinationStatusEnum } from '../models';


export function DestinationsFactory(rawData: any[]): Destination[] {
  if (!rawData || !rawData.length) {
    return [];
  }

  return rawData.map((item) => {
    return DestinationFactory(item);
  });
}

export function DestinationFactory(rawData: any): Destination {
  if (!rawData) {
    return;
  }

  const destination = new Destination();

  destination.id = rawData.id;
  destination.seqId = rawData.seq_id;
  destination.name = rawData.name;
  destination.destType = rawData.dest_type;
  destination.destTypeDisplay = rawData.dest_type_display;
  destination.createdByDisplayName = rawData.created_by_display_name;
  destination.createdBy = rawData.created_by;
  destination.destTypeLogoUrl = rawData.dest_type_logo_url;
  destination.destTypeDarkModeLogoUrl = rawData.dest_type_dark_mode_logo_url;
  destination.hasSyncPolicy = rawData.dest_type_has_sync_policy;
  destination.executionPolicy = getExecutionPolicyFromRawData(rawData.execution_policy);
  destination.displayDetails = getMapFromObj(rawData.display_config);
  destination.displayDetailsArray = destination.displayDetails ? Array.from(destination.displayDetails.entries()) : [];
  destination.config = rawData.config;
  destination.error = rawData.error;
  destination.status = DestinationStatusEnum[rawData.status];
destination.oauthUserId = rawData.oauth_user_id;
  destination.editConfigSupported = rawData.edit_config_supported;
  destination.validForWorkbenchView = rawData.valid_for_workbench_view;
  destination.validForTableLevelSyncStatusView = rawData.valid_for_table_level_sync_status_view;
  destination.validForPendingEventsView = rawData.valid_for_pending_events_view;
  destination.dedupEnabled = rawData.dedup_enabled || null;
  destination.tablesSupported = rawData.tables_supported;
  destination.triggerBasedLoad = rawData.trigger_based_load;
  destination.triggerJobStatus = rawData.trigger_job_status

  return destination;
}

export function SchemasFactory(rawData: any[]) {
  if (!Array.isArray(rawData)) {
    return;
  }

  return rawData.map(element => {
    return SchemaFactory(element);
  });
}

export function SchemaFactory(rawData: any) {
  if (!rawData) {
    return rawData;
  }

  return {
    ...rawData,
    fields: SchemaFieldsFactory(rawData.fields)
  };
}

export function SchemaFieldsFactory(rawData: any[]) {
  if (!Array.isArray(rawData)) {
    return;
  }

  return rawData.map(element => {
    return SchemaFieldFactory(element);
  });
}

export function SchemaFieldFactory(rawData: any) {
  if (!rawData) {
    return rawData;
  }

  const field: any = {};

  field.name = rawData.nm;
  field.optional = rawData.sc.ol;
  field.value_type = rawData.sc.vs ? rawData.sc.vs.ty : undefined;
  field.value_type_display = rawData.sc.vtd;
  field.type = rawData.sc.ty;
  field.type_display = rawData.sc.ftd;
  field.parameters = SchemaFieldParamsFactory(rawData.sc.pr);
  field.fields = SchemaFieldsFactory(rawData.sc.ty === 'ARRAY' ? rawData.sc.vs.fs : rawData.sc.fs);

  if (rawData.sc?.pr?.name) {
    field.display_name = rawData.sc.pr.name;
  }

  if (rawData.sc?.pr?.type) {
    field.custom_field_type = rawData.sc.pr.type;
  }

  return field;
}

export function SchemaFieldParamsFactory(rawData: any) {
  if (!rawData) {
    return;
  }

  const params: any = {};

  params.pk_pos = rawData.pk_p;
  params.is_dk = rawData.dk;
  params.is_ck = rawData.ck;
  params.sk_pos = rawData.sk_p;
  params.partition_key_pos = rawData.pa_p;
  params.data_length = rawData.dl;
  params.date_format = rawData.df;
  params.dt_rg = rawData.dt_rg;
  params.ingestion_time_based_partition = rawData.itp;
  params.hinted_schema = rawData.sh;

  return params;
}
