import { GenericSourceConfigHelper } from '../../../containers/pipeline/create/ConfigSourceType/GenericSource/configHelper';
import { JobMode } from '../source-type/job-mode';
import { SOURCE_TYPES } from '../source-type/source-type';
import { SOURCE_TYPE_CONFIG_HELPERS } from '../source-type/source-type-config-helpers';
import { SourceTypeConfigHelper, SourceTypeConfigHelperConstructor } from './config-helper';


export class SourceConfigHelperService {
  private _configHelpers: { [key: string]: SourceTypeConfigHelper } = {};

  getConfigHelper(sourceTypeIdentifier: string, jobMode?: JobMode): SourceTypeConfigHelper {
    const existingRef = this._configHelpers[sourceTypeIdentifier];

    if (existingRef) {
      return existingRef;
    }

    const sourceTypeMetaData = SOURCE_TYPES[sourceTypeIdentifier];
    const configHelperClass: SourceTypeConfigHelperConstructor =
      sourceTypeMetaData.genericReactComponent
      ? GenericSourceConfigHelper
      : SOURCE_TYPE_CONFIG_HELPERS[sourceTypeIdentifier];

    let newRef: SourceTypeConfigHelper = new configHelperClass(sourceTypeIdentifier, jobMode);

    this._configHelpers[sourceTypeIdentifier] = newRef;

    return newRef;
  }
}
