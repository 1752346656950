import { SourceTypeConfigHelper } from '../../../../../components/Node/source-settings-form/config-helper';
import { ConnectorAuthTypeEnum } from '../../../../../components/Node/source-type/auth-type';
import {
  getSelectorInclusionStateFromRawData,
  getSelectorRawDataFromInclusionState
} from '../../../../../components/SingleLevelSelector/models';
import PipelinesAPI from '../../../PipelinesAPI';

export class BigQuerySourceConfigHelper implements SourceTypeConfigHelper {
  getConfigFromRawData(rawData: any) {
    rawData.entities = rawData.dataset_id;

    return {
      selectedProjectValue: rawData.project_id ? {
        id: rawData.project_id,
        display_name: rawData.project_display_value,
        name: rawData.project_display_value
      } : undefined,
      selectorRequestParams: rawData.project_id ? { id: rawData.project_id } : null,
      selectedDatasetIDs: getSelectorInclusionStateFromRawData(rawData),
      bucketName: rawData.gcs_bucket_path
    };
  }

  getDefaultConfig(metaData: any) {
    return {
      selectedDatasetIDs: {
        selected: true,
        entities: []
      }
    };
  }

  buildConnectionParams(data: any) {
    const params = {
      project_id: data.selectedProjectValue?.id,
      project_display_value: data.selectedProjectValue?.display_name,
      dataset_id: getSelectorRawDataFromInclusionState(data.selectedDatasetIDs)?.entities,
      all_select: getSelectorRawDataFromInclusionState(data.selectedDatasetIDs)?.all_select,
      gcs_bucket_path: data.bucketName || undefined,
      account_type: data.authType || ConnectorAuthTypeEnum.OAUTH
    };

    if (data.sourceVersion && data.sourceVersion < 2) {
      params.gcs_bucket_path = null;
    }

    return params;
  }

  metaDataResolver(sourceType: string, authData: any, rawData: any) {
    const accountId = authData.oauthTokenId || authData.securityServiceAccountId;
    const authType = authData.authType || ConnectorAuthTypeEnum.OAUTH;

    return PipelinesAPI.getBigQuerySourceProjectId(authType, accountId).then(({ data }) => {
      const { projects } = data;
      return {
        projectIDList: projects.map(project => ({ ...project, name: project.display_name }))
      };
    });
  }
}
