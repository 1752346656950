import axios, { AxiosError } from 'axios';
import { Observable, of, throwError } from 'rxjs';
import { catchError, filter, finalize, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { ToasterService } from '../../../components/Toaster/toaster.service';
import { DEFAULT_NETWORK_REQ_OPTIONS, REQUEST_TIMED_OUT_ERROR_MSG } from '../constants';
import { HTTPRequestMethodEnum, NetworkRequestOptions, RequestUIOptions } from '../models/request';
import { getErrorFromAxiosError, getErrorMessageFromObj, getSuccessMessageFromObj } from '../../../legacy-utils/request';
import { retryBackoff } from '../../../legacy-utils/rxjs-operators';
import { GlobalBlockingLoaderService } from './global-blocking-loader.service';
import { UnauthorisedEventService } from './unauthorised-event.service';

import './rest.interceptor';


const BACKOFF_MAX_DELAY = 30000;
const BACKOFF_INIT_DELAY = 1000;

export class RxRequestService {
  static deps = [
    GlobalBlockingLoaderService,
    ToasterService,
    UnauthorisedEventService
  ];

  constructor(
    private loaderService: GlobalBlockingLoaderService,
    private toasterService: ToasterService,
    private _unauthorisedEventService: UnauthorisedEventService
  ) {
  }

  private _defaultOptions = DEFAULT_NETWORK_REQ_OPTIONS;

  unauthorisedErrorObs = this._unauthorisedEventService.unauthorisedErrorObs;

  get(url: string, networkOpts: NetworkRequestOptions = {}) {
    return this.sendRequest(HTTPRequestMethodEnum.GET, url, undefined, networkOpts);
  }

  post(url: string, networkOpts: NetworkRequestOptions = {}, params?: any) {
    return this.sendRequest(HTTPRequestMethodEnum.POST, url, params, networkOpts);
  }

  put(url: string, networkOpts: NetworkRequestOptions = {}, params?: any) {
    return this.sendRequest(HTTPRequestMethodEnum.PUT, url, params, networkOpts);
  }

  delete(url: string, networkOpts: NetworkRequestOptions = {}) {
    return this.sendRequest(HTTPRequestMethodEnum.DELETE, url, undefined, networkOpts);
  }

  sendRequest(
    method: HTTPRequestMethodEnum,
    url: string,
    params?: any,
    opts: NetworkRequestOptions = {}
  ): Observable<any> {

    const networkOptions: any = Object.assign(
      {},
      this._defaultOptions.networkOptions,
      opts.networkOptions
    );

    const uiOptions: RequestUIOptions = Object.assign(
      {},
      this._defaultOptions.uiOptions,
      opts.uiOptions
    );

    const reqFunction: any = this._generateRequestFunction(
      method,
      url,
      params,
      networkOptions
    );

    return of([]).pipe(
      tap(() => {
        this._showLoader(uiOptions);
      }),
      switchMap(() => {
        return reqFunction();
      })
    ).pipe(
      // Cancel request if the request requires auth and unauthorised error fired
      takeUntil(
        this.unauthorisedErrorObs.pipe(
          filter(() => {
            return opts.requireAuth;
          })
        )
      ),
      retryBackoff({
        initialInterval: BACKOFF_INIT_DELAY,
        maxInterval: BACKOFF_MAX_DELAY,
        shouldRetry: (error) => {
          if (!error.response || !error.response.status || error.config?.method !== 'get') {
            return false;
          }

          return [502, 503, 504].includes(error.response.status);
        }
      }),
      map((res: any) => {
        if (uiOptions.showSuccessMsg) {
          const displayMessage: string = uiOptions.successMsg || getSuccessMessageFromObj(res.data);
          if (displayMessage) {
            this.toasterService.pop('success', undefined, displayMessage);
          }
        }

        if (networkOptions.observe === 'response') {
          return res;
        }

        return res.data;
      }),
      catchError((error: AxiosError<any>) => {
        const err = getErrorFromAxiosError(error);

        if (uiOptions.handleTimeoutError && [504, 503].includes(err.status)) {
          err.error = {
            error_message: REQUEST_TIMED_OUT_ERROR_MSG
          };
        }

        const errorMsg = getErrorMessageFromObj(err);

        if (errorMsg && this._shouldShowErrorMsg(err, uiOptions)) {
          this.toasterService.pop('error', undefined, errorMsg);
        }

        // Emit unauthorisedErrorObs when an api that requires auth return 401
        if (uiOptions.handleUnauthorisedResponse && err.status === 401) {
          this.unauthorisedErrorObs.next(uiOptions.unauthorisedHandleNext);
        }

        return throwError(err);
      }),
      finalize(() => {
        this._hideLoader(uiOptions);
      })
    );
  }

  private _generateRequestFunction(
    method: HTTPRequestMethodEnum,
    url: string,
    params?: any,
    options?: any
  ): any {

    switch (method) {
      case HTTPRequestMethodEnum.GET: {
        return axios.get.bind(axios, url, options);
      }

      case HTTPRequestMethodEnum.POST: {
        return axios.post.bind(axios, url, params, options);
      }

      case HTTPRequestMethodEnum.DELETE: {
        return axios.delete.bind(axios, url, options);
      }

      case HTTPRequestMethodEnum.PUT: {
        return axios.put.bind(axios, url, params, options);
      }
    }

  }

  private _shouldShowErrorMsg(err: any, uiOptions: RequestUIOptions): boolean {
    if (!err.status || err.status <= 0) {
      return true;
    }

    if (uiOptions.handleUnauthorisedResponse && err.status === 401) {
      return true;
    }

    if (err.status === 403) {
      return false;
    }

    return uiOptions.showErrorMsg;
  }

  private _hideLoader(uiOptions: RequestUIOptions) {
    if (uiOptions.showLoading) {
      this.loaderService.pop();
    }
  }

  private _showLoader(uiOptions: RequestUIOptions) {
    if (uiOptions.showLoading) {
      this.loaderService.push();
    }
  }
}
