import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { from, Subscription } from 'rxjs';
import { concatMap, delay, first, tap } from 'rxjs/operators';
import { DrawerDir } from './models';
import { wrapIntoObservable } from '../../components/wizard/wizard-native/utils';
import { NotificationDrawer } from '../../components/NotificationDrawer';
import { TroubleshootDrawer } from './TroubleshootDrawer/TroubleshootDrawer';
import styles from './styles.module.scss';
import { animated } from '@react-spring/web';
import { useDrawerAnimation } from './useDrawerAnimation';

export interface DrawerDefinition {
  path: string;
  component: React.FC<any>;
  guards?: Array<() => Promise<boolean> | boolean>;
  direction?: DrawerDir;
}

export function DrawersContainer() {
  const location = useLocation();
  const navigate = useNavigate();

  const DRAWERS: DrawerDefinition[] = [
    {
      path: 'alerts',
      component: NotificationDrawer,
      direction: 'right'
    },
    {
      path: 'troubleshoot',
      component: TroubleshootDrawer,
      direction: 'right'
    }
  ];

  const DRAWERS_MIGRATED_TO_LIST = {
    'pipelines': '/pipeline',
    'model': '/model',
    'transform': '/model',
    'workflow': '/workflow',
    'destinations': '/destination',
    'activate': '/activation',
    'targets': '/activation?tab=target'
  };

  const [ activeDrawerDefinition, setActiveDrawerDefinition ] = useState<DrawerDefinition>(null);

  const activeDrawerPath = new URLSearchParams(location.search).get('drawer');

  const guardsCheckSub = useRef(Subscription.EMPTY);

  useEffect(() => {
    const drawerDefinition = DRAWERS.find((def) => def.path === activeDrawerPath);

    if (!drawerDefinition) {
      setActiveDrawerDefinition(null);

      if (DRAWERS_MIGRATED_TO_LIST[activeDrawerPath]) {
        navigate(DRAWERS_MIGRATED_TO_LIST[activeDrawerPath], {
          replace: true
        });
      } else {
        const searchParams = new URLSearchParams(location.search);
        searchParams.delete('drawer');

        navigate({
          pathname: window.location.pathname,
          search: searchParams.toString(),
        }, {
          replace: true
        });
      }

      return;
    }

    guardsCheckSub.current = from(drawerDefinition.guards || []).pipe(
      delay(0),
      concatMap((guard) => {
        const guardResp = guard();

        return wrapIntoObservable(guardResp).pipe(first());
      }),
      first(result => {
        return result !== true;
      }, true),
      tap((guardsResult) => {
        if (!guardsResult) {
          setActiveDrawerDefinition(null);
        } else {
          setActiveDrawerDefinition({
            ...drawerDefinition,
            direction: drawerDefinition.direction || 'left'
          });
        }
      })
    ).subscribe();

    return () => {
      guardsCheckSub.current.unsubscribe();
    };
  }, [ activeDrawerPath ]);

  const {
    containerSpringScaleValues,
    containerSpringOpacityValues,
    drawerTransition
  } = useDrawerAnimation(activeDrawerDefinition);

  return (
    <animated.div
      className={styles.drawersContainer}
      style={{
        ...containerSpringScaleValues,
        ...containerSpringOpacityValues
      }}
    >
      {drawerTransition((style, drawerDefinition) => {
        return (
          <drawerDefinition.component
            drawerDirection={drawerDefinition.direction}
            springValues={style}
            closeInitiated={false}
            disableRestoreFocus={false}
            usingReactRouter={true}
          />
        );
      })}
    </animated.div>
  );
}
