import { Policy } from '../scheduler/ExecutionPolicyDialog/models';
import { Enum } from '../core/models/object-enum';
import { EntityStatus } from '../core/models/entity-status';
import { DESTINATION_TYPES } from '../../components/Node/destination-type/model';
import { Policy as ExecutionPolicy } from '../scheduler/models';

export class DestinationStatusEnum extends Enum {
  static OK = new EntityStatus('Active', 'OK', 'success');
  static FAILED_PERMANENTLY = new EntityStatus('Failed', 'FAILED_PERMANENTLY', 'error');
}

export interface DestinationCompactData {
  destinationId: number;
  destTypeLogoUrl: string;
  destTypeDarkModeLogoUrl: string;
  destType: string;
  destName: string;
  hasAppendOnlyRecommendations?: boolean;
}

export interface DestinationRecommendationsData {
  hadRecommendations: boolean;
  hasPendingRecommendations: boolean;
  hadAppliedRecommendations: boolean;
}

export class Destination {
  seqId: number;
  id: number;
  name: string;
  destType: string;
  destTypeDisplay: string;
  createdByDisplayName: string;
  createdBy: number;
  displayDetails: Map<string, string>;
  destTypeLogoUrl: string;
  destTypeDarkModeLogoUrl: string;
  executionPolicy: Policy | ExecutionPolicy;
  hasSyncPolicy = false;
  displayDetailsArray: string[][];
  config: any = {};
  error: string;
  status: EntityStatus;
  oauthUserId: number;
  editConfigSupported: boolean;
  dedupEnabled?: boolean;
  validForWorkbenchView: boolean;
  validForTableLevelSyncStatusView: boolean;
  validForPendingEventsView: boolean;
  tablesSupported?: boolean;
  schemas?: any[];
  triggerBasedLoad?: boolean;
  triggerJobStatus?:string

  constructor() {
  }

  canReauthoriseAccount() {
    return this.config && this.config.oauth_token_id;
  }

  editReAuthRequired(userId: number) {
    return this.config && this.config.oauth_token_id && this.oauthUserId !== userId;
  }

  canConnectBITools(userId: number) {
    return this.createdBy === userId;
  }

  isPartitionBased() {
    return DESTINATION_TYPES[this.destType].isPartitionBased;
  }
}

export enum DestinationConnectionActionableErrorStatus {
  DATA_REQUIRED = 'DATA_REQUIRED',
  CONFIRMATION_REQUIRED = 'CONFIRMATION_REQUIRED'
}
