import { TargetTypesEnum } from '../../activate-target/models/target-type';
import { GoogleSheetsTargetObjectSettings } from '../GoogleSheetsActivation/target-object-settings';
import { KlaviyoTargetObjectSettings } from '../KlaviyoActivation/target-object-settings';
import { FieldsMappingType } from './fields-mapping-type';
import { GenericTargetObjectSettings, TargetObjectSettingsConstructor } from './target-object-settings';


interface ActivationTargetMetaData {
  targetObjectSettingsHelper?: TargetObjectSettingsConstructor;
  fieldsMappingType?: FieldsMappingType;
  hasIdentifiers?: boolean;
  hasMandatoryFields?: boolean;
  supportsCustomFields?: boolean;
}

export const ACTIVATION_TARGET_META_DATA: { [key: string]: ActivationTargetMetaData } = {
  [TargetTypesEnum.SALESFORCE]: {
    targetObjectSettingsHelper: GenericTargetObjectSettings,
    fieldsMappingType: FieldsMappingType.GENERIC,
    hasIdentifiers: true,
    hasMandatoryFields: true,
    supportsCustomFields: false
  },
  [TargetTypesEnum.HUBSPOT]: {
    targetObjectSettingsHelper: GenericTargetObjectSettings,
    fieldsMappingType: FieldsMappingType.GENERIC,
    hasIdentifiers: true,
    hasMandatoryFields: true,
    supportsCustomFields: false
  },
  [TargetTypesEnum.INTERCOM]: {
    targetObjectSettingsHelper: GenericTargetObjectSettings,
    fieldsMappingType: FieldsMappingType.GENERIC,
    hasIdentifiers: true,
    hasMandatoryFields: true,
    supportsCustomFields: false
  },
  [TargetTypesEnum.ZENDESK]: {
    targetObjectSettingsHelper: GenericTargetObjectSettings,
    fieldsMappingType: FieldsMappingType.GENERIC,
    hasIdentifiers: true,
    hasMandatoryFields: true,
    supportsCustomFields: false
  },
  [TargetTypesEnum.GOOGLE_SHEETS]: {
    targetObjectSettingsHelper: GoogleSheetsTargetObjectSettings,
    fieldsMappingType: FieldsMappingType.GOOGLE_SHEETS,
    hasIdentifiers: false,
    hasMandatoryFields: false,
    supportsCustomFields: false
  },
  [TargetTypesEnum.MARKETO]: {
    targetObjectSettingsHelper: GenericTargetObjectSettings,
    fieldsMappingType: FieldsMappingType.GENERIC,
    hasIdentifiers: true,
    hasMandatoryFields: true,
    supportsCustomFields: false
  },
  [TargetTypesEnum.MAILCHIMP]: {
    targetObjectSettingsHelper: GenericTargetObjectSettings,
    fieldsMappingType: FieldsMappingType.GENERIC,
    hasIdentifiers: true,
    hasMandatoryFields: true,
    supportsCustomFields: false
  },
  [TargetTypesEnum.KLAVIYO]: {
    targetObjectSettingsHelper: KlaviyoTargetObjectSettings,
    fieldsMappingType: FieldsMappingType.KLAVIYO,
    hasIdentifiers: true,
    hasMandatoryFields: true,
    supportsCustomFields: true
  },
  [TargetTypesEnum.MIXPANEL]: {
    targetObjectSettingsHelper: GenericTargetObjectSettings,
    fieldsMappingType: FieldsMappingType.GENERIC,
    hasIdentifiers: true,
    hasMandatoryFields: true,
    supportsCustomFields: true
  },
  [TargetTypesEnum.FRESHDESK]: {
    targetObjectSettingsHelper: GenericTargetObjectSettings,
    fieldsMappingType: FieldsMappingType.GENERIC,
    hasIdentifiers: true,
    hasMandatoryFields: true,
    supportsCustomFields: false
  },
  [TargetTypesEnum.CLEVERTAP]: {
    targetObjectSettingsHelper: KlaviyoTargetObjectSettings,
    fieldsMappingType: FieldsMappingType.KLAVIYO,
    hasIdentifiers: true,
    hasMandatoryFields: true,
    supportsCustomFields: true
  }
};
