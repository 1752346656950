import clsx from 'clsx';
import React, { ReactNode } from 'react';
import { BannerColorPalette } from './interface';
import { getDataIdGenerator } from '../../utils/generateDataId';
import { HdHoverTracker } from '../Tracking/HoverTracker';
import { HdIcon, HdIconButton } from '../UIElements';
import styles from './styles.module.scss';

export interface BannerProps {
  open: boolean;
  dataId: string;
  className?: string;
  icon?: string;
  iconClass?: string;
  iconSrc?: string;
  canClose?: boolean;
  hoverTrackingAction?: string;
  hoverTrackingProps?: any;
  closeHoverTrackingAction?: string;
  closeHoverTrackingProps?: any;
  color: BannerColorPalette;
  Title?: any;
  Action?: any;
  onClose?: Function;
  children?: ReactNode;
}

export function Banner({
  open,
  dataId,
  className = '',
  icon,
  iconClass,
  iconSrc,
  canClose,
  hoverTrackingAction,
  hoverTrackingProps,
  closeHoverTrackingAction,
  closeHoverTrackingProps,
  color,
  Title,
  Action,
  onClose,
  children
}: BannerProps) {
  if (!open) {
    return null;
  }

  const dataIdGenerator = getDataIdGenerator(dataId, 'banner');

  return (
    <HdHoverTracker trackingAction={hoverTrackingAction} trackingProps={hoverTrackingProps}>
      <div
        className={clsx(styles.banner, styles[color], className)}
        data-id={dataIdGenerator('')}
      >
        <div className='center-flex-row'>
          {icon && !iconSrc ? (
            <HdIcon name={icon} className={clsx(styles.bannerThumbnail, iconClass)} />
          ) : null}

          {iconSrc ? (
            <img
              src={iconSrc}
              className={styles.bannerThumbnail}
              alt=''
              data-id={dataIdGenerator('icon')}
            />
          ) : null}

          <div className='ml-2'>
            {Title ? <div className={styles.bannerTitle}>{Title}</div> : null}

            {children ? <div className={styles.bannerContent}>{children}</div> : null}
          </div>

          {Action || null}
        </div>

        {canClose ? (
          <HdHoverTracker
            trackingAction={closeHoverTrackingAction}
            trackingProps={closeHoverTrackingProps}
            dataId={dataIdGenerator('close-hover-tracker')}
          >
            <HdIconButton
              dataId={dataIdGenerator('close')}
              className={styles.closeBanner}
              onClick={() => onClose()}
            >
              <HdIcon name='close' />
            </HdIconButton>
          </HdHoverTracker>
        ) : null}
      </div>
    </HdHoverTracker>
  );
}
