import { useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { ResetPasswordRequestType } from './models';
import { AuthService } from '../core/services/auth.service';
import { AngularDIContext } from '../../components/AngularDI/di-context';
import AuthAPI from './AuthAPI';
import { useHandleAuthResponse } from './useHandleAuthResponse';

export function useResetPassword() {
  const angularDIContext = useContext(AngularDIContext);
  const handleAuthResponse = useHandleAuthResponse();
  const { search } = useLocation();

  const next = new URLSearchParams(search).get('next');

  const resetPassword = (
    code: string,
    password: string,
    resetPasswordRequestType: ResetPasswordRequestType
  ) =>
    AuthAPI.resetPassword(code, password, resetPasswordRequestType).then(res => {
      handleAuthResponse(res, next || '');
      return res;
    });

  return {
    resetPassword
  };
}
