import { SourceTypeConfigHelper } from '../../../../../components/Node/source-settings-form/config-helper';
import PipelinesAPI from '../../../PipelinesAPI';

export class InstagramBusinessConfigHelper implements SourceTypeConfigHelper {
  getConfigFromRawData(rawData: any, metaData: any) {
    const config = {
      account: rawData.accounts
    };

    if (rawData.schema_version && rawData.schema_version < 17) {
      config.account = (rawData.accounts || []).map((accountName: string) => {
        return (metaData.accounts || []).find((account: any) => account.display_field === accountName);
      });
    }

    return config;
  }

  getDefaultConfig(metaData: any) {
    return {
      account: metaData.accounts
    };
  }

  buildConnectionParams(data: any) {
    let accounts = data.account;

    if (data.schemaVersion && data.schemaVersion < 17) {
      accounts = (data.account || []).map((account: any) => account.display_field);
    }

    return {
      accounts
    };
  }

  metaDataResolver(sourceType: string, authData: any, rawData: any) {
    return PipelinesAPI.getInstagramAccounts(authData.oauthTokenId).then((accounts) => {
      return {
        accounts
      };
    });
  }
}
