import { SourceTypeConfigHelper } from '../../../../../components/Node/source-settings-form/config-helper';

export class OutbrainConfigHelper implements SourceTypeConfigHelper {
  getConfigFromRawData(rawData: any) {
    return {
      username: rawData.username,
      password: rawData.password
    };
  }

  getDefaultConfig() {
  }

  buildConnectionParams(data: any) {
    return {
      username: data.username,
      password: data.password
    };
  }
}
