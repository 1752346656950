import {
  BACKGROUND_NETWORK_REQ_OPTIONS,
  BACKGROUND_NETWORK_REQ_UI_OPTIONS
} from '../core/constants';
import { RecaptchaTokenPayload } from '../core/models/recaptcha';
import { NetworkRequestOptions } from '../core/models/request';
import { createHttpParams } from '../../legacy-utils/request';
import { AppConfig } from '../core/app.config';
import { courier } from '../core/services/courier.service';
import { LoginCredentials } from '../core/models/login-credentials';
import { ResetPasswordRequestType } from './models';

const appConfig = new AppConfig();

const AuthAPI = {
  securityServiceURL: appConfig.getSecurityServiceURL(),
  oAuthURL: `${appConfig.getSecurityServiceURL()}/oauth`,
  serviceAuthURL: `${appConfig.getSecurityServiceURL()}/service-accounts`,
  tfaAuthURL: `${appConfig.getSecurityServiceURL()}/tfa`,
  samlURL: `${appConfig.getSecurityServiceURL()}/saml`,
  authURL: `${appConfig.getSecurityServiceURL()}/auth`,
  loginURL: `${appConfig.getSecurityServiceURL()}/auth/authenticate`,
  userDetailsURL: `${appConfig.getSecurityServiceURL()}/users/me`,
  googleLoginURL: `${appConfig.getSecurityServiceURL()}/oauth/google/callback`,

  // ------- OAuth Account APIs ---------
  getAuthTokens(provider: any, next: any, cancel: any, networkConfig = {}) {
    const requestUrl = `${this.oAuthURL}/tokens/${provider}`;

    const options: NetworkRequestOptions = {
      uiOptions: {
        showLoading: false
      },
      networkOptions: {
        params: createHttpParams({
          next,
          cancel
        })
      },
      ...networkConfig
    };

    return courier.get(requestUrl, options);
  },

  deleteAccount(provider: string, accountId: number) {
    const requestUrl = `${this.oAuthURL}/token/provider/${provider}/${accountId}`;

    const options: NetworkRequestOptions = {
      uiOptions: {
        ...BACKGROUND_NETWORK_REQ_UI_OPTIONS,
        successMsg: 'User account deleted successfully',
        showSuccessMsg: true,
        showErrorMsg: true
      }
    };

    return courier.delete(requestUrl, options);
  },

  reAuthoriseAccount(provider: string, accountId: number, next: string, cancel: string) {
    const requestUrl = `${this.oAuthURL}/reconnect/${accountId}/${provider}`;

    const options: NetworkRequestOptions = {
      networkOptions: {
        params: createHttpParams({
          next,
          cancel
        })
      }
    };

    return courier.get(requestUrl, options);
  },

  // ------- Service Account APIs ---------
  fetchServiceAccounts(provider: string, networkConfig = {}) {
    const requestUrl = `${this.serviceAuthURL}/provider/${provider}`;

    const options: NetworkRequestOptions = {
      uiOptions: {
        showLoading: false,
        showErrorMsg: true
      },

      ...networkConfig
    };

    return courier.get(requestUrl, options);
  },

  addServiceAccount(provider: string, email: string, token: any) {
    const requestUrl = `${this.serviceAuthURL}/provider/${provider}`;

    const params = {
      email,
      key: token || null,
      key_format: 'JSON'
    };

    const options: NetworkRequestOptions = {
      uiOptions: {
        showSuccessMsg: false
      }
    };

    return courier.post(requestUrl, options, params);
  },

  editServiceAccount(provider: string, accountId: number, token: any) {
    const requestUrl = `${this.serviceAuthURL}/${accountId}`;

    const params = {
      key: token || null,
      key_format: 'JSON',
      provider
    };

    const options: NetworkRequestOptions = {
      uiOptions: {
        ...BACKGROUND_NETWORK_REQ_UI_OPTIONS,
        showErrorMsg: true
      }
    };

    return courier.put(requestUrl, options, params);
  },

  deleteServiceAccount(accountId: number) {
    const requestUrl = `${this.serviceAuthURL}/${accountId}`;

    const options: NetworkRequestOptions = {
      uiOptions: {
        ...BACKGROUND_NETWORK_REQ_UI_OPTIONS,
        successMsg: 'Service account deleted successfully',
        showSuccessMsg: true,
        showErrorMsg: true
      }
    };

    return courier.delete(requestUrl, options);
  },

  disableTFA(userId?: number) {
    let requestUrl = `${this.tfaAuthURL}/disable`;

    if (userId) {
      requestUrl += `/${userId}`;
    }

    return courier.post(requestUrl);
  },

  getAuthConfigForLogin(
    payload: { email: string } & RecaptchaTokenPayload
  ) {
    const requestUrl = `${this.authURL}/login-auth-configurations`;

    const options: NetworkRequestOptions = {
      uiOptions: {
        showLoading: false,
        handleUnauthorisedResponse: false
      }
    };

    return courier.post(requestUrl, options, payload);
  },

  getSAMLMetadata() {
    const requestUrl = `${this.samlURL}/metadata`;

    return courier.get(requestUrl, BACKGROUND_NETWORK_REQ_OPTIONS);
  },

  setAccessToken(params: any, provider: string) {
    const requestUrl = `${this.oAuthURL}/set-access-token/${provider}`;

    const options: NetworkRequestOptions = {
      uiOptions: {
        showLoading: false
      }
    };

    return courier.post(requestUrl, options, params);
  },

  resendVerificationLink(showLoading = false) {
    const requestUrl: string = this.securityServiceURL + '/users/resend-email-verification';

    const options: NetworkRequestOptions = {
      uiOptions: {
        showLoading: showLoading,
        showErrorMsg: showLoading,
        showSuccessMsg: showLoading
      }
    };

    return courier.post(requestUrl, options);
  },

  forgotPassword(params: any) {
    const requestUrl = `${this.authURL}/forgot-password`;

    return courier.put(requestUrl, BACKGROUND_NETWORK_REQ_OPTIONS, params);
  },

  resetPassword(code: any, password: string, requestType: ResetPasswordRequestType) {
    const requestUrl = `${this.authURL}/reset-password`;

    const params: any = {
      code,
      password,
      type: requestType
    };

    const options: NetworkRequestOptions = {
      uiOptions: {
        ...BACKGROUND_NETWORK_REQ_UI_OPTIONS,
        handleUnauthorisedResponse: false
      }
    };

    return courier.put(requestUrl, options, params);
  },

  getGoogleOAuthURL(queryParams: any) {
    return `${this.oAuthURL}/google/authenticate?${queryParams.toString()}`;
  },

  getSAMLRequestURL(email: string) {
    const requestUrl = `${this.samlURL}/authn-request`;

    const options: NetworkRequestOptions = {
      networkOptions: {
        params: createHttpParams({
          email
        })
      }
    };

    return courier.get(requestUrl, options);
  },

  authenticateAccount(email: string) {
    const requestUrl = `${this.loginURL}/hevo`;

    const params: any = {
      email
    };

    const options: NetworkRequestOptions = {
      uiOptions: {
        handleUnauthorisedResponse: false
      }
    };

    return courier.post(requestUrl, options, params);
  },

  login(
    credentials: LoginCredentials & RecaptchaTokenPayload,
    options?: NetworkRequestOptions,
    tfaCode?: string
  ) {
    const body: any = {
      ...credentials
    };

    if (tfaCode) {
      body.otp = tfaCode;
    }

    return courier.post(this.loginURL, options, body);
  },

  submitOTP(otp: number) {
    const requestUrl = `${this.loginURL}/otp`;

    const params = {
      otp
    };

    const options: NetworkRequestOptions = {
      uiOptions: {
        ...BACKGROUND_NETWORK_REQ_UI_OPTIONS,
        handleUnauthorisedResponse: false
      }
    };

    return courier.post(requestUrl, options, params);
  },

  isUserSessionStillValid() {
    const options: NetworkRequestOptions = {
      uiOptions: {
        ...BACKGROUND_NETWORK_REQ_UI_OPTIONS,
        handleUnauthorisedResponse: false
      }
    };

    return courier.get(this.userDetailsURL, options).then(
      () => {
        return true;
      },
      (err: any) => {
        return !(err.status === 404 || err.status === 401);
      }
    );
  },

  verifyEmail(code: string) {
    const requestUrl: string = this.authURL + '/verify-email';

    const options: NetworkRequestOptions = {
      uiOptions: {
        showLoading: false,
        showErrorMsg: true,
        showSuccessMsg: false,
        handleUnauthorisedResponse: false
      }
    };

    const params = {
      code: code
    };

    return courier.put(requestUrl, options, params);
  },

  logout(handleNext = false, redirect = true, showErrorMsg = false) {
    const requestUrl = this.authURL + '/logout';

    const options: NetworkRequestOptions = {
      uiOptions: {
        showSuccessMsg: false,
        showErrorMsg
      }
    };

    return courier.post(requestUrl, options);
  },

  googleLogin(err: any, code: any, state: any, tfaCode?: string) {
    const options: NetworkRequestOptions = {
      uiOptions: {
        handleUnauthorisedResponse: false,
        showLoading: false,
        showErrorMsg: false
      },
      networkOptions: {
        params: createHttpParams({
          error: err,
          code: code,
          state: state,
          otp: tfaCode
        })
      }
    };

    return courier.get(this.googleLoginURL, options);
  },

  loginWithCode(code: any, next: string) {
    const requestUrl = this.loginURL + `/code/${code}`;

    const options: NetworkRequestOptions = {
      uiOptions: {
        handleUnauthorisedResponse: false,
        showLoading: false
      }
    };

    return courier.post(requestUrl, options);
  },

  userAdditionalDetails(params: {}) {
    const requestUrl = `${this.authURL}/user-additional-details`;

    const options: NetworkRequestOptions = {
      uiOptions: {
        ...BACKGROUND_NETWORK_REQ_UI_OPTIONS,
        handleUnauthorisedResponse: false
      }
    };

    return courier.post(requestUrl, options, params);
  },

  getOAuthAccountFromMarketPlaceAuthCode(provider: string, authCode: string) {
    return courier.post(
      `${ this.oAuthURL }/token-marketplace/${provider}?auth_code=${authCode}`,
      BACKGROUND_NETWORK_REQ_OPTIONS
    );
  }
};

export default AuthAPI;
