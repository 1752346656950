import { Subject } from 'rxjs';


export class TargetEditService {
  private _targetEditSuccessful$ = new Subject<void>();

  constructor() {
  }

  targetEditSuccessful$ = this._targetEditSuccessful$.asObservable();

  updateTargetEditSuccessful() {
    this._targetEditSuccessful$.next();
  }
}
