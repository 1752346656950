import axios from 'axios';
import { SESSION_KEY } from '../constants';
import { cookieService } from './cookie.service';

axios.interceptors.request.use(config => {
  if (config.headers.get('Anonymous') === 'True') {
    config.headers.delete('Anonymous');
    return config;
  }

  config.headers.set('Content-Type', 'application/json');

  const token = cookieService.get('ht');
  const sessionId = localStorage.getItem(SESSION_KEY);

  if (token || sessionId) {
    config.headers.set('Authorization', `Bearer ${sessionId || token}`);
  }

  return config;
});

axios.interceptors.response.use(response => {
  const newSessionId = (response.data?.data as { session_token: string })?.session_token;

  if (newSessionId) {
    localStorage.setItem(SESSION_KEY, newSessionId);
  }

  return response;
});
