import { NodeTypeConfigHelper } from '../../../../../components/Node/node-config-helper';

export class MixpanelConfigHelper implements NodeTypeConfigHelper {
  getConfigFromRawData(rawData) {
    return {
      projectId: rawData.project_id,
      projectToken: rawData.project_token,
      serviceAccountUsername: rawData.service_account_username,
      serviceAccountSecret: rawData.service_account_secret,
      region: rawData.project_region
    };
  }

  getDefaultConfig() {
    return {};
  }

  buildConnectionParams(data) {
    return {
      project_id: data.projectId,
      project_token: data.projectToken,
      service_account_username: data.serviceAccountUsername,
      service_account_secret: data.serviceAccountSecret,
      project_region:  data.region
    };
  }
}
