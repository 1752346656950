import { SourceTypeConfigHelper } from '../../../../../components/Node/source-settings-form/config-helper';
import PipelinesAPI from '../../../PipelinesAPI';

export class GooglePlayConfigHelper implements SourceTypeConfigHelper {
  getConfigFromRawData(rawData: any, metaData: any) {
    const includedReports = metaData.flattenedReportsList.filter(
      (obj: { type: string; dimension: string }) =>
        (rawData.included_reports || []).findIndex(
          elem => obj.type === elem.type && (!obj.dimension || obj.dimension === elem.dimension)
        ) > -1
    );

    return {
      bucket: rawData.gcs_bucket,
      oauthTokenId: rawData.oauth_token_id,
      authorisedAccount: rawData.authenticated_user,
      reports: includedReports
    };
  }

  getDefaultConfig(metaData: any) {
    return {
      reports: metaData.flattenedReportsList
    };
  }

  buildConnectionParams(data: any) {
    const params: any = {
      gcs_bucket: data.bucket,
      included_reports: (data.reports || []).map(({ type, dimension }) => {
        if (dimension) {
          return {
            type,
            dimension
          };
        }
        return {
          type
        };
      })
    };

    return params;
  }

  staticDependenciesResolver() {
    return PipelinesAPI.getGooglePlayReportsList().then(res => {
      let { reports } = res.data;
      const flattenedReportsList = [];
      reports = Object.keys(reports).forEach((report: any) =>
        reports[report].forEach((child: any) =>
          flattenedReportsList.push({
            ...child,
            category: report,
            name: child.readable_name,
            id: child.readable_name
          })
        )
      );
      return {
        flattenedReportsList
      };
    });
  }
}
