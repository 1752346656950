export enum SQL_SOURCE {
  // sql sources
  MYSQL = 'MYSQL',
  MARIA_DB = 'MARIA_DB',
  AURORA = 'AURORA',
  AWS_RDS_MYSQL = 'AWS_RDS_MYSQL',
  GCP_MYSQL = 'GCP_MYSQL',
  AZURE_MYSQL = 'AZURE_MYSQL',
  ORACLE_MYSQL = 'ORACLE_MYSQL',
  TOKU_DB_MYSQL = 'TOKU_DB_MYSQL',
  AWS_RDS_MARIA_DB = 'AWS_RDS_MARIA_DB',
  AZURE_MARIA_DB = 'AZURE_MARIA_DB',
  PRESTASHOP_VIA_MYSQL = 'PRESTASHOP_VIA_MYSQL',
  MAGENTO_VIA_MYSQL = 'MAGENTO_VIA_MYSQL',
  WOOCOMMERCE_VIA_MYSQL = 'WOOCOMMERCE_VIA_MYSQL',
  WORDPRESS_VIA_MYSQL = 'WORDPRESS_VIA_MYSQL',
  REDSHIFT = 'REDSHIFT',

  // oracle sources
  ORACLE = 'ORACLE',
  AWS_RDS_ORACLE = 'AWS_RDS_ORACLE',

  // postgres sources
  POSTGRES = 'POSTGRES',
  AWS_RDS_POSTGRES = 'AWS_RDS_POSTGRES',
  AWS_AURORA_POSTGRES = 'AWS_AURORA_POSTGRES',
  GCP_POSTGRES = 'GCP_POSTGRES',
  AZURE_POSTGRES = 'AZURE_POSTGRES',
  SF_HEROKU_POSTGRES = 'SF_HEROKU_POSTGRES',

  // sql server sources
  MS_SQL = 'MS_SQL',
  AZURE_SQL = 'AZURE_SQL',
  GCP_MS_SQL = 'GCP_MS_SQL',
  AWS_RDS_MSSQL = 'AWS_RDS_MSSQL'
}

export enum SqlFormFields {
  HOST = 'host',
  USER = 'user',
  PORT = 'port',
  DATABASE_NAME = 'databaseName',
  SQL_SCHEMA = 'sqlSchema',
  DATABASE_NAMES = 'databaseNames',
  SCHEMA_NAMES = 'schemaNames',
  LOAD_ALL_DB = 'loadAllDb',
  LOAD_ALL_SCHEMAS = 'loadAllSchemas',
  MERGE_TABLES = 'mergeTables',
  PASSWORD = 'password',
  SSH_IP = 'sshIp',
  SSH_PORT = 'sshPort',
  SSH_USER = 'sshUser',
  SCHEMA_NAME = 'schemaName',
  USE_SSL = 'useSSL',
  USE_SSH = 'useSSH',
  SSL_CA = 'sslCA',
  SSL_CLIENT_KEY = 'sslClientKey',
  SSL_CLIENT_CERT = 'sslClientCert',
  RENDER_OLD_DB_NAME_FIELD = 'renderOldDbNameField',
  JOB_MODE = 'jobMode',
  IMPORT_HISTORICAL_DATA = 'importHistoricalData',
  INCLUDE_NEW_TABLES = 'includeNewTables',
  DATABASE_NAME_ERROR = 'databaseNameError',
  SCHEMA_NAME_ERROR = 'schemaNameError',
  POLL_INTERVAL = 'pollInterval',
  QUERY_FETCH_SIZE = 'queryFetchSize',
  LONG_TRANSACTION_WINDOW = 'longTransactionWindow',
  ONLINE_CATALOG = 'onlineCatalog',
  ARCHIVE_LOG_ONLY = 'archiveLogOnly',
  ORACLE_POLL_TASK_V2_FLAG = 'oraclePollTaskV2Flag',
  AZURE_SERVER_FLEXI = 'azureServerFlexi',
  APPLICATION_INTENT_READ_ONLY = 'applicationIntentReadOnly'
}

export enum SqlVariations {
  AMAZON_RDS = 'AMAZON_RDS',
  GOOGLE_CLOUD = 'GOOGLE_CLOUD',
  AURORA = 'AURORA',
  HEROKU = 'HEROKU',
  ORACLE = 'ORACLE',
  AZURE = 'AZURE',
  TOKUDB = 'TOKUDB'
}
