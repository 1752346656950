import { execIfDefined } from '../../../legacy-utils/exec-if-defined';
import { UITracker } from '../models/tracking';
import { User } from '../models/user';

declare var _hsq: any;

export class HubspotService implements UITracker {
  init(trackingConfig) {
    execIfDefined('_hsq', () => {
      _hsq.push(['doNotTrack', { track: true }]);
    });
  }

  identifyUser(user: User) {
    execIfDefined('_hsq', () => {
      _hsq.push(['identify', {
        email: user.email,
        'userId': user.id,
        'env': user.clusterId,
        'company': user.teamName,
        'companyId': user.teamId,
        'name': user.name,
        'createdTs': user.createdTs
      }]);
    });
  }

  updateIdentity(user: User) {
    this.identifyUser(user);
  }

  updateUserProperties(user, data: any) {
    execIfDefined('_hsq', () => {
      _hsq.push(['identify', {
        email: user.email,
        ...data
      }]);
    });
  }

  reset() {
    document.cookie = 'hubspotutk=;Path=/;Domain=.your_domain.com;expires=Thu, 01 Jan 1970 0';
  }

  stop() {
    execIfDefined('_hsq', () => {
      _hsq.push(['doNotTrack']);
    });
  }
}
