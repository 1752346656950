import React, { useState } from 'react';
import useInterval from '../../../hooks/useInterval';
import { getSecondsUntilUpdate } from '../../../utils/dayjsUtils';
import dayjs from 'dayjs';

export interface HdTimeAgoProps {
  timestamp: number | Date;
}

export default function HdTimeAgo({ timestamp }: HdTimeAgoProps) {
  const [displayTime, setDisplayTime] = useState(dayjs(timestamp).fromNow());

  const dayjsInstance = dayjs(timestamp);
  const timeToUpdate = getSecondsUntilUpdate(dayjsInstance) * 1000;

  const getTimeAgo = () => {
    setDisplayTime(dayjs(timestamp).fromNow());
  };

  useInterval(getTimeAgo, timeToUpdate);

  return <>{displayTime}</>;
}
