import { AppConfig } from '../app.config';
import { BACKGROUND_NETWORK_REQ_OPTIONS } from '../constants';
import { RxRequestService } from './rx-request.service';


export class RxTaskService {
  static deps = [
    AppConfig,
    RxRequestService
  ];

  constructor(
    private _appConfig: AppConfig,
    private _rxRequestService: RxRequestService
  ) {
  }

  getIntegrationUrl(id: any): string {
    return `${ this._appConfig.getIntegrationsURL() }/${ id }`;
  }

  getTasksLoadProgress(pipelineId: number) {
    const requestUrl = `${ this.getIntegrationUrl(pipelineId) }/task-category-progress`;

    return this._rxRequestService.get(requestUrl, BACKGROUND_NETWORK_REQ_OPTIONS);
  }

  getHistoricalLoadProgress(pipelineId: number) {
    const requestUrl = `${ this.getIntegrationUrl(pipelineId) }/historical-load-status`;

    return this._rxRequestService.get(requestUrl, BACKGROUND_NETWORK_REQ_OPTIONS);
  }
}
