import { execIfDefined } from '../../../legacy-utils/exec-if-defined';
import { UITracker } from '../models/tracking';
import { User } from '../models/user';

declare var mixpanel: any;

export class MixpanelService implements UITracker {
  init(trackingConfig) {
    execIfDefined('mixpanel', () => {
      if (mixpanel.has_opted_out_tracking()) {
        mixpanel.opt_in_tracking();
      }
    });
  }

  identifyUser(user: User) {
    execIfDefined('mixpanel', () => {
      mixpanel.people.set({
        'User ID': user.id,
        'Company': user.teamName,
        'Company ID': user.teamId,
        'Env': user.clusterId,
        '$email': user.email,
        '$name': user.name,
        '$created': user.createdTs
      });

      mixpanel.identify(user.email);
    });
  }

  updateIdentity(data: User) {
    this.identifyUser(data);
  }

  updateUserProperties(user, data: any) {
    execIfDefined('mixpanel', () => {
      mixpanel.people.set(data);
      mixpanel.identify(user.email);
    });
  }

  reset() {
    execIfDefined('mixpanel', () => {
      mixpanel.reset();
    });
  }

  stop() {
    execIfDefined('mixpanel', () => {
      mixpanel.opt_out_tracking();
    });
  }
}
