import { useHistory } from '../../hooks/useHistoryCompat/useHistoryCompat';
import { RestrictedTokenType } from './models';

export function useHandleAuthResponse() {
  const history = useHistory();
  const handleAuthResponse = (res: any, next: string) => {
    const restrictedTokenType: RestrictedTokenType = res.data.restricted_token_type;
    const redirectQueryParams = new URLSearchParams({ next });

    if (restrictedTokenType === RestrictedTokenType.SETUP_TFA) {
      history.replace({
        pathname: '/tfa/qr',
        search: redirectQueryParams.toString()
      });
      return;
    }

    if (restrictedTokenType === RestrictedTokenType.LOGIN_WITH_OTP) {
      history.replace({
        pathname: '/tfa/otp',
        search: redirectQueryParams.toString()
      });
      return;
    }

    if (restrictedTokenType === RestrictedTokenType.HEVO_LOGIN) {
      history.replace({
        pathname: '/emp-login-ac-select',
        search: redirectQueryParams.toString()
      });
      return;
    }

    if (restrictedTokenType === RestrictedTokenType.SAML_LOGIN) {
      const { email } = res.data;

      redirectQueryParams.set('email', email);

      history.replace({
        pathname: '/saml-login',
        search: redirectQueryParams.toString()
      });

      return;
    }

    navigateToDashboard(next);
  };

  const navigateToDashboard = (nextUrl: string) => {
    if (nextUrl) {
      history.replace(nextUrl);
    } else {
      history.replace('/');
    }
  };

  return handleAuthResponse;
}
