import React, { useMemo, useRef, useState } from 'react';
import clsx from 'clsx';
import { FormikProps } from 'formik';
import styles from '../styles.module.scss';
import { HdButton } from '../../../components/UIElements';
import Scheduler from '../index';
import { HevoEntity } from '../../core/models/hevo-entity';
import { SchedulerOptions } from '../SchedulerOptions/schedulerOptions';
import useScheduler from '../useScheduler';
import { getPolicyMessage, getPolicyRawData } from '../utils';
import { ACTIVATION_POLICY_MESSAGE_PREFIX } from '../ExecutionPolicyDialog/constants';
import ExecutionPolicyMessage from '../ExecutionPolicyMessage';
import { TRACKER_ACTIVATION_SYNC_SCHEDULE_SUBMIT } from '../../activate/tracking';
import ActivationAPI from '../../activate/ActivationsAPI';
import useAnalyticsTracker from '../../../hooks/useAnalyticsTracker';
import { getDataIdGenerator } from '../../../utils/generateDataId';
import { SchedulerType } from '../models';
import { SchedulerHeader } from '../components/SchedulerHeader';
import { Activation } from '../../activate/models/activation';

export default function ActivationExecutionPolicyDialog({
  onClose,
  entityDetails
}: { onClose: Function, entityDetails: Activation }) {
  const analyticsTracker = useAnalyticsTracker();
  const formikRef = useRef<FormikProps<{}>>();
  const [savingSchedule, setSavingSchedule] = useState(false);
  const dataIdGenerator = getDataIdGenerator('ActivationExecutionPolicyDialog');

  const {
    selectedOption,
    policyObj,
    setPolicyObj,
    isCustom,
    handleOptionSelection
  } = useScheduler({
    policy: entityDetails.executionPolicy,
    entity: HevoEntity.ACTIVATION.value,
    schedulerType: SchedulerType.FrequencyType,
    formikRef
  });

  const handleSubmit = () => {
    setSavingSchedule(true);

    ActivationAPI.updateActivation(
      entityDetails.id,
      {
        execution_policy: getPolicyRawData(policyObj)
      },
      null,
      {
        showLoading: false,
        showSuccessMsg: false
      }
    ).then(() => {
      onClose(true);

      analyticsTracker.eventTrack({
        action: TRACKER_ACTIVATION_SYNC_SCHEDULE_SUBMIT,
        properties: {
          executionPolicy: getPolicyRawData(policyObj)
        }
      });
    }).finally(() => {
      setSavingSchedule(false);
    });
  };

  const policyMessage = useMemo(() => getPolicyMessage(policyObj), [policyObj]);

  return (
    <div className={clsx('dialog-content', styles.dialogContent)}>
      <SchedulerHeader
        title='Change Activation Schedule'
        subTitle='Schedule this Activation to run at a fixed frequency. All Activation Jobs will
          run at the same schedule as configured below.'
        dataIdGenerator={dataIdGenerator}
        onClose={onClose}
      />

      <div className={clsx('dialog-body', styles.dialogBody)}>
        <div>
          <div className='text-body-2 mb-6'>
            Ingests Every
          </div>

          <SchedulerOptions
            entity={HevoEntity.ACTIVATION.value}
            options={policyObj.displayedFrequencies}
            onScheduleOptionSelected={handleOptionSelection}
            selectedOption={selectedOption}
          />

          <Scheduler
            schedulerType={SchedulerType.FrequencyType}
            entity={HevoEntity.ACTIVATION.value}
            policyObj={policyObj}
            setPolicyObj={setPolicyObj}
            ref={formikRef}
            handleSubmit={handleSubmit}
            isCustom={isCustom}
          />

          {policyMessage ? (
            <ExecutionPolicyMessage
              className='mb-5 mt-7'
              policyMessage={policyMessage}
              messagePrefix={ACTIVATION_POLICY_MESSAGE_PREFIX}
            />
          ) : null}
        </div>
      </div>

      <div className='dialog-footer'>
        <HdButton
          dataId={dataIdGenerator('cancel')}
          variation='outline'
          disabled={savingSchedule}
          onClick={() => onClose()}
        >
          Cancel
        </HdButton>

        <HdButton
          dataId={dataIdGenerator('submit')}
          disabled={savingSchedule}
          showProgress={savingSchedule}
          type='submit'
          onClick={() => formikRef.current.submitForm()}
        >
          Schedule
        </HdButton>
      </div>
    </div>
  );
}
