import { Observable, Subject } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { wrapIntoObservable } from '../../../components/wizard/wizard-native/utils';
import { getConfigDataFromHistoricalOption } from '../../../components/Node/nodes-settings/historical-load-option';
import { canSkipNewTables } from '../../../components/Node/nodes-settings/utils';
import { SourceConfigHelperService } from '../../../components/Node/source-settings-form/source-config-helper.service';
import { ConnectorAuthTypeEnum } from '../../../components/Node/source-type/auth-type';
import { JobMode, JobModeEnum } from '../../../components/Node/source-type/job-mode';
import { SOURCE_TYPES } from '../../../components/Node/source-type/source-type';
import { getDerivedSourceTypeFromSourceConfig } from '../../../components/Node/source-type/utils';

import { StorageKey } from '../../pipeline/models/storage-key';
import { AppConfig } from '../app.config';
import {
  BACKGROUND_NETWORK_REQ_OPTIONS,
  BACKGROUND_NETWORK_REQ_UI_OPTION_HANDLE_TIMEOUT,
  BACKGROUND_NETWORK_REQ_UI_OPTIONS
} from '../constants';
import { HevoEntity } from '../models/hevo-entity';
import { HTTPRequestMethodEnum, NetworkRequestOptions } from '../models/request';
import { createHttpParams } from '../../../legacy-utils/request';
import { RecentEntitiesService } from './recent-entities.service';
import { RxRequestService } from './rx-request.service';
import { SettingsStorageService } from './settings-storage.service';
import { SSHService } from './ssh.service';
import { TeamSettingsService } from './team-settings.service';


export class SourceService {
  static deps = [
    AppConfig,
    RxRequestService,
    RecentEntitiesService,
    SSHService,
    SourceConfigHelperService,
    SettingsStorageService,
    TeamSettingsService
  ];

  constructor(
    private _appConfig: AppConfig,
    private _rxRequestService: RxRequestService,
    private _recentEntitiesService: RecentEntitiesService,
    private _sshService: SSHService,
    private _sourceConfigHelperService: SourceConfigHelperService,
    private _settingsStorageService: SettingsStorageService,
    protected _teamSettingsService: TeamSettingsService
  ) {
  }

  draftSourcesURL = this._appConfig.getDraftSourcesURL();
  sourcesURL = this._appConfig.getSourcesURL();
  integrationsURL = this._appConfig.getIntegrationsURL();
  spikeAlertURL = this._appConfig.getSpikeAlertURL();

  sourceListChangeObservable = new Subject<number>();
  sourceEditObservable = new Subject<any>();

  getIntegrationURL(id: any): string {
    return `${ this.integrationsURL }/${ id }`;
  }

  getSourceTypes(showLoading = true) {
    const requestUrl = this.sourcesURL + '/get-types';

    const options: NetworkRequestOptions = {
      uiOptions: {
        showLoading: showLoading
      }
    };

    return this._rxRequestService.get(requestUrl, options);
  }

  getSourceList() {
    const options: NetworkRequestOptions = {
      uiOptions: {
        showLoading: false
      }
    };

    return this._rxRequestService.get(this.sourcesURL, options);
  }

  getSampleSettings() {
    const requestUrl = this.sourcesURL + '/samples';

    const options: NetworkRequestOptions = {
      uiOptions: {
        showLoading: false
      }
    };

    return this._rxRequestService.get(requestUrl, options);
  }

  testConnection(data: any, sourceTypeIdentifier: string) {
    const options: NetworkRequestOptions = {
      uiOptions: {...BACKGROUND_NETWORK_REQ_UI_OPTIONS, ...BACKGROUND_NETWORK_REQ_UI_OPTION_HANDLE_TIMEOUT},
      networkOptions: {
        params: createHttpParams({
          integration_id: data.id,
          draft_source_id: this.getDraftDataIdFromStorage()
        })
      }
    };

    return this.buildConnectionParams(data, sourceTypeIdentifier).pipe(
      switchMap((params: any) => {
        const requestUrl = `${ this.sourcesURL }/${ sourceTypeIdentifier }/test`;

        return this._rxRequestService.post(requestUrl, options, params);
      })
    );
  }

  testConnectionDiagnose(data: any, sourceTypeIdentifier: string) {
    const options: NetworkRequestOptions = {
      uiOptions: BACKGROUND_NETWORK_REQ_UI_OPTIONS,
      networkOptions: {
        params: createHttpParams({
          integration_id: data.id,
          draft_source_id: this.getDraftDataIdFromStorage()
        })
      }
    };

    return this.buildConnectionParams(data, sourceTypeIdentifier).pipe(
      switchMap((params: any) => {
        const requestUrl = `${ this.sourcesURL }/${ sourceTypeIdentifier }/test/job`;

        return this._rxRequestService.post(requestUrl, options, params);
      })
    );
  }


  getIntegration(id: any, showLoading = false) {
    const options: NetworkRequestOptions = {
      uiOptions: {
        showLoading
      }
    };

    return this._rxRequestService.get(this.getIntegrationURL(id), options);
  }

  getIntegrationBySeqId(seqId: any, showLoading = false) {
    const requestUrl = this.integrationsURL + `/by-seq-id/${ seqId }`;

    const options: NetworkRequestOptions = {
      uiOptions: {
        showErrorMsg: false,
        showLoading
      }
    };

    return this._rxRequestService.get(requestUrl, options);
  }

  getIntegrationDisplayDetails(id: any) {
    const requestUrl: string = this.getIntegrationURL(id) + '/display-details';

    return this._rxRequestService.get(requestUrl, BACKGROUND_NETWORK_REQ_OPTIONS).pipe(
      map((res) => {
        res.data.config = this._sshService.filterSSHDisplayConfig(res.data.config);
        return res;
      })
    );
  }

  addIntegration(data: any, sourceTypeIdentifier: string) {
    return this.getSourceParams(data, sourceTypeIdentifier).pipe(
      switchMap((params: any) => {
        const options: NetworkRequestOptions = {
          uiOptions: {
            showErrorMsg: false,
            showLoading: false
          },
          networkOptions: {
            params: createHttpParams({
              draft_source_id: this.getDraftDataIdFromStorage()
            })
          }
        };

        params['source_type'] = getDerivedSourceTypeFromSourceConfig(sourceTypeIdentifier, params['config']);

        return this._rxRequestService.post(this.integrationsURL, options, params).pipe(
          tap((res) => {
            this._recentEntitiesService.setRecent(HevoEntity.PIPELINE.value, res.data);
          })
        );
      })
    );
  }

  validateIntegration(data: any, sourceTypeIdentifier: string, skipSourceTest?: boolean) {
    return this.getSourceParams(data, sourceTypeIdentifier).pipe(
      switchMap((params: any) => {
        const requestUrl = this.integrationsURL + '/validate-source-settings';

        const options: NetworkRequestOptions = {
          uiOptions: {
            showErrorMsg: false,
            showLoading: false,
            ...BACKGROUND_NETWORK_REQ_UI_OPTION_HANDLE_TIMEOUT
          },
          networkOptions: {
            params: createHttpParams({
              draft_source_id: this.getDraftDataIdFromStorage(),
              // passing param to api only if skipSourceTest is true
              skip_source_test: !skipSourceTest ? undefined : skipSourceTest
            })
          }
        };

        return this._rxRequestService.post(requestUrl, options, params);
      })
    );
  }

  editIntegration(data: any, sourceTypeIdentifier: string, skipSourceTest?: boolean, skipMergeConfig?: boolean) {
    return this.getSourceParams(data, sourceTypeIdentifier).pipe(
      switchMap((params: any) => {
        const requestUrl = this.integrationsURL + `/${ data.id }/config`;

        const options: NetworkRequestOptions = {
          uiOptions: {
            showErrorMsg: false,
            showLoading: false,
            ...BACKGROUND_NETWORK_REQ_UI_OPTION_HANDLE_TIMEOUT
          },
          networkOptions: {
            params: createHttpParams({
              // passing param to api only if skipSourceTest is true
              skip_source_test: !skipSourceTest ? undefined : skipSourceTest,
              skip_merge_config: !!skipMergeConfig
            }),
          }
        };

        return this._rxRequestService.put(requestUrl, options, params);
      })
    );
  }

  getSourceParams(data: any, sourceTypeIdentifier: string) {
    return this.buildConnectionParams(data, sourceTypeIdentifier).pipe(
      map((connectionParamsData: any) => {
        const params: any = {
          name: data.name,
          source_type: sourceTypeIdentifier,
          config: Object.assign(
            {},
            connectionParamsData,
            {
              destination_prefix: data.destinationPrefix,
              s3_dest_partition_rules: data.directoryStructure
            }
          )
        };

        if (data.syncFrequencyPolicy) {
          params.config.execution_policy = data.syncFrequencyPolicy;
        } else if (data.pipelineFrequencyPolicy) {
          params.config.execution_policy = data.pipelineFrequencyPolicy;
        }

        if (data.destination) {
          params.destination_id = data.destination.id;
        }

        if (data.objectsConfig) {
          params.object_selection_criteria = data.objectsConfig;
        }

        if (typeof data.jsonStringMatchingStrategy !== 'undefined') {
          params.config.json_parsing_strategy = data.jsonStringMatchingStrategy;
        }

        if (data.autoMappingStatus) {
          params.auto_mapping_status = data.autoMappingStatus;
        }

        return params;
      })
    );
  }

  buildConnectionParams(data: any, sourceTypeIdentifier: string) {
    const sourceTypeMetaData = SOURCE_TYPES[sourceTypeIdentifier];
    const connectionParams = wrapIntoObservable(
      this._sourceConfigHelperService.getConfigHelper(
        sourceTypeIdentifier
      ).buildConnectionParams(data)
    );

    return connectionParams.pipe(
      map((res: any) => {
        if (data.jobMode) {
          this.appendJobMode(res, data.jobMode);
        }

        if (sourceTypeMetaData.requireAuth) {
          res.oauth_token_id = data.oauthTokenId;
        }

        if (sourceTypeMetaData.requireServiceAuth) {
          res.account_type = data.authType;

          if (data.authType === ConnectorAuthTypeEnum.OAUTH) {
            res.oauth_token_id = data.oauthTokenId;
          } else if (data.authType === ConnectorAuthTypeEnum.SERVICE) {
            res.security_service_account_id = data.securityServiceAccountId;
          }
        }

        if (sourceTypeMetaData.showHistoricalLoadOptions && data.historicalSyncDuration) {
          res = {
            ...res,
            ...getConfigDataFromHistoricalOption(sourceTypeIdentifier, data)
          };
        }

        if (sourceTypeMetaData.variation) {
          res.provider = sourceTypeMetaData.variation;
        }

        if (canSkipNewTables(data.jobMode, sourceTypeIdentifier)) {
          res.include_new_tables = data.includeNewTables;
        }

        return res;
      })
    );
  }

  appendJobMode(config: any, jobMode: JobMode) {
    if (jobMode.value === JobModeEnum.COLLECTION.value) {
      config.has_oplog = false;
      config.has_change_streams = false;
      return;
    }
    if (jobMode.value === JobModeEnum.OPLOG.value) {
      config.has_oplog = true;
      config.has_change_streams = false;
      return;
    }
    if (jobMode.value === JobModeEnum.CHANGE_STREAMS.value) {
      config.has_oplog = false;
      config.has_change_streams = true;
      return;
    }

    config.type = jobMode.value;
  }


  computeObjectsAndConfig(sourceTypeIdentifier: string, sourceConfig: any, jobMode: JobMode): Observable<string> {
    const options: NetworkRequestOptions = {
      uiOptions: BACKGROUND_NETWORK_REQ_UI_OPTIONS,
      networkOptions: {
        params: createHttpParams({
          draft_source_id: this.getDraftDataIdFromStorage()
        })
      }
    };

    return this.buildConnectionParams({ ...sourceConfig, jobMode }, sourceTypeIdentifier).pipe(
      switchMap((params: any) => {
        const requestUrl = this.sourcesURL + `/${ sourceTypeIdentifier }/objects-and-config`;


        return this._rxRequestService.post(requestUrl, options, params).pipe(
          map((res) => res.data.session_id)
        );
      })
    );
  }

  getJobConfigStaticData(sourceTypeIdentifier: string, sourceConfig: any, jobMode: JobMode) {
    const options: NetworkRequestOptions = {
      uiOptions: BACKGROUND_NETWORK_REQ_UI_OPTIONS,
      networkOptions: {
        params: createHttpParams({
          draft_source_id: this.getDraftDataIdFromStorage()
        })
      }
    };

    return this.buildConnectionParams({ ...sourceConfig, jobMode }, sourceTypeIdentifier).pipe(
      switchMap((params: any) => {
        const requestUrl = this.sourcesURL + `/${ sourceTypeIdentifier }/objects-and-config/static-data`;

        return this._rxRequestService.post(requestUrl, options, params);
      })
    );
  }


  getJobConfigDropdownData(
    url: string,
    method: string,
    jobConfig: any,
    sourceConfig: any
  ) {
    const requestUrl = this._appConfig.getDynamicURL() + '/' + url;

    const options: NetworkRequestOptions = {
      uiOptions: {
        showLoading: false,
        showErrorMsg: false
      }
    };

    const reqBody = {
      source_config: sourceConfig,
      job_config: jobConfig
    };

    return this._rxRequestService.sendRequest(HTTPRequestMethodEnum[method], requestUrl, reqBody, options);
  }

  onSourceListChange(id: number) {
    this.sourceListChangeObservable.next(id);
  }

  onSourceEdit(data?: any) {
    this.sourceEditObservable.next(data);
  }


  updatePipelineAutoMappingStatus(
    pipelineID: number, autoMappingStatus: string
  ) {
    const requestUrl = this.getIntegrationURL(pipelineID) + `/auto-mapping`;
    const params: any = {
      status: autoMappingStatus
    };

    return this._rxRequestService.put(requestUrl, BACKGROUND_NETWORK_REQ_OPTIONS, params);
  }

  getPipelineSetupProgress(pipelineID: number) {
    const requestUrl = this.getIntegrationURL(pipelineID) + `/progress`;

    return this._rxRequestService.get(requestUrl, BACKGROUND_NETWORK_REQ_OPTIONS);
  }

  getApiQuotaStatus(pipelineID: number) {
    const requestUrl = this.getIntegrationURL(pipelineID) + `/api-limits`;
    return this._rxRequestService.get(requestUrl, BACKGROUND_NETWORK_REQ_OPTIONS);
  }

  validateJobConfig(sourceConfig: any, sourceTypeIdentifier: string, queryData: any) {
    const requestUrl = this.integrationsURL + `/${ sourceTypeIdentifier }/validate/secondary-config`;

    const options: NetworkRequestOptions = {
      uiOptions: BACKGROUND_NETWORK_REQ_UI_OPTIONS,
      networkOptions: {
        params: createHttpParams({
          draft_source_id: this.getDraftDataIdFromStorage()
        })
      }
    };

    return this.buildConnectionParams(sourceConfig, sourceTypeIdentifier).pipe(
      switchMap((params: any) => {
        const requestParams = {
          'source_config': { ...params },
          'data': { ...queryData }
        };

        return this._rxRequestService.post(requestUrl, options, requestParams);
      })
    );
  }

  getHistoricalLoadOptions(sourceTypeIdentifier: string) {
    const requestUrl = this.integrationsURL + `/${ sourceTypeIdentifier }/historical-load-options`;

    return this._rxRequestService.get(requestUrl, BACKGROUND_NETWORK_REQ_OPTIONS).pipe(
      map((res: any) => res.data)
    );
  }

  getMongoDatabases(data: any, sourceTypeIdentifier: string) {
    const requestUrl = this.integrationsURL + `/${ sourceTypeIdentifier }/list-of-databases`;

    const options: NetworkRequestOptions = {
      uiOptions: BACKGROUND_NETWORK_REQ_UI_OPTIONS,
      networkOptions: {
        params: createHttpParams({
          integration_id: data.id,
          draft_source_id: this.getDraftDataIdFromStorage()
        })
      }
    };

    return this.buildConnectionParams(data, sourceTypeIdentifier).pipe(
      switchMap((params: any) => {
        return this._rxRequestService.post(requestUrl, options, params);
      })
    );
  }

  deleteDraftSource(id: number, showLoading = false) {
    const requestUrl = `${ this.draftSourcesURL }/${ id }`;
    const options: NetworkRequestOptions = {
      uiOptions: {
        showLoading
      }
    };

    return this._rxRequestService.delete(requestUrl, options);
  }

  getDraftDataIdFromStorage() {
    if (!!this._teamSettingsService.getTeamSettingsValue()?.['draft_pipeline_enabled']) {
      const draftData = this._settingsStorageService.getSettings(StorageKey.CREATE_DRAFT_PIPELINE_SOURCE);
      return draftData && draftData.id ? draftData.id : null;
    }
    return null;
  }

  getSpikeAlertStatus(integrationId: number) {
    const requestUrl = `${this.spikeAlertURL}/status/${integrationId}`;

    return this._rxRequestService.get(requestUrl, BACKGROUND_NETWORK_REQ_OPTIONS).pipe(
      map((res: any) => res.data)
    );
  }
}
