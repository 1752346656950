import { AnsibleBoolean } from './models/ansible-config';

declare const __H20_ENABLED_FEATURES__: {
  ALERTS: AnsibleBoolean;
  RBAC: AnsibleBoolean;
  BILLING: AnsibleBoolean;
};

export const EnvConfig = {
  getH20EnabledFeatures() {
    if (typeof __H20_ENABLED_FEATURES__ === 'undefined') {
      return {};
    }

    return __H20_ENABLED_FEATURES__;
  },

  isH20FeatureEnabled(feature: keyof typeof __H20_ENABLED_FEATURES__) {
    return this.getH20EnabledFeatures()[feature] === AnsibleBoolean.TRUE;
  }
};
