import { DestinationRecommendationsData } from './models';
import { TimeFilterOption } from '../../components/TimeFilter/interface';

export const DESTINATION_SCHEMA_PAGE_LIMIT = 10000;
export const DESTINATION_SCHEMA_POLL_INTERVAL = 60000;

export const TRIGGER_BASED_LOAD_ERROR_TOOLTIP_TITLE =
  'The last frequency change is not applied. Please try editing the frequency again';
export const CREATE_DESTINATION_PROGRESS_STORAGE_KEY = 'destination.general.create.wizard';
export const DESTINATION_STATS_DURATIONS: TimeFilterOption[] = [
  {
    name: '2h',
    value: 120
  },
  {
    name: '12h',
    value: 720
  },
  {
    name: '24h',
    value: 1440
  },
  {
    name: 'Last 2 days',
    value: 2880
  },
  {
    name: 'Last 7 days',
    value: 10080
  },
  {
    name: 'Last 15 days',
    value: 21600
  },
  {
    name: 'Last 30 days',
    value: 43200
  }
];
export const USER_SETTINGS_DESTINATION_STATS_KEY = 'statsFilter';
export const DESTINATION_STATS_DEFAULT_FILTER_DURATION = 1440;
export const DESTINATION_STATS_POLL_PERIOD = 60000;
export const USER_SETTINGS_QUERY_MANAGED_WAREHOUSE_ALERT_KEY = 'queryManagedWarehouseAlert';
export const DEFAULT_DESTINATION_RECOMMENDATION_SETTINGS: DestinationRecommendationsData = {
  hadRecommendations: false,
  hasPendingRecommendations: false,
  hadAppliedRecommendations: false
};
export const PREFERRED_DESTINATIONS_SORTED = ['REDSHIFT', 'SNOWFLAKE', 'BIGQUERY'];
export const PREFERRED_DESTINATIONS_IMAGE_IDS_FOR_INTRO_ANIM = {
  BIGQUERY: 'image_bigquery',
  SNOWFLAKE: 'image_snowflake',
  REDSHIFT: 'image_redshift'
};
export const PREFERRED_DESTINATIONS_ANIMATION_LOGOS: {
  [key: string]: {
    logoURL: string;
    darkModeLogoURL?: string;
  };
} = {
  BIGQUERY: {
    logoURL:
      'https://res.cloudinary.com/hevo/image/upload/v1675078082/dashboard/destination/skewed-logo/bigquery_odfo8m.png'
  },
  FIREBOLT: {
    logoURL:
      'https://res.cloudinary.com/hevo/image/upload/v1675078083/dashboard/destination/skewed-logo/firebolt_e1hx0a.png'
  },
  SNOWFLAKE: {
    logoURL:
      'https://res.cloudinary.com/hevo/image/upload/v1675078082/dashboard/destination/skewed-logo/snowflake_nt4clw.png'
  },
  REDSHIFT: {
    logoURL:
      'https://res.cloudinary.com/hevo/image/upload/v1675156872/dashboard/destination/skewed-logo/redshift_ljxurs.png'
  },
  DATABRICKS: {
    logoURL:
      'https://res.cloudinary.com/hevo/image/upload/v1675078082/dashboard/destination/skewed-logo/redbricks_ogvjg9.png'
  },
  MYSQL: {
    logoURL:
      'https://res.cloudinary.com/hevo/image/upload/v1675078082/dashboard/destination/skewed-logo/mysql_h40dfe.png'
  },
  AURORA: {
    logoURL:
      'https://res.cloudinary.com/hevo/image/upload/v1675078082/dashboard/destination/skewed-logo/amazon-aurora_kwpwxc.png'
  },
  POSTGRES: {
    logoURL:
      'https://res.cloudinary.com/hevo/image/upload/v1675078082/dashboard/destination/skewed-logo/postgre_fjhkqq.png'
  },
  MS_SQL: {
    logoURL:
      'https://res.cloudinary.com/hevo/image/upload/v1675078082/dashboard/destination/skewed-logo/ms-sql_a6wyec.png'
  },
  S3: {
    logoURL:
      'https://res.cloudinary.com/hevo/image/upload/v1675078082/dashboard/destination/skewed-logo/aws-s3_bixkpz.png'
  },
  AZURE_SYNAPSE: {
    logoURL:
      'https://res.cloudinary.com/hevo/image/upload/v1675156872/dashboard/destination/skewed-logo/synapse_av94rj.png'
  }
};
