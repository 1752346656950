// eslint-disable-next-line max-classes-per-file
export interface Type<T = object> extends Function {
  new (...args: any[]): T;
  deps?: any[];
}

export interface AbstractType<T> extends Function {
  prototype: T;
}

export interface TypeProvider extends Type<any> {
}

export interface ValueProvider {
  provide: any;
  useValue: any;
  multi?: boolean;
}

export interface ClassProvider {
  provide: any;
  useClass: Type<any>;
  multi?: boolean;
}

export interface ExistingProvider {
  provide: any;
  useExisting: AbstractType<any> | Type<any>;
  multi?: boolean;
}

export type Provider = TypeProvider | ValueProvider | ClassProvider | ExistingProvider;

export class InjectionToken<T = any> {
  // eslint-disable-next-line no-empty-function
  constructor(public desc: string) { }

  toString() {
    return this.desc;
  }
}

export class Injector {
  resolvedProviders = {};

  // eslint-disable-next-line no-empty-function
  constructor(public providers: Provider[], public parentInjector?: Injector) {
    this.resolvedProviders[Injector.name] = this;
  }

  getIdentifier<T = any>(Service: AbstractType<T> | Type<T> | InjectionToken<T>) {
    if (Service instanceof InjectionToken) {
      return Service.toString();
    }

    return Service.name;
  }

  get<T = any>(Service: AbstractType<T> | Type<T> | InjectionToken<T>, notFoundValue?: T): T {
    const providerIndex  = this.providers.findIndex((p) => {
      if ('provide' in p) {
        return p.provide === Service;
      }

      return p === Service;
    });

    if (providerIndex < 0) {
      if (!this.parentInjector) {
        if (notFoundValue) {
          return notFoundValue;
        }

        throw new Error('Provider not found!');
      }

      return this.parentInjector.get(Service);
    }

    const serviceProvider = this.providers[providerIndex];

    const serviceIdentifier = this.getIdentifier(Service);

    let resolvedService = this.resolvedProviders[serviceIdentifier];

    if (resolvedService) {
      return resolvedService;
    }

    if ('useValue' in serviceProvider) {
      resolvedService = serviceProvider.useValue;
      this.resolvedProviders[serviceIdentifier] = resolvedService;

      return resolvedService;
    }

    let ServiceToResolve = Service as Type<T>;

    if ('useExisting' in serviceProvider) {
      resolvedService = this.get(serviceProvider.useExisting);
      this.resolvedProviders[serviceIdentifier] = resolvedService;

      return resolvedService;
    }

    if ('useClass' in serviceProvider) {
      ServiceToResolve = serviceProvider.useClass;
    }

    if (!ServiceToResolve.deps?.length) {
      resolvedService = new ServiceToResolve();
      this.resolvedProviders[serviceIdentifier] = resolvedService;

      return resolvedService;
    }

    const resolvedDeps = ServiceToResolve.deps.map((dep) => {
      let resolvedDep = this.resolvedProviders[this.getIdentifier(dep)];

      if (!resolvedDep) {
        resolvedDep = this.get(dep);
      }

      return resolvedDep;
    });

    resolvedService = new ServiceToResolve(...resolvedDeps);
    this.resolvedProviders[serviceIdentifier] = resolvedService;

    return resolvedService;
  }
}

export class ReflectiveInjector {
  static resolveAndCreate(providers: Provider[], parent?: Injector) {
    return new Injector(providers, parent);
  }
}
