import React from 'react';
import { EntityUIState, EntityUIStateWrapper } from '../../../containers/core/models/entitiy-ui-state';
import { getArrayFromSize } from '../../../legacy-utils/array';
import { TFARecoveryCode } from '../models';
import styles from './styles.module.scss';

interface TFACodeListProps {
  uiState?: EntityUIStateWrapper;
  codes: TFARecoveryCode[];
}

function TFACodeList({ uiState, codes }: TFACodeListProps) {
  const shimmerCodes = getArrayFromSize(5);
  const loaded = uiState.state === EntityUIState.IDLE;
  const loading = uiState.state === EntityUIState.LOADING;

  return (
    <div className={styles.wrapper} data-id='recovery-codes-dialog-code-list'>
      {loaded ? (
        <div className={styles.recoveryCodes}>
          {codes.map(code => (
            <div className={styles.recoveryCodeWrapper} key={code.value}>
              <div className={`${styles.recoveryCode} ${!code.active ? styles.disabled : ''}`}>
                {code.active ? code.value : 'Used'}
              </div>
            </div>
          ))}
        </div>
      ) : null}

      {loading ? (
        <div className={styles.recoveryCodes}>
          {shimmerCodes.map(index => (
            <div className={styles.recoveryCodeWrapper} key={index}>
              <div className={styles.recoveryCode}>
                <div className='shimmer shimmer-line' />
              </div>
            </div>
          ))}
        </div>
      ) : null}
    </div>
  );
}

export default TFACodeList;
