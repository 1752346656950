import { Angulartics2 } from '@angularticsCompat';
import { useContext } from 'react';
import { uuidv4 } from '../legacy-utils/uuid';
import useService from './useService';
import { AnalyticsContext } from '../components/Analytics';

export default function useAnalyticsTracker() {
  const angulartics2 = useService(Angulartics2);

  const { sessionId, updateSessionId } = useContext(AnalyticsContext);

  return {
    eventTrack: (data: { action: string; properties?: any }) => {
      let { properties = {} } = data;
      if (sessionId) {
        properties = {
          sessionId,
          ...properties
        };
      }
      angulartics2.eventTrack.next({
        action: data.action,
        properties
      });
    },
    startSession: () => {
      updateSessionId(uuidv4());
    },
    endSession: () => {
      updateSessionId(null);
    }
  };
}
