import { BehaviorSubject } from 'rxjs';


interface SourceTypeData {
  sourceType: any;
}

export class RedirectToSourceSettingService {
  private _redirectToSourceType$ = new BehaviorSubject<SourceTypeData>(null);

  selectSourceType(sourceType$) {
    this._redirectToSourceType$.next(sourceType$);
  }

  getSourceType() {
    return this._redirectToSourceType$.getValue()?.sourceType;
  }
}
