import React, { useEffect } from 'react';
import { Box, Grid } from '@mui/material';
import { Formik, FormikHelpers, FormikProps } from 'formik';
import { Policy, SchedulerType } from './models';
import { HevoEntity } from '../core/models/hevo-entity';
import ErrorFocus from '../../utils/ErrorFocus';
import { getExecutionPolicyFormInitialValues, validationSchema } from './form-helper';
import FrequencyForm from './components/FrequencyForm';
import MultiCronForm from './components/MultiCronForm';
import CronForm from './components/CronForm';

export interface SchedulerProps {
  policyObj: Policy;
  entity: HevoEntity;
  setPolicyObj: Function;
  handleSubmit: (values: {}, formikHelpers: FormikHelpers<{}>) => void | Promise<any>;
  isCustom?: boolean;
  schedulerType?: SchedulerType;
}

const Scheduler = React.forwardRef(
  (
    {
      policyObj,
      setPolicyObj,
      entity,
      schedulerType = SchedulerType.FrequencyType,
      handleSubmit,
      isCustom
    }: SchedulerProps,
    ref: React.MutableRefObject<FormikProps<{}>>
  ) => {
    useEffect(() => {
      if (ref?.current) {
        ref.current.setValues({
          ...policyObj,
          selectedCustomOption:
            schedulerType === SchedulerType.CronType
              ? policyObj.cronType
              : policyObj.cronType?.value,
          cronTime: policyObj.cronTimeList?.length ? policyObj.cronTimeList[0] : []
        });
      }
    }, [policyObj.cronType.value, policyObj.cronTimeList?.length]);

    return (
      <Formik
        innerRef={ref}
        validationSchema={() => validationSchema(schedulerType, isCustom, policyObj)}
        initialValues={getExecutionPolicyFormInitialValues(schedulerType)}
        onSubmit={handleSubmit}
      >
        {props =>
          isCustom ? (
            <SchedulerInner
              formikProps={props}
              schedulerType={schedulerType}
              policyObj={policyObj}
              setPolicyObj={setPolicyObj}
              entity={entity}
            />
          ) : null
        }
      </Formik>
    );
  }
);

export default Scheduler;

interface SchedulerInnerProps {
  policyObj: Policy;
  entity: HevoEntity;
  setPolicyObj: Function;
  schedulerType?: SchedulerType;
  formikProps: any;
}

export function SchedulerInner({
  formikProps,
  schedulerType,
  policyObj,
  setPolicyObj,
  entity
}: SchedulerInnerProps) {
  return (
    <Box component='form' className='mt-7' onSubmit={formikProps?.handleSubmit} noValidate>
      <ErrorFocus formik={formikProps} onFormError={() => {}} />

      {schedulerType === SchedulerType.FrequencyType && (
        <Grid container spacing={2}>
          <Grid item xs={6} className='center-flex-row'>
            <FrequencyForm policyObj={policyObj} setPolicyObj={setPolicyObj} />
          </Grid>
        </Grid>
      )}

      {schedulerType === SchedulerType.CronType && (
        <CronForm policyObj={policyObj} setPolicyObj={setPolicyObj} />
      )}

      {schedulerType === SchedulerType.MultiCronType && (
        <MultiCronForm entity={entity} policyObj={policyObj} setPolicyObj={setPolicyObj} />
      )}
    </Box>
  );
}
