import { Subject } from 'rxjs';

export class TourRegisterService {

  registry = new Set<string>();
  registryUpdate$ = new Subject<{
    type: 'register' | 'deregister';
    elementId: string;
  }>();

  registryRefresh$ = new Subject<void>();

  registerElement(elementId: string) {
    this.registry.add(elementId);
    this.registryUpdate$.next({
      type: 'register',
      elementId
    });

    this.registryRefresh$.next();
  }

  refreshScreen() {
    this.registryRefresh$.next();
  }

  deregisterElement(elementId: string) {
    this.registry.delete(elementId);
    this.registryUpdate$.next({
      type: 'deregister',
      elementId
    });

    this.registryRefresh$.next();
  }

}
