import { getDaysFromTimestamp } from '../../../legacy-utils/date';

export enum TeamTrialStatus {
  SENT = 'SENT', // we have created team
  CREATED = 'CREATED', // trial has started, integration created
  EXPIRED = 'EXPIRED', // trial has ended, customer did not convert
  ENDED = 'ENDED', // its a paid customer
  DELETED = 'DELETED' // account has been deleted.
}

export class TrialDetails {
  status: TeamTrialStatus;
  trialEndTs: number;
  extensions: number;
  teamStatus: string;
  config: { [key: string]: any };

  getDaysLeft() {
    return getDaysFromTimestamp(this.trialEndTs - new Date().getTime());
  }

  isInTrial() {
    return this.status === TeamTrialStatus.SENT || this.status === TeamTrialStatus.CREATED;
  }

  isPaidUser() {
    return this.teamStatus === 'PAID';
  }
}

export function TrialDetailsFactory(rawData: any): TrialDetails {
  if (!rawData) {
    return;
  }
  const details = new TrialDetails();
  details.status = rawData.trial_status;
  details.trialEndTs = rawData.trial_end_timestamp;
  details.extensions = rawData.trial_extensions;
  details.teamStatus = rawData.status;
  details.config = rawData.config;

  return details;
}

export interface UserBlockDetails {
  blockUser: boolean;
  redirectRoute?: string;
}
