/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, {
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState
} from 'react';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import { List, AutoSizer } from 'react-virtualized';
import clsx from 'clsx';
import { EntityUIState } from '../../containers/core/models/entitiy-ui-state';
import { InclusionItem } from '../../containers/core/models/inclusion-list-v2';
import { isEqual } from '../../legacy-utils/equality';
import {
  GetList,
  ListError,
  ListNextPage,
  ListResponse,
  ResetList
} from '../pagination/list-conductor/list-actions';
import { ListConductor } from '../pagination/list-conductor/list-conductor';
import { SELECTOR_SHIMMER_DIMENSIONS } from './constants';
import { SingleLevelSelectorPageData } from './interface';
import {
  getSelectorInclusionStateFromRawData,
  getSelectorRawDataFromInclusionState,
  SelectorItemsFactory,
  SelectorItemWithListData
} from './models';
import useAbortController from '../../hooks/useAbortController';
import useDontMountAtFirst from '../../hooks/useDontMountAtFirst';
import useForceUpdate from '../../hooks/useForceUpdate';
import useInclusionList from '../../hooks/useInclusionList';
import useListConductor from '../../hooks/useListConductor';
import { getDataIdGenerator } from '../../utils/generateDataId';
import { capitalizeFirstLetter } from '../../utils/stringFormatter';
import RetryApiAlert from '../RetryApiAlert';
import ScrollToTopButton from '../ScrollToTopButton';
import SearchArea from '../SearchArea';
import { HdIconButton, HdIcon, HdCheckbox, HdLink } from '../UIElements';
import { SingleLevelSelectorProps } from './model';
import styles from './styles.module.scss';
import {
  SingleLevelSelectorItemShimmer,
  SingleLevelSelectorEmptyListError,
  SingleLevelSelectorEmptyFilteredListError,
  SingleLevelSelectorPartialFilteredList
} from './Components';
import { getSingleLevelSelectorPaginationStrategy } from './utils';

const DEBOUNCE_INTERVAL = 500;
const FETCH_LIST_INTERVAL = 1000;
const ROW_HEIGHT = 56;
const selectorShimmerDimensions = SELECTOR_SHIMMER_DIMENSIONS;

function SingleLevelSelectorWithMemoryFilter({
  dataId,
  name,
  selectableItemName,
  showSearchBar = false,
  getList,
  fetchListInBackground = false,
  canBulkSelect = true,
  requestParams,
  listUpdateCallback,
  showRefresh = true,
  collapsible = false,
  disabled = false,
  groupBy,
  pageSize = 10,
  renderSelectAll = true,
  groupSelectAll = true,
  inMemoryFilterFn,
  NoItemsFoundError,
  NoItemsMatchingFilterError,
  listInteractionCallback,
  selectedItems,
  renderListItem,
  renderHeader,
  resetList = false,
  onSearchChange,
  selectorRef,
  forceUpdateUIOnSelectionInteraction = false,
  partialFilteredListMessage = 'Please wait, we are still fetching the list.'
}: SingleLevelSelectorProps) {
  const pagination = useRef(getSingleLevelSelectorPaginationStrategy('cursor'));
  const paginationHelper = useRef(pagination.current);
  const forceUpdate = useForceUpdate();
  const [currentRenderingItems, setCurrentRenderingItems] = useState<SelectorItemWithListData[]>(
    []
  );

  const { listConductor, changes } = useListConductor({
    paginationStrategy: pagination.current.getStrategy(pageSize)
  });

  const { inclusionList, togglePageCheckedState, setCheckedState } = useInclusionList(
    'selector',
    null,
    listUpdateCallback,
    selectedItems
  );

  useImperativeHandle(selectorRef, () => ({
    refresh: () => {
      onRefresh();
    }
  }));

  const [searchFilter, setSearchFilter] = useState('');
  const [expandList, setExpandList] = useState(true);
  const [groupExpand, setGroupExpand] = useState({});
  const [fetchListNextPage, setFetchListNextPage] = useState(false);

  const filteredList = useMemo(() => {
    if (searchFilter) {
      return inMemoryFilterFn(currentRenderingItems, searchFilter);
    }
    return currentRenderingItems;
  }, [searchFilter, currentRenderingItems, inMemoryFilterFn]);

  const filteredGroup = useMemo(() => {
    if (groupBy) {
      let renderingItems = currentRenderingItems;

      if (searchFilter) {
        renderingItems = inMemoryFilterFn(currentRenderingItems, searchFilter);
      }

      const formattedGroupList = renderingItems.reduce((allGroups, item) => {
        const group = groupBy(item.data);
        // eslint-disable-next-line no-param-reassign
        allGroups[group] = allGroups[group] ?? [];
        allGroups[group].push(item);
        return allGroups;
      }, {});

      /**
       * if search filter is applied then force/default expand that particular group
       */
      if (searchFilter) {
        const [currentlyExpanded] = Object.keys(groupExpand);
        if (!formattedGroupList[currentlyExpanded]) {
          const [expandFirstSearchedGroup] = Object.keys(formattedGroupList);
          setGroupExpand({ [expandFirstSearchedGroup]: true });
        }
      }
      return formattedGroupList;
    }
    return [];
  }, [currentRenderingItems, searchFilter, groupBy]);

  const { abortController, abort } = useAbortController();
  const selectableItemNameLabel = capitalizeFirstLetter(selectableItemName);
  const listConductorMeta = listConductor.getListMeta();
  const listConductorCount = inclusionList.getIncludedItemsCount();
  const listConductorSize = inclusionList.state.size;
  const listConductorAllIncluded = inclusionList.isAllIncluded();
  const listConductorAllSelectable =
    canBulkSelect && (listConductorCount > 0 || fetchListInBackground);
  const listConductorState = listConductor.getListState().state;
  const listConductorStateError = listConductor.getListState().error;
  const listConductorRefreshing = listConductorState === EntityUIState.REFRESHING;
  const listConductorIdle = listConductorState === EntityUIState.IDLE && !fetchListNextPage;
  const listConductorErrored = listConductorState === EntityUIState.ERRORED;
  const listConductorEmpty = filteredList.length === 0 && !!searchFilter;
  const listConductorNoItem = listConductorState === EntityUIState.NEW && listConductorSize === 0;
  const listConductorCheckboxIndeterminate = !!inclusionList.pageIndeterminate;
  const listConductorCheckboxChecked = !!(
    inclusionList.pageIncluded && !inclusionList.pageIndeterminate
  );
  const listConductorRefreshingOrLoading =
    (listConductorState === EntityUIState.LOADING ||
      listConductorState === EntityUIState.LOADING_MORE ||
      listConductorState === EntityUIState.REFRESHING) &&
    !fetchListNextPage;
  const listConductorShowListItem =
    listConductorState === EntityUIState.IDLE || listConductorState === EntityUIState.LOADING_MORE;
  const showOfSelected = listConductorSize && canBulkSelect;
  const partiallyFilteredList = searchFilter && fetchListNextPage;

  useDontMountAtFirst(() => {
    getListData(requestParams, listConductor);
  }, [changes]);

  /**
   * listening change in inclusion list on selection changes
   * require for scenario where we need to manipulate the list data
   * on each selection/value, like disabling set of items
   */
  useDontMountAtFirst(() => {
    if (resetList) {
      getListData(requestParams, listConductor, true);
    }
  }, [inclusionList.getIncludedItemsCount(), resetList]);

  useEffect(() => {
    listConductor.dispatch(new GetList());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestParams]);

  /**
   * listen change in selected items from outside and
   * update the inclusion list accordingly
   * comparing new list with current before updating inclusion list state
   */
  useEffect(() => {
    const selectedItemsEntities =
      getSelectorRawDataFromInclusionState(selectedItems)?.entities || [];
    const currentItemsEntities =
      getSelectorRawDataFromInclusionState(inclusionList.state).entities || [];

    if (JSON.stringify(selectedItemsEntities) !== JSON.stringify(currentItemsEntities)) {
      const updatedListData = getSelectorInclusionStateFromRawData({
        selected: inclusionList.state.selected,
        entities: [...selectedItemsEntities]
      });
      updateInclusionListState(updatedListData);
    }
  }, [resetList]);

  useEffect(() => {
    if (fetchListNextPage) {
      setTimeout(() => {
        listConductor.dispatch(new ListNextPage());
      }, FETCH_LIST_INTERVAL);
    }
  }, [fetchListNextPage]);

  const getListData = async (
    selectorRequestParams: any,
    listConductorArg: ListConductor,
    forceResetList = false
  ) => {
    try {
      const data = await getList(selectorRequestParams, listConductorArg, {
        signal: abortController.signal
      });
      processItemsInclusionList(data, forceResetList);
      /**
       * if more data is available and fetchListInBackground prop is true
       * then set setFetchListNextPage true which will trigger the
       * next page request with set time out of 1 sec
       */
      resetFetchListNextPage();
      if (fetchListInBackground && !!data.metaData.hasMore) {
        setFetchListNextPage(true);
      }
    } catch (error) {
      listConductor.dispatch(new ListError(error));
      resetFetchListNextPage();
      forceUpdate();
    }
  };

  const resetFetchListNextPage = () => {
    if (fetchListNextPage) {
      setFetchListNextPage(false);
    }
  };

  const processItemsInclusionList = (res: SingleLevelSelectorPageData, forceResetList = false) => {
    let rawItems = [];

    if (res.items) {
      rawItems = res.items;
    }

    const selectorItems = SelectorItemsFactory(rawItems) || [];

    const inclusionItems = [];

    let itemsWithListData = selectorItems.map(item => {
      const inclusionItem = new InclusionItem(item.id);
      inclusionItems.push(inclusionItem);

      return {
        ...item,
        inclusionItem
      };
    });

    if (paginationHelper.current.shouldAppendPage(listConductor) && !forceResetList) {
      itemsWithListData = [...currentRenderingItems, ...itemsWithListData];
      inclusionList.addItems(inclusionItems);
    } else {
      inclusionList.setItems(inclusionItems);
    }

    const currentListSize = itemsWithListData.length;
    const totalCount = paginationHelper.current.getListSize(res, currentListSize);
    inclusionList.setSize(totalCount);

    listConductor.dispatch(
      new ListResponse({
        count: totalCount || currentListSize,
        ...paginationHelper.current.getListResponseData(res)
      })
    );
    setCurrentRenderingItems(itemsWithListData);
  };

  const toggleChecklist = () => {
    const disabledItems = filteredList.filter(item => item.data?.disabled === true);
    togglePageCheckedState();

    if (disabledItems.length) {
      const updatedListData = getSelectorInclusionStateFromRawData({
        selected: inclusionList.state.selected,
        entities: [...disabledItems.map(item => item.id)]
      });
      updateInclusionListState(updatedListData);
    }

    listInteractionCallback();
  };

  const handleClearAll = () => {
    setCheckedState(false);
    listInteractionCallback();
  };

  const handleSelectAll = () => {
    setCheckedState(true);
    listInteractionCallback();
  };

  const toggleCheckbox = (inclusionItem: InclusionItem) => {
    inclusionItem.toggleCheckedState();
    listInteractionCallback();

    if (forceUpdateUIOnSelectionInteraction) {
      forceUpdate();
    }
  };

  const inMemoryToggleCheckbox = (inclusionItem: InclusionItem) => {
    inclusionItem.toggleCheckedState(false);
    listInteractionCallback();

    if (forceUpdateUIOnSelectionInteraction) {
      forceUpdate();
    }
  };

  const updateInclusionListState = items => {
    inclusionList.setState(items);

    if (forceUpdateUIOnSelectionInteraction) {
      forceUpdate();
    }
  };

  const onSearch = useCallback(search => {
    setSearchFilter(search);

    if (typeof onSearchChange === 'function') {
      onSearchChange(search);
    }
  }, []);

  const onRefresh = () => {
    listConductor.dispatch(new ResetList());
  };

  const handleErrorRefreshClick = () => {
    setSearchFilter('');
    onRefresh();
  };

  const [sentryRef] = useInfiniteScroll({
    loading: listConductorRefreshingOrLoading,
    hasNextPage: !!listConductorMeta.pagination.page,
    onLoadMore: () => listConductor.dispatch(new ListNextPage()),
    disabled: listConductorErrored || fetchListInBackground, // disable sentry when auto fetch is on
    rootMargin: '0px 0px 400px 0px'
  });

  const dataIdGenerator = useCallback(getDataIdGenerator(dataId, 'single-level-selector'), [
    dataId
  ]);

  const scrollViewportId = dataIdGenerator('items-container');

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {!listConductorErrored ? (
        <div
          data-id={dataIdGenerator('')}
          className={clsx('table-container', styles.wrapper, groupBy && styles.noBorder)}
        >
          <table className='box-table'>
            <thead>
              <tr className={clsx(groupBy && styles.noBorder)}>
                <th>
                  <div className='center-flex-row'>
                    {listConductorRefreshingOrLoading ? (
                      <span className='shimmer shimmer-square mr-4' />
                    ) : null}

                    {listConductorIdle && renderSelectAll ? (
                      <HdCheckbox
                        dataId={dataIdGenerator(`root-${name}`)}
                        name={name}
                        value={`all-${selectableItemNameLabel}-selector`}
                        className={clsx(
                          'mr-4',
                          !listConductorCheckboxIndeterminate && 'hd-indeterminate-checked'
                        )}
                        checked={listConductorCheckboxChecked}
                        indeterminate={listConductorCheckboxIndeterminate}
                        onChange={toggleChecklist}
                        disabled={disabled}
                      />
                    ) : null}

                    {typeof renderHeader === 'function' ? (
                      renderHeader({ title: selectableItemNameLabel })
                    ) : (
                      <span className='mr-1 text-ellipsis'>Select {selectableItemNameLabel}</span>
                    )}

                    {listConductorIdle &&
                      (showOfSelected ? (
                        <span
                          data-id={dataIdGenerator('partial-selection-count')}
                        >{`(${listConductorCount} of ${listConductorSize})`}</span>
                      ) : (
                        <span data-id={dataIdGenerator('selection-count')}>
                          {listConductorCount
                            ? `(${listConductorCount} Selected)`
                            : inclusionList.isAllIncluded()
                            ? '(All Selected)'
                            : ''}
                        </span>
                      ))}
                  </div>

                  {listConductorAllSelectable ? (
                    <div className='ml-2 px-2 border-left'>
                      {!listConductorAllIncluded ? (
                        <HdLink
                          tag='button'
                          className='text-link'
                          onClick={handleSelectAll}
                          dataId={dataIdGenerator('select-all')}
                        >
                          Select All
                          {` ${listConductorSize || ''} ${selectableItemNameLabel}`}
                        </HdLink>
                      ) : (
                        <HdLink
                          tag='a'
                          className='text-link'
                          onClick={handleClearAll}
                          dataId={dataIdGenerator('clear-all')}
                        >
                          Clear All
                        </HdLink>
                      )}
                    </div>
                  ) : null}

                  <div className='center-flex-row justify-end flex-1'>
                    {showSearchBar && expandList ? (
                      <SearchArea
                        dataId={dataIdGenerator('')}
                        collapsible
                        autofocus
                        defaultSearch={searchFilter}
                        defaultExpanded={!!searchFilter}
                        onSearch={onSearch}
                        debounceInterval={DEBOUNCE_INTERVAL}
                        placeholder={`Search ${selectableItemNameLabel}`}
                        onChange={_ => {
                          if (!fetchListInBackground) {
                            abort();
                          }
                        }}
                      />
                    ) : null}

                    {showRefresh ? (
                      <HdIconButton
                        disabled={listConductorRefreshingOrLoading}
                        onClick={onRefresh}
                        className={`${styles.refreshButton} ml-3`}
                        dataId={dataIdGenerator('refresh')}
                      >
                        <HdIcon
                          name='refresh'
                          size={3}
                          className={clsx('refresh-icon', { refreshing: listConductorRefreshing })}
                        />
                      </HdIconButton>
                    ) : null}

                    {collapsible ? (
                      <HdIconButton
                        disabled={false}
                        className='ml-3'
                        onClick={() => setExpandList(prev => !prev)}
                        dataId={dataIdGenerator('toggle-list')}
                      >
                        <HdIcon
                          name={`${expandList ? 'dropdown-arrow' : 'right-arrow'}`}
                          size={3}
                        />
                      </HdIconButton>
                    ) : null}
                  </div>
                </th>
              </tr>
            </thead>
          </table>

          <div
            className={clsx({
              [`ml-5 mr-5 ${styles.groupListWrapper}`]: groupBy,
              'd-none': !expandList
            })}
            id={scrollViewportId}
            data-id={scrollViewportId}
          >
            <table className='box-table'>
              <tbody>
                {partiallyFilteredList ? (
                  <SingleLevelSelectorPartialFilteredList
                    dataId={dataIdGenerator('partial-filtered-list')}
                    message={partialFilteredListMessage}
                  />
                ) : null}

                {listConductorShowListItem && groupBy ? (
                  <RenderGroupComponent
                    groups={filteredGroup}
                    selectableItemNameLabel={selectableItemNameLabel}
                    groupExpand={groupExpand}
                    toggleCheckbox={inMemoryToggleCheckbox}
                    setGroupExpand={setGroupExpand}
                    renderListItem={renderListItem}
                    listInteractionCallback={listInteractionCallback}
                    groupSelectAll={groupSelectAll}
                    inclusionList={inclusionList}
                    disabled={disabled}
                    searchFilter={searchFilter}
                    dataIdGenerator={dataIdGenerator}
                    forceUpdateUIOnSelectionInteraction={forceUpdateUIOnSelectionInteraction}
                  />
                ) : null}

                {listConductorShowListItem && !groupBy && filteredList.length > 0 ? (
                  <tr>
                    <td className='p-0'>
                      <div
                        style={{ height: ROW_HEIGHT * filteredList.length }}
                        className={clsx('w-100', styles.virtualizedContainer)}
                      >
                        <AutoSizer>
                          {({ height, width }) => (
                            <List
                              className={styles.virtualizedWrapper}
                              height={height}
                              width={width}
                              rowHeight={ROW_HEIGHT}
                              overscanCount={2}
                              rowCount={filteredList.length}
                              role='listbox'
                              rowRenderer={props =>
                                rowRenderer({
                                  item: filteredList?.[props.index],
                                  style: props.style,
                                  index: props.index,
                                  key: props.key,
                                  selectableItemNameLabel,
                                  disabled,
                                  renderListItem,
                                  toggleCheckbox,
                                  dataIdGenerator
                                })
                              }
                            />
                          )}
                        </AutoSizer>
                      </div>
                    </td>
                  </tr>
                ) : null}

                <tr ref={sentryRef} data-id={dataIdGenerator('cursor-sentry-ref')} />

                {listConductorRefreshingOrLoading
                  ? selectorShimmerDimensions.map((dimension, index) => (
                      <SingleLevelSelectorItemShimmer
                        key={index}
                        dimension={dimension}
                        dataId={dataIdGenerator(`item-${index}-shimmer`)}
                      />
                    ))
                  : null}

                {listConductorEmpty ? (
                  <SingleLevelSelectorEmptyFilteredListError
                    dataId={dataIdGenerator('empty-filtered-list-error')}
                    selectableItemNameLabel={selectableItemNameLabel}
                    NoItemsMatchingFilterError={NoItemsMatchingFilterError}
                  />
                ) : null}

                {listConductorNoItem ? (
                  <SingleLevelSelectorEmptyListError
                    dataId={dataIdGenerator('empty-list-error')}
                    selectableItemNameLabel={selectableItemNameLabel}
                    NoItemsFoundError={NoItemsFoundError}
                  />
                ) : null}
              </tbody>
            </table>
          </div>

          {listConductorShowListItem ? (
            <ScrollToTopButton variant='compact' scrollViewportId={scrollViewportId} />
          ) : null}
        </div>
      ) : (
        <RetryApiAlert
          variant='compact'
          classes='my-0'
          actionHandler={handleErrorRefreshClick}
          error={listConductorStateError}
          dataId={dataIdGenerator('')}
        />
      )}
    </>
  );
}

function RenderGroupComponent({
  groups,
  selectableItemNameLabel,
  renderListItem,
  groupExpand,
  setGroupExpand,
  toggleCheckbox,
  listInteractionCallback,
  groupSelectAll,
  inclusionList,
  disabled,
  searchFilter,
  dataIdGenerator,
  forceUpdateUIOnSelectionInteraction
}) {
  const forceUpdate = useForceUpdate();

  const toggleGroupSelection = (selected, group) => {
    /**
     * on group selection, if item is not disabled
     * toggle their checkbox state
     */
    groups?.[group].map(child => {
      if (selected ? !child?.inclusionItem?.included : child?.inclusionItem?.included) {
        if (!child?.data?.disabled) {
          child.inclusionItem.toggleCheckedState(false);
        }
      }
      return child;
    });
    listInteractionCallback();

    if (forceUpdateUIOnSelectionInteraction) {
      forceUpdate();
    }
  };

  return (
    <>
      {Object.keys(groups).map((group: string) => (
        <React.Fragment key={group}>
          <GroupComponent
            group={group}
            groupChild={groups?.[group]}
            groupSelectAll={groupSelectAll}
            expand={groupExpand}
            setExpand={setGroupExpand}
            inclusionList={inclusionList}
            disabled={disabled}
            toggleGroupSelection={toggleGroupSelection}
            dataIdGenerator={dataIdGenerator}
          />

          {groupExpand[group] ? (
            <tr className={styles.noBackground}>
              <td>
                <div className={`${styles.childWrapper} table-container`}>
                  <table className='box-table'>
                    <tbody>
                      <ItemsComponent
                        classes={`${styles.noBackground} ${styles.groupItems}`}
                        items={groups[group]}
                        selectableItemNameLabel={selectableItemNameLabel}
                        renderListItem={renderListItem}
                        toggleCheckbox={toggleCheckbox}
                        disabled={disabled}
                        searchFilter={searchFilter}
                        dataIdGenerator={dataIdGenerator}
                      />
                    </tbody>
                  </table>
                </div>
              </td>
            </tr>
          ) : null}
        </React.Fragment>
      ))}
    </>
  );
}

function GroupComponent({
  group,
  groupChild,
  groupSelectAll,
  expand,
  setExpand,
  inclusionList,
  disabled,
  toggleGroupSelection,
  dataIdGenerator
}) {
  const getSelectedItems = () => (groupChild || []).filter(child => child?.inclusionItem?.included);

  const getInitialState = () => {
    const selectedChild = getSelectedItems();
    const allSelected = selectedChild.length === groupChild.length;

    if (!allSelected && selectedChild.length > 0) {
      return 'indeterminate';
    }
    return allSelected;
  };

  const [groupSelected, setGroupSelected] = useState<true | false | 'indeterminate'>(
    getInitialState()
  );

  useDontMountAtFirst(() => {
    setGroupSelected(getInitialState());
  }, [inclusionList.getIncludedItemsCount(), groupChild]);

  return (
    <tr
      key={group}
      className={`${styles.noBackground} ${styles.groupHeader} ${
        expand[group] ? styles.expanded : ''
      }`}
      data-id={dataIdGenerator(`${group}-group-header`)}
    >
      <td>
        <div className='center-flex-row'>
          <HdIconButton
            disabled={false}
            className='mr-3'
            onClick={() => setExpand({ [group]: !expand[group] })}
            dataId={dataIdGenerator(`group-${group}-toggle`)}
          >
            <HdIcon name={expand[group] ? 'dropdown-arrow' : 'right-arrow'} />
          </HdIconButton>

          {groupSelectAll ? (
            <HdCheckbox
              value={`${group}-selector`}
              className='mr-4'
              checked={!!groupSelected}
              indeterminate={groupSelected === 'indeterminate'}
              onChange={selected => toggleGroupSelection(selected, group)}
              disabled={disabled}
              dataId={dataIdGenerator(`group-${group}`)}
            />
          ) : null}

          <span className='mr-1 text-ellipsis text-secondary'>
            {group} ({getSelectedItems().length} of {groupChild.length})
          </span>
        </div>
      </td>
    </tr>
  );
}

/**
 * using usememo to make sure widget doesn't rerender on item change
 * or when user toggles the checkbox, else it scrolls down to the top of the widget
 */
const ItemsComponent = ({
  classes,
  items,
  selectableItemNameLabel,
  renderListItem,
  disabled,
  toggleCheckbox,
  searchFilter,
  dataIdGenerator
}) =>
  useMemo(
    () =>
      items.map((item: SelectorItemWithListData) => (
        <tr key={item.id || item.name} className={classes}>
          <td>
            {rowRenderer({
              item,
              key: item.id || item.name,
              selectableItemNameLabel,
              disabled,
              toggleCheckbox,
              dataIdGenerator,
              renderListItem
            })}
          </td>
        </tr>
      )),
    [items, toggleCheckbox, searchFilter]
  );

const rowRenderer = ({
  item,
  style = {},
  index = 0,
  key,
  selectableItemNameLabel,
  disabled,
  toggleCheckbox,
  dataIdGenerator,
  renderListItem
}) => (
  <div
    key={key}
    style={style}
    className={`center-flex-row ${styles.virtualizedRow} ${index % 2 ? styles.odd : ''}`}
  >
    <HdCheckbox
      value={`${selectableItemNameLabel}-selector`}
      className='mr-4'
      checked={item.inclusionItem.included}
      disabled={item.data?.disabled || disabled}
      onChange={() => toggleCheckbox(item.inclusionItem)}
      dataId={dataIdGenerator(`item-${item.id}`)}
    />

    {typeof renderListItem === 'function' ? (
      renderListItem({ item })
    ) : (
      <>
        <span className='mr-1 text-ellipsis'>{item.name}</span>

        <span className='text-secondary mr-2'> #{item.id} </span>
      </>
    )}
  </div>
);

export const singleLevelSelectorWithMemoryFilterMemoCompare = (
  prevProps: SingleLevelSelectorProps,
  nextProps: SingleLevelSelectorProps
) => {
  let remainingPreviousProps: Partial<SingleLevelSelectorProps>;
  let remainingNextProps: Partial<SingleLevelSelectorProps>;

  if (prevProps) {
    const { selectedItems, getList, renderListItem, ...restPrevProps } = prevProps;
    remainingPreviousProps = restPrevProps;
  }

  if (nextProps) {
    const { selectedItems, getList, renderListItem, ...restNextProps } = nextProps;
    remainingNextProps = restNextProps;
  }

  return isEqual(remainingPreviousProps, remainingNextProps);
};

export default React.memo(
  SingleLevelSelectorWithMemoryFilter,
  singleLevelSelectorWithMemoryFilterMemoCompare
);
