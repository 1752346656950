import { AppConfig } from '../app.config';
import { BACKGROUND_NETWORK_REQ_OPTIONS, BACKGROUND_NETWORK_REQ_UI_OPTIONS } from '../constants';
import { NetworkRequestOptions } from '../models/request';
import { createHttpParams } from '../../../legacy-utils/request';
import { RxRequestService } from './rx-request.service';


export class PaymentService {
  static deps = [
    AppConfig,
    RxRequestService
  ];

  constructor(
    private _appConfig: AppConfig,
    private _rxRequestService: RxRequestService
  ) {
  }

  paymentUrl = this._appConfig.getIronBankURL();

  plansUrl = `${ this.paymentUrl }/plans`;
  chatSupportUrl = `${ this.paymentUrl }/chat-support`;
  pendingInvoicesUrl = `${ this.paymentUrl }/pending-invoices`;
  overageUrl = `${ this.paymentUrl }/upcoming-invoice-details`;
  paidSourceUrl = `${ this.paymentUrl }/paid-source`;

  getCurrentPlan() {
    const options: NetworkRequestOptions = {
      networkOptions: {
        params: createHttpParams({
          all_plans: false
        })
      },
      uiOptions: BACKGROUND_NETWORK_REQ_UI_OPTIONS
    };

    return this._rxRequestService.get(this.plansUrl, options);
  }

  getChatSupportDetails() {
    return this._rxRequestService.get(this.chatSupportUrl, BACKGROUND_NETWORK_REQ_OPTIONS);
  }

  paidSourceAttemptNotification(sourceType: string) {
    const params = {
      source_type: sourceType
    };

    return this._rxRequestService.post(this.paidSourceUrl, BACKGROUND_NETWORK_REQ_OPTIONS, params);
  }

  getPendingInvoicesDetails() {
    return this._rxRequestService.get(this.pendingInvoicesUrl, BACKGROUND_NETWORK_REQ_OPTIONS);
  }

  getOverageDetails() {
    return this._rxRequestService.get(this.overageUrl, BACKGROUND_NETWORK_REQ_OPTIONS);
  }

  getUsageAlert() {
    const requestUrl = this.paymentUrl + '/usage-alert';

    return this._rxRequestService.get(requestUrl, BACKGROUND_NETWORK_REQ_OPTIONS);
  }
}
