import { ListActionType } from './list-actions';
import { PaginationStrategy, Params } from './pagination-strategy';
import { EntityUIState } from '../../../containers/core/models/entitiy-ui-state';

export interface CursorPaginationData {
  page: string; // Next page token
  limit: number;
  total?: number;
}

export class CursorPaginationStrategy extends PaginationStrategy<CursorPaginationData> {
  isLastPage = false;

  constructor(private _pageSize: number) {
    super();
  }

  applyAction(action: ListActionType, paginationData: CursorPaginationData, data?: any): CursorPaginationData {
    const newData: CursorPaginationData = {
      page: paginationData.page,
      total: paginationData.total,
      limit: this._pageSize
    };

    if (action === ListActionType.RESPONSE) {
      newData.page = data.page;
      newData.total = data.count;

      if (typeof data.hasMore !== 'undefined') {
        this.isLastPage = !data.hasMore;
      } else {
        this.isLastPage = !newData.page;
      }
    }

    if (action === ListActionType.UPDATE_PAGE_META) {
      newData.page = data.page;
    }

    if (action === ListActionType.UPDATE_PAGE_SIZE && data) {
      newData.limit = data.pageSize;
      this._pageSize = data.pageSize;
    }

    return newData;
  }

  getDataFromQueryParams(queryParamsPrefix: string, queryParams: Params, data: CursorPaginationData): CursorPaginationData {
    const page = queryParams[`${queryParamsPrefix}_page`];
    return {
      page: page ? page : undefined,
      limit: this._pageSize,
    };
  }

  getQueryParamsFromData(queryParamsPrefix: string, data: CursorPaginationData) {
    return {
      [`${queryParamsPrefix}_page`]: data.page
    };
  }

  getDefaultPaginationData(): CursorPaginationData {
    return {
      page: undefined,
      limit: this._pageSize,
    };
  }

  getNetworkParams(paginationData: CursorPaginationData) {
    return {
      page: paginationData.page,
      limit: paginationData.limit
    };
  }

  isFirstPage(paginationData: CursorPaginationData) {
    return !paginationData.page;
  }

  getListState(filtersLength: number, total: number) {
    if (filtersLength === 0 && total === 0 && this.isLastPage) {
      return EntityUIState.NEW;
    }

    if (total === 0 && this.isLastPage) {
      return EntityUIState.EMPTY;
    }

    return EntityUIState.IDLE;
  }
}
