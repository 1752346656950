import { SourceTypeConfigHelper } from '../../../../../components/Node/source-settings-form/config-helper';

export class PardotConfigHelper implements SourceTypeConfigHelper {
  getConfigFromRawData(rawData: any) {
    return {
      pardotBusinessUnitId: rawData.pardot_business_unit_id,
      domainName: rawData.pardot_domain_name
    };
  }

  getDefaultConfig() {
    return {
      domainName: 'pi.pardot.com'
    };
  }

  buildConnectionParams(data: any) {
    return {
      pardot_business_unit_id: data.pardotBusinessUnitId,
      pardot_domain_name: data.domainName
    };
  }
}
