import { BehaviorSubject, combineLatest, Observable, of, throwError, timer } from 'rxjs';
import { catchError, distinctUntilChanged, filter, map, share, startWith, switchMap, take, tap } from 'rxjs/operators';
import { TRIAL_PERIOD_DETAILS_POLL_INTERVAL } from '../constants';
import { TeamTrialStatus, TrialDetails, TrialDetailsFactory, UserBlockDetails } from '../models/trial-details';
import { muteFirst } from '../../../legacy-utils/rxjs-observables';
import { AuthService } from './auth.service';
import { ReactRouterService } from './react-router.service';
import { TeamService } from './team.service';
import { TrackingService } from './tracking.service';

export class TrialDetailsService {
  static deps = [
    ReactRouterService,
    AuthService,
    TrackingService,
    TeamService
  ];

  constructor(
    private _router: ReactRouterService,
    private _authService: AuthService,
    private _trackingService: TrackingService,
    private _teamService: TeamService
  ) {
  }

  private _pollInterval = TRIAL_PERIOD_DETAILS_POLL_INTERVAL;

  private _trialDetailsSubject = new BehaviorSubject<any>(null);
  private _needTrialDetailsSubject = new BehaviorSubject(true);

  private _trialPollEffect$ = this._needTrialDetailsSubject.pipe(
    switchMap(() => {
      return timer(0, this._pollInterval).pipe(
        switchMap(() => {
          return this._getLatestTrialDetails();
        }),
        catchError(() => {
          return of(null);
        })
      );
    }),
    share(),
    startWith(null)
  );

  private _clearDataEffect$ = this._authService.beforeLogoutSubject.pipe(
    tap(() => {
      this._trialDetailsSubject.next(null);
    }),
    share(),
    startWith(null)
  );

  private _trackTrialStatusEffect$ = this._trialDetailsSubject.pipe(
    filter(data => data && data.trial_status),
    map((data) => data.trial_status),
    distinctUntilChanged(),
    tap((status: TeamTrialStatus) => {
      this._trackTrialStatus(status);
    }),
    share(),
    startWith(null)
  );

  trialDetails$: Observable<TrialDetails> = muteFirst(
    combineLatest([
      this._trialPollEffect$,
      this._trackTrialStatusEffect$,
      this._clearDataEffect$
    ]),
    this._trialDetailsSubject
  ).pipe(
    filter((details) => {
      return details !== null;
    }),
    map((details: any) => {
      return TrialDetailsFactory(details);
    })
  );

  private _isTrialExpired$ = this.trialDetails$.pipe(
    map((details) => {
      return details.status === TeamTrialStatus.EXPIRED;
    })
  );

  private _isAccountSuspended$ = this.trialDetails$.pipe(
    map((details) => {
      return details.teamStatus === 'SUSPENDED';
    })
  );

  shouldBlockUser$: Observable<UserBlockDetails> = combineLatest([
    this._isTrialExpired$,
    this._isAccountSuspended$
  ]).pipe(
    map(([ isTrialExpired, isUserSuspended ]) => {
      if (this._shouldBlockUser(isTrialExpired)) {
        return { blockUser: true, redirectRoute: 'trial-expired' };
      } else if (this._shouldBlockUser(isUserSuspended)) {
        return { blockUser: true, redirectRoute: 'account-suspended' };
      }

      return { blockUser: false };
    })
  );

  accountDeleted$ = this.trialDetails$.pipe(
    filter((details) => {
      return details.teamStatus === 'DELETED';
    }),
    take(1)
  );

  timeLeft$ = this.trialDetails$.pipe(
    map((details) => {
      return details.trialEndTs - new Date().getTime();
    })
  );

  private _getLatestTrialDetails() {
    return this._teamService.getTeamDetails().pipe(
      tap((res: any) => {
        this._trialDetailsSubject.next(res.data);

        const trialExpired = res.data.trial_status === TeamTrialStatus.EXPIRED;
        const accountSuspended = res.data.status === 'SUSPENDED';

        if (this._shouldBlockUser(trialExpired) && this._router.url !== '/payment/setup-billing') {
          this._router.navigate({
            pathname: '/trial-expired'
          });
        } else if (this._shouldBlockUser(accountSuspended) && this._router.url !== '/payment/payment-history') {
          this._router.navigate({
            pathname: '/account-suspended'
          });
        }
      }),
      catchError((error: any) => {
        return throwError(error);
      })
    );
  }

  private _shouldBlockUser(blockUser: boolean) {
    if (this._authService.getUser().email.includes('@hevodata.com')) {
      return false;
    }
    return blockUser;
  }

  private _trackTrialStatus(status: TeamTrialStatus) {
    const user = this._authService.getUser();
    if (!user) {
      return;
    }
    this._trackingService.updateProperties(user, { trial_status: status });
  }

  endTrial() {
    this._trialDetailsSubject.next({
      ...this._trialDetailsSubject.getValue(),
      trial_status: TeamTrialStatus.ENDED
    });
  }

  updateTrialDetails() {
    this._needTrialDetailsSubject.next(true);
  }
}
