import React from 'react';
import { getKeyIconClass } from '../../../containers/shortcuts/utils';
import { getKeyFromCommand } from '../../../utils/keyboardShortcuts';

interface HdShortcutProps {
  command: string;
}

export default function HdShortcut({ command }: HdShortcutProps) {
  const keyCombination = getKeyFromCommand(command);
  const keys = keyCombination.split('.');
  const keysCount = keys.length - 1;

  return (
    <>
      {keys.map((key, index) => {
        const keyIconClass = getKeyIconClass(key);

        return (
          <React.Fragment key={index}>
            <span className={keyIconClass}>
              {!keyIconClass ? key : null}
              {index < keysCount ? <>&nbsp;</> : null}
            </span>
          </React.Fragment>
        );
      })}
    </>
  );
}
