import { useLocation } from 'react-router-dom';
import { LoginCredentials } from '../core/models/login-credentials';
import { RecaptchaTokenPayload } from '../core/models/recaptcha';
import { NetworkRequestOptions } from '../core/models/request';
import AuthAPI from './AuthAPI';
import { useHandleAuthResponse } from './useHandleAuthResponse';

export function useLogin() {
  const { search } = useLocation();
  const handleAuthResponse = useHandleAuthResponse();

  const login = (
    credentials: LoginCredentials & RecaptchaTokenPayload,
    options?: NetworkRequestOptions,
    tfaCode?: string
  ) => {
    const next = new URLSearchParams(search).get('next');

    return AuthAPI.login(credentials, options, tfaCode).then(res => {
      if (res?.data?.require_challenge || res?.data?.challenge_failed) {
        return res;
      }

      handleAuthResponse(res, next || '');
      return res;
    });
  };

  const loginWithCode = (code: any, next: string) =>
    AuthAPI.loginWithCode(code, next).then(res => {
      handleAuthResponse(res, next);
    });

  const googleLogin = (err: any, code: any, state: any, tfaCode?: string) =>
    AuthAPI.googleLogin(err, code, state, tfaCode).then(res => {
      handleAuthResponse(res, res.data.next);
    });

  return {
    login,
    googleLogin,
    loginWithCode
  };
}
