export const REGION_NAMES: { [key: string]: string } = {
  'us-east-2': 'US East (Ohio)',
  'us-east-1': 'US East (N. Virginia)',
  'us-west-1': 'US West (N. California)',
  'us-west-2': 'US West (Oregon)',
  'central-us': 'Central US (Iowa)',
  'ca-central-1': 'Canada (Central)',
  'ap-south-1': 'Asia Pacific (Mumbai)',
  'ap-northeast-2': 'Asia Pacific (Seoul)',
  'ap-northeast-3': 'Asia Pacific (Osaka-Local)',
  'ap-southeast-1': 'Asia Pacific (Singapore)',
  'ap-southeast-2': 'Asia Pacific (Sydney)',
  'ap-northeast-1': 'Asia Pacific (Tokyo)',
  'ap-east-1': 'Asia Pacific (Hong Kong)',
  'cn-north-1': 'China (Beijing)',
  'cn-northwest-1': 'China (Ningxia)',
  'eu-central-1': 'EU (Frankfurt)',
  'eu-west-1': 'EU (Ireland)',
  'eu-west-2': 'EU (London)',
  'eu-west-3': 'EU (Paris)',
  'eu-north-1': 'EU (Stockholm)',
  'eu-south-1': 'EU (Milan)',
  'sa-east-1': 'South America (São Paulo)',
  'us-gov-west-1': 'AWS GovCloud (US-West)',
  'us-gov-east-1': 'AWS GovCloud (US-East)',
  'east-us-2': 'East US 2 (Virginia)',
  'west-us-2': 'West US 2 (Washington)',
  'us-gov-virginia': 'US Gov Virginia',
  'switzerland-north': 'Switzerland North (Zurich)',
  'canada-central': 'Canada Central (Toronto)',
  'north-europe': 'North Europe (Ireland)',
  'west-europe': 'West Europe (Netherlands)',
  'australia-east': 'Australia East (New South Wales)',
  'southeast-asia': 'Southeast Asia (Singapore)',
  'af-south-1': 'Africa (Cape Town)',
  'me-south-1': 'Middle East (Bahrain)',
  'japan-east': 'Japan East (Tokyo)',
  'ap-southeast-3': 'Asia Pacific (Jakarta)'
};

export const DEFAULT_SCHEMA_NAME = 'public';

export const AUTH_ACC_CREATED_QUERY_FLAG = 'auth_acc_created';

export const EDIT_DESTINATION_QUERY_PARAM_TRIGGER = 'edit_destination';
export const AUTH_ACC_CREATED_AT_QUERY_FLAG = 'created_at';

export const EDIT_DESTINATION_SEQ_ID_QUERY_PARAM_TRIGGER = 'edit_destination_by_seq_id';

export const NODE_SETTINGS_ASK_SUPPORT_ERRORS_THRESHOLD = 3;

export const SOURCE_SETTINGS_SUPPORT_CONCERN = 'Facing issues while connecting your Source?';
export const DEST_SETTINGS_SUPPORT_CONCERN = 'Facing issues while connecting your Destination?';

export const SANITIZE_NAMES_DOC_LINK = '/data-loading/name-sanitization/';

export const SHOW_SHARE_DIALOG_ON_ERROR_CODES = [
  1001, 1005, 1006, 1007, 1008, 1009, 1010, 1011, 1014, 1020
];

export const SOURCE_TYPE_ERROR_CODE_TROUBLESHOOT_MAP: { [type: string]: any } = {
  MYSQL: {
    keyword: 'mysql',
    suffix: 'mysql',
    errorCodes: [1003, 1006, 1007, 1011, 1012, 1017, 1026, 1027]
  },
  MARIA_DB: {
    keyword: 'maria-db',
    suffix: 'maria-db',
    errorCodes: [1003, 1006, 1007, 1011, 1012, 1017, 1026, 1027]
  },
  POSTGRES: {
    keyword: 'postgresql',
    suffix: 'pgsql',
    errorCodes: [1003, 1006, 1011, 1012, 1014, 1017, 1023, 1024]
  },
  MONGODB: {
    keyword: 'mongodb',
    suffix: 'mongodb',
    errorCodes: [1001, 1005, 1006, 1007, 1008, 1011, 1028, 1029]
  },
  MS_SQL: {
    keyword: 'ms-sql',
    suffix: 'ms-sql',
    errorCodes: [1003, 1005, 1011, 1006]
  }
};

export const DESTINATION_TYPE_ERROR_CODE_TROUBLESHOOT_MAP: { [type: string]: any } = {
  REDSHIFT: {
    errorCodes: [1003, 1006]
  },
  POSTGRES: {
    errorCodes: [1017]
  }
};

export const BINLOG_FAILURE_ERROR_CODES = [1026, 1027, 1045];
