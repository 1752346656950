import { SourceTypeConfigHelper } from '../../../../../components/Node/source-settings-form/config-helper';

export class AmplitudeAnalyticsConfigHelper implements SourceTypeConfigHelper {
  getConfigFromRawData(rawData: any) {
    const dateOffset = new Date(rawData.start_offset);

    return {
      apiKey: rawData.api_key,
      secretKey: rawData.api_secret,
      dateOffset
    };
  }

  getDefaultConfig(metaData: any) {
    return {};
  }

  buildConnectionParams(data: any) {
    const dateOffset = new Date(data.dateOffset).getTime();
    const params: any = {
      api_key: data.apiKey,
      api_secret: data.secretKey
    };

    if (data.sourceVersion && data.sourceVersion < 5) {
      params.start_offset = dateOffset;
    }

    return params;
  }
}
