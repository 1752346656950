import { Angulartics2 } from '@angularticsCompat';

import { MODEL_SESSION_ID_KEY } from '../constants';
import { SessionTrackingAngularticsService } from './session-tracking-angulartics.service';
import { SettingsStorageService } from './settings-storage.service';


export class CreateModelAngularticsService extends SessionTrackingAngularticsService {
  static deps = [
    Angulartics2,
    SettingsStorageService
  ];

  constructor(
    protected _angulartics2: Angulartics2,
    protected _settingsStorageService: SettingsStorageService
  ) {
    super(
      _angulartics2,
      _settingsStorageService,
      MODEL_SESSION_ID_KEY
    );
  }
}
