import {
  SettingsStorageService,
  StorageSettingsInfo
} from '../../containers/core/services/settings-storage.service';
import useService from '../useService';

export default function useSettingsStorageService() {
  const settingsStorageService = useService(SettingsStorageService);

  return {
    getSettings: (key: string, storageSettingsInfo?: StorageSettingsInfo) =>
      settingsStorageService.getSettings(key, storageSettingsInfo),
    applySettings: (key: string, value: any, storageSettingsInfo?: StorageSettingsInfo) =>
      settingsStorageService.applySettings(key, value, storageSettingsInfo)
  };
}
