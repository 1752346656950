import { ReflectiveInjector, Type, InjectionToken, Provider, AbstractType } from 'injection-js';
import { useContext, useMemo } from 'react';
import { AngularDIContext } from '../components/AngularDI/di-context';

export default function useService<T>(service: Type<T> | InjectionToken<T> | AbstractType<T>, notFoundValue?: T): T {
  const angularDIRef = useContext(AngularDIContext);
  return angularDIRef?.get(service, notFoundValue);
}

export function useRegisterProviders(providers: Provider[]) {
  const angularDIRef = useContext(AngularDIContext);

  return useMemo(() => {
    try {
      return ReflectiveInjector.resolveAndCreate(providers, angularDIRef);
    } catch (e) {
      console.log(e);
      return undefined;
    }
  }, [ angularDIRef ]);
}
