import { BACKGROUND_NETWORK_REQ_OPTIONS } from '../core/constants';
import { NetworkRequestOptions } from '../core/models/request';
import { AppConfig } from '../core/app.config';
import { courier } from '../core/services/courier.service';
import { createHttpParams } from '../../legacy-utils/request';
import { ACTIVITY_PAGE_LIMIT } from './constants';

const appConfig = new AppConfig();
const CLIENT_LOGGER_CLOUDWATCH_KEY = 'CLOUDWATCH_LOGS';

const ActivityLogAPI = {
  activityUrl: appConfig.getActivityLogsURL(),
  cloudwatchLoggerUrl: `${appConfig.getActivityLogsURL()}/client-loggers`,

  getFilterOptions() {
    const requestUrl = `${this.activityUrl}/filter-options`;

    const options: NetworkRequestOptions = {
      uiOptions: {
        showLoading: false,
        showErrorMsg: false
      }
    };

    return courier.get(requestUrl, options);
  },

  getActivities(params: any, pageData: any = {}) {
    const options: NetworkRequestOptions = {
      uiOptions: {
        showLoading: false,
        showErrorMsg: false
      },
      networkOptions: {
        params: createHttpParams({
          page_size: pageData.pageSize || ACTIVITY_PAGE_LIMIT,
          start_row_number: pageData.start || 0
        })
      }
    };
    return courier.post(this.activityUrl, options, params);
  },

  getClientLogger() {
    const options: NetworkRequestOptions = {
      uiOptions: {
        showLoading: false,
        showErrorMsg: false
      }
    };

    return courier.get(this.cloudwatchLoggerUrl, options);
  },

  deleteClientLogger(clientLoggerId: number) {
    const options: NetworkRequestOptions = {
      uiOptions: {
        showLoading: false
      }
    };

    return courier.delete(`${this.cloudwatchLoggerUrl}/${clientLoggerId}`, options);
  },

  testCloudwatchConnection(config: any) {
    const params: any = {
      config,
      type: CLIENT_LOGGER_CLOUDWATCH_KEY
    };

    return courier.post(`${this.cloudwatchLoggerUrl}/test`, BACKGROUND_NETWORK_REQ_OPTIONS, params);
  },

  enableCloudwatch(config: any) {
    const params: any = {
      config,
      type: CLIENT_LOGGER_CLOUDWATCH_KEY
    };

    return courier.post(`${this.cloudwatchLoggerUrl}`, BACKGROUND_NETWORK_REQ_OPTIONS, params);
  },

  updateCloudwatchConfig(config: any, clientLoggerId: number) {
    const params: any = {
      config,
      type: CLIENT_LOGGER_CLOUDWATCH_KEY
    };

    return courier.put(`${this.cloudwatchLoggerUrl}/${clientLoggerId}`, BACKGROUND_NETWORK_REQ_OPTIONS, params);
  }
};

export default ActivityLogAPI;
