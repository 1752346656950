import { Subject } from 'rxjs';
import { SourceObjectEditPayload } from '../../pipeline/SourceObjects/models/source-object-edit-payload';


export class SourceObjectService {
  _objectEdit$ = new Subject<SourceObjectEditPayload>();
  objectEdit$ = this._objectEdit$.asObservable();

  _needOverviewPageRefresh$ = new Subject<void>();
  needOverviewPageRefresh$ = this._needOverviewPageRefresh$.asObservable();

  refreshOverviewList() {
    this._needOverviewPageRefresh$.next();
  }

  onSourceObjectEdit(payload: SourceObjectEditPayload) {
    this._objectEdit$.next(payload);
  }
}
