/* eslint-disable no-shadow */
import { SourceTypeConfigHelper } from '../../../../../components/Node/source-settings-form/config-helper';

export class DriveConfigHelperReact implements SourceTypeConfigHelper {
  getConfigFromRawData(rawData: any) {
    const config: any = {
      parseStringsAsDatetime: !!rawData.parse_strings_as_datetime
    };

    const tableConfigs = rawData.table_configs;

    config.folderIds = {
      selected: false,
      entities: Object.keys(tableConfigs).map(tableId => ({
        key: tableId,
        selected: true
      }))
    };

    return config;
  }

  getDefaultConfig(metaData: any) {}

  buildConnectionParams(data: any) {
    const { folderIds } = data;

    const tableConfigs = Object.values(folderIds.entities)
      .map((folderId: any) => ({ [folderId.key]: { is_first_row_header: true } }))
      .reduce((prev, curr) => ({ ...prev, ...curr }), {});

    return {
      table_configs: tableConfigs,
      account_type: data.authType,
      parse_strings_as_datetime: data.parseStringsAsDatetime
    };
  }
}
