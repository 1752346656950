import React from 'react';
import { Typography } from '@mui/material';
import { AppConfig } from '../../containers/core/app.config';
import HdCode from '../UIElements/HdCode';

interface RequestSSHAccessInterface {
  message?: string;
  entityTypeIdentifier?: string;
}

const appConfig = new AppConfig();

function RequestSSHAccess({ message, entityTypeIdentifier }: RequestSSHAccessInterface) {
  const sshIPs = appConfig.getBastionIPs();
  const hide = !sshIPs || !sshIPs.length;
  const defaultMessage = `Please open access to the ${entityTypeIdentifier} port from Hevo's IP addresses`;
  const showIPList = IPs =>
    IPs.map((ip, index) => {
      let appendText = '';
      if (index === IPs.length - 2) {
        appendText = ' and ';
      } else if (index !== IPs.length - 1) {
        appendText = ',';
      }
      return (
        <span key={ip}>
          <HdCode variant='ip'>{ip}</HdCode>
          {appendText}
        </span>
      );
    });

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {!hide ? (
        <Typography variant='body3' component='p' className='mb-7'>
          {formatMessage(message || defaultMessage, sshIPs)} {showIPList(sshIPs)}
        </Typography>
      ) : null}
    </>
  );
}

export default RequestSSHAccess;

const formatMessage = (message: string, sshIPs: string[]) => {
  if (sshIPs.length === 1) {
    return message.replace('IP addresses', 'IP address');
  }
  return message;
};
