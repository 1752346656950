import { Enum } from '../core/models/object-enum';
import { EntityStatus } from '../core/models/entity-status';

export class ModelStatusEnum extends Enum {
  static DRAFT              = new EntityStatus('Draft', 'DRAFT', 'default');
  static COMPLETED          = new EntityStatus('Not Scheduled', 'COMPLETED', 'success');
  static SCHEDULED          = new EntityStatus('Scheduled', 'SCHEDULED', 'success');
  static RUNNING            = new EntityStatus('Running', 'RUNNING', 'success');
  static PAUSED             = new EntityStatus('Paused', 'PAUSED', 'warning');
  static FAILED             = new EntityStatus('Failed', 'FAILED', 'error');
  static QUEUED             = new EntityStatus('Queued', 'QUEUED', 'primary');
  static CANCEL_REQUESTED   = new EntityStatus('Cancelling', 'CANCEL_REQUESTED', 'warning');
  static CANCELLED          = new EntityStatus('Cancelled', 'CANCELLED', 'warning');
  static UPDATING           = new EntityStatus('Updating', 'UPDATING', 'warning');
  static UPDATE_FAILED      = new EntityStatus('Update Failed', 'UPDATE_FAILED', 'error');
  static RESETTING = new EntityStatus('Resetting', 'RESETTING', 'warning');
  static RESET_FAILED = new EntityStatus('Reset Failed', 'RESET_FAILED', 'error');

  static isQueryRunScheduled(status: EntityStatus) {
    return [ModelStatusEnum.QUEUED.value, ModelStatusEnum.RUNNING.value].indexOf(status.value) >= 0;
  }
}
