import { SourceTypeConfigHelper } from '../../../../../components/Node/source-settings-form/config-helper';

export class JiraConfigHelper implements SourceTypeConfigHelper {
  getConfigFromRawData(rawData: any) {
    return {
      apiKey: rawData.api_key,
      userEmail: rawData.user_email,
      siteName: rawData.site_name,
      savedUserEmail: rawData.user_email
    };
  }

  getDefaultConfig() {
    return {};
  }

  buildConnectionParams(data: any) {
    return {
      api_key: data.apiKey,
      user_email: data.userEmail,
      site_name: data.siteName
    };
  }
}
