import {
  IncludedChildState,
  InclusionListState,
} from '../../../../core/models/inclusion-list-v2';
import { GenericKeyValueType } from '../../../../../generics';
import { AmazonAdsCustomerProfileEntity, AmazonAdsReportCategory, AmazonAdsSelectedProfile } from './models';

export const isCustomReportType = reportType => reportType === AmazonAdsReportCategory.CUSTOM_REPORTS;
export const isStandardReportType = reportType => reportType === AmazonAdsReportCategory.STANDARD_REPORTS;

function ProfileFactory(profile: AmazonAdsSelectedProfile) {
  return {
    profileId: profile.profileId,
    profileName: profile.profileName,
    timezone: profile?.timezone || ''
  };
}

function CustomerProfileFactory(customer: AmazonAdsCustomerProfileEntity) {
  return {
    adsRegion: customer.data.adsRegion,
    profileId: customer.id,
    profileName: customer.data.profileName,
    timezone: customer.data.timezone
  };
}

export function getSelectorListStateFromRawData(selectedProfiles: AmazonAdsSelectedProfile[]): InclusionListState {
  const entities: IncludedChildState[] = [];
  const groupedProfiles = selectedProfiles.reduce((acc, curr) => {
    return {
      ...acc,
      [curr.adsRegion]: [
        ...acc[curr.adsRegion],
        curr
      ]
    };
  }, {});

  Object.keys(groupedProfiles).forEach((key) => {
    const selectedAccounts = groupedProfiles[key] as AmazonAdsSelectedProfile[];
    const cannotManageClients =
      selectedAccounts.length === 1 &&
      selectedAccounts[0].profileId === '' &&
      selectedAccounts[0].profileName === '';

    entities.push({
      key,
      selected: cannotManageClients,
      size: cannotManageClients ? null : selectedAccounts.length,
      entities: !cannotManageClients ? selectedAccounts.map(profile => ({
        key: profile.profileId,
        selected: true,
        metaData: {
          ...ProfileFactory(profile)
        }
      })) : []
    });
  });

  return {
    selected: false,
    entities: entities
  };
}

function setSelectedCustomerProfiles(
  selectedProfilesMap: Map<number, object>,
  manager: InclusionListState,
  customers: AmazonAdsCustomerProfileEntity[]
) {
  if (manager.selected) {
    if (manager.entities.length === 0) {
      // all customers are selected
      customers.forEach((customer) => {
        if (!selectedProfilesMap.has(customer.id)) {
          selectedProfilesMap.set(customer.id, CustomerProfileFactory(customer));
        }
      });
    } else {
      // present customers are excluded
      customers.forEach((customer) => {
        const isExcluded = !!manager.entities.find(entity => entity.key === customer.id && !entity.selected);

        if (!isExcluded && !selectedProfilesMap.has(customer.id)) {
          selectedProfilesMap.set(customer.id, CustomerProfileFactory(customer));
        }
      });
    }
  } else {
    // present entities are included
    manager.entities.forEach((entity) => {
      if (entity.selected) {
        const customer = customers.find((customer) => customer.id === entity.key);

        if (customer && !selectedProfilesMap.has(customer.id)) {
          selectedProfilesMap.set(customer.id, CustomerProfileFactory(customer));
        }
      }
    });
  }
}

export function getSelectedProfilesFromListData(
  listData: InclusionListState,
  managerCustomersMap: GenericKeyValueType<string, AmazonAdsCustomerProfileEntity[]>
) {
  const selectedProfilesMap = new Map<number, object>();

  if (listData.selected) {
    if (listData.entities.length === 0) {
      // all items in the list are selected
      for (const managerId in managerCustomersMap) {
        const customers = managerCustomersMap[managerId];

        customers.forEach((customer) => {
          selectedProfilesMap.set(customer.id, CustomerProfileFactory(customer));
        });
      }

      return Array.from(selectedProfilesMap.values());
    }

    listData.entities.forEach((manager: InclusionListState) => {
      const customers = managerCustomersMap[manager.key];
      setSelectedCustomerProfiles(selectedProfilesMap, manager, customers);
    });

    const allEntitiesPresent = listData.entities.length === Object.keys(managerCustomersMap).length;
    if (!allEntitiesPresent) {
      Object.keys(managerCustomersMap).forEach(key => {
        const isExcluded = listData.entities.find(manager => manager.key === key && !manager.selected);

        if (!isExcluded) {
          const customers = managerCustomersMap[key];
          customers.forEach((customer) => {
            if (!selectedProfilesMap.has(customer.id)) {
              selectedProfilesMap.set(customer.id, CustomerProfileFactory(customer));
            }
          });
        }
      });
    }

    return Array.from(selectedProfilesMap.values());
  }

  listData.entities.forEach((manager: InclusionListState) => {
    const customers = managerCustomersMap[manager.key];
    setSelectedCustomerProfiles(selectedProfilesMap, manager, customers);
  });

  return Array.from(selectedProfilesMap.values());
}
