import React, { Suspense, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { HdResolvedComponent } from '../../components/Routing/HdResolvedComponent';
import { GenericPageShimmer } from '../../components/Shimmer/GenericPageShimmer';
import { useAuthGuard } from '../auth/useAuthGuard';
import { AccountAccessService } from '../core/services/account-access.service';
import { AllowedActionsService } from '../core/services/entity-actions.service';
import { MultiRegionService } from '../core/services/multi-region.service';
import { RecentEntitiesService } from '../core/services/recent-entities.service';
import { TeamSettingsService } from '../core/services/team-settings.service';
import { TeamService } from '../core/services/team.service';
import { KeyboardShortcuts } from '../shortcuts/service/keyboard-shortcuts.service';
import { ShortcutsDialogHandlerReactService } from '../shortcuts/service/shortcuts-dialog-handler-react.service';
import { AngularDiProviders } from '../../components/AngularDI/AngularDiProviders';
import { ShareDocDialogService } from '../../components/Dialog/InviteMembersDialog/share-doc-dialog.service';
import { BannerOutlet } from '../../components/Banner/BannerOutlet';
import { useAlertDialog } from '../../components/Dialog/AlertDialog/useAlertDialog';
import SourceInviteMembersDialog from '../../components/Dialog/InviteMembersDialog/SourceInviteMembersDialog';
import { ProductTourRoot } from '../../components/ProductTourV2';
import { useKeyboardShortcutListeners } from '../../hooks/useKeyboardShortcutListeners';
import { useObservableState } from '../../hooks/useObservableState';
import useService from '../../hooks/useService';
import { useSubscribe } from '../../hooks/useSubscribe';
import { DELAY_BEFORE_DELETING_ACCOUNT } from '../core/constants';
import { AuthService } from '../core/services/auth.service';
import { OverageDataService } from '../core/services/overage-data.service';
import { PlanChatSupportService } from '../core/services/plan-chat-support.service';
import { TrialDetailsService } from '../core/services/trial-details.service';
import { UserTimezoneService } from '../core/services/user-timezone.service';
import { DrawersContainer } from '../drawer/DrawersContainer';
import { PCNASupportService } from '../pipeline/services/pcna-support.service';
import AdvancedGlobalSearchDialog from '../shortcuts/AdvancedGlobalSearch';
import ShortcutsDialog from '../shortcuts/ShortcutsDialog';
import { Appbar } from './Appbar/Appbar';
import TopBar from './TopBar';
import styles from './styles.module.scss';
import { usePostLoginDataUpdateGuard } from './usePostLoginDataUpdateGuard';
import { DashboardRoutes } from './DashboardRoutes';

const providers = [OverageDataService, ShareDocDialogService, KeyboardShortcuts];

export function Dashboard() {
  return (
    <AngularDiProviders providers={providers}>
      <DashboardWithResolvedData />
    </AngularDiProviders>
  );
}

export function DashboardWithResolvedData() {
  const teamService = useService(TeamService);
  const multiRegionService = useService(MultiRegionService);
  const accountAccessService = useService(AccountAccessService);
  const teamSettingsService = useService(TeamSettingsService);
  const allowedActionsService = useService(AllowedActionsService);
  const recentEntitiesService = useService(RecentEntitiesService);

  const { executePostLoginDataUpdateGuard } = usePostLoginDataUpdateGuard();
  const { executeAuthGuard } = useAuthGuard();

  const getTeamDetails = () => {
    return teamService
      .getTeamDetails()
      .pipe(
        map((res: any) => res.data),
        catchError((err: any) => {
          return of(null);
        })
      )
      .toPromise();
  };

  const getMultiRegions = () => {
    return multiRegionService
      .getMultiRegions(false)
      .pipe(
        map((res: any) => res.data),
        catchError((err: any) => {
          return of(null);
        })
      )
      .toPromise();
  };

  const executeTeamSettingsGuard = () => {
    return teamSettingsService
      .getTeamSettings(false, false, false)
      .pipe(
        map(() => true),
        catchError(() => {
          return of(true);
        })
      )
      .toPromise();
  };

  const fetchAccountAccessConsentStatus = () => {
    return accountAccessService
      .fetchAccountAccessConsentStatus()
      .pipe(
        map((res: any) => res.data),
        catchError((err: any) => {
          return of(null);
        })
      )
      .toPromise();
  };

  const executeFetchGlobalActionsGuard = () => {
    return allowedActionsService
      .getAllowedActions(true, false)
      .pipe(
        map(() => true),
        catchError(() => {
          return of(true);
        })
      )
      .toPromise();
  };

  const getRecentEntities = () => {
    return recentEntitiesService.fetchRecent().pipe(
      catchError(() => {
        return of(null);
      })
    ).toPromise();
  };

  return (
    <HdResolvedComponent
      Component={DashboardInner}
      resolve={{
        recentEntities: getRecentEntities,
        teamDetails: getTeamDetails,
        multiRegion: getMultiRegions,
        accountAccess: fetchAccountAccessConsentStatus
      }}
      canActivate={[
        executeAuthGuard,
        executeTeamSettingsGuard,
        executeFetchGlobalActionsGuard,
        executePostLoginDataUpdateGuard
      ]}
    />
  );
}

export function DashboardInner() {
  const { alert } = useAlertDialog();

  /** Needed to initialise */
  useService(KeyboardShortcuts);
  const shareDocDialogService = useService(ShareDocDialogService);
  const trialDetailsService = useService(TrialDetailsService);
  const authService = useService(AuthService);
  const shortcutsDialogHandlerReactService = useService(ShortcutsDialogHandlerReactService);
  const planChatSupportService = useService(PlanChatSupportService);
  const userTimezoneService = useService(UserTimezoneService);
  const pcnaSupportService = useService(PCNASupportService);
  const teamService = useService(TeamService);

  const navigate = useNavigate();
  const location = useLocation();

  const redirectOnHomePageNav = () => {
    const currentUrl = new URL(window.location.href);

    if (currentUrl.pathname === '/') {
      let redirectUrl = '/pipeline';

      const teamDetails = teamService.teamDetails;

      if (teamDetails?.sign_up_source === 'ACTIVATE') {
        redirectUrl = '/activation';
      }

      navigate(redirectUrl, {
        replace: true
      });
    }
  };

  useEffect(() => {
    redirectOnHomePageNav();
  }, [location]);

  useSubscribe(
    trialDetailsService.accountDeleted$.pipe(
      tap(() => {
        alert({
          dataId: '',
          title: 'You will be logged out soon',
          body: 'Your Hevo account has been deleted. You will be logged out soon'
        }).then();

        setTimeout(() => {
          authService.logout().subscribe();
        }, DELAY_BEFORE_DELETING_ACCOUNT);
      })
    ),
    []
  );

  useSubscribe(userTimezoneService.setDefaultTimezone(), []);

  useSubscribe(planChatSupportService.liveChatDisabled$, []);

  useSubscribe(authService.pollUserData(), []);

  useKeyboardShortcutListeners(
    {
      'global.keyboardShortcuts': (event: KeyboardEvent) => {
        shortcutsDialogHandlerReactService.closeGlobalSearchDialog();
        shortcutsDialogHandlerReactService.openKeyboardShortcutDialog();
        event.preventDefault();
      },
      'global.searchDialog': (event: KeyboardEvent) => {
        shortcutsDialogHandlerReactService.closeKeyboardShortcutDialog();
        shortcutsDialogHandlerReactService.openGlobalSearchDialog();
        event.preventDefault();
      }
    },
    {
      priority: 0,
      terminal: 'match'
    }
  );

  useEffect(() => {
    pcnaSupportService.init();

    return () => {
      planChatSupportService.reset();
    };
  }, []);

  const [isShareDocDialogVisible] = useObservableState(shareDocDialogService.isDialogVisible$);
  const [isOpen] = useObservableState(shortcutsDialogHandlerReactService.isAdvancedGlobalSearchDialogOpen());

  return (
    <div className='flex-col h-100'>
      <BannerOutlet />
      <ShortcutsDialog />
      {isOpen ? <AdvancedGlobalSearchDialog /> : null}
      <ProductTourRoot />

      <div className={styles.dashboardContainer}>
        <Appbar />

        <div className={styles.appBody}>
          <TopBar />

          <Suspense fallback={<GenericPageShimmer />}>
            <DashboardRoutes />
          </Suspense>
        </div>

        <DrawersContainer />

        <SourceInviteMembersDialog
          renderAsDialog
          open={isShareDocDialogVisible}
          onClose={() => shareDocDialogService.hide()}
          docLink={shareDocDialogService.dialogOptions.docLink}
          entity={shareDocDialogService.dialogOptions.entity}
          sourceId={shareDocDialogService.dialogOptions.sourceId}
          sourceDisplayName={shareDocDialogService.dialogOptions.displayName}
          type={shareDocDialogService.dialogOptions.type}
        />
      </div>
    </div>
  );
}
