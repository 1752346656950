export class BodyInteractionService {

  isMouseIntent = false;

  constructor(
  ) {
    document.addEventListener(
      'mousedown',
      (e: MouseEvent) => this.onDocumentClick(e)
    );

    document.addEventListener(
      'keydown',
      (e: KeyboardEvent) => this.onDocumentKeydown(e)
    );
  }

  onDocumentClick(e: MouseEvent) {
    this.isMouseIntent = true;
    document.querySelector('body').classList.add('intent-mouse');
  }

  onDocumentKeydown(e: KeyboardEvent) {
    this.isMouseIntent = false;
    document.querySelector('body').classList.remove('intent-mouse');
  }
}
