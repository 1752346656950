import { ReactRouterService } from '../core/services/react-router.service';

export class DrawerRoutingService {
  static deps = [
    ReactRouterService
  ];

  constructor(
    private _router: ReactRouterService
  ) {
  }

  navigateByPath(path: string) {
    const queryParams = this._router.getQueryParams();

    queryParams.set('drawer', path);

    this._router.navigate({
      pathname: this._router.pathname,
      search: `?${queryParams.toString()}`,
    });
  }
}

