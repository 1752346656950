import React from 'react';
import { BannerData } from '../../../components/Banner/DefaultBanner/interface';
import { HdCurrencyFormat } from '../../../components/UIElements';

import dayjs from 'dayjs';

export default function OverageBannerMessage({ bannerData }: { bannerData: BannerData }) {
  return (
    <>
      You have surpassed the quota of Events allowed in your plan. An additional charge of{' '}
      <b>
        <HdCurrencyFormat value={bannerData?.messageContext?.overageSubAmount} />
      </b>{' '}
      or more will be applied on{' '}
      <b>
        {' '}
        {dayjs
          .utc(bannerData?.messageContext?.overageSubNextPaymentAttempt)
          .format('Do MMM, YYYY')}{' '}
        UTC
      </b>{' '}
      based on the number of Events used by then.{' '}
      <a className='text-link text-link-light' href='/payment/usage-summary'>
        View Usage Summary
      </a>
    </>
  );
}
