import {
  S3_BUCKET_REGIONS,
  S3_FILE_FORMATS
} from '../S3Base/constants';
import { SourceTypeConfigHelper } from '../../../../../components/Node/source-settings-form/config-helper';
import {
  _getFolderSelectorRawDataFromInclusionState,
  _getSelectorInclusionStateFromRawData
} from '../../../../../components/FolderSelector/util';
import { AwsConfigHelper } from '../AWS/configHelper';

export class S3ConfigHelperReact implements SourceTypeConfigHelper {
  defaultDelay = 5;

  getConfigFromRawData(rawData: any) {
    let config: any = {
      bucket: rawData.s3_bucket,
      delay: rawData.s3_delay ? rawData.s3_delay / 60000 : this.defaultDelay,
      prefix: rawData.path_prefix,
      pathPrefix: (rawData?.path_prefixes || []).map(prefix => (prefix === '' ? '/' : prefix)),
      pathPrefixSelector: rawData?.path_prefixes
        ? _getSelectorInclusionStateFromRawData(rawData?.path_prefixes)
        : null,
      splitByFolders: !!rawData.split_by_folders,
      bucketRegion: S3_BUCKET_REGIONS.map(elem => ({
        ...elem,
        id: elem.value,
        subtext: elem.value
      })).find((region: any) => region.value === rawData.s3_bucket_region),
      fileFormat: S3_FILE_FORMATS.map(elem => ({ ...elem, id: elem.value })).find(
        (format: any) => format.value === rawData.file_format
      ),
      delimiter: rawData.delimiter,
      compressedFiles: rawData.process_compressed_files,
      createEventsFromChildNodes: rawData.file_format === 'XML' ? rawData.xml_depth === 1 : null,
      parseStringsAsDatetime: !!rawData.parse_strings_as_datetime,
    };

    if (rawData.file_format === 'CSV') {
      if ('header_row' in rawData) {
        config.headerRow = rawData.header_row;
      } else {
        config.headerRow = rawData.first_line_as_header ? 1 : 0;
      }
    }

    config = {
      ...config,
      ...AwsConfigHelper.getConfigFromRawData(rawData)
    };

    return config;
  }

  getDefaultConfig() {
    const config: any = {
      prefix: '/',
      pathPrefix: ['/'],
      delimiter: ',',
      delay: this.defaultDelay,
      headerRow: 1,
      createEventsFromChildNodes: true,
      parseStringsAsDatetime: false
    };
    return config;
  }

  buildConnectionParams(data: any) {
    let params: any = {
      s3_bucket: data.bucket,
      s3_bucket_region: data.bucketRegion?.value,
      split_by_folders: !!data.splitByFolders,
      file_format: data.fileFormat?.value,
      delimiter: data.delimiter,
      process_compressed_files: data.compressedFiles,
      parse_strings_as_datetime: data.parseStringsAsDatetime
    };

    if (data.folderSelector) {
      params.path_prefixes = data.folderSelectorFailed
        ? (data.pathPrefix || []).map(prefix => (prefix === '/' ? '' : prefix))
        : _getFolderSelectorRawDataFromInclusionState(data.pathPrefixSelector)?.entities;
    } else {
      params.s3_delay = data.delay * 60000;
      params.path_prefix = data.prefix || undefined;
    }

    if (data.fileFormat?.value === 'XML') {
      params.xml_depth = data.createEventsFromChildNodes ? 1 : 0;
    }

    params.delimiter = null;

    if (data.fileFormat?.value === 'CSV') {
      params.delimiter = data.delimiter;
      params.header_row = data.headerRow;
    }

    if (data.fileFormat?.value === 'TSV') {
      params.delimiter = '\\t';
    }

    params = {
      ...params,
      ...AwsConfigHelper.buildConnectionParams(data)
    };

    return params;
  }
}
