/* eslint-disable jsx-a11y/label-has-associated-control */

import React, { useCallback } from 'react';
import { EntityUIState } from '../../containers/core/models/entitiy-ui-state';
import { numFormatter } from '../../legacy-utils/number';
import { PaginationData } from '../pagination/models';
import { getDataIdGenerator } from '../../utils/generateDataId';
import { HdIconButton } from '../UIElements/HdIconButton';
import HdIcon from '../UIElements/HdIcon';
import styles from './styles.module.scss';

interface PaginationControlProps {
  dataId: string;
  paginationData: PaginationData;
  listState: EntityUIState;
  nextPage: Function;
  prevPage: Function;
  className?: string;
}

export default function PaginationControl({
  dataId,
  paginationData,
  listState,
  nextPage,
  prevPage,
  className
}: PaginationControlProps) {
  const showControl = paginationData && paginationData.totalCount > 0;
  const pageStart = paginationData.page * paginationData.limit + 1;
  const pageEnd = Math.min(
    (paginationData.page + 1) * paginationData.limit,
    paginationData.totalCount
  );

  const canGetPrev = paginationData.page > 0;
  const canGetNext = (paginationData.page + 1) * paginationData.limit < paginationData.totalCount;
  const disablePrev = !canGetPrev || (listState && listState === EntityUIState.LOADING);
  const disableNext = !canGetNext || (listState && listState === EntityUIState.LOADING);

  const dataIdGenerator = useCallback(getDataIdGenerator(dataId, 'paginationControl'), [dataId]);

  return (
    <div className={`${styles.wrapper}  ${!showControl ? styles.hidden : ''} ${className}`}>
      <HdIconButton
        className='prev-btn'
        onClick={() => prevPage()}
        disabled={!!disablePrev}
        dataId={dataIdGenerator('prev')}
        horizontalEdge='both'
        verticalEdge='both'
      >
        <HdIcon name='left-arrow' size={3} />
      </HdIconButton>

      <span className='mx-1 text-secondary'>
        <b>{`${pageStart} - ${pageEnd}`}</b>
        {' of '}
        <b>{numFormatter(paginationData.totalCount)}</b>
      </span>

      <HdIconButton
        onClick={() => nextPage()}
        disabled={!!disableNext}
        dataId={dataIdGenerator('next')}
        horizontalEdge='both'
        verticalEdge='both'
      >
        <HdIcon name='right-arrow' size={3} />
      </HdIconButton>
    </div>
  );
}
