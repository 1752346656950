import { NodeTypeConfigHelper } from '../../../../../components/Node/node-config-helper';
import ActivateTargetAPI from '../../../ActivateTargetAPI';

export class GoogleSheetsConfigHelper implements NodeTypeConfigHelper {
  getConfigFromRawData(rawData: any) {
    return {
      folderId: rawData.folder_id,
      spreadsheetId: rawData.spreadsheet_id,
      spreadsheetName: rawData.spreadsheet_name
    };
  }

  getDefaultConfig() {
    return {};
  }

  metaDataResolver(targetType: string, data: any) {
    const accountId = data.oauthTokenId || data.serviceAccountId;

    return Promise.all([
      ActivateTargetAPI.getGoogleSheetsFolders(accountId, data.authType),
      ActivateTargetAPI.getGoogleSheetSpreadsheets(accountId, data.authType)
    ]).then(([foldersResponse, spreadsheetsResponse]) => ({
      folders: foldersResponse?.data?.files || [],
      spreadsheets: spreadsheetsResponse?.data?.files || []
    }));
  }

  buildConnectionParams(data) {
    return {
      folder_id: data.folderId,
      spreadsheet_id: data.spreadsheetId,
      spreadsheet_name: data.spreadsheetName
    };
  }
}
