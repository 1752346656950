import { EnterpriseRawSourceType } from 'hermes-ui-lib';
import { SOURCE_TYPES } from '../../../../../components/Node/source-type/source-type';
import { RawSourceType, StandardSourceType } from '../interfaces/source-type';

export function StandardSourceTypesFactory(rawSourceTypes: RawSourceType[]): StandardSourceType[] {
  return rawSourceTypes.map(StandardSourceTypeFactory);
}


export function StandardSourceTypeFactory(rawSourceType: RawSourceType): StandardSourceType {
  return {
    sourceType: rawSourceType.source_type,
    sourceTypeDisplay: rawSourceType.source_type_display,
    sourceTypeLogoUrl: rawSourceType.source_type_logo_url,
    sourceTypeDarkModeLogoUrl: rawSourceType.source_type_dark_mode_logo_url || rawSourceType.source_type_logo_url,
    sourceTypeDescription: rawSourceType.source_type_description,
    docLink: SOURCE_TYPES[rawSourceType.source_type]?.docLink,
    sourceTypePausable: !!rawSourceType.source_type_pausable,
    sourceTypeHasFileTracking: !!rawSourceType.source_type_has_file_tracking,
    sourceCategoryType: [...rawSourceType.source_category_type, 'STANDARD'],
    hasCentralizedOffsets: !!rawSourceType.has_centralized_offsets,
    baseSourceType: rawSourceType.base_source_type,
    hasFullLoadFrequency: !!rawSourceType.has_full_load_frequency,
    sourceTypeSearchKeywords: [...(rawSourceType.source_type_search_keywords || []), 'standard'],
    sortOrder: rawSourceType.sortOrder,
    daysLeftInTrial: rawSourceType.days_left_in_trial,
    isQueryable: !!rawSourceType.is_queryable,
    isFree: !!rawSourceType.is_free,
    isDisabled: !!rawSourceType.is_disabled,
    isUnused: !!rawSourceType.is_unused,
    isUpcoming: !!rawSourceType.is_upcoming,
    isPrivateBeta: !!SOURCE_TYPES[rawSourceType.source_type]?.privateBeta,
    isBeta: !!SOURCE_TYPES[rawSourceType.source_type]?.beta,
    isEarlyAccess: !!SOURCE_TYPES[rawSourceType.source_type]?.earlyAccess,
    primaryColor:
      SOURCE_TYPES[rawSourceType.source_type]?.primaryColor ||
      rawSourceType?.source_type_colour_code,
    darkModePrimaryColor:
      SOURCE_TYPES[rawSourceType.source_type]?.darkModePrimaryColor ||
      rawSourceType?.source_type_colour_code,
    isHidden: !!SOURCE_TYPES[rawSourceType.source_type]?.isHidden,
    isEdge: false
  };
}

export function RawEnterpriseToStandardSourceTypesFactory(rawEnterpriseSourceTypes: EnterpriseRawSourceType[]): StandardSourceType[] {
  return rawEnterpriseSourceTypes.map(RawEnterpriseToStandardSourceTypeFactory);
}

export function RawEnterpriseToStandardSourceTypeFactory(rawEnterpriseSourceType: EnterpriseRawSourceType): StandardSourceType {
  return {
    sourceType: rawEnterpriseSourceType.type,
    sourceTypeDisplay: rawEnterpriseSourceType.display_name,
    sourceTypeLogoUrl: rawEnterpriseSourceType.logo_url,
    sourceTypeDarkModeLogoUrl: rawEnterpriseSourceType.dark_mode_logo_url,
    sourceTypeDescription: rawEnterpriseSourceType.description,
    docLink: rawEnterpriseSourceType.doc_link,
    sourceTypePausable: false,
    sourceTypeHasFileTracking: false,
    sourceCategoryType: ['EDGE', ...(rawEnterpriseSourceType.source_category_type || [])],
    hasCentralizedOffsets: false,
    baseSourceType: rawEnterpriseSourceType.base_type,
    hasFullLoadFrequency: false,
    sourceTypeSearchKeywords: ['edge'],
    sortOrder: 0,
    daysLeftInTrial: 0,
    isQueryable: false,
    isFree: true,
    isDisabled: false,
    isUnused: false,
    isUpcoming: false,
    isPrivateBeta: false,
    isBeta: false,
    isEarlyAccess: false,
    primaryColor: SOURCE_TYPES[rawEnterpriseSourceType.type]?.primaryColor,
    darkModePrimaryColor: SOURCE_TYPES[rawEnterpriseSourceType.type]?.darkModePrimaryColor,
    isHidden: false,
    isEdge: true
  };
}
