import axios from 'axios';
import { useNavigate } from 'react-router-dom';

declare const __WEBSITE_URL__: string;

export function usePostLoginDataUpdateGuard() {
  const navigate = useNavigate();

  const executePostLoginDataUpdateGuard = () => {
    return axios.get(
      `${ __WEBSITE_URL__ }/auth/post-login-updates`,
      {
        headers: { 'Anonymous': 'True' },
        withCredentials: true
      }
    ).then(
      (res: any) => {
        if (res.data.data.store_subscription_meta) {
          const accountId = res.data.data.marketplace_metadata.account_id;
          const accountUrl = `/subscribe-marketplace?marketplace_provider=${res.data.data.marketplace_metadata.account_provider}&account_id=${accountId}`;
          navigate(accountUrl);
          return false;
        }

        return true;
      },
      () => {
        return true;
      }
    );
  };

  return {
    executePostLoginDataUpdateGuard
  };
}
