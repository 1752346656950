import { BehaviorSubject } from 'rxjs';
import { share, startWith, switchMap, tap } from 'rxjs/operators';
import { getMonthlyAmount } from '../../payment/utils/utils';
import { muteFirst } from '../../../legacy-utils/rxjs-observables';
import { retryBackoff } from '../../../legacy-utils/rxjs-operators';
import { IntercomService } from './intercom.service';
import { PaymentService } from './payment.service';
import { RefreshService } from './refresh.service';

export interface ChatSupportCurrentPlan {
  paymentSource?: string;
  currentPlanTier: string;
}

export class PlanChatSupportService {
  static deps = [
    RefreshService,
    PaymentService,
    IntercomService
  ];

  currentPlan$ = new BehaviorSubject<ChatSupportCurrentPlan>(null);

  private _liveChatDisabled$ = new BehaviorSubject(false);

  private _effect$ = this._refreshService.refreshChatSupportDetails$.pipe(
    startWith(null),
    switchMap(() => {
      const BACKOFF_MAX_DELAY = 30000;
      const BACKOFF_INIT_DELAY = 1000;

      return this._paymentService.getChatSupportDetails().pipe(
        retryBackoff({
          initialInterval: BACKOFF_INIT_DELAY,
          maxInterval: BACKOFF_MAX_DELAY,
          shouldRetry: (error) => {
            return error.status;
          }
        }),
        tap((resp) => {
          if (resp && resp.data) {
            if (resp.data.base_plan) {
              this.currentPlanTier = resp.data.base_plan.tier;

              this._intercomService.setPlanDetails({
                plan: resp.data.base_plan.tier,
                billing_provider_type: resp.data.billing_provider_type,
                monthly_spend: getMonthlyAmount(resp.data.base_plan.amount, resp.data.base_plan.frequency)
              });
            } else {
              this.currentPlanTier = 'NO_PLAN_SELECTED';

              this._intercomService.setPlanDetails({
                plan: null,
                monthly_spend: null,
                billing_provider_type: null
              });
            }

            this._liveChatDisabled$.next(!resp.data.chat_support_enabled);
            this.currentPlan$.next({
              paymentSource: resp.data.billing_provider_type,
              currentPlanTier: this.currentPlanTier
            });
          } else {
            this._liveChatDisabled$.next(false);
            this._intercomService.setPlanDetails(null);
          }
        })
      );
    }),
    share()
  );

  liveChatDisabled$ = muteFirst(
    this._effect$,
    this._liveChatDisabled$.asObservable()
  );

  currentPlanTier: string;

  constructor(
    private _refreshService: RefreshService,
    private _paymentService: PaymentService,
    private _intercomService: IntercomService
  ) {
  }

  isLiveChatDisabled() {
    return this._liveChatDisabled$.getValue();
  }

  reset() {
    this._liveChatDisabled$.next(false);
    this.currentPlan$.next(null);
  }
}
