import { execIfDefined } from '../../../legacy-utils/exec-if-defined';
import { UITracker } from '../models/tracking';
import { User } from '../models/user';

declare const clarity: any;

export class ClarityService implements UITracker {
  init(trackingConfig) {
  }

  identifyUser(user: User) {
    execIfDefined('clarity', () => {
      clarity('identify', user.email, undefined, undefined, user.email);

      clarity('set', 'userName', user.name);
      clarity('set', 'userEmail', user.email);
      clarity('set', 'userId', user.id);
      clarity('set', 'role', user.role);
    });
  }

  updateIdentity(user: User) {
    this.identifyUser(user);
  }

  updateUserProperties (user: User, data: any) {
  }

  reset() {
  }

  stop() {
  }
}
