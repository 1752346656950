import React, { useEffect, useState } from 'react';
import {
  ShortcutsDialogHandlerReactService
} from '../service/shortcuts-dialog-handler-react.service';
import { useObservableState } from '../../../hooks/useObservableState';
import styles from './styles.module.scss';
import HdModal from '../../../components/UIElements/HdModal';
import { HdIconButton } from '../../../components/UIElements';
import HdIcon from '../../../components/UIElements/HdIcon';
import { getKeyIconClass } from '../utils';
import useService from '../../../hooks/useService';
import { ActivationStatusService } from '../../core/services/activation-status.service';
import { getDisplayableShortcuts } from './utils';
import useTeamSettings from '../../../hooks/services/useTeamSettingsService';

function ShortcutsDialog() {
  const [shortcuts, setShortcuts] = useState([]);

  const _activationStatusService = useService(ActivationStatusService);
  const shortcutsDialogHandlerReactService = useService(ShortcutsDialogHandlerReactService);
  const { isWorkFlowsEnabled } = useTeamSettings();

  useEffect(() => {
    setShortcuts(
      getDisplayableShortcuts(isWorkFlowsEnabled, _activationStatusService.shouldShowActivation())
    );
  }, []);

  const closeDialog = () => {
    shortcutsDialogHandlerReactService.closeKeyboardShortcutDialog();
  };

  const [isOpen] = useObservableState(shortcutsDialogHandlerReactService.isKeyboardShortcutDialogOpen());

  return (
    <HdModal
      open={isOpen}
      onClose={closeDialog}
      placement='top'
      contentClassName={styles.dialogContent}
    >
      <>
        <div className='dialog-header'>
          <div className='dialog-title'>Keyboard Shortcuts</div>
          <HdIconButton
            onClick={closeDialog}
            className='ml-2 pure-ripple'
            dataId='shortcuts-dialog-close'
          >
            <HdIcon name='close' size={3} />
          </HdIconButton>
        </div>

        <div className={`dialog-body ${styles.dialogBody}`}>
          <div className='row'>
            {shortcuts.map((shortcutGroup: any, index) => (
              <div
                key={shortcutGroup.name}
                className={`col-md-6 col-xs-12 ${styles.shortcutGroup} ${
                  index === shortcuts.length - 2 ? 'mb-md-0' : ''
                }`}
              >
                <div className='mb-5 text-body-2'>{shortcutGroup.name}</div>

                {shortcutGroup.keys.map(shortcutKey => (
                  <div key={shortcutKey} className={`row ${styles.shortcut}`}>
                    <div className={`col-xs-4 ${styles.shortcutKeyCombo}`}>
                      {shortcutGroup.map[shortcutKey].command.split('.').map(key => (
                        <span key={key} className={styles.shortcutKey}>
                          {getKeyIconClass(key) ? (
                            <span className={`hevo-icon icon-size-1 ${getKeyIconClass(key)}`} />
                          ) : (
                            <span className='icon-size-1'>{key}</span>
                          )}
                        </span>
                      ))}
                    </div>

                    <div className='col-xs-8'>{shortcutGroup.map[shortcutKey].description}</div>
                  </div>
                ))}
              </div>
            ))}
          </div>
        </div>
      </>
    </HdModal>
  );
}

export default ShortcutsDialog;
