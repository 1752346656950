import { BehaviorSubject, Observable, of, Subject, timer } from 'rxjs';
import { exhaustMap, map, takeUntil, tap } from 'rxjs/operators';
import { SettingsStorageService } from '../../core/services/settings-storage.service';
import { SourceService } from '../../core/services/source.service';


export class ApiQuotaWarningService {
  static deps = [
    SourceService,
    SettingsStorageService
  ];

  private _apiQuotaRemainingMessageBanner$ = new BehaviorSubject<{
    [id: number]: {
      total?: number;
      remaining?: number;
      consumed?: number;
      showWarning: boolean;
    }
  }>({});

  private _closeBanner$ = new Subject();

  constructor(
    private _sourceService: SourceService,
    private _settingsStorageService: SettingsStorageService
  ) {

  }

  hideApiQuotaRemainingMessageBanner(pipelineId: number) {
    this._apiQuotaRemainingMessageBanner$.next({
      ...this._apiQuotaRemainingMessageBanner$.getValue(),
      [pipelineId]: {
        showWarning: false
      }
    });

    this._settingsStorageService.applySettings('API_QUOTA_BANNER_STATUS', {
      pipelineId: pipelineId,
      bannerIsClosed: true
    });

    this._closeBanner$.next();
  }

  getApiQuotaObservable(pipelineId: number) {
    let observable: Observable<{
      total?: number;
      remaining?: number;
      consumed?: number;
      showWarning: boolean;
    }>;

    const bannerStatus = this._settingsStorageService.getSettings('API_QUOTA_BANNER_STATUS');

    if (pipelineId === bannerStatus?.pipelineId && bannerStatus.bannerIsClosed) {
      observable = of(1).pipe(
        tap(() => {
          this._apiQuotaRemainingMessageBanner$.next({
            [pipelineId]: {
              showWarning: false
            }
          });
        }),
        map(() => this._apiQuotaRemainingMessageBanner$.getValue()[pipelineId])
      );
    } else {
      observable = timer(0, 1800000).pipe(
        exhaustMap(() => {
          return this._sourceService.getApiQuotaStatus(pipelineId).pipe(
            tap((apiVal: any) => {
              const apiQuota = apiVal?.data?.limits?.daily_bulk_api_batches;
              if (apiQuota) {
                const totalConsumed = (apiQuota.total - apiQuota.remaining) / apiQuota.total * 100;

                this._apiQuotaRemainingMessageBanner$.next({
                  [pipelineId]: {
                    total: apiQuota.total,
                    remaining: apiQuota.remaining,
                    consumed: totalConsumed,
                    showWarning: totalConsumed > 80
                  }
                });
              } else {
                this._apiQuotaRemainingMessageBanner$.next({
                  [pipelineId]: {
                    showWarning: false
                  }
                });
              }
            })
          );
        }),
      );
    }

    return observable.pipe(
      takeUntil(this._closeBanner$)
    );
  }
}
