import { useEffect, useState } from 'react';
import { TrialDetailsService } from '../containers/core/services/trial-details.service';
import useAnalyticsTracker from './useAnalyticsTracker';
import useService from './useService';

export function useScheduleCall() {
  const [ visible, setVisible ] = useState(false);
  const { eventTrack } = useAnalyticsTracker();
  const trialDetailsService = useService(TrialDetailsService);

  const openScheduleCallLink = (url: string, action: string, section: string) => {
    window.open(url, '_blank');

    eventTrack({
      action: action,
      properties: {
        section: section
      }
    });
  };

  useEffect(() => {
    const sub = trialDetailsService.trialDetails$.subscribe(details => {
      setVisible(details.isInTrial());
    });

    return () => {
      sub.unsubscribe();
    };
  }, []);

  return {
    openScheduleCallLink,
    scheduleCallVisible: visible
  };
}
