import { BehaviorSubject } from 'rxjs';

export class DestinationCommService {

  private _needDestinationTablesRefresh$ = new BehaviorSubject(false);

  constructor() { }

  public needDestinationTablesRefresh$ = this._needDestinationTablesRefresh$.asObservable();

  refreshDestinationTables() {
    this._needDestinationTablesRefresh$.next(true);
  }
}
