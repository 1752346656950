import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';
import { ProductVersion } from './models';
import styles from './styles.module.scss';

declare var __DASHBOARD_VERSION__: string;
declare var __VALYRIA_HOST__: string;
declare var __GROOT_HOST__: string;


export function VersionPage() {
  const [ productVersions, setProductVersions ] = useState(null);

  const getProductVersion = () => {
    const servicesUrl = '/api/config/v1.0/utility/app-version';
    const valyriaUrl = __VALYRIA_HOST__ + '/utility/version';
    const grootUrl = __GROOT_HOST__ + '/core/v1.0/app-version';

    forkJoin(
      axios.get(servicesUrl, { headers: { 'Content-Type': 'application/json' } }).then(res => res.data),
      axios.get(valyriaUrl, {
        headers: { 'Anonymous': 'True' }
      }).then(res => res.data).catch(
        (err => ({ data: { 'app-version': 'Unable to fetch version' }}))
      ),
      axios.get(grootUrl, {
        headers: { 'Anonymous': 'True' }
      }).then(res => res.data).catch(
        (err => ({ data: { 'app-version': 'Unable to fetch version' }}))
      )
    ).pipe(
      map((res: any[]) => {
        return [
          {
            name: 'Dashboard',
            version: __DASHBOARD_VERSION__
          },
          {
            name: 'Services',
            version: res[0].data['app-version']
          },
          {
            name: 'Valyria',
            version: res[1].data['app-version']
          },
          {
            name: 'Groot',
            version: res[2].data['app-version']
          }
        ];
      })
    ).subscribe((res) => {
      setProductVersions(res);
    });
  };

  useEffect(() => {
    getProductVersion();
  }, []);

  return (
    <>
      {!productVersions ? 'Loading Version Info' : (
        <VersionPageInner
          productVersions={productVersions}
        />
      )}
    </>
  );
}

function VersionPageInner({
  productVersions
}: {
  productVersions: ProductVersion[]
}) {
  return (
    <table className={styles.versionTable}>
      <thead>
        <tr>
          <th>Application</th>
          <th>Version</th>
        </tr>
      </thead>

      <tbody>
        {productVersions.map((productVersion, index) => (
          <tr key={index}>
            <td>{productVersion.name}</td>
            <td>{productVersion.version}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}
