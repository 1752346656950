import { Field, useFormikContext } from 'formik';
import React, { useContext } from 'react';
import { Grid, Typography } from '@mui/material';
import HdFormControl from '../../../components/UIElements/HdFormControl';
import {
  CRON_TYPE_DAILY,
  CRON_TYPE_FIXED_INTERVAL,
  FREQUENCY_TYPE_HOURS
} from '../ExecutionPolicyDialog/constants';
import {
  TRACKER_CHANGE_SCHEDULE_DIALOG_RUN_AT_FIXED_INTERVAL_CLICK,
  TRACKER_CHANGE_SCHEDULE_DIALOG_RUN_DAILY_CLICK
} from '../constants';
import FrequencyForm from './FrequencyForm';
import useAnalyticsTracker from '../../../hooks/useAnalyticsTracker';
import { PolicyTypeEnum } from '../ExecutionPolicyDialog/models';
import { getDataIdGenerator } from '../../../utils/generateDataId';
import { HdIconButton, HdRadio, HdRadioGroup, HdTooltip } from '../../../components/UIElements';
import MultiCronTimeListComponent from './MultiCronTimeList';
import HdIcon from '../../../components/UIElements/HdIcon';
import { ExecutionPolicyContext } from '../utils';

export default function MultiCronForm({ setPolicyObj, entity, policyObj }) {
  const { values } = useFormikContext<{
    selectedCustomOption: string;
  }>();

  return (
    <>
      <Grid container>
        <Grid item>
          <HdFormControl>
            <Field
              name='selectedCustomOption'
              required
              component={PolicyTypeRadioGroup}
              entity={entity}
              setPolicyObj={setPolicyObj}
              policyObj={policyObj}
            />
          </HdFormControl>
        </Grid>
      </Grid>

      {values.selectedCustomOption === CRON_TYPE_FIXED_INTERVAL.value && (
        <Grid container spacing={2}>
          <Grid item xs={6} className='center-flex-row'>
            <FrequencyForm policyObj={policyObj} setPolicyObj={setPolicyObj} />
          </Grid>
        </Grid>
      )}

      {values.selectedCustomOption === CRON_TYPE_DAILY.value && (
        <MultiCronTimeListComponent
          entity={entity}
          policyObj={policyObj}
          setPolicyObj={setPolicyObj}
        />
      )}
    </>
  );
}

function PolicyTypeRadioGroup({ field, form, entity, setPolicyObj }) {
  const { eventTrack } = useAnalyticsTracker();
  const { tooltipData, setTooltipData } = useContext(ExecutionPolicyContext);

  const onRadioButtonClick = (selectedOption: string) => {
    form.resetForm();
    eventTrack({
      action:
        selectedOption === CRON_TYPE_FIXED_INTERVAL.value
          ? TRACKER_CHANGE_SCHEDULE_DIALOG_RUN_AT_FIXED_INTERVAL_CLICK
          : TRACKER_CHANGE_SCHEDULE_DIALOG_RUN_DAILY_CLICK,
      properties: {
        entity
      }
    });

    if (selectedOption === CRON_TYPE_DAILY.value) {
      setPolicyObj(prev => ({
        ...prev,
        type: PolicyTypeEnum.CRON,
        cronType: CRON_TYPE_DAILY,
        cronTimeList: [null]
      }));
    } else {
      setPolicyObj(prev => ({
        ...prev,
        type: PolicyTypeEnum.FREQUENCY,
        frequencyType: FREQUENCY_TYPE_HOURS,
        frequencyValue: undefined,
        cronTimeList: []
      }));

      eventTrack({
        action: TRACKER_CHANGE_SCHEDULE_DIALOG_RUN_AT_FIXED_INTERVAL_CLICK,
        properties: {
          entity
        }
      });
    }
  };

  const closeTooltip = () => {
    setTooltipData(prev => ({
      ...prev,
      showTooltip: false
    }));
  };

  const dataIdGenerator = getDataIdGenerator('PolicyTypeRadioGroup');

  return (
    <HdRadioGroup
      {...field}
      name='selectedCustomOption'
      onChange={e => {
        onRadioButtonClick(e);
        form.setFieldValue(field.name, e);
      }}
    >
      <HdRadio
        dataId={dataIdGenerator(CRON_TYPE_FIXED_INTERVAL.value)}
        key={CRON_TYPE_FIXED_INTERVAL.value}
        value={CRON_TYPE_FIXED_INTERVAL.value}
      >
        <div className='pl-2 ml-1'>
          <Typography variant='body2'>Run at fixed interval</Typography>
        </div>
      </HdRadio>

      <HdTooltip
        disableHoverListener
        placement='right'
        disableInteractive={false}
        mode='surface'
        forceOpen={tooltipData.showTooltip}
        disabled={!tooltipData.message}
        title={<ShowDailyRunMsg closeTooltip={closeTooltip} tooltipMessage={tooltipData.message} />}
      >
        <div>
          <HdRadio
            dataId={dataIdGenerator(CRON_TYPE_DAILY.value)}
            key={CRON_TYPE_DAILY.value}
            value={CRON_TYPE_DAILY.value}
            className='ml-5'
          >
            <div className='pl-2 ml-1'>
              <Typography variant='body2'>Run daily</Typography>
            </div>
          </HdRadio>
        </div>
      </HdTooltip>
    </HdRadioGroup>
  );
}

function ShowDailyRunMsg({ tooltipMessage, closeTooltip }) {
  return (
    <div className='d-flex align-items-start justify-between'>
      <div>{tooltipMessage}</div>

      <HdIconButton
        horizontalEdge='both'
        onClick={closeTooltip}
        dataId='tooltip-close'
        className='p-0 m-0 popup-action'
      >
        <HdIcon name='close' size={1} />
      </HdIconButton>
    </div>
  );
}
