import { TeamSettingsService } from './team-settings.service';

export let _sshService;

export class SSHService {
  static deps = [
    TeamSettingsService
  ];

  constructor(private _teamSettingsService: TeamSettingsService) {
    _sshService = this;
  }

  buildSSHParams(params, data) {
    const vpcPeeringConfig = this._teamSettingsService.getVPCPeeringConfig();

    if (vpcPeeringConfig.enabled) {
      params.ssh_host = vpcPeeringConfig.host;
      params.ssh_user = 'hevo_vpc_peering';
      params.ssh_port = '1278';
    } else if (data.useSSH) {
      params.ssh_user = data.sshUser;
      params.ssh_host = data.sshIp;
      params.ssh_port = data.sshPort;
    }
  }

  sshEnabled(data) {
    return this._teamSettingsService.getVPCPeeringConfig().enabled || data.useSSH;
  }

  filterSSHDisplayConfig(displayConfig: {[key: string]: any}) {
    if (!this._teamSettingsService.getVPCPeeringConfig().enabled || !displayConfig) {
      return displayConfig;
    }

    const newConfig = { ...displayConfig };

    delete newConfig['SSH Host'];
    delete newConfig['SSH Port'];
    delete newConfig['SSH User'];

    return newConfig;
  }

  getSSHParams(data) {
    const vpcPeeringConfig = this._teamSettingsService.getVPCPeeringConfig();

    if (vpcPeeringConfig.enabled) {
      return {
        ssh_host: vpcPeeringConfig.host,
        ssh_user: 'hevo_vpc_peering',
        ssh_port: '1278'
      };
    } else if (data.useSSH) {
      return {
        ssh_host: data.sshIp,
        ssh_user: data.sshUser,
        ssh_port: data.sshPort
      };
    }
  }
}
