export class BeaconService {
  sendBeacon(url: string, data: any) {
    if (navigator && typeof navigator.sendBeacon === 'function') {
      try {
        return navigator.sendBeacon(
          url,
          data
        );
      } catch (e) {
        return false;
      }
    }
  }
}
