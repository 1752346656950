import { AppConfig } from '../../core/app.config';
import {
  BACKGROUND_NETWORK_REQ_OPTIONS,
  UNSUBSCRIPTION_TYPE
} from '../../core/constants';
import { NetworkRequestOptions } from '../../core/models/request';
import { courier } from '../../core/services/courier.service';

const appConfig = new AppConfig();

const NotificationPreferencesAPI = {
  notificationPreferenceUrl: appConfig.getAlertPreferencesURL(),
  notificationPreferenceUrlV2: appConfig.getAlertExternalPreferencesV2URL(),
  externalNotificationPreferenceUrl: `${appConfig.getAlertPreferencesURL()}/email`,

  getNotificationPreference(networkPreferences = {}) {
    return courier.get(this.notificationPreferenceUrl, {
      ...BACKGROUND_NETWORK_REQ_OPTIONS,
      ...networkPreferences
    });
  },

  getExternalEmailNotificationPreference(email, networkPreferences = {}) {
    const url = `${this.externalNotificationPreferenceUrl}/${email}`;
    return courier.get(url, {
      ...BACKGROUND_NETWORK_REQ_OPTIONS,
      ...networkPreferences
    });
  },

  setExternalEmailNotificationPreference(email, params) {
    const url = `${this.externalNotificationPreferenceUrl}/${email}`;
    const options: NetworkRequestOptions = {
      uiOptions: {
        showLoading: false
      }
    };

    return courier.put(url, options, params);
  },

  addEmails(emails: string[]) {
    return courier.post(this.notificationPreferenceUrlV2, BACKGROUND_NETWORK_REQ_OPTIONS, emails);
  },

  deleteEmail(email: string) {
    return courier.delete(`${this.notificationPreferenceUrlV2}/email/${email}`, {
      uiOptions: {
        showErrorMsg: true,
        showLoading: false
      }
    });
  },

  setNotificationPreference(params: any) {
    const options: NetworkRequestOptions = {
      uiOptions: {
        showLoading: false
      }
    };

    return courier.put(this.notificationPreferenceUrl, options, params);
  },

  fetchEmails() {
    const options: NetworkRequestOptions = {
      uiOptions: {
        showLoading: false
      }
    };

    return courier.get(this.notificationPreferenceUrlV2, options);
  },

  toggleBillingSummaryEmail(
    billingSummaryEmailStatus: boolean,
    dashboardStatus: boolean,
    slackStatus: boolean
  ) {
    const params = {
      USAGE_REPORT: {
        DASHBOARD: dashboardStatus,
        SLACK: slackStatus,
        EMAIL: billingSummaryEmailStatus
      }
    };

    const options: NetworkRequestOptions = {
      uiOptions: {
        showLoading: true
      }
    };

    return courier.put(this.notificationPreferenceUrl, options, params);
  },

  unsubscribeEmailNotifications(
    encryptedIdentifier: string,
    unsubscriptionType: UNSUBSCRIPTION_TYPE
  ) {
    const unsubscribeUrl = `${this.notificationPreferenceUrl}/unsubscribe/${unsubscriptionType}/${encryptedIdentifier}`;

    return courier.put(unsubscribeUrl, BACKGROUND_NETWORK_REQ_OPTIONS);
  }
};

export default NotificationPreferencesAPI;
