import { easings, useTransition } from '@react-spring/web';
import React, { useEffect, useRef, useState } from 'react';
import { ToasterService } from './toaster.service';
import useService from '../../hooks/useService';
import { Toast } from './Toast';
import styles from './styles.module.scss';
import { ToastConfig } from './toast-config';

const DEFAULT_TOASTER_TIMEOUT = 5000;

export function ToasterContainer() {
  const [ toastConfig, setToastConfig ] = useState<ToastConfig>(null);

  const timeoutRef = useRef(null);

  const toasterService = useService(ToasterService);

  useEffect(() => {
    const addToastSub = toasterService.addToastSubject.subscribe((_toast) => {
      setToastConfig(_toast);
    });

    return (() => {
      addToastSub.unsubscribe();
      clearTimer();
    });
  }, []);

  useEffect(() => {
    clearTimer();

    if (toastConfig) {
      setTimer();
    }
  }, [ toastConfig ]);

  const clearTimer = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  };

  const restartTimer = () => {
    if (toastConfig) {
      setTimer();
    }
  };

  const setTimer = () => {
    timeoutRef.current = setTimeout(() => {
      setToastConfig(null);
    }, toastConfig.timeout || DEFAULT_TOASTER_TIMEOUT);
  };

  const transition = useTransition(toastConfig || [], {
    from: {
      opacity: 0
    },
    enter: {
      opacity: 1
    },
    leave: {
      opacity: 0
    },
    config: {
      duration: 300,
      easing: easings.easeInOutCubic
    }
  });

  return (
    <div className={styles.toasterContainer}>
      {transition((styles, _toastConfig) => (
        <Toast
          {..._toastConfig}
          onMouseOver={clearTimer}
          onMouseOut={restartTimer}
          onCloseClick={() => setToastConfig(null)}
          springValues={styles}
        />
      ))}
    </div>
  );
}
