import { ColorMode } from '../containers/core/models/color-mode';
import { convertToRgba } from '../legacy-utils/colors';
import { useActiveTheme } from '../utils/themeProvider';

export default function useEntityLogoBg(primaryColor, darkModePrimaryColor) {
  const colorMode = useActiveTheme();

  if (colorMode === ColorMode.DARK && darkModePrimaryColor) {
    return convertToRgba(darkModePrimaryColor, 0.15);
  }

  return convertToRgba(primaryColor, 0.1);
}
