import { _userServiceInstance } from '../../../../core/services/user.service';
import { AWSAuthenticationType, AWSFormField } from './model';

export class AwsConfigHelper {
  static getConfigFromRawData(rawData: any) {
    const config: any = {
      [AWSFormField.AUTH_TYPE]: rawData.auth_type
        ? AWSAuthenticationType[rawData.auth_type]
        : AWSAuthenticationType.ACCESS_CREDENTIALS
    };

    if (config[AWSFormField.AUTH_TYPE] === AWSAuthenticationType.IAM_ROLE) {
      config[AWSFormField.IAM_ROLE_ARN] = rawData.aws_iam_role_arn;
      config[AWSFormField.IAM_EXTERNAL_ID] = rawData.aws_iam_role_external_id;
    } else {
      config[AWSFormField.ACCESS_KEY] = rawData.aws_access_key;
      config[AWSFormField.SECRET_KEY] = rawData.aws_secret_key;
    }

    return config;
  }

  static buildConnectionParams(data: any, addTeamId = true) {
    const params: any = {
      auth_type: data[AWSFormField.AUTH_TYPE]
    };

    // this is required to fetch the aws role arn from team settings
    if (addTeamId) {
      params.team_id = _userServiceInstance.getUser().teamId;
    }

    if (data[AWSFormField.AUTH_TYPE] === AWSAuthenticationType.IAM_ROLE) {
      params.aws_iam_role_arn = data[AWSFormField.IAM_ROLE_ARN];
      params.aws_iam_role_external_id = data[AWSFormField.IAM_EXTERNAL_ID];
    } else {
      params.aws_access_key = data[AWSFormField.ACCESS_KEY];
      params.aws_secret_key = data[AWSFormField.SECRET_KEY];
    }

    return params;
  }
}
