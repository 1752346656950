import { BehaviorSubject } from 'rxjs';

interface EntityData {
  entity: any;
  fastTravel: boolean;
}

export class GlobalSearchCreateNewEntityService {
  private _sourceType$ = new BehaviorSubject<EntityData>(null);
  private _destinationType$ = new BehaviorSubject<EntityData>(null);

  constructor() {
  }

  selectSourceType(sourceType$) {
    this._sourceType$.next(sourceType$);
  }

  selectDestinationType(destinationType$) {
    this._destinationType$.next(destinationType$);
  }

  getDestinationType() {
    return this._destinationType$.getValue();
  }

  getSourceType() {
    return this._sourceType$.getValue()?.entity;
  }
}
