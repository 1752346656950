import { StatsDataPointsFactory } from './ActivationStats/factory';
import {
  StatsRunStageInfo,
  StatsRunStages
} from './ActivationSyncHistory/models/stats-run-stage';
import { SyncFailureTypes } from './ActivationSyncHistory/models/sync-failure';
import { ActivationRunType } from './ActivationSyncHistory/models/sync-history-item';
import { ActivationStrings } from './strings';
import { AppConfig } from '../core/app.config';
import {
  BACKGROUND_NETWORK_REQ_OPTIONS,
  BACKGROUND_NETWORK_REQ_UI_OPTIONS
} from '../core/constants';
import { NetworkRequestOptions, RequestPaginationData } from '../core/models/request';
import { courier } from '../core/services/courier.service';
import { createHttpParams } from '../../legacy-utils/request';

export interface UpdateActivationQueryParams {
  queryUpdated?: boolean;
  restartActivation?: boolean;
}

const appConfig = new AppConfig();
const ActivationsAPI = {
  activationRunsUrl: appConfig.getActivationRunsURL(),
  activationUrl: appConfig.getActivationsURL(),
  activationWarehouseUrl: appConfig.getWarehousesURL(),

  getActivations() {
    return courier.get(this.activationUrl, BACKGROUND_NETWORK_REQ_OPTIONS);
  },

  getActivationRunDuplicates(activationRunId: number) {
    const requestUrl = `${this.activationRunsUrl}/${activationRunId}/duplicates`;

    return courier.get(requestUrl, BACKGROUND_NETWORK_REQ_OPTIONS);
  },

  getActivationRunError(activationRunId: number) {
    const requestUrl = `${this.activationRunsUrl}/${activationRunId}/errors`;

    return courier.get(requestUrl, BACKGROUND_NETWORK_REQ_OPTIONS);
  },

  getActivationRunErrorReport(activationRunId: number) {
    const requestUrl = `${this.activationRunsUrl}/${activationRunId}/error-downloads`;

    const options: NetworkRequestOptions = {
      uiOptions: BACKGROUND_NETWORK_REQ_UI_OPTIONS,
      networkOptions: {
        responseType: 'text'
      }
    };

    return courier.get(requestUrl, options);
  },

  getActivationSyncHistory(params) {
    const requestUrl = `${this.activationRunsUrl}/recent`;

    const options: NetworkRequestOptions = {
      uiOptions: BACKGROUND_NETWORK_REQ_UI_OPTIONS,
      networkOptions: {
        params: createHttpParams(params)
      }
    };

    return courier.get(requestUrl, options);
  },

  getActivationRunStats(activationId: number, minutes: number, maxPoints: number) {
    const requestUrl = `${this.activationUrl}/stats/${activationId}`;

    const options: NetworkRequestOptions = {
      networkOptions: {
        params: createHttpParams({
          since_minutes: minutes,
          max_points: maxPoints
        })
      },
      uiOptions: BACKGROUND_NETWORK_REQ_UI_OPTIONS
    };

    return courier.get(requestUrl, options).then(res => {
      const statsData = res.data;
      const statsRunStages = StatsRunStages.toArray();

      return statsRunStages.map((runStage: StatsRunStageInfo) => ({
        runStageInfo: runStage,
        dataPoints: StatsDataPointsFactory(statsData[runStage.rawValue])
      }));
    });
  },

  getActivationRunErrorMeta(activationId: number, failureType: SyncFailureTypes) {
    const requestUrl = `${this.activationUrl}/${activationId}/errors-meta/${failureType}`;

    return courier.get(requestUrl, BACKGROUND_NETWORK_REQ_OPTIONS);
  },

  getActivationRunErrorBasedOnType(activationId: number, failureType: SyncFailureTypes) {
    const requestUrl = `${this.activationUrl}/${activationId}/errors/${failureType}`;

    return courier.get(requestUrl, BACKGROUND_NETWORK_REQ_OPTIONS);
  },

  getActivationLastRetriedRun(activationId: number) {
    const requestUrl = `${this.activationRunsUrl}/recent`;

    const params: any = {
      activation_id: activationId,
      limit: 1,
      run_type: ActivationRunType.RETRY_TEMP_ERRORS
    };

    const networkOpts: NetworkRequestOptions = {
      uiOptions: BACKGROUND_NETWORK_REQ_UI_OPTIONS,
      networkOptions: {
        params: createHttpParams(params)
      }
    };

    return courier.get(requestUrl, networkOpts);
  },

  getActivationRunErrorReportOnType(activationId: number, failureType: SyncFailureTypes) {
    const requestUrl = `${this.activationUrl}/${activationId}/error-downloads/${failureType}`;

    const options: NetworkRequestOptions = {
      uiOptions: BACKGROUND_NETWORK_REQ_UI_OPTIONS,
      networkOptions: {
        responseType: 'text'
      }
    };

    return courier.get(requestUrl, options);
  },

  retryFailedActivationRun(activationId: number) {
    const requestUrl = `${this.activationUrl}/${activationId}/retry-temp-errors`;

    const options: NetworkRequestOptions = {
      uiOptions: {
        showLoading: false,
        successMsg: ActivationStrings.triggerRetryRunSuccessMessage
      }
    };

    return courier.put(requestUrl, options);
  },


  getActivationsForWarehouse(warehouseId: number) {
    const requestUrl = `${ this.activationWarehouseUrl }/${ warehouseId }/activations-display-details`;

    return courier.get(requestUrl, BACKGROUND_NETWORK_REQ_OPTIONS);
  },

  pauseActivation(activationId: number) {
    const requestUrl = `${this.activationUrl}/${activationId}/states/PAUSED`;

    const options: NetworkRequestOptions = {
      uiOptions: {
        ...BACKGROUND_NETWORK_REQ_UI_OPTIONS,
        showErrorMsg: true
      }
    };

    return courier.put(requestUrl, options);
  },

  resumeActivation(activationId: number) {
    const requestUrl = `${this.activationUrl}/${activationId}/states/ACTIVE`;

    const options: NetworkRequestOptions = {
      uiOptions: {
        ...BACKGROUND_NETWORK_REQ_UI_OPTIONS,
        showErrorMsg: true
      }
    };
    return courier.put(requestUrl, options);
  },

  updateActivation(
    activationId: number,
    body: any,
    queryParams: UpdateActivationQueryParams = null,
    uiOptions = BACKGROUND_NETWORK_REQ_UI_OPTIONS
  ) {
    const requestUrl = `${this.activationUrl}/${activationId}`;

    const params: any = {};
    if (queryParams && Object.keys(queryParams).length) {
      if (queryParams.queryUpdated) {
        params.query_changed = true;
      }

      if (queryParams.restartActivation) {
        params.restart = true;
      }
    }

    const options: NetworkRequestOptions = {
      uiOptions: {
        ...uiOptions,
        successMsg: uiOptions?.showSuccessMsg ? 'Activation was updated successfully' : ''
      },
      networkOptions: {
        params: createHttpParams(params)
      }
    };

    return courier.put(requestUrl, options, body);
  },

  createActivation(params: any) {
    const requestUrl = this.activationUrl;

    const options: NetworkRequestOptions = {
      uiOptions: {
        successMsg: 'Activation created successfully'
      }
    };

    return courier.post(requestUrl, options, params);
  },

  getActivation(activationSeqId: any, showLoading = false) {
    const requestUrl = this.activationUrl + `/${ activationSeqId }/details`;

    const options: NetworkRequestOptions = {
      uiOptions: {
        showSuccessMsg: false,
        showLoading: showLoading
      }
    };

    return courier.get(requestUrl, options);
  },

  deleteActivation(activationId: number) {
    const requestUrl = this.activationUrl + '/' + activationId;

    const options: NetworkRequestOptions = {
      uiOptions: {
        ...BACKGROUND_NETWORK_REQ_UI_OPTIONS,
        showErrorMsg: true
      }
    };

    return courier.delete(requestUrl, options);
  },

  runNow(activationId: number) {
    const requestUrl = this.activationUrl + `/${ activationId }/instant-run`;

    const options: NetworkRequestOptions = {
      uiOptions: {
        showLoading: false,
        successMsg: ActivationStrings.triggerInstantRunSuccessMessage
      }
    };

    return courier.put(requestUrl, options);
  },

  restartActivation(activationId: number) {
    const requestUrl = this.activationUrl + `/${ activationId }/restart`;

    const options: NetworkRequestOptions = {
      uiOptions: {
        successMsg: 'Activation restarted successfully'
      }
    };

    return courier.put(requestUrl, options);
  },

  getActivationRunsResult(activationId: number, paginationData: RequestPaginationData) {
    const requestUrl = this.activationRunsUrl + `/recent`;

    const params: any = {
      activation_id: activationId,
      ...paginationData
    };

    if ('page' in params) {
      params['offset'] = params.page;
      delete params.page;
    }

    const networkOpts: NetworkRequestOptions = {
      uiOptions: BACKGROUND_NETWORK_REQ_UI_OPTIONS,
      networkOptions: {
        params: createHttpParams(params)
      }
    };

    return courier.get(requestUrl, networkOpts);
  },

  getQueryMappings(query: string, activationId: number) {
    const requestUrl = this.activationUrl + `/${ activationId }/query-mappings`;

    const params = {
      query: query
    };

    return courier.post(requestUrl, BACKGROUND_NETWORK_REQ_OPTIONS, params);
  },

  getWarehouseQueryFields(params: any) {
    const requestUrl = this.activationUrl + '/warehouse/fetch-query-fields';

    return courier.post(requestUrl, BACKGROUND_NETWORK_REQ_OPTIONS, params);
  },
};

export default ActivationsAPI;
