import { execIfDefined } from '../../../legacy-utils/exec-if-defined';
import { UserService } from './user.service';
import { appendQueryParams } from '../../../legacy-utils/url';
import { createHttpParams } from '../../../legacy-utils/request';

declare var Calendly: any;

export class CalendlyService {
  static deps = [
    UserService
  ];

  constructor(
    private _userService: UserService
  ) {
  }

  openWidget(url) {
    execIfDefined('Calendly', () => {
      Calendly.initPopupWidget({
        url: this.getWidgetURL(url),
        prefill: this.getUserData()
      });
    });
  }

  openUrl(url) {
    window.open(this.getNewTabURL(url), '_blank');
  }

  getWidgetURL(url) {
    const themeProperties = this.getThemeProperties();

    const params = {
      background_color: themeProperties.background,
      primary_color: themeProperties.primaryColor,
      text_color: themeProperties.textColor
    };

    return this.getUrlWithQueryParams(url, params);
  }

  getNewTabURL(url) {
    const themeProperties = this.getThemeProperties();

    const params = {
      ...this.getUserData(),
      primary_color: themeProperties.lightModePrimaryColor,
      text_color: themeProperties.lightModeTextColor
    };

    return this.getUrlWithQueryParams(url, params);
  }

  getUrlWithQueryParams(url, params) {
    return appendQueryParams(url, createHttpParams(params).toString());
  }

  getUserData() {
    const user = this._userService.getUser();
    return {
      name: user.name.match(user.email) ? null : user.name,
      email: user.email
    };
  }

  getThemeProperties() {
    const bodyComputedStyles = getComputedStyle(document.body);

    return {
      background: this._prepColor(bodyComputedStyles.getPropertyValue('--calendly-surface-bg')),
      textColor: this._prepColor(bodyComputedStyles.getPropertyValue('--calendly-text-color')),
      primaryColor: this._prepColor(bodyComputedStyles.getPropertyValue('--calendly-primary-color')),
      lightModePrimaryColor: this._prepColor(bodyComputedStyles.getPropertyValue('--blue-400')),
      lightModeTextColor: this._prepColor(bodyComputedStyles.getPropertyValue('--dark-text'))
    };
  }

  private _prepColor(colorHex) {
    return colorHex.trim().replace('#', '');
  }
}
