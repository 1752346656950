import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { NodeLogo } from '../../../../components/NodeLogo';
import { HdDocLink, HdIcon, HdTooltip } from '../../../../components/UIElements';
import { SOURCE_TYPES } from '../../../../components/Node/source-type/source-type';
import { DESTINATION_TYPES } from '../../../../components/Node/destination-type/model';
import { TARGET_TYPES } from '../../../activate-target/models/target-type';
import RoundedIcon from '../../../../components/RoundedIcon';
import { GlobalSearchEntityAlgoliaSearchResultData, GlobalSearchMenuItemType } from '../models';
import styles from '../styles.module.scss';
import {
  ENTITIES_HEADER_DATA,
  GLOBAL_SEARCH_ALL_ENTITIES_LINKS,
  RBAC_PROTECTED_ROUTES
} from '../constants';
import { GlobalSearchStateEntity, GlobalSearchEntity } from '../GlobalSearchReducer';
import { GlobalSearchEntitiesHeader } from './Layout';
import { GlobalSearchMenuItem } from './GlobalSearchMenuItem';
import { GlobalSearchEntitiesShimmer } from './GlobalSearchShimmer';
import useHasPermission from '../../../../hooks/useHasPermission';

function getPath(entityType, location): string | null {
  const url = GLOBAL_SEARCH_ALL_ENTITIES_LINKS[entityType];

  if (!url) {
    return null;
  }

  if (url.absolute) {
    return url.path;
  }

  return `${location.pathname}${url.path}`;
}

const EntityTypeComponent = {
  [GlobalSearchEntity.PIPELINES]: GlobalSearchEntityPipeline,
  [GlobalSearchEntity.DESTINATIONS]: GlobalSearchEntityDestination,
  [GlobalSearchEntity.MODELS]: GlobalSearchEntityModel,
  [GlobalSearchEntity.WORKFLOWS]: GlobalSearchEntityWorkflow,
  [GlobalSearchEntity.ACTIVATIONS]: GlobalSearchEntityActivation,
  [GlobalSearchEntity.TARGETS]: GlobalSearchEntityTarget,
  [GlobalSearchEntity.SOURCE_TYPES]: GlobalSearchEntitySelectSource,
  [GlobalSearchEntity.DESTINATION_TYPES]: GlobalSearchEntitySelectDestination,
  [GlobalSearchEntity.ALGOLIA_SEARCH_RESULTS]: GlobalSearchEntityDocResult
};

interface GlobalSearchEntitiesProps {
  entityType: GlobalSearchEntity;
  menuItems: Set<{ current: GlobalSearchMenuItemType }>;
  entity: GlobalSearchStateEntity;
  addMenuItemRef: Function;
  onEntitySelect: Function;
  onEntityClick?: Function;
  navigate: Function;
  dataIdGenerator: Function;
  showFilteredEntitiesCount?: boolean;
  showViewAllEntitiesCTA?: boolean;
  location: object;
  className?: string;
}

export function GlobalSearchEntities({
  entityType,
  menuItems,
  addMenuItemRef,
  onEntitySelect,
  onEntityClick,
  navigate,
  location,
  entity,
  dataIdGenerator,
  showFilteredEntitiesCount = true,
  showViewAllEntitiesCTA = true,
  className = ''
}: GlobalSearchEntitiesProps) {
  const [url] = useState(getPath(entityType, location));

  const showLoadingShimmer = entity.loadingData;
  const showEntities = !showLoadingShimmer && entity.filteredEntities.length > 0;
  const entityHeaderData = ENTITIES_HEADER_DATA[entityType];

  const wrapperClasses = clsx(styles.globalSearchEntities, className);

  const Component = EntityTypeComponent[entityType];

  return (
    <div className={wrapperClasses} data-id={dataIdGenerator(`${entityType}-list`)}>
      {entityHeaderData ? (
        <GlobalSearchEntitiesHeader
          showFilteredCount={showFilteredEntitiesCount && !showLoadingShimmer}
          cta={entityHeaderData?.cta}
          dataId={dataIdGenerator(`${entityType}-view-all`)}
          entityHeader={entityHeaderData.headerText}
          filterEntities={entity.filteredEntities.length}
          totalEntities={entity.entities.length}
          navigate={navigate}
          path={url}
          showCTA={!showLoadingShimmer && showViewAllEntitiesCTA && !!entityHeaderData?.cta}
        />
      ) : null}

      {showLoadingShimmer ? (
        <GlobalSearchEntitiesShimmer dataId={dataIdGenerator('entities-loading-shimmer')} />
      ) : null}

      {showEntities
        ? entity.filteredEntities.map((filteredEntity, index) => (
            <GlobalSearchMenuItem
              key={filteredEntity.id}
              dataId={dataIdGenerator(entityType)}
              index={index}
              menuItems={menuItems}
              addMenuItemRef={addMenuItemRef}
              entity={{
                ...filteredEntity,
                entityType
              }}
              select={() => onEntitySelect({ ...filteredEntity, entityType })}
              onEntityClick={onEntityClick}
            >
              <Component
                entity={filteredEntity}
                dataId={dataIdGenerator(`entities-${entityType}-${filteredEntity.id}`)}
              />
            </GlobalSearchMenuItem>
          ))
        : null}
    </div>
  );
}

const NODE_LOGO_COMMON_PROPS = {
  className: 'mr-2 border-radius-md',
  size: 2,
  roundedBorders: false
};

const ROUNDED_ICON_COMMON_PROPS = {
  className: 'mr-2 border-radius-md bg-surface-overlay',
  containerSize: 8,
  isIconColorWhite: false,
  hasBg: false
};

export function GlobalSearchEntityPipeline({ entity, dataId }) {
  return (
    <>
      <div
        className={`${styles.nodeInfo} ${styles.nodeInfoResponsive}`}
        data-id={`${dataId}-pipeline-source-name`}
      >
        <HdTooltip title={entity.source_type_display}>
          <div>
            <NodeLogo
              {...NODE_LOGO_COMMON_PROPS}
              logoURL={entity?.source_type_logo_url}
              darkModeLogoURL={entity?.source_type_dark_mode_logo_url}
              primaryColor={SOURCE_TYPES[entity.source_type]?.primaryColor}
              darkModePrimaryColor={SOURCE_TYPES[entity.source_type]?.darkModePrimaryColor}
            />
          </div>
        </HdTooltip>

        <HdTooltip title={entity.source_name} enableOnTextOverflow>
          <div className={styles.nodeName}>{entity.source_name}</div>
        </HdTooltip>
      </div>

      <HdIcon
        name='pipeline-connection'
        className={`${styles.pipelineConnectionIndicator} text-${entity.pipeline_status.color_type}`}
      />

      <div className={styles.nodeInfo} data-id={`${dataId}-pipeline-dest-name`}>
        <HdTooltip title={entity.dest_type_display}>
          <div>
            <NodeLogo
              {...NODE_LOGO_COMMON_PROPS}
              logoURL={entity?.dest_type_logo_url}
              darkModeLogoURL={entity?.dest_type_dark_mode_logo_url}
              primaryColor={DESTINATION_TYPES[entity.dest_type]?.primaryColor}
              darkModePrimaryColor={DESTINATION_TYPES[entity.dest_type]?.darkModePrimaryColor}
            />
          </div>
        </HdTooltip>

        <HdTooltip title={entity.dest_name} enableOnTextOverflow>
          <div className={styles.nodeName}>{entity.dest_name}</div>
        </HdTooltip>
      </div>
    </>
  );
}

export function GlobalSearchEntityModel({ entity, dataId }) {
  return (
    <>
      <div
        className={`${styles.nodeInfo} ${styles.nodeInfoResponsive}`}
        data-id={`${dataId}-model-name`}
      >
        <RoundedIcon {...ROUNDED_ICON_COMMON_PROPS} iconName='models' />

        <HdTooltip title={entity.name} enableOnTextOverflow>
          <div className={styles.nodeName}>{entity.name}</div>
        </HdTooltip>
      </div>

      <div className={styles.nodeInfo} data-id={`${dataId}-model-logo`}>
        <NodeLogo
          {...NODE_LOGO_COMMON_PROPS}
          logoURL={entity.destination.dest_type_logo_url}
          darkModeLogoURL={entity.destination.dest_type_dark_mode_logo_url}
          primaryColor={DESTINATION_TYPES[entity.destination.dest_type].primaryColor}
          darkModePrimaryColor={
            DESTINATION_TYPES[entity.destination.dest_type].darkModePrimaryColor
          }
        />

        <HdTooltip title={entity.destination.name} enableOnTextOverflow>
          <div className={styles.nodeName}>{entity.destination.name}</div>
        </HdTooltip>
      </div>
    </>
  );
}

export function GlobalSearchEntityWorkflow({ entity, dataId }) {
  return (
    <div className={styles.nodeInfo} data-id={`${dataId}-workflow`}>
      <RoundedIcon {...ROUNDED_ICON_COMMON_PROPS} iconName='workflow' />

      <HdTooltip title={entity.name} enableOnTextOverflow>
        <div data-id={`${dataId}-workflow-name`} className={styles.nodeName}>
          {entity.name}
        </div>
      </HdTooltip>
    </div>
  );
}

export function GlobalSearchEntityDestination({ entity, dataId }) {
  return (
    <>
      <div
        className={`${styles.nodeInfo} ${styles.nodeInfoResponsive}`}
        data-id={`${dataId}-dest-name`}
      >
        <RoundedIcon {...ROUNDED_ICON_COMMON_PROPS} iconName='destinations' />

        <HdTooltip title={entity.name} enableOnTextOverflow>
          <div className={`${styles.nodeName} mr-4`}>{entity.name}</div>
        </HdTooltip>
      </div>

      <div className={styles.nodeInfo} data-id={`${dataId}-dest-logo`}>
        <NodeLogo
          {...NODE_LOGO_COMMON_PROPS}
          logoURL={entity.dest_type_logo_url}
          darkModeLogoURL={entity.dest_type_dark_mode_logo_url}
          primaryColor={DESTINATION_TYPES[entity.dest_type].primaryColor}
          darkModePrimaryColor={DESTINATION_TYPES[entity.dest_type].darkModePrimaryColor}
        />

        <HdTooltip title={entity.dest_type_display} enableOnTextOverflow>
          <div className={styles.nodeName}>{entity.dest_type_display}</div>
        </HdTooltip>
      </div>
    </>
  );
}

export function GlobalSearchEntityActivation({ entity, dataId }) {
  return (
    <>
      <div
        className={`${styles.nodeInfo} ${styles.nodeInfoResponsive}`}
        data-id={`${dataId}-activation-name`}
      >
        <HdTooltip title={entity.warehouse.typeDisplay}>
          <div>
            <NodeLogo
              {...NODE_LOGO_COMMON_PROPS}
              logoURL={entity.warehouse.warehouseTypeLogoUrl}
              darkModeLogoURL={entity.warehouse.warehouseTypeDarkModeLogoUrl}
              primaryColor={DESTINATION_TYPES[entity.warehouse.warehouseType].primaryColor}
              darkModePrimaryColor={
                DESTINATION_TYPES[entity.warehouse.warehouseType].darkModePrimaryColor
              }
            />
          </div>
        </HdTooltip>

        <HdTooltip title={entity.name} enableOnTextOverflow>
          <div className={styles.nodeName}>{entity.name}</div>
        </HdTooltip>
      </div>

      <HdIcon
        name='pipeline-connection'
        className={`${styles.pipelineConnectionIndicator} text-${entity.status.colorType}`}
      />

      <div className={styles.nodeInfo} data-id={`${dataId}-target-name`}>
        <HdTooltip title={entity.target.targetTypeDisplay}>
          <div>
            <NodeLogo
              {...NODE_LOGO_COMMON_PROPS}
              logoURL={entity.target.targetTypeLogoUrl}
              darkModeLogoURL={entity.target.targetTypeDarkModeLogoUrl}
              primaryColor={TARGET_TYPES[entity.target.targetType].primaryColor}
              darkModePrimaryColor={TARGET_TYPES[entity.target.targetType].darkModePrimaryColor}
            />
          </div>
        </HdTooltip>

        <HdTooltip title={entity.target.name} enableOnTextOverflow>
          <div className={styles.nodeName}>{entity.target.name}</div>
        </HdTooltip>
      </div>
    </>
  );
}

export function GlobalSearchEntityTarget({ entity, dataId }) {
  return (
    <>
      <div
        className={`${styles.nodeInfo} ${styles.nodeInfoResponsive}`}
        data-id={`${dataId}-target-name`}
      >
        <RoundedIcon {...ROUNDED_ICON_COMMON_PROPS} iconName='target' />

        <HdTooltip title={entity.name} enableOnTextOverflow>
          <div className={`${styles.nodeName} mr-4`}>{entity.name}</div>
        </HdTooltip>
      </div>

      <div className={styles.nodeInfo} data-id={`${dataId}-target-logo`}>
        <NodeLogo
          {...NODE_LOGO_COMMON_PROPS}
          logoURL={entity.targetTypeLogoUrl}
          darkModeLogoURL={entity.targetTypeDarkModeLogoUrl}
          primaryColor={TARGET_TYPES[entity.targetType].primaryColor}
          darkModePrimaryColor={TARGET_TYPES[entity.targetType].darkModePrimaryColor}
        />

        <HdTooltip title={entity.targetTypeDisplay} enableOnTextOverflow>
          <div className={styles.nodeName}>{entity.targetTypeDisplay}</div>
        </HdTooltip>
      </div>
    </>
  );
}

export function GlobalSearchEntitySelectSource({ entity, dataId }) {
  return (
    <div className={styles.nodeInfo} data-id={`${dataId}-source`}>
      <NodeLogo
        {...NODE_LOGO_COMMON_PROPS}
        logoURL={entity.source_type_logo_url}
        darkModeLogoURL={entity.source_type_dark_mode_logo_url || entity.source_type_logo_url}
        primaryColor={SOURCE_TYPES[entity.source_type]?.primaryColor}
        darkModePrimaryColor={SOURCE_TYPES[entity.source_type]?.darkModePrimaryColor}
      />

      <HdTooltip title={entity.source_type_display} enableOnTextOverflow>
        <div data-id={`${dataId}-source-name`} className={styles.nodeName}>
          {entity.source_type_display}
        </div>
      </HdTooltip>
    </div>
  );
}

export function GlobalSearchEntitySelectDestination({ entity, dataId }) {
  return (
    <div className={styles.nodeInfo} data-id={`${dataId}-dest`}>
      <NodeLogo
        {...NODE_LOGO_COMMON_PROPS}
        logoURL={entity.dest_type_logo_url}
        darkModeLogoURL={entity?.dest_type_dark_mode_logo_url || entity.dest_type_logo_url}
        primaryColor={DESTINATION_TYPES[entity.dest_type]?.primaryColor}
        darkModePrimaryColor={DESTINATION_TYPES[entity.dest_type]?.darkModePrimaryColor}
      />

      <HdTooltip title={entity.dest_type_display} enableOnTextOverflow>
        <div data-id={`${dataId}-dest-name`} className={styles.nodeName}>
          {entity.dest_type_display}
        </div>
      </HdTooltip>
    </div>
  );
}

export function GlobalSearchEntityDocResult({ entity, dataId }) {
  const [hasRbacPermission, setHasRbacPermission] = useState(true);
  const { hasPermission } = useHasPermission();

  const isDocLink = entity.category === 'docs';

  useEffect(() => {
    if (entity.category === 'product') {
      const rbacPermission = RBAC_PROTECTED_ROUTES.find(item =>
        item.route.includes((entity as GlobalSearchEntityAlgoliaSearchResultData).url)
      );

      if (rbacPermission) {
        setHasRbacPermission(hasPermission(rbacPermission.permission));
      }
    }
  }, [entity]);

  const wrapperClasses = clsx({
    [styles.nodeInfo]: true,
    [styles.alignStart]: !!entity?.displayContent
  });

  const iconClasses = clsx({
    'mr-2 border-radius-md': true,
    'bg-surface-overlay': !isDocLink,
    'bg-primary-faded text-primary': isDocLink
  });

  return (
    <div className={wrapperClasses} data-id={`${dataId}-${entity.category}-result`}>
      <HdTooltip title={isDocLink ? 'Documentation' : ''} disabled={!isDocLink}>
        <div>
          <RoundedIcon
            className={iconClasses}
            containerSize={8}
            iconName={entity.icon}
            isIconColorWhite={false}
          />
        </div>
      </HdTooltip>

      <div className={styles.nodeName} data-id={`${dataId}-name`}>
        {isDocLink ? (
          <HdDocLink
            label={<div className='text-ellipsis'>{entity.title}</div>}
            docLink={entity.url}
            className={styles.algoliaLink}
            icon='new-window'
            dataId={`${dataId}-${entity.category}-result`}
            onClick={e => e.preventDefault()}
          />
        ) : (
          <HdTooltip
            title={
              hasRbacPermission ? '' : 'Access restricted! Please contact your admin to get access'
            }
            placement='right'
          >
            <div className='mxw-fit-content text-ellipsis'>{entity.title}</div>
          </HdTooltip>
        )}

        {entity.displayContent ? (
          <div
            className='text-default mt-1 text-secondary text-ellipsis'
            data-id={`${dataId}-${entity.category}-result-body`}
          >
            {entity.displayContent}
          </div>
        ) : null}
      </div>
    </div>
  );
}
