import { _sshService } from '../../../../core/services/ssh.service';
import { DestinationTypeConfigHelper } from '../../../../../components/Node/destination-settings-form/destination-type-config-helper';
import { stringToBool } from '../../../../../utils/helper';

export class AzureSynapseConfigHelper implements DestinationTypeConfigHelper {
  constructor(public destinationType: string) {}

  getDefaultConfig() {
    const config: any = {
      port: 1433,
      schemaName: 'dbo',
      sshPort: 22,
      sanitizeName: true
    };

    return config;
  }

  getConfigFromRawData(rawData: any) {
    const config: any = {
      isConnectionUri:
        typeof rawData.config_as_connection_string !== 'boolean'
          ? String(false) // Stringfying because radio component takes 'true' or 'false'
          : String(rawData.config_as_connection_string),
      URI: `jdbc:sqlserver://${rawData.db_host}:${rawData.db_port};database=${rawData.db_name};user=${rawData.db_user};`,
      host: rawData.db_host,
      user: rawData.db_user,
      port: rawData.db_port,
      databaseName: rawData.db_name,
      password: rawData.db_password,
      sshIp: rawData.ssh_host,
      sshPort: rawData.ssh_port,
      sshUser: rawData.ssh_user,
      schemaName: rawData.schema_name,
      sanitizeName: rawData.sanitize_name,
      useSSH: !!(rawData.ssh_host || rawData.ssh_port || rawData.ssh_user)
    };

    return config;
  }

  buildConnectionParams(data: any) {
    const params: any = {
      db_host: data.host,
      db_port: data.port,
      db_user: data.user,
      db_password: data.password,
      db_name: data.databaseName,
      sanitize_name: data.sanitizeName,
      config_as_connection_string: stringToBool(data.isConnectionUri)
    };

    if (data.schemaName) {
      params.schema_name = data.schemaName;
    }

    _sshService.buildSSHParams(params, data);

    return params;
  }
}
