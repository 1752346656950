import { timezoneAbbr } from './tz-abbr';
import { Frequency } from '../containers/core/models/frequency';
import { TIMEZONES } from '../containers/team/Timezone/timezones';
import { addSuffixWhenNounPlural } from './string';
import dayjs from 'dayjs';

import duration from 'dayjs/plugin/duration';
import calendar from 'dayjs/plugin/calendar';

export function getDataFromBase64URL(base64Url) {
  const base64: string = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  return JSON.parse(atob(base64));
}

export function getDataFromToken(token: string) {
  const base64Url: string = token.split('.')[1];
  return getDataFromBase64URL(base64Url);
}

export const TIME_UNITS = [
  {
    denominator: 1,
    range: 1000,
    precision: 3,
    format: {
      singular: 'milli second',
      plural: 'milli seconds',
      abbr: 'ms'
    }
  },
  {
    denominator: 1000,
    range: 60,
    precision: 3,
    format: {
      singular: 'second',
      plural: 'seconds',
      abbr: 'sec'
    }
  },
  {
    denominator: 60,
    range: 60,
    precision: 2,
    format: {
      singular: 'minute',
      plural: 'minutes',
      abbr: 'min'
    }
  },
  {
    denominator: 60,
    range: 24,
    precision: 2,
    format: {
      singular: 'hour',
      plural: 'hours',
      abbr: 'hr'
    }
  },
  {
    denominator: 24,
    range: 365,
    precision: 0,
    format: {
      singular: 'day',
      plural: 'days',
      abbr: 'd'
    }
  }
];

export function getDurationString(
  duration: number,
  useAbbr = false,
  separateUnits = false,
  precision?: number
) {
  let i = 0;
  let unitValue = duration;

  while (i < TIME_UNITS.length) {
    unitValue = unitValue / TIME_UNITS[i].denominator;

    if (unitValue < TIME_UNITS[i].range || i === TIME_UNITS.length - 1) {
      const decimalFixedVal = parseInt(
        ((unitValue % 1) * TIME_UNITS[i].denominator).toFixed(0),
        10
      );

      if (separateUnits) {
        const integerPart = Math.floor(unitValue);

        let formattedString =
          integerPart + ' ' + getTimeUnitString(integerPart, TIME_UNITS[i].format, useAbbr);

        if (decimalFixedVal > 0 && i > 0) {
          formattedString +=
            ' ' +
            decimalFixedVal +
            ' ' +
            getTimeUnitString(decimalFixedVal, TIME_UNITS[i - 1].format, useAbbr);
        }

        return formattedString;
      }

      if (precision == null) {
        precision = TIME_UNITS[i].precision;
      }
      return (
        unitValue.toFixed(precision) +
        ' ' +
        getTimeUnitString(unitValue, TIME_UNITS[i].format, useAbbr)
      );
    }

    i++;
  }
}

export function getTimeUnitString(value: number, format: any, useAbbr = false) {
  if (useAbbr) {
    return format.abbr;
  }
  return value <= 1 ? format.singular : format.plural;
}

export function getFrequencyFromSecs(frequencies: Frequency[], secs: number) {
  if (!secs) {
    return '';
  }

  const frequency = frequencies.find((freq: any) => {
    return freq.value === secs;
  });

  if (frequency) {
    return frequency.name;
  }

  if (secs < 60) {
    return `${secs} ${addSuffixWhenNounPlural('Second', secs)}`;
  }

  if (secs < 3600) {
    return parseFloat((secs / 60).toFixed(2)) + ` ${addSuffixWhenNounPlural('Minute', secs / 60)}`;
  }

  return parseFloat((secs / 3600).toFixed(2)) + ` ${addSuffixWhenNounPlural('Hour', secs / 3600)}`;
}

export function timeFormatter(num: number) {
  let result: string;
  const digits = 2;
  if (num >= 1000 && num < 60000) {
    result = (num / 1000).toFixed(digits) + 's';
  } else if (num >= 60000 && num < 3.6e6) {
    result = (num / 60000).toFixed(digits) + 'm';
  } else if (num >= 3.6e6 && num < 8.64e7) {
    result = (num / 3.6e6).toFixed(digits) + 'h';
  } else if (num >= 8.64e7) {
    result = (num / 8.64e7).toFixed(digits) + 'd';
  } else {
    result = num + 'ms';
  }
  return result;
}

export function getDaysFromTimestamp(timestamp: number) {
  return timestamp / 86400000;
}

export function getHoursFromTimestamp(timestamp: number) {
  return timestamp / 3600000;
}

export function getTimeDifferenceInMinutes(startTime: number, endTime: number) {
  return dayjs.duration(dayjs.utc(endTime).diff(dayjs.utc(startTime))).asMinutes();
}

export function getCalendarDateWithTimezone(timestamp: number | string) {
  return (
    dayjs(timestamp).calendar(null, {
      sameElse: 'Do MMM, YYYY [at] LT'
    }) +
    ' (' +
    timezoneAbbr(new Date(timestamp)) +
    ')'
  );
}

export function getDaysDifferenceFromCurrentTimestamp(timestamp: number) {
  const currentTime = new Date().getTime();
  return getDaysFromTimestamp(timestamp - currentTime);
}

export function getDaysDifferenceToCurrentTimestamp(timestamp: number) {
  const currentTime = new Date().getTime();
  return getDaysFromTimestamp(currentTime - timestamp);
}

export function getHoursFromMS(ms) {
  return ms / (1000 * 60 * 60);
}

export function getMSFromHours(hrs) {
  return hrs * 1000 * 60 * 60;
}

export function getBrowserTimezone() {
  const offset = new Date().getTimezoneOffset();
  const hours = Math.floor(Math.abs(offset) / 60);
  const mins = Math.abs(offset) % 60;

  let offsetString = offset <= 0 ? '+' : '-';
  offsetString += hours === 0 ? '00' : hours < 10 ? '0' + hours : hours;
  offsetString += ':';
  offsetString += mins === 0 ? '00' : mins < 10 ? '0' + mins : mins;

  return TIMEZONES.find(timezone => {
    return timezone.offset === offsetString;
  });
}

export function getTimestampDifferenceFromNow(fromTimestamp) {
  return new Date().getTime() - fromTimestamp;
}

export function getUTCEpochFromTimezoneEpoch(date: Date) {
  return date.getTime() - date.getTimezoneOffset() * 60 * 1000;
}

export function getLastDateOfMonth(date: Date) {
  return new Date(date.getUTCFullYear(), date.getUTCMonth() + 1, 0).getDate();
}
