/* eslint-disable class-methods-use-this */
import { SourceTypeConfigHelper } from '../../../../../components/Node/source-settings-form/config-helper';
import PipelinesAPI from '../../../PipelinesAPI';
import {
  COMPARISON_OPERATORS,
  DEFAULT_HISTORICAL_IMPORT_DAYS,
  INCLUDE_EXCLUDE_OPTIONS,
  LOGICAL_OPERATORS,
  MATCH_OPERATORS,
  ReportTypeEnum
} from './model';
import { fetchDimensionFilterClauseData, fetchMetricFilterClauseData } from './utils';

export class GoogleAnalyticsConfigHelperReact implements SourceTypeConfigHelper {
  sourceTypeIdentifier = '';

  getConfigFromRawData(rawData: any, metadata: any) {
    let reports = [];
    const { views } = metadata;
    if (this.sourceTypeIdentifier !== 'GOOGLE_ANALYTICS') {
      reports = (rawData.reports || []).map((report: any) => {
        let reportParsed: any;

        try {
          reportParsed = JSON.parse(report);
        } catch {
          reportParsed = report;
        }

        return this.responseFactory(reportParsed, views);
      });
    } else {
      return {
        reports: [{ ...this.responseFactory(rawData, views) }],
        historicalImportDays: rawData.historical_import_days,
        segments: rawData.segments
      };
    }

    return {
      segments: rawData.segments,
      historicalImportDays: rawData.historical_import_days,
      reports
    };
  }

  private _getObjectFromList(list: any[], value: any, key: string) {
    return list.find((item: any) => item[key] === value);
  }

  createDimensionFilterClauses(rawData) {
    let dimensionFilterClauses: any[] = [];

    if (Array.isArray(rawData.dimension_filter_clauses)) {
      dimensionFilterClauses = rawData.dimension_filter_clauses
        .filter((dimensionFilterClause: any) => dimensionFilterClause.filters?.length)
        .map((dimensionFilterClause: any) => {
          const dimensionFilters: any[] = dimensionFilterClause.filters
            .filter((filter: any) => !!filter)
            .map((filter: any) => ({
              unit: { key: filter.dimensionName },
              includeExclude: this._getObjectFromList(INCLUDE_EXCLUDE_OPTIONS, filter.not, 'value'),
              operator: this._getObjectFromList(MATCH_OPERATORS, filter.operator, 'value'),
              caseSensitive: filter.caseSensitive,
              expression: filter.expressions?.toString()
            }));

          return {
            operator: this._getObjectFromList(
              LOGICAL_OPERATORS,
              dimensionFilterClause.operator,
              'value'
            ),
            filters: dimensionFilters
          };
        });
    }

    return dimensionFilterClauses;
  }

  createMetricFilterClauses(rawData) {
    let metricFilterClauses: any[] = [];

    if (Array.isArray(rawData.metric_filter_clauses)) {
      metricFilterClauses = rawData.metric_filter_clauses
        .filter((metricFilterClause: any) => metricFilterClause.filters?.length)
        .map((metricFilterClause: any) => {
          const filters: any[] = metricFilterClause.filters
            .filter((filter: any) => !!filter)
            .map((filter: any) => ({
              unit: { key: filter.metricName },
              includeExclude: this._getObjectFromList(INCLUDE_EXCLUDE_OPTIONS, filter.not, 'value'),
              operator: this._getObjectFromList(COMPARISON_OPERATORS, filter.operator, 'value'),
              expression: filter.comparisonValue
            }));

          return {
            operator: this._getObjectFromList(
              LOGICAL_OPERATORS,
              metricFilterClause.operator,
              'value'
            ),
            filters
          };
        });
    }
    return metricFilterClauses;
  }

  responseFactory(rawData: any, views: any) {
    const accountId = this._getObjectFromList(views, rawData.account_id, 'id');
    const propertyId = this._getObjectFromList(accountId.webProperties, rawData.property_id, 'id');
    const viewId = this._getObjectFromList(propertyId.profiles, rawData.view_id, 'id');
    return {
      reportName: rawData.report_name,
      reportType: rawData.report_type,
      preBuiltReport: rawData.pre_built_report,
      metrics: rawData.metrics,
      dimensions: rawData.dimensions,
      viewId,
      propertyId,
      accountId,
      historicalImportDays: rawData.historical_import_days,
      dimensionFilterClauses: this.createDimensionFilterClauses(rawData),
      metricFilterClauses: this.createMetricFilterClauses(rawData)
    };
  }

  getObjectFromList(list: any[], value: any, key: string) {
    return list.find((item: any) => item[key] === value);
  }

  getDefaultConfig() {
    return {
      historicalImportDays: DEFAULT_HISTORICAL_IMPORT_DAYS,
      reports: [
        {
          reportType: ReportTypeEnum.preBuiltReport,
          metricFilterClauses: [],
          dimensionFilterClauses: [],
          reportName: '',
          accountId: null,
          propertyId: null,
          viewId: null,
          dimensions: null,
          metrics: null
        }
      ]
    };
  }

  metaDataResolver(sourceType: string, authData: any, rawData: any) {
    const views = PipelinesAPI.getGAViews(authData.oauthTokenId);
    const gaCubes = PipelinesAPI.getGACubes();
    const metadata = PipelinesAPI.getGAMetaData(authData.oauthTokenId);
    const segments = PipelinesAPI.getGASegments(authData.oauthTokenId);
    const preBuiltReports = PipelinesAPI.getGAPreBuiltReports();

    return Promise.all([views, gaCubes, metadata, segments, preBuiltReports]).then(res => ({
      views: res[0],
      gaCubes: res[1],
      metadata: res[2],
      segments: res[3],
      preBuiltReports: res[4]
    }));
  }

  buildParams(rawData: any) {
    return {
      view_id: rawData.viewId?.id,
      property_id: rawData.propertyId?.id,
      account_id: rawData.accountId?.id,
      metrics: rawData?.metrics,
      dimensions: rawData?.dimensions,
      report_type: rawData?.reportType,
      pre_built_report: rawData?.preBuiltReport,
      metric_filter_clauses: fetchMetricFilterClauseData(rawData),
      dimension_filter_clauses: fetchDimensionFilterClauseData(rawData)
    };
  }

  buildConnectionParams(data: any): any {
    if (data.type === 'GOOGLE_ANALYTICS_V2') {
      const segments = data.segments || [];

      const reports = (data.reports || []).map((reportData: any) => {
        const reportClone: any = this.buildParams(reportData);

        reportClone.report_name = reportData.reportName;

        return reportClone;
      });

      return {
        historical_import_days: data.historicalImportDays,
        segments,
        reports
      };
    }

    return {
      ...this.buildParams(data),
      segments: data.segments || [],
      historical_import_days: data.historicalImportDays
    };
  }
}
