import axios from 'axios';
import { of } from 'rxjs';
import { AnsibleBoolean } from '../core/models/ansible-config';
import { HevoCustomersFactory, HevoTestimonialsFactory } from '../../legacy-utils/customers';
import { CLIENTS, TESTIMONIALS } from './TestimonialSection/constants';

declare const __WEBSITE_URL__: string;
declare const __DEPLOYMENT_ENV__: string;
declare const __LOAD_FEATURED_CUSTOMERS_FROM_WEBSITE__: AnsibleBoolean;

export function useFeaturedCustomers() {
  const getFeaturedCustomers = () => {
    const customersUrl = `${ __WEBSITE_URL__ }/hevo-utility/dashboard-data?env=${__DEPLOYMENT_ENV__}`;

    const defaultData = {
      customers: CLIENTS,
      testimonials: TESTIMONIALS
    };

    if (__LOAD_FEATURED_CUSTOMERS_FROM_WEBSITE__ !== AnsibleBoolean.TRUE) {
      return of(defaultData);
    }


    return axios.get(customersUrl, {
      headers: { 'Anonymous': 'True' }
    }).then(
      (res: any) => {
        return {
          customers: res.data.customers?.length ? HevoCustomersFactory(res.data.customers) : CLIENTS,
          testimonials: res.data.testimonials?.length ? HevoTestimonialsFactory(res.data.testimonials) : TESTIMONIALS
        };
      },
      (err) => {
        return defaultData;
      }
    );
  };

  return {
    getFeaturedCustomers
  };
}
