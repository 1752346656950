import { JobModeEnum } from '../../components/Node/source-type/job-mode';
import { getBaseSourceType } from '../../components/Node/source-type/utils';

export function isChangeTrackingV2EnabledForPipeline(teamSettings: any, pipeline: any): boolean {
  if (!pipeline) {
    return false;
  }

  const sourceType = getBaseSourceType(pipeline.source_type).name;

  return (
    sourceType === 'MS_SQL' &&
    isChangeTrackingV2EnabledForTeam(teamSettings) &&
    pipeline.config.schema_version >= 42 &&
    pipeline.source_config.allow_only_change_tracking_tables &&
    ('change_tracking_v2_enabled' in pipeline.source_config
      ? pipeline.source_config.change_tracking_v2_enabled
      : true)
  );
}

export function isChangeTrackingV2EnabledForTeam(teamSettings: any): boolean {
  if (!teamSettings) {
    return false;
  }

  return (
    (teamSettings['team_version'] >= 11 && teamSettings['change_tracking_v2_enabled_for_team']) ||
    teamSettings['change_tracking_v2_enabled_for_old_team']
  );
}

export function isPgAutoPopulateTaskConfigEnabledForPipeline(teamSettings: any, pipeline: any): boolean {
  if (!teamSettings) {
    return false;
  }

  const sourceType = getBaseSourceType(pipeline.source_type).name;

  return sourceType === 'POSTGRES' && (
    (pipeline.source_config.type === JobModeEnum.XMIN.value && pipeline.config.schema_version >= 43)
    || (teamSettings['pg_auto_populate_task_config_enabled'] && pipeline.config.schema_version >= 42));
}

export function isOracleAutoPopulateTaskConfigEnabledForPipeline(teamSettings: any, pipeline: any): boolean {
  if (!teamSettings) {
    return false;
  }

  const sourceType = getBaseSourceType(pipeline.source_type).name;

  return sourceType === 'ORACLE' && teamSettings['oracle_auto_populate_task_config_enabled']
    && pipeline.config.schema_version >= 42;
}
