import { useEffect } from 'react';
import { Observable } from 'rxjs';

export function useSubscribe(observable$: Observable<any>, deps: unknown[]) {
  useEffect(() => {
    const sub = observable$.subscribe();

    return () => {
      sub.unsubscribe();
    };
  }, deps);
}
