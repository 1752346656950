import { AuthAccountEnvironmentMetaData } from '../../../../../components/Node/source-type/source-type';

export interface SelectAuthAccountEnvironmentDialogData {
  providerDisplay: string;
  consentUrls: AuthAccountConsentUrl[];
  authAccountEnvironmentsMetaData: { [key: string]: AuthAccountEnvironmentMetaData };
  connectorType: string;
}

export interface AuthAccountConsentUrl {
  env: AuthAccountEnvironment;
  url: string;
}

export enum AuthAccountEnvironment {
  PRODUCTION = 'PRODUCTION',
  TESTING = 'TESTING'
}
