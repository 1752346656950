import { BehaviorSubject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { PaymentPlan } from '../../payment/models/payment-plan';
import { AuthService } from './auth.service';
import { PaymentService } from './payment.service';

export class CurrentPlanService {
  static deps = [
    PaymentService,
    AuthService
  ];

  private _currentPlan$ = new BehaviorSubject<PaymentPlan>(null);
  currentPlan$ = this._currentPlan$.asObservable();

  get currentPlan() {
    return this._currentPlan$.getValue();
  }

  constructor(
    private _paymentService: PaymentService,
    private _authService: AuthService
  ) {
    this._authService.logoutSubject.subscribe(() => {
      this.clearPlan();
    });
  }

  getCurrentPlan() {
    return this._paymentService.getCurrentPlan().pipe(
      tap((res) => {
        this._currentPlan$.next(res?.data);
      })
    );
  }

  setCurrentPlan(plan: any) {
    this._currentPlan$.next(plan);
  }

  clearPlan() {
    this._currentPlan$.next(null);
  }
}
