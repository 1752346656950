import React, { forwardRef, ReactElement, useMemo } from 'react';
import { RBAC_DISABLED_MESSAGE } from '../../../containers/core/models/rbac';
import { RbacPermissions } from '../../../containers/core/models/user';
import useHasPermission from '../../../hooks/useHasPermission';
import HdButton, { HdButtonProps } from '../HdButton';
import { DEFAULT_TOOLTIP_MODE, HdTooltip, TooltipMode } from '../HdTooltip';

export interface HdRbacButtonProps extends HdButtonProps {
  rbacPermission: RbacPermissions;
  tooltipContent?: ReactElement | string;
  tooltipMode?: TooltipMode;
  disabledToolTipContent?: ReactElement | string;
}

export const HdRbacButton: React.FC<HdRbacButtonProps> = forwardRef<any, HdRbacButtonProps>(
  (
    {
      rbacPermission,
      disabled = false,
      tooltipContent,
      tooltipMode = DEFAULT_TOOLTIP_MODE,
      disabledToolTipContent,
      ...props
    }: HdRbacButtonProps,
    ref
  ) => {
    const { hasPermission } = useHasPermission();
    const isRbacActionEnabled = hasPermission(rbacPermission);

    const tooltip = useMemo(() => {
      if (!isRbacActionEnabled) {
        return RBAC_DISABLED_MESSAGE;
      }

      if (disabled) {
        return disabledToolTipContent;
      }

      return tooltipContent;
    }, [disabled, disabledToolTipContent, isRbacActionEnabled, tooltipContent]);

    const isButtonDisabled = !isRbacActionEnabled || disabled;
    return (
      <HdTooltip
        title={tooltip}
        disabled={!tooltip}
        mode={!isRbacActionEnabled ? DEFAULT_TOOLTIP_MODE : tooltipMode}
      >
        <span className='btn-rbac-wrapper'>
          <HdButton
            style={isButtonDisabled ? { pointerEvents: 'none' } : null}
            disabled={isButtonDisabled}
            {...props}
            ref={ref}
          />
        </span>
      </HdTooltip>
    );
  }
);
