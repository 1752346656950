import { SourceTypeConfigHelper } from '../../../../../components/Node/source-settings-form/config-helper';
import PipelinesAPI from '../../../PipelinesAPI';

export class YoutubeAnalyticsConfigHelper implements SourceTypeConfigHelper {
  getConfigFromRawData(rawData: any, metaData: any) {
    const config = {
      channelReports: []
    };

    if (rawData.channel_reports) {
      config.channelReports = this.getListFromRawData(
        metaData.channelReportList,
        rawData.channel_reports || []
      );
    }

    return config;
  }

  getDefaultConfig(metaData: any) {
    return {
      channelReports: metaData.channelReportList
    };
  }

  buildConnectionParams(data: any) {
    return {
      channel_reports: (data.channelReports || []).map(report => report.id),
      content_owners: []
    };
  }

  async metaDataResolver(sourceType: string, authData: any, rawData: any) {
    const { data } = await PipelinesAPI.getYoutubeChannelReportList(authData.oauthTokenId);
    const sortedOptions = data.reports.sort((a, b) => a.group.localeCompare(b.group));
    return {
      channelReportList: sortedOptions.map(option => ({ ...option, category: option.group }))
    };
  }

  getListFromRawData(listName, rawDataList) {
    return rawDataList.map(id => listName.find(obj => id === obj.id));
  }
}
