export const DEFAULT_EXPERIMENT_VARIATION = 'DEFAULT';

export enum AbExperiment {
  PIPELINE_DESTINATION_AUTO_FILL_SNOWFLAKE_FIELDS = 'pipeline.create.destination.autoFillSnowflakeFields'
}

export interface AbExperimentConfig {
  enabled: boolean;
  goalId: number;
  campaignId: number;
  signupConversion?: boolean;
  dependentGoalIds?: number[];
}

export interface AbExperimentConfigGrowthBook {
  enabled: boolean;
}

export interface AbExperimentsConfig<T> {
  [key: string]: T;
}
