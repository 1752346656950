import { useHistory } from '@useHistory/useHistoryCompat';
import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { NetworkRequestOptions } from '../core/models/request';
import { AuthService } from '../core/services/auth.service';
import useService from '../../hooks/useService';

export function useAuthGuard() {
  const history = useHistory();
  const authService = useService(AuthService);

  const loginWithSessionToken = async (sessionToken: string) => {
    return authService
      .loginWithSessionToken(sessionToken)
      .pipe(
        map(() => {
          history.replace(history.location.pathname);
          return true;
        }),
        catchError(error => {
          if (error.status === 401) {
            return authService.onUnAuthorisedEvent(false).pipe(map(() => false));
          }
          return of(false);
        })
      )
      .toPromise();
  };

  const executeAuthGuard = async () => {
    const requestOptions: NetworkRequestOptions = {
      uiOptions: {
        handleUnauthorisedResponse: false,
        showLoading: false
      }
    };

    const searchParams = new URLSearchParams(history.location?.search);

    const sessionToken = searchParams.get('session_token');

    if (sessionToken) {
      const res = await loginWithSessionToken(sessionToken);

      if (!res) {
        return false;
      }
    }

    return authService
      .getLoggedInUser(requestOptions, true)
      .pipe(
        map(() => true),
        catchError((error: any) => {
          if (error.status === 401) {
            return authService.onUnAuthorisedEvent(true).pipe(map(() => false));
          }

          return of(false);
        })
      )
      .toPromise();
  };

  return {
    executeAuthGuard
  };
}
