import { Observable } from 'rxjs';

export class ReactRouterServiceBridge {
  url: string;

  queryParams: Observable<any>;

  listen(callback: Function) {
  }

  navigate(
    props: { pathname: string; search?: string },
    options?: { replace: boolean }
  ) {
    return Promise.resolve();
  }
}

export class ReactRouterService {
  static deps = [
    ReactRouterServiceBridge
  ];

  constructor(
    private _router: ReactRouterServiceBridge
  ) {}

  navigate(props: { pathname: string; search?: string }, options?: { replace: boolean }) {
    return this._router.navigate(props, options);
  }

  getQueryParams() {
    return new URLSearchParams(window.location.search);
  }

  get url() {
    return this._router.url;
  }

  get pathname() {
    return window.location.pathname;
  }

  get hash() {
    return window.location.hash;
  }

  get queryParams$() {
    return this._router.queryParams;
  }

  getMergedQueryParams(params: object) {
    const searchParams = this.getQueryParams();

    Object.entries(params).forEach(([key, value]) => {
      if (typeof value === 'undefined' || value === null) {
        searchParams.delete(key);
      } else {
        searchParams.set(key, value);
      }
    });

    return searchParams;
  }

  listen(callback: Function) {
    return this._router.listen(callback);
  }
}
