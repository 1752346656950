import localeData from 'dayjs/plugin/localeData';
import utc from 'dayjs/plugin/utc';
import objectSupport from 'dayjs/plugin/objectSupport';
import duration from 'dayjs/plugin/duration';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import calendar from 'dayjs/plugin/calendar';
import relativeTime from 'dayjs/plugin/relativeTime';
import isBetween from 'dayjs/plugin/isBetween';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';

dayjs.extend(quarterOfYear);
dayjs.extend(isBetween);
dayjs.extend(relativeTime);
dayjs.extend(calendar);
dayjs.extend(advancedFormat);
dayjs.extend(localizedFormat);
dayjs.extend(duration);
dayjs.extend(objectSupport);
dayjs.extend(utc);
dayjs.extend(localeData);
