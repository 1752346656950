import { AwsConfigHelper } from '../AWS/configHelper';
import { DYNAMODB_REGIONS } from './constants';
import { SourceTypeConfigHelper } from '../../../../../components/Node/source-settings-form/config-helper';

export class DynamodbConfigHelper implements SourceTypeConfigHelper {
  getConfigFromRawData(rawData: any) {
    return {
      ...AwsConfigHelper.getConfigFromRawData(rawData),
      region: DYNAMODB_REGIONS.find((region: any) => region.value === rawData.region),
      importHistoricalData: !!rawData.sync_historical_data
    };
  }

  getDefaultConfig() {}

  buildConnectionParams(data: any) {
    return {
      ...AwsConfigHelper.buildConnectionParams(data),
      region: data.region?.value,
      sync_historical_data: !!data.importHistoricalData
    };
  }
}
