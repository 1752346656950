import React, { createContext, ReactNode, useCallback, useLayoutEffect, useState } from 'react';
import { Router, NavigationType, Location } from 'react-router-dom';
import { History } from 'history';

export const HistoryContext = createContext(null);

export function CompatRouter({
  basename,
  history,
  children
}: {
  basename?: string;
  history: History;
  children?: ReactNode;
}) {
  const [state, setStateImpl] = useState({
    action: history.action,
    location: history.location
  });

  const setState = useCallback(
    (newState: { action: NavigationType; location: Location }) => {
      setStateImpl(newState);
    },
    [setStateImpl]
  );

  useLayoutEffect(() => history.listen((s) => {
    /**
     * This is a hack to make sure that priority handlers like canDeactivate guards
     * get control before other listeners get a chance to modify the URL.
     */
    (history as any).beforeListenersFired$?.next(s);
    setState(s);
  }), [history, setState]);

  return (
    <HistoryContext.Provider value={history}>
      <Router
        basename={basename}
        children={children}
        location={state.location}
        navigationType={state.action}
        navigator={history}
      />
    </HistoryContext.Provider>
  );
}
