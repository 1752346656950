/**
 * dayjs-date-time-format.class
 */
import { OwlDateTimeFormats } from '../date-time-format.class';

export const OWL_DAYJS_DATE_TIME_FORMATS: OwlDateTimeFormats = {
  parseFullPickerInput: 'l LT',
  parseDatePickerInput: 'l',
  parseTimePickerInput: 'LT',
  fullPickerInput: 'l LT',
  datePickerInput: 'l',
  timePickerInput: 'LT',
  monthYearLabel: 'MMM YYYY',
  dateA11yLabel: 'LL',
  monthYearA11yLabel: 'MMMM YYYY'
};
