import { ListActionType } from './list-actions';
import { EntityUIState } from '../../../containers/core/models/entitiy-ui-state';

export interface Params {
  [key: string]: any;
}

export abstract class PaginationStrategy<T> {
  isLastPage: boolean;

  abstract applyAction(action: ListActionType, paginationData: T, data?: any): T;

  abstract getDataFromQueryParams(queryParamsPrefix: string, queryParams: Params, paginationData: T): T;

  abstract getQueryParamsFromData(queryParamsPrefix: string, data: T);

  abstract getDefaultPaginationData(paginationData?: T): T;

  abstract getNetworkParams(paginationData: T): { [key: string]: any };

  abstract isFirstPage(paginationData: T): boolean;

  abstract getListState(filtersLength: number, total: number): EntityUIState;
}

