import React from 'react';
import { HdButton, HdLink } from '../../../components/UIElements';
import useAnalyticsTracker from '../../../hooks/useAnalyticsTracker';
import { TRACKER_DPA_SET_CONSENT } from '../constants';
import useService from '../../../hooks/useService';
import { TeamService } from '../../core/services/team.service';
import { DPA_ACCEPTED_BY_USER_ID_KEY, DPA_DOWNLOADABLE_LINK } from '../../core/constants';
import { AuthService } from '../../core/services/auth.service';

export default function DpaBannerMessage({ close }) {
  const analyticsTracker = useAnalyticsTracker();
  const _teamService = useService(TeamService);
  const _authService = useService(AuthService);
  const agree = () => {
    analyticsTracker.eventTrack({
      action: TRACKER_DPA_SET_CONSENT
    });
    close();
    setDPAConsent();
  };

  const setDPAConsent = () => {
    _teamService
      .updateTeamConfig({
        config_key: DPA_ACCEPTED_BY_USER_ID_KEY,
        config_value: _authService.getUser().id
      })
      .subscribe();
  };

  return (
    <>
      We have augmented our Terms of Service by adding a Data Processing Addendum (DPA).{' '}
      <HdLink
        dataId='dpa-banner-message-link'
        color='light'
        href={DPA_DOWNLOADABLE_LINK}
        target='_blank'
      >
        Download DPA
      </HdLink>{' '}
      and click I Agree to acknowledge.
      <HdButton
        dataId='dpa-banner-message-button'
        size='sm'
        variation='outline'
        palette='light'
        inlineText
        className='ml-2'
        onClick={agree}
      >
        I Agree
      </HdButton>
    </>
  );
}
