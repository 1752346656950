import { HevoEntity } from '../core/models/hevo-entity';
import {
  PolicyTypeEnum,
  FrequencyType,
  FrequencyChip,
  CronType,
  CronTime,
  CronDay
} from './ExecutionPolicyDialog/models';
import { Activation } from '../activate/models/activation';
import { Destination } from '../destination/models';
import { Model } from '../transform-model/models';
import { Workflow } from '../workflow/models/workflow';
import { RawPipeline } from '../pipeline/model';
import { SyncFrequencyInitiator } from './SyncFrequencyDialog';

export enum SchedulerType {
  FrequencyType = 'FrequencyType',
  MultiCronType = 'MultiCronType',
  CronType = 'CronType'
}

export interface EntityExecutionPolicyDialogProps {
  onClose: Function;
  entityDetails: Destination | Activation | Workflow | Model | RawPipeline;
  openSyncFrequencyMigrationDialog?: Function;
}

export interface ExecutionPolicyDialogProps extends EntityExecutionPolicyDialogProps {
  open?: boolean;
  entity?: HevoEntity;
}

export interface SyncFrequencyPolicyProps {
  onClose: Function;
  entityDetails?: any;
  initiator?: string;
  onSubmit?: Function;
}

export interface SyncFrequencyDialogProps extends SyncFrequencyPolicyProps {
  open?: boolean;
  entity?: HevoEntity;
}

export interface Policy {
  id: number;
  message: string;
  status: string;
  type: PolicyTypeEnum;
  frequencyType: FrequencyType;
  frequencyValue: number;
  minFrequency: number;
  maxFrequency: number;
  cronType: CronType;
  cronDays: CronDay[];
  cronTime: CronTime;
  cronTimeList: CronTime[];
  destinationId: number;
  destTables: String[];
  suggestedFrequencies: FrequencyChip[];
  displayedFrequencies: FrequencyChip[];
  suggestedCronTimeList: CronTime[];
  isDailyCronConfigurable: boolean;
  showStreamingOption?: boolean;
  streamingScheduleEnabled?: boolean;
}
