import { AxiosError } from 'axios';
import { CONNECTION_ERROR_MSG, INVALID_INPUT_MSG } from '../containers/core/constants';

export function getErrorFromAxiosError(error: AxiosError) {
  let errorResp = error.response?.data;

  try {
    if (typeof errorResp === 'string') {
      errorResp = JSON.parse(errorResp);
    }
  } catch (e) {
    errorResp = undefined;
  }

  return {
    status: error.code === AxiosError.ERR_NETWORK ? 0 : (!error.response?.status ? 0 : error.response?.status),
    error: errorResp,
    message: error.message
  };
}

export function getErrorMessageFromObj(err: any) {
  if (!err) {
    return undefined;
  }

  if (err.status <= 0) {
    return CONNECTION_ERROR_MSG;
  }

  return err.status === 422
    ? INVALID_INPUT_MSG
    : (err.error?.error_message || err.error?.message || err.message);
}

export function getSuccessMessageFromObj(response: any) {
  if (!response) {
    return undefined;
  }

  const res: any = { ...response };
  return res.body ? res.body.success_message : res.success_message;
}

export function createHttpParams(params: {}) {
  if (Object.keys(params).length === 0) {
    return {};
  }

  Object.keys(params).forEach(key => {
    if (typeof params[key] === 'undefined' || params[key] === null) {
      delete params[key];
    }
  });

  return params;
}

export function getBackendErrorCodeFromObj(error: any) {
  return error && error.error ? error.error.error_code : null;
}
