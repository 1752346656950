import {
  DEFAULT_INCLUSION_LIST_STATE,
  InclusionListState
} from '../../../../core/models/inclusion-list-v2';
import { GenericKeyValueType } from '../../../../../generics';
import { GoogleSheetsSpreadsheets, SheetsAccounts, Worksheet } from './models';

export function SheetAccountsFactory(rawData: SheetsAccounts) {
  if (!rawData) {
    return {};
  }

  const { page, sheets } = rawData;

  return {
    totalCount: undefined,
    metaData: { cursor: page },
    entities: sheets.map(sheet => ({
      id: sheet.id,
      name: sheet.name,
      canRenderChildren: true,
      size: undefined,
      data: {
        ...sheet
      }
    }))
  };
}

export function WorksheetFactory(rawData: Worksheet[]) {
  if (!rawData) {
    return [];
  }

  return rawData.map(item => ({
    id: item.id,
    name: item.name,
    data: {
      ...item
    }
  }));
}

export function getSelectedSheetsConfigRawData(
  sheets: InclusionListState,
  sheetWorksheetsMap: GenericKeyValueType<string, { name: string; id: string }[]>
) {
  const includedList: string[] = [];
  const worksheetConfig: GenericKeyValueType<string, string[]> = {};

  (sheets?.entities || []).forEach((sheet: InclusionListState) => {
    if (sheet.selected || sheet.entities.length > 0) {
      includedList.push(sheet.key as string);
    }

    /**
     * User has selected all the worksheets by clicking on parent checkbox
     * and then clicked on toggle button to select or unselect worksheets.
     * In that case, all the unselected worksheets are present as entities.
     * */
    if (
      sheet.selected &&
      sheet.entities.length > 0 &&
      (sheet.size === undefined || sheet.entities.length !== sheet.size) &&
      !!sheetWorksheetsMap[sheet.key]
    ) {
      const worksheets = sheetWorksheetsMap[sheet.key];
      const includedWorksheets: string[] = [];

      (worksheets || []).forEach(worksheet => {
        const isExcluded = !!sheet.entities.find(item => item.key === worksheet.id);

        if (!isExcluded) {
          includedWorksheets.push(worksheet.id);
        }
      });

      worksheetConfig[sheet.key as string] = includedWorksheets;
    }

    /**
     * User toggles to select the worksheets but does not select all worksheets from parent.
     * User selects some worksheets. These worksheets will be entities in the entity.
     * */
    if (
      !sheet.selected &&
      sheet.entities.length > 0 &&
      (sheet.size === undefined || sheet.entities.length !== sheet.size)
    ) {
      worksheetConfig[sheet.key as string] = sheet.entities.map(
        childEntity => childEntity.key
      ) as string[];
    }
  });

  return {
    selected: !!sheets?.selected,
    included_list: includedList,
    excluded_list: [],
    worksheet_config: worksheetConfig,
    filters: {}
  };
}

export function SelectedSheetsConfigFactory(rawData: string): InclusionListState | null {
  if (!rawData) {
    return null;
  }

  let spreadsheets: GoogleSheetsSpreadsheets;
  try {
    spreadsheets = JSON.parse(rawData);
  } catch (e) {
    console.error('Error parsing sheets', e);
    return DEFAULT_INCLUSION_LIST_STATE;
  }

  if (spreadsheets) {
    const entities = [];

    spreadsheets.included_list.forEach(item => {
      entities.push({
        key: item,
        selected: (spreadsheets.worksheet_config[item] || []).length === 0,
        entities: (spreadsheets.worksheet_config[item] || []).map(worksheet => ({
          key: worksheet,
          selected: true
        }))
      });
    });

    return {
      selected: false,
      entities
    };
  }

  return DEFAULT_INCLUSION_LIST_STATE;
}

export function onPasteSheetsHeaderRowFieldHandler(event) {
  const data = Number(event.clipboardData.getData('Text'));
  if (!Number.isInteger(data)) {
    event.preventDefault();
  }
}

export function onKeyDownSheetsHeaderRowFieldHandler(event) {
  if (event.key === '.' || event.key === '+') {
    event.preventDefault();
  }
}

export function getBuildConnectionParamsForSheets(data: GoogleSheetsSpreadsheets) {}
