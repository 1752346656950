import { ColorMode } from '../containers/core/models/color-mode';
import { User } from '../containers/core/models/user';
import { ActionType } from './actionTypes';

export const onThemeChange = (theme: ColorMode) => ({
  type: ActionType.CHANGE_THEME,
  payload: theme
});

export const onUserLogout = () => ({
  type: ActionType.LOGOUT_USER,
  payload: true
});

export const onUserLogin = (userData: User) => ({
  type: ActionType.LOGIN_USER,
  payload: userData
});
