import React from 'react';
import { HdButton } from '../../../components/UIElements';
import useAnalyticsTracker from '../../../hooks/useAnalyticsTracker';
import { TRACKER_VERSION_UPDATE_REFRESH } from '../constants';

export default function VersionUpdateBannerMessage() {
  const analyticsTracker = useAnalyticsTracker();

  const refresh = () => {
    analyticsTracker.eventTrack({
      action: TRACKER_VERSION_UPDATE_REFRESH
    });
    window.location.reload();
  };

  return (
    <>
      Hevo is ready with a new version, tap refresh to update now.
      <HdButton
        dataId='version-update-banner-message'
        size='sm'
        variation='outline'
        palette='light'
        inlineText
        className='ml-2'
        onClick={refresh}
      >
        Refresh
      </HdButton>
    </>
  );
}
