import { getSelectorInclusionStateFromRawData, getSelectorRawDataFromInclusionState } from '../../../../../components/SingleLevelSelector/models';
import { SourceTypeConfigHelper } from '../../../../../components/Node/source-settings-form/config-helper';
import { PINTEREST_ATTRIBUTION_WINDOWS, PINTEREST_BREAKDOWNS, PINTEREST_REPORT_TIMES } from './constants';

export class PinterestAdsConfigHelper implements SourceTypeConfigHelper {
  clickAttributionWindows = [ ...PINTEREST_ATTRIBUTION_WINDOWS ];
  viewAttributionWindows = [ ...PINTEREST_ATTRIBUTION_WINDOWS ];
  engagementAttributionWindows = [ ...PINTEREST_ATTRIBUTION_WINDOWS ];
  reportTimes = [ ...PINTEREST_REPORT_TIMES ];
  breakdownList = [ ...PINTEREST_BREAKDOWNS ];

  getConfigFromRawData(rawData: any) {
    return {
      oauthTokenId: rawData.oauth_token_id,
      selectedAccounts: getSelectorInclusionStateFromRawData({ ...rawData, entities: rawData.ad_accounts }),
      selectedBreakdowns: this.breakdownList.filter(breakdown => (rawData.selected_granularities || []).includes(breakdown.value)),
      clickAttributionWindow: this.clickAttributionWindows.find((item) => item.value === rawData.click_window_days),
      engagementAttributionWindow: this.engagementAttributionWindows.find((item) => item.value === rawData.engagement_window_days),
      viewAttributionWindow: this.viewAttributionWindows.find((item) => item.value === rawData.view_window_days),
      reportTime: this.reportTimes.find(time => time.value === rawData.conversion_report_time)
    };
  }

  getDefaultConfig() {
    return {
      reportTime: this.reportTimes.find(time => time.value === 'TIME_OF_AD_ACTION'),
      clickAttributionWindow: this.clickAttributionWindows.find((item) => item.value === 30),
      engagementAttributionWindow: this.engagementAttributionWindows.find((item) => item.value === 30),
      viewAttributionWindow: this.viewAttributionWindows.find((item) => item.value === 1)
    };
  }

  buildConnectionParams(data: any) {
    let params = {
      oauth_token_id: data.oauthTokenId,
      selected_granularities: (data.selectedBreakdowns || []).map(breakdown => breakdown.value),
      click_window_days: data.clickAttributionWindow ? data.clickAttributionWindow.value : null,
      engagement_window_days: data.engagementAttributionWindow ? data.engagementAttributionWindow.value : null,
      view_window_days: data.viewAttributionWindow ? data.viewAttributionWindow.value : null,
      conversion_report_time: data.reportTime ? data.reportTime.value : null
    };

    const selectionRawData = getSelectorRawDataFromInclusionState(data.selectedAccounts);

    if (selectionRawData) {
      const selectionDTO = {
        all_select: selectionRawData.all_select,
        ad_accounts: selectionRawData.entities
      };

      params = {
        ...params,
        ...selectionDTO
      };
    }

    return params;
  }
}
