import React from 'react';
import * as Yup from 'yup';
import { InclusionListState, listIncluded } from '../containers/core/models/inclusion-list-v2';
import { _sshService } from '../containers/core/services/ssh.service';
import { HdLink } from '../components/UIElements';
import HdSetupGuideLink from '../components/UIElements/HdSetupGuideLink';
import { HdTooltip } from '../components/UIElements/HdTooltip';
import { hostNameValidator, localHostValidator } from './hostNameHandler';
import { SetupGuideLinkParams } from './setupGuideLinkHandler';

export const hostNameValidatorSchema = ({ type = '' }: { type?: string } = {}) => ({
  name: 'hostValidation',
  exclusive: true,
  message: () => getHostNameErrorMessage(type),
  test: value => hostNameValidator(value)
});

export const localHostValidatorSchema = ({
  type = '',
  typeIdentifier = '',
  localhostDocLinkParams
}: {
  type?: string;
  typeIdentifier?: string;
  localhostDocLinkParams?: SetupGuideLinkParams;
} = {}) => ({
  name: 'localHostValidation',
  exclusive: true,
  message: () => getLocalhostErrorMessage(localhostDocLinkParams),
  test(value) {
    if (type === 'SQL') {
      const useSSH = this.resolve(Yup.ref('useSSH'));
      const shouldCheckLocalHost = () =>
        ['MYSQL', 'MS_SQL'].includes(typeIdentifier) && !_sshService.sshEnabled({ useSSH });

      return localHostValidator(value, !shouldCheckLocalHost());
    }
    return localHostValidator(value);
  }
});

export const schemaNameValidatorSchema = (options = {}) => ({
  name: 'schemaValidation',
  exclusive: true,
  message: 'You can specify only one schema name',
  test(value) {
    if (!value || (value && value.length === 0)) {
      return true;
    }
    return value?.indexOf(',') === -1;
  }
});

export const singleLevelSelectorValidatorSchema = ({ message }: { message: string }) => ({
  name: 'noItemListValidator',
  exclusive: true,
  message,
  test: (value: InclusionListState) => {
    if (value) {
      return listIncluded(value);
    }
    return false;
  }
});

export const folderSelectorValidatorSchema = () => ({
  name: 'pathPrefix',
  exclusive: true,
  message: 'You need to select at least one folder',
  test(value) {
    return !(!value || (value?.entities && value?.entities.length === 0));
  }
});

export const validateDbName = (dbName: string) => {
  const regEx = /^((?![/\\."*<>:|?$ ]).)*$/;
  if (!dbName) {
    return false;
  }
  return regEx.test(dbName);
};

export const mongodbNameValidator = (multipleDbNames = false) => ({
  name: 'mongodbNameValidator',
  exclusive: true,
  message: 'Database name cannot have whitespace, /, \\, ., ", *, <, >, :, |, ?, $',
  test(value) {
    if (!value) {
      return true;
    }
    if (multipleDbNames) {
      return value.split(',').some(dbName => !validateDbName(dbName));
    }
    return validateDbName(value);
  }
});

/**
 * reusable error messages
 */

export const getHostNameErrorMessage = (type = '') => (
  <>
    <span>Invalid hostname</span>
    <HdTooltip
      title={
        type === 'SQL'
          ? 'The hostname must be a valid IPv4 or IPv6 address or DNS name. For example: 123.23.34.45, 2001:0db8:85a3::8a2e:0370:7334, or demodb.hevodata.com.'
          : 'The hostname must be a valid DNS name. For example: demodb.hevodata.com.'
      }
      placement='right-start'
    >
      <span className='mat-error hevo-icon hevo-info cursor-hand info-icon-xsm' />
    </HdTooltip>
  </>
);

export const getLocalhostErrorMessage = (
  localhostDocLinkParams: SetupGuideLinkParams = undefined
) => (
  <span>
    Want to connect to a local database host?{` `}
    {localhostDocLinkParams ? (
      <HdSetupGuideLink
        {...localhostDocLinkParams}
        target='_blank'
        icon='right'
        className='text-link-sm'
        dataId='connect-localhost'
      >
        See steps here
      </HdSetupGuideLink>
    ) : null}
  </span>
);

export const getSiteNameError = () => (
  <span
    data-id='site-name-error'
    style={{ lineHeight: '1.33' }}
  >{`Site Name must:\n • Contain 3 or more characters\n • Contain only lowercase letters, digits, or hyphens\n • Hyphens are allowed only in the middle`}</span>
);

export const getJDBCErrorMessageForSynapse = () => (
  <>
    <span data-id='invalid-jdbc-string'>
      Invalid JDBC connection string. Format :
      `jdbc:sqlserver://yourserver.sql.azuresynapse.net:1433;...`{' '}
    </span>
    <HdLink
      href='https://learn.microsoft.com/en-us/azure/synapse-analytics/sql/connection-strings?source=recommendations#sample-jdbc-connection-string'
      target='_blank'
      size='sm'
      icon='new-window'
      dataId='synapse-help-documentation-conection-string'
    >
      Learn More
    </HdLink>
  </>
);

export const multiLevelSelectorValidatorSchema = ({ message }: { message: string }) => ({
  name: 'noItemListValidator',
  exclusive: true,
  message,
  test: (value: InclusionListState) => {
    if (value) {
      return listIncluded(value);
    }
    return false;
  }
});
