/* eslint-disable camelcase */
/* eslint-disable no-param-reassign */
/* eslint-disable import/no-cycle */
import { getRandomString } from '../../../../../legacy-utils/string';
import { ConnectorAuthTypeEnum } from '../../../../../components/Node/source-type/auth-type';
import DestinationsAPI from '../../../DestinationsAPI';
import { BigQueryResource } from './interface';
import { AUTH_FORM_FIELD_NAME, BigqueryResourceType } from './model';

export function getAutoCreateDatasetName(projectId) {
  return `hevo_dataset_${projectId.replace(/[^0-9a-zA-Z_]/g, '_')}`;
}

export function getAutoCreateBucketName(projectId) {
  return `hevo_bucket_${projectId.replace(/[^0-9a-z_\-.]/g, '_')}`.substr(0, 63);
}

export function getBucketRequestBody(formData, type, id, projectId?: string) {
  const params: any = {
    project_id: projectId || formData.projectId,
    authenticated_user: formData.authorisedAccount
  };

  if (type === ConnectorAuthTypeEnum.OAUTH) {
    params.oauth_token_id = id;
  }

  if (type === ConnectorAuthTypeEnum.SERVICE) {
    params.security_service_account_id = id;
  }

  return params;
}

export function getBigQueryAuthAccountInfo(config, authPath = null) {
  let type: ConnectorAuthTypeEnum;
  let id: number;

  if ('oauth_token_id' in config) {
    type = ConnectorAuthTypeEnum.OAUTH;
    id = config.oauth_token_id;
  }

  if ('security_service_account_id' in config) {
    type = ConnectorAuthTypeEnum.SERVICE;
    id = config.security_service_account_id;
  }

  if (config.oauthTokenId) {
    type = ConnectorAuthTypeEnum.OAUTH;
    id = config.oauthTokenId;
  }

  if (config.serviceAccountId) {
    type = ConnectorAuthTypeEnum.SERVICE;
    id = config.serviceAccountId;
  }

  if (authPath && config[authPath]) {
    return config[authPath];
  }

  return { type, id };
}

export function groupResourceListOnLocation(resourceList: BigQueryResource[]) {
  return resourceList.reduce((resourceGroupList, currentResource) => {
    let { location = '' } = currentResource;

    if (location.toLowerCase() === 'us' || location.toLowerCase() === 'eu') {
      location += ' (Multi-region)';
    }

    const groupIndex = resourceGroupList.findIndex(
      resourceGroup => resourceGroup.location === location
    );

    if (groupIndex >= 0) {
      resourceGroupList[groupIndex].children = [
        ...resourceGroupList[groupIndex].children,
        currentResource
      ];
    } else {
      resourceGroupList = [
        ...resourceGroupList,
        {
          location,
          children: [currentResource]
        }
      ];
    }

    return resourceGroupList;
  }, []);
}

export function groupResourceListOnLocationInReactFormat(resourceList) {
  return groupResourceListOnLocation(resourceList).reduce((resourceGroupList, currentResource) => {
    currentResource.children = currentResource.children.map(ele => ({
      ...ele,
      category: ele.location
    }));
    resourceGroupList = resourceGroupList.concat(currentResource.children);
    return resourceGroupList;
  }, []);
}

export function getDatasetName(projectId) {
  return `${getAutoCreateDatasetName(projectId)}_${getRandomString(4)}`;
}

export function getBucketName(projectId) {
  return `${getAutoCreateBucketName(projectId).substr(0, 58)}_${getRandomString(4, true, false)}`;
}

export const fetchDatasetList = (
  accountType: ConnectorAuthTypeEnum,
  accountId: number,
  projectId: string,
  config: any = {}
) =>
  DestinationsAPI.getBigqueryDatasetListV2(accountType, accountId, projectId, config).then(
    datasets =>
      (datasets || []).map(({ dataset_name, location }) => ({
        name: dataset_name,
        id: dataset_name,
        location,
        apiLocation: location,
        existing: true
      }))
  );

export const fetchBucketsList = (params: any, config: any = {}) =>
  DestinationsAPI.getBigqueryGCSBucketList(params, config).then(buckets =>
    buckets?.data?.map(({ bucket_name, location }) => ({
      name: bucket_name,
      id: bucket_name,
      location,
      apiLocation: location,
      existing: true
    }))
  );

export const fetchProjectList = (
  accountType: ConnectorAuthTypeEnum,
  accountId: number,
  config: any = {}
) =>
  DestinationsAPI.getBigqueryProjectIDList(accountType, accountId, config).then(projects =>
    (projects || []).map(project => ({ id: project.id, name: project.display_name }))
  );

export const fetchResourceListFn = (stateData, resource, config = {}) => {
  const { type, id } = getBigQueryAuthAccountInfo(stateData, AUTH_FORM_FIELD_NAME);
  const projectId = stateData.projectId?.id;

  if (resource === BigqueryResourceType.PROJECT) {
    return fetchProjectList(type, id, config);
  }

  if (resource === BigqueryResourceType.DATASET) {
    return fetchDatasetList(type, id, projectId, config);
  }

  if (resource === BigqueryResourceType.BUCKET) {
    const params = getBucketRequestBody(stateData, type, id, projectId);
    return fetchBucketsList(params, config);
  }

  return Promise.reject(new Error('invalid resource'));
};

export const checkForUserPermission = (stateData, resource) => {
  const { type, id } = getBigQueryAuthAccountInfo(stateData, AUTH_FORM_FIELD_NAME);
  const projectId = stateData.projectId?.id;

  if (resource === BigqueryResourceType.DATASET) {
    return DestinationsAPI.getBigqueryCreateDatasetPermission(type, id, projectId);
  }

  if (resource === BigqueryResourceType.BUCKET) {
    const params = getBucketRequestBody(stateData, type, id, projectId);
    return DestinationsAPI.getBigqueryCreateBucketPermission(params);
  }

  return Promise.reject(new Error('invalid resource'));
};
