import { DestinationTypeConfigHelper } from '../../../../../components/Node/destination-settings-form/destination-type-config-helper';

export class BlackHoleConfigHelper implements DestinationTypeConfigHelper {
  getConfigFromRawData(rawData: any) {
    return {
      queryLatency: rawData.query_latency
    };
  }

  getDefaultConfig() {

  }

  buildConnectionParams(data: any) {
    return {
      query_latency: data.queryLatency
    };
  }
}
