import { MOD_KEY_CLASSES } from './constants';
import { isMac } from '../../legacy-utils/user-agent';

export function getKeyIconClass(key: string) {
  if (key.toLowerCase() === 'mod' && isMac()) {
    return MOD_KEY_CLASSES['cmd'];
  }
  if (key.toLowerCase() === 'mod' && !isMac()) {
    return MOD_KEY_CLASSES['ctrl'];
  }
  return MOD_KEY_CLASSES[key.toLowerCase()];
}
