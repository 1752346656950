import { InviteMemberOptions } from '../core/models/user';
import { ShareDocOptions } from '../../components/Dialog/InviteMembersDialog/interface';
import { AppConfig } from '../core/app.config';
import {
  BACKGROUND_NETWORK_REQ_OPTIONS,
  BACKGROUND_NETWORK_REQ_UI_OPTIONS
} from '../core/constants';
import { NetworkRequestOptions, RequestPaginationData } from '../core/models/request';
import { courier } from '../core/services/courier.service';
import { createHttpParams } from '../../legacy-utils/request';
import { ACCESS_SEARCH_FILTER_KEY } from './AccountAccess/constants';
import { MemberPageURLBasedAction } from './Members/models';

const appConfig = new AppConfig();

const TeamAPI = {
  usersURL: `${appConfig.getSecurityServiceURL()}/users`,
  multiTeamUsersURL: `${appConfig.getSecurityServiceURL()}/multi-team-users`,
  teamURL: `${appConfig.getSecurityServiceURL()}/teams/my`,
  teamsURL: `${appConfig.getSecurityServiceURL()}/teams`,
  feedbackUrl: `${appConfig.getSecurityServiceURL()}/feedbacks`,
  consentUrl: `${appConfig.getSecurityServiceURL()}/consent`,

  getMembers(abortSignal: AbortSignal) {
    return courier.get(`${this.usersURL}/all-users`, {
      ...BACKGROUND_NETWORK_REQ_OPTIONS,
      signal: abortSignal
    });
  },

  invite(email: string) {
    const requestUrl = `${this.usersURL}/invite/${email}`;

    const options: NetworkRequestOptions = {
      uiOptions: {
        showLoading: true
      }
    };

    return courier.post(requestUrl, options);
  },

  inviteMember(inviteOptions: InviteMemberOptions) {
    const requestUrl = this.usersURL + '/invite-member';

    return courier.post(requestUrl, BACKGROUND_NETWORK_REQ_OPTIONS, inviteOptions);
  },

  deleteMember(email: string) {
    return courier.delete(`${this.usersURL}/remove-user/${email}`);
  },

  updateTeamName(name: string) {
    return courier.put(
      `${this.teamsURL}/name`,
      { uiOptions: { showLoading: false } },
      {
        team_name: name
      }
    );
  },

  updateAuthenticationConfig(configPayload: any) {
    const requestUrl = `${this.teamURL}/auth-configurations`;

    const options: NetworkRequestOptions = {
      uiOptions: {
        showSuccessMsg: true,
        successMsg: 'Authentication Settings updated successfully',
        showErrorMsg: false,
        showLoading: false
      }
    };

    return courier.put(requestUrl, options, configPayload);
  },

  getAccountDeletionReasonOptions() {
    const requestUrl = `${this.feedbackUrl}/account-deletion-feedback-options`;

    return courier.get(requestUrl, BACKGROUND_NETWORK_REQ_OPTIONS);
  },

  deleteTeam() {
    const options: NetworkRequestOptions = {
      uiOptions: {
        showSuccessMsg: false,
        showLoading: false
      }
    };

    return courier.delete(this.teamURL, options);
  },

  shareSetupGuide(shareOptions: ShareDocOptions & { rbacRoles?: any }) {
    const requestUrl = `${this.usersURL}/share-setup-guide`;

    const postBody = {
      to_emails: shareOptions.toEmails,
      entity: shareOptions.entity,
      entity_type: shareOptions.entityType,
      rbac: shareOptions.rbacRoles,
      should_invite_members: shareOptions.shouldInviteMembers,
      draft_source_id: shareOptions.sourceId
    };

    return courier.post(requestUrl, BACKGROUND_NETWORK_REQ_OPTIONS, postBody);
  },

  inviteAction(code: string, action: MemberPageURLBasedAction) {
    const actionPath = action === MemberPageURLBasedAction.ACCEPT_INVITE ? 'accept' : 'decline';

    const requestUrl = `${this.multiTeamUsersURL}/add-member/${actionPath}`;

    return courier
      .put(
        requestUrl,
        {
          ...BACKGROUND_NETWORK_REQ_OPTIONS,
          uiOptions: {
            ...BACKGROUND_NETWORK_REQ_OPTIONS.uiOptions,
            handleUnauthorisedResponse: false
          }
        },
        { code }
      )
      .then(res => ({
        action,
        userName: res.name,
        userEmail: res.email,
        code
      }))
      .catch(error => ({
        action,
        error,
        code
      }));
  },

  teamAccountDeletionFeedback(email: string, accountDeletionReasonCode: string, comment: string) {
    const requestUrl = `${this.feedbackUrl}/account-deletion-feedback`;

    const options: NetworkRequestOptions = {
      uiOptions: {
        showSuccessMsg: false,
        showLoading: false
      }
    };

    // set keys only if value is not undefined
    const params = {
      email,
      ...(accountDeletionReasonCode && { account_deletion_reason_code: accountDeletionReasonCode }),
      ...(comment && { comment })
    };

    return courier.post(requestUrl, options, params);
  },

  getTeamSubscriptions() {
    const requestUrl = `${this.teamsURL}/subscription`;

    return courier.get(requestUrl, BACKGROUND_NETWORK_REQ_OPTIONS);
  },

  getTeamDetails() {
    return courier.get(`${this.teamURL}`, BACKGROUND_NETWORK_REQ_OPTIONS);
  },

  removeOwner(email: string) {
    const requestUrl = `${this.usersURL}/remove-owner/${email}`;

    return courier.put(requestUrl);
  },

  makeOwner(email: string) {
    const requestUrl = `${this.usersURL}/make-owner/${email}`;

    return courier.put(requestUrl);
  },

  getCurrentAccountAccessConsentStatus() {
    const consentStatusUrl = `${this.consentUrl}/status`;

    const options: NetworkRequestOptions = {
      uiOptions: {
        showSuccessMsg: false,
        showLoading: false
      }
    };

    return courier.get(consentStatusUrl, options);
  },

  toggleAccountAccessConsent() {
    const options: NetworkRequestOptions = {
      uiOptions: {
        showSuccessMsg: false,
        showLoading: false
      }
    };

    return courier.put(this.consentUrl, options);
  },

  getTeamRoles() {
    const rolesURL = `${this.teamsURL}/roles-breakup`;

    return courier.get(rolesURL, BACKGROUND_NETWORK_REQ_OPTIONS);
  },

  updateUserRoles(userId, payload, networkOptions) {
    const requestUrl = `${this.usersURL}/roles/${userId}`;

    const options: NetworkRequestOptions = {
      uiOptions: {
        ...BACKGROUND_NETWORK_REQ_UI_OPTIONS,
        showErrorMsg: true
      },
      ...networkOptions
    };

    return courier.put(requestUrl, options, payload);
  },

  getAccountAccessHistoryLogs(paginationData: RequestPaginationData) {
    const consentLogsUrl = `${this.consentUrl}/logs`;

    const params: any = {
      page: paginationData.page,
      limit: paginationData.limit
    };

    if (
      ACCESS_SEARCH_FILTER_KEY in paginationData &&
      paginationData[ACCESS_SEARCH_FILTER_KEY] !== ''
    ) {
      params.user_name = paginationData[ACCESS_SEARCH_FILTER_KEY];
    }

    const options: NetworkRequestOptions = {
      uiOptions: {
        showSuccessMsg: false,
        showLoading: false
      },
      networkOptions: {
        params: createHttpParams(params)
      }
    };

    return courier.get(consentLogsUrl, options);
  },

  inviteUserWithRBAC(payload) {
    const requestUrl = `${this.usersURL}/invite`;

    const options : NetworkRequestOptions = {
      uiOptions: {
        ...BACKGROUND_NETWORK_REQ_UI_OPTIONS,
        showSuccessMsg: true,
        showErrorMsg: true,
        successMsg: 'Invited user(s) successfully.'
      }
    }
    return courier.post(requestUrl, options, payload);
  },

  shareEmailForBulkActionPermissionError(shareOptions: {
    toEmails: string[],
    docLink: string,
    pipelineUrl: string,
    sourceType: string
  }) {
    const requestUrl = `${ this.usersURL }/change-source-config-request`;

    const postBody = {
      to_emails: shareOptions.toEmails,
      documentation_link: shareOptions.docLink,
      pipeline_url: shareOptions.pipelineUrl,
      source_type: shareOptions.sourceType
    };

    return courier.post(requestUrl, BACKGROUND_NETWORK_REQ_OPTIONS, postBody);
  }
};

export default TeamAPI;
