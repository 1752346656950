import { SourceTypeConfigHelper } from '../../../../../components/Node/source-settings-form/config-helper';
import PipelinesAPI from '../../../PipelinesAPI';

export class RecurlyConfigHelper implements SourceTypeConfigHelper {
  getConfigFromRawData(rawData: any) {
    return {
      apiKey: rawData.recurly_api_key,
      recurlyEuEnv: rawData.recurly_eu_env,
    };
  }

  getDefaultConfig() {
  }

  buildConnectionParams(data: any) {
    return {
      recurly_api_key: data.apiKey,
      recurly_eu_env: data.recurlyEuEnv,
    };
  }

  metaDataResolver(sourceType: string, authData: any) {
    return PipelinesAPI.getRecurlyEuEnabledStatus().then((res: any) => ({
        showEuToggle: res?.data,
      })
    );
  }
}
