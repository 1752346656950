import { SourceTypeConfigHelper } from '../../../../../components/Node/source-settings-form/config-helper';
import { SENDGRID_ACCOUNT_TIMEZONES } from './constants';

export class SendgridConfigHelper implements SourceTypeConfigHelper {
  timezones = SENDGRID_ACCOUNT_TIMEZONES;

  getConfigFromRawData(rawData: any) {
    return {
      apiKey: rawData.api_key,
      timezone: this.timezones.find((timezone) => rawData.sendgrid_account_timezone === timezone.timezone)
    };
  }

  getDefaultConfig() {
  }

  buildConnectionParams(data: any) {
    const params: any = {
      api_key: data.apiKey
    };

    if (data.timezone) {
      params.sendgrid_account_timezone = data.timezone.timezone;
    }

    return params;
  }
}
