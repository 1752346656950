/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-param-reassign */
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { styled } from '@mui/material/styles';
import FocusLock from 'react-focus-lock';
import { ClickAwayListener, Popper } from '@mui/material';
import useDontMountAtFirst from '../../../hooks/useDontMountAtFirst';
import { getDataIdGenerator } from '../../../utils/generateDataId';
import { defaultMaxDate, defaultMinDate } from './constants';
import { HdTextField } from '../HdTextField';
import { Calendar } from './nativeCode';
import { HdIconButton } from '../HdIconButton';
import { HdIcon } from '../index';
import { DayjsDateTimeAdapter } from './adapter/dayjs-adapter/dayjs-date-time-adapter.class';
import { OWL_DAYJS_DATE_TIME_EXTENDED_FORMATS } from './adapter/dayjs-adapter/dayjs-date-time-format-extended.class';
import { DateTimeAdapterContext, useDateTimeAdapter } from './useDateTimeAdapter';
import dayjs from 'dayjs';

export interface DatePickerProps {
  dataId: string;
  id?: string;
  helperText?: React.ReactNode;
  placeholder: string;
  label: string;
  disabled?: boolean;
  value?: Date;
  minDate?: Date;
  error?: boolean;
  maxDate?: Date;
  iconSize?: number;
  required?: boolean;
  offsetUTC?: string;
  showTime?: boolean;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  onChange: (date: Date) => void;
}

export const HdPopper = styled(Popper)(({ theme }) => ({
  boxShadow: 'var(--shadow-dropdown)',
  borderRadius: 'var(--border-radius-md)',
  width: 290,
  zIndex: theme.zIndex.modal,
  background: 'var(--surface-bg-secondary-color)'
}));

export default function DatePickerInput(props: DatePickerProps) {
  const dayjsAdapter = useMemo(() => {
    const adaptor = new DayjsDateTimeAdapter('', {
      useUtc: false
    });
    adaptor.setOffset(props.offsetUTC);
    return {
      dateTimeAdapter: adaptor
    };
  }, [props.offsetUTC]);

  return (
    <DateTimeAdapterContext.Provider value={dayjsAdapter}>
      <DatePickerInputInner {...props} />
    </DateTimeAdapterContext.Provider>
  );
}

function DatePickerInputInner({
  dataId,
  placeholder,
  label,
  value,
  minDate = defaultMinDate,
  maxDate = defaultMaxDate,
  iconSize = 2,
  onChange,
  offsetUTC,
  showTime = false,
  ...inputTextProps
}: DatePickerProps) {
  const inputRef = useRef<any>();
  const [isOpen, setOpen] = useState(false);

  const inputFormat = useMemo(
    () =>
      showTime
        ? OWL_DAYJS_DATE_TIME_EXTENDED_FORMATS.fullPickerInput
        : OWL_DAYJS_DATE_TIME_EXTENDED_FORMATS.datePickerInput,
    [showTime]
  );

  const { dateTimeAdapter: dayjsAdapter } = useDateTimeAdapter<dayjs.Dayjs>();

  const getValidDate = obj =>
    dayjsAdapter.isDateInstance(obj) && dayjsAdapter.isValid(obj) ? obj : null;

  const sanitizeDate = _value => {
    _value = dayjsAdapter.deserialize(_value);
    return getValidDate(_value);
  };

  const formatInputValue = _value => {
    if (!_value) {
      return '';
    }

    return dayjsAdapter.format(sanitizeDate(_value), inputFormat);
  };

  const parseInputValue = _value => dayjsAdapter.parse(_value, inputFormat);

  const [dateInputText, setDateInputText] = useState<string>(formatInputValue(value));

  useDontMountAtFirst(() => {
    const parsedDayjsDate = parseInputValue(value);
    if (parsedDayjsDate === null || !parsedDayjsDate.isValid()) {
      return;
    }

    setDateInputText(formatInputValue(value));
  }, [value]);

  const handleOnCalendarChange = (date: dayjs.Dayjs) => {
    setDateInputText(formatInputValue(date));
    onChange(date.toDate());
  };

  const handleInputChange = e => {
    const inputText = e.target.value;
    setDateInputText(inputText);

    const parsedDayjsDate = parseInputValue(inputText);
    if (parsedDayjsDate === null || !parsedDayjsDate.isValid()) {
      onChange(null);
    }

    const parsedDate = parsedDayjsDate.toDate();
    if (parsedDate < maxDate && parsedDate > minDate) {
      onChange(parsedDayjsDate.toDate());
    } else {
      onChange(null);
    }
  };

  const onKeyDownHandler = event => {
    if (event.key === 'Escape') {
      event.preventDefault();
      setOpen(false);
    }
  };

  const handleCalendarIconClick = () => {
    setOpen(prevState => !prevState);
  };

  const handleInputFieldClick = () => {
    setOpen(true);
  };

  const dataIdGenerator = useCallback(getDataIdGenerator(dataId, 'datePicker'), [dataId]);

  const sanitizedValue = sanitizeDate(value);
  return (
    <>
      <HdTextField
        inputRef={inputRef}
        placeholder={placeholder}
        variant='outlined'
        value={dateInputText}
        onKeyDown={onKeyDownHandler}
        label={label}
        onChange={handleInputChange}
        onClick={() => handleInputFieldClick()}
        dataId={dataIdGenerator('')}
        suffixElement={
          <HdIconButton
            onClick={() => handleCalendarIconClick()}
            dataId={dataIdGenerator('calender')}
          >
            <HdIcon name='date-time' size={iconSize} />
          </HdIconButton>
        }
        {...inputTextProps}
      />

      <HdPopper
        onKeyDown={onKeyDownHandler}
        anchorEl={inputRef.current}
        open={isOpen}
        modifiers={[
          {
            name: 'preventOverflow',
            enabled: true,
            options: {
              altAxis: true,
              altBoundary: true,
              tether: true,
              rootBoundary: 'document',
              padding: 8
            }
          }
        ]}
      >
        <ClickAwayListener
          onClickAway={() => {
            if (isOpen) {
              setOpen(false);
            }
          }}
        >
          <FocusLock returnFocus autoFocus>
            <Calendar<dayjs.Dayjs>
              maxDate={sanitizeDate(maxDate)}
              minDate={sanitizeDate(minDate)}
              value={sanitizedValue}
              showTime={showTime}
              onChange={handleOnCalendarChange}
            />
          </FocusLock>
        </ClickAwayListener>
      </HdPopper>
    </>
  );
}
