import { coerceBooleanProperty } from '../../../../../legacy-utils/coercion';
import { SSHService } from '../../../../core/services/ssh.service';
import { DEFAULT_SCHEMA_NAME } from '../../../../../components/Node/constants';
import { DestinationTypeConfigHelper } from '../../../../../components/Node/destination-settings-form/destination-type-config-helper';
import { DESTINATION_TYPES } from '../../../../../components/Node/destination-type/model';
import { stringToBool } from '../../../../../utils/helper';
import { DEFAULT_MS_SQL_SCHEMA_NAME } from './constants';

export class DestinationSqlConfigHelper implements DestinationTypeConfigHelper {
  constructor(public destinationType: string, private _sshService: SSHService) {}

  getDefaultConfig() {
    const config: any = {
      sshPort: 22,
      schemaName: DEFAULT_SCHEMA_NAME,
      sanitizeName: true
    };

    switch (this.destinationType) {
      case 'MYSQL':
      case 'AURORA':
      case 'AWS_RDS_MYSQL':
      case 'GCP_MYSQL':
      case 'AZURE_MYSQL':
      case 'ORACLE_MYSQL': {
        config.port = 3306;
        break;
      }
      case 'MS_SQL':
      case 'AWS_RDS_MSSQL':
      case 'GCP_MS_SQL':
      case 'AZURE_SQL': {
        config.port = 1433;
        config.schemaName = DEFAULT_MS_SQL_SCHEMA_NAME;
        break;
      }
      case 'REDSHIFT': {
        config.port = 5439;
        break;
      }
      case 'POSTGRES':
      case 'AWS_RDS_POSTGRES':
      case 'AWS_AURORA_POSTGRES':
      case 'GCP_POSTGRES':
      case 'AZURE_POSTGRES':
      case 'SF_HEROKU_POSTGRES': {
        config.port = 5432;
        break;
      }
      default:
        break;
    }

    return config;
  }

  getConfigFromRawData(rawData: any) {
    const config: any = {
      host: rawData.db_host,
      user: rawData.db_user,
      port: rawData.db_port,
      databaseName: rawData.db_name,
      password: rawData.db_password,
      sshIp: rawData.ssh_host,
      sshPort: rawData.ssh_port,
      sshUser: rawData.ssh_user,
      schemaName: rawData.schema_name,
      useSSH: coerceBooleanProperty(rawData.ssh_host || rawData.ssh_port || rawData.ssh_user),
      useSSL: this.canUseSSL() ? coerceBooleanProperty(rawData.use_ssl) : undefined,
      sanitizeName: rawData.sanitize_name,
      isConnectionUri: 'false'
    };

    if ('is_connection_uri' in rawData) {
      config.isConnectionUri =
        typeof rawData.is_connection_uri !== 'boolean'
          ? String(false) // Stringfying because radio component takes 'true' or 'false'
          : String(rawData.is_connection_uri);
      config.connectionString = `jdbc:redshift://${rawData.db_host}:${rawData.db_port}/${rawData.db_name}`;
    }

    if (config.useSSL) {
      if ('ssl_ca' in rawData) {
        config.sslCA = { content: rawData.ssl_ca };
      }
      if ('ssl_client_key' in rawData) {
        config.sslClientKey = { content: rawData.ssl_client_key };
      }
      if ('ssl_client_cert' in rawData) {
        config.sslClientCert = { content: rawData.ssl_client_cert };
      }
      config.sslLoadAllCA = rawData.ssl_load_all_ca || false;
    }

    if (this.destinationType === 'REDSHIFT') {
      config.isServerless = rawData.is_serverless;
    }

    return config;
  }

  canUseSSL() {
    return DESTINATION_TYPES[this.destinationType].canUseSSL;
  }

  buildConnectionParams(data: any) {
    const params: any = {
      db_host: data.host,
      db_port: data.port,
      db_user: data.user,
      db_password: data.password,
      db_name: data.databaseName,
      sanitize_name: data.sanitizeName
    };

    if (data.schemaName) {
      params.schema_name = data.schemaName;
    }

    this._sshService.buildSSHParams(params, data);

    if (data.useSSL) {
      params.use_ssl = true;
      params.ssl_ca = data.sslCA?.content || null;
      params.ssl_client_key = data.sslClientKey?.content || null;
      params.ssl_client_cert = data.sslClientCert?.content || null;
      params.ssl_load_all_ca = data.sslLoadAllCA;
    }

    if (data.isConnectionUri !== undefined) {
      params.is_connection_uri = stringToBool(data.isConnectionUri);
    }

    if (this.destinationType === 'REDSHIFT') {
      params.is_serverless = data.isServerless;
    }

    return params;
  }
}
