import React from 'react';
import { getRateLimitViolationMsg } from '../../core/models/rate-limit-violation';
import { HdDocLink } from '../../../components/UIElements';

export default function RateLimitBannerMessage({ bannerData }) {
  const rateLimitViolationMsg = getRateLimitViolationMsg(bannerData?.messageContext || {});
  return (
    <>
      {rateLimitViolationMsg}{' '}
      <HdDocLink
        dataId='rate-limit-violation-banner-link'
        color='light'
        docLink='/data-loading/loading-in-batches/'
        section='rate_limit_violation_banner'
        label='Read More'
        icon=' '
      />
    </>
  );
}
