import { SourceTypeConfigHelper } from '../../../../../components/Node/source-settings-form/config-helper';

export class TwilioConfigHelper implements SourceTypeConfigHelper {
  getDefaultConfig() {
    return {};
  }

  getConfigFromRawData(rawData: any) {
    return {
      apiSid: rawData.api_sid,
      apiSecret: rawData.api_secret
    };
  }

  buildConnectionParams(data: any) {
    return {
      api_sid: data.apiSid?.trim(),
      api_secret: data.apiSecret?.trim()
    };
  }
}
