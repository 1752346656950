import { useHistory } from '@useHistory/useHistoryCompat';
import React, { ReactNode, useMemo, useRef } from 'react';
import { createPath, useNavigate, useSearchParams } from 'react-router-dom';
import { BehaviorSubject } from 'rxjs';
import { AngularDiProviders } from './components/AngularDI/AngularDiProviders';
import {
  ReactRouterServiceBridge,
  ReactRouterService
} from './containers/core/services/react-router.service';
import { rootProviders } from './containers/rootInjector';

export function RouterServiceProvider({ children }: { children?: ReactNode }) {
  const [ searchParams ] = useSearchParams();
  const searchParams$ = useRef(new BehaviorSubject(searchParams));
  const history = useHistory();
  const navigate = useNavigate();

  useMemo(() => {
    searchParams$.current.next(searchParams);
  }, [searchParams]);

  const providers = useMemo(() => {
    return [{
      provide: ReactRouterServiceBridge,
      useValue: {
        get url() {
          return createPath({
            pathname: window.location.pathname,
            hash: window.location.hash,
            search: window.location.search
          });
        },
        listen: history.listen.bind(history),
        navigate: (props: { pathname: string, search?: string }, options?: { replace: boolean }) => {
          navigate(props, options);
          return Promise.resolve();
        },
        get queryParams() {
          return searchParams$.current.asObservable();
        }
      }
    }, ReactRouterService, ...rootProviders];
  }, []);

  return (
    <AngularDiProviders providers={providers}>
      {children}
    </AngularDiProviders>
  );
}
