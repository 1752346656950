import { BACKGROUND_NETWORK_REQ_UI_OPTIONS } from '../constants';
import { AnsibleBoolean } from '../models/ansible-config';
import { NetworkRequestOptions } from '../models/request';
import { TrackerConfig, UITracker, UITrackerRegistry } from '../models/tracking';
import { User } from '../models/user';
import { ClarityService } from './clarity.service';
import { HubspotService } from './hubspot.service';
import { MixpanelService } from './mixpanel.service';
import { RxRequestService } from './rx-request.service';
import { SegmentService } from './segment.service';

declare var __CLARITY_CONFIG__: TrackerConfig;
declare var __SEGMENT_CONFIG__: TrackerConfig;
declare var __HS_CONFIG__: TrackerConfig;
declare var __MIXPANEL_CONFIG__: TrackerConfig;
declare var __DEPLOYMENT_ENV__: string;

export class TrackingService {
  private _registry: Map<string, UITrackerRegistry> = new Map();
  user: User;

  static deps = [
    ClarityService,
    HubspotService,
    SegmentService,
    RxRequestService,
    MixpanelService
  ];

  constructor(
    private _clarityService: ClarityService,
    private _hubspotService: HubspotService,
    private _segmentService: SegmentService,
    private _requestService: RxRequestService,
    private _mixpanelService: MixpanelService
  ) {
    this._register('mixpanel', _mixpanelService, __MIXPANEL_CONFIG__);
    this._register('clarity', _clarityService, __CLARITY_CONFIG__);
    this._register('hubspot', _hubspotService, __HS_CONFIG__);
    this._register('segment', _segmentService, __SEGMENT_CONFIG__);
  }

  private _register(name: string, service: UITracker, config: TrackerConfig) {
    this._registry.set(name, { service, config });
  }

  setUser(user: User) {
    this.user = user;
    this.storeDNTSettings();
  }

  refresh() {
    this._registry.forEach((registry) => {
      if (this.isTrackingEnabled(registry.config)) {
        this._initService(registry.service, registry.config);
      } else {
        this._stopService(registry.service);
      }
    });
  }

  identifyUser(user: User) {
    this._registry.forEach((registry) => {
      registry.service.identifyUser(user);
    });
  }

  updateIdentity(user: User) {
    this._registry.forEach((registry) => {
      registry.service.updateIdentity(user);
    });
  }

  updateProperties(user: User, data: any) {
    this._registry.forEach((registry) => {
      if (this.isTrackingEnabled(registry.config)) {
        registry.service.updateUserProperties(user, data);
      }
    });
  }

  reset() {
    this._registry.forEach((registry) => {
      registry.service.reset();
    });
  }

  storeDNTSettings() {
    const params = {
      event: 'do not track settings',
      properties: {
        email: this.user.email,
        doNotTrack: navigator.doNotTrack || 'unspecified'
      }
    };

    if (typeof __DEPLOYMENT_ENV__ !== 'undefined') {
      params['deploymentEnv'] = __DEPLOYMENT_ENV__;
    }

    const options: NetworkRequestOptions = {
      uiOptions: BACKGROUND_NETWORK_REQ_UI_OPTIONS,
      networkOptions: {
        headers: { 'Anonymous': 'True' }
      }
    };

    this._requestService.post(
      'https://asia-webhook.hevodata.com/t/ryj1atyfhb',
      options,
      params
    ).subscribe();
  }

  private _initService(service: UITracker, config: TrackerConfig) {
    service.init(config);
  }

  private _stopService(service: UITracker) {
    service.stop();
  }

  isTrackingEnabled(config: TrackerConfig) {
    if (typeof config === 'undefined' || config.enabled !== AnsibleBoolean.TRUE) {
      return false;
    }

    const enabledForHevo = config.enabledForHevo === AnsibleBoolean.TRUE;

    return !(this.user && this.user.email.includes('@hevodata.com') && !enabledForHevo);
  }
}
