import { SourceTypeConfigHelper } from '../../../../../components/Node/source-settings-form/config-helper';

export class MarketoConfigHelper implements SourceTypeConfigHelper {
  getConfigFromRawData(rawData: any) {
    return {
      clientID: rawData.client_id,
      clientSecret: rawData.client_secret,
      apiEndpoint: rawData.api_endpoint,
      identityEndpoint: rawData.identity_endpoint,
    };
  }

  getDefaultConfig() {
  }

  buildConnectionParams(data: any) {
    return {
      client_id: data.clientID,
      client_secret: data.clientSecret,
      api_endpoint: data.apiEndpoint,
      identity_endpoint: data.identityEndpoint
    };
  }
}
