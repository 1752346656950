import React, { useEffect, useState } from 'react';
import HdMenuTrigger from '../../UIElements/HdMenuTrigger';
import { HdMenu, HdMenuItem } from '../../UIElements';
import {
  SYNC_FREQUENCY_CHIPS,
  FREQUENCY_TYPE_HOURS,
  FREQUENCY_TYPE_MINUTES
} from '../../../containers/scheduler/ExecutionPolicyDialog/constants';
import styles from './styles.module.scss';
import { SyncFrequencyInitiator } from '../../../containers/scheduler/SyncFrequencyDialog';
import { PolicyTypeEnum } from '../../../containers/scheduler/ExecutionPolicyDialog/models';
import { ExecutionPolicyDialog } from '../../../containers/scheduler/ExecutionPolicyDialog/ExecutionPolicyDialog';
import { HevoEntity } from '../../../containers/core/models/hevo-entity';
import { getSyncFrequencyDisplayText } from '../utils';

const customOption: any = {
  frequency_value: 'Custom',
  value: 'Custom',
  displayText: 'Custom',
  disabled: false
};

const options = SYNC_FREQUENCY_CHIPS.map(frequency => ({
  type: PolicyTypeEnum.FREQUENCY,
  frequency_value: frequency.value,
  frequency_type: frequency.frequencyType.value,
  frequency: frequency.value * frequency.frequencyType.minSeconds
}));

export const defaultSyncFrequencyOption = options[1]; // 15 mins

interface SyncFrequencyDropdownProps {
  id: string;
  syncFrequencyPolicy: any;
  onChange: Function;
}

export function SyncFrequencyDropdown({
  id,
  syncFrequencyPolicy,
  onChange
}: SyncFrequencyDropdownProps) {
  const [openFrequencyMenu, setOpenFrequencyMenu] = useState(false);
  const [customFrequencySelected, setCustomFrequencySelected] = useState(false);
  const [selectedOption, setSelectedOption] = useState<any>(defaultSyncFrequencyOption);
  const [showExecutionPolicyDialog, setShowExecutionPolicyDialog] = useState(false);

  useEffect(() => {
    if (syncFrequencyPolicy?.type) {
      // options.f;
      setSelectedOption(syncFrequencyPolicy);
    }
  }, [syncFrequencyPolicy]);

  const isOptionSelected = frequency => {
    if (frequency.frequency_value === customOption.frequency_value) {
      return (
        frequency.frequency_value === selectedOption.frequency_value || customFrequencySelected
      );
    }
    return (
      frequency.frequency_value === selectedOption.frequency_value &&
      frequency.frequency_type === selectedOption.frequency_type &&
      !customFrequencySelected
    );
  };

  const onFrequencyChange = (syncFrequencyObj, isCustom) => {
    setOpenFrequencyMenu(false);
    if (isCustom) {
      if (syncFrequencyObj.type !== PolicyTypeEnum.CRON) {
        const minSeconds =
          syncFrequencyObj.frequency_type === FREQUENCY_TYPE_MINUTES.value
            ? FREQUENCY_TYPE_MINUTES.minSeconds
            : FREQUENCY_TYPE_HOURS.minSeconds;
        // eslint-disable-next-line no-param-reassign
        syncFrequencyObj = {
          ...syncFrequencyObj,
          frequency: syncFrequencyObj.frequency_value * minSeconds
        };
      }
    }

    const isOptionPresentInDropdown = [...options, customOption].filter(
      frequency =>
        frequency.frequency_value === syncFrequencyObj.frequency_value &&
        frequency.frequency_type === syncFrequencyObj.frequency_type
    );
    setSelectedOption(syncFrequencyObj);

    setCustomFrequencySelected(!isOptionPresentInDropdown.length);
    if (syncFrequencyObj.frequency_value !== customOption.frequency_value) {
      onChange(syncFrequencyObj, [id]);
    } else {
      setShowExecutionPolicyDialog(true);
    }
  };

  const frequencyMenuItems = [...options, customOption].map((frequency, i) => (
    <HdMenuItem
      key={i}
      dataId={frequency.frequency_value}
      selected={isOptionSelected(frequency)}
      onClick={() => {
        onFrequencyChange(frequency, false);
      }}
    >
      {frequency.frequency_value !== customOption.frequency_value
        ? getSyncFrequencyDisplayText(frequency)
        : frequency.displayText}
    </HdMenuItem>
  ));
  return (
    <>
      <div className={`${styles.frequencyMenuTriggerWrapper}`}>
        <HdMenuTrigger
          onForceClose={() => {
            setOpenFrequencyMenu(false);
          }}
          id={`frequency-menu-${id}`}
          onClick={() => {
            setOpenFrequencyMenu(!openFrequencyMenu);
          }}
          dataId='sync-frequency'
        >
          {selectedOption.frequency_value !== customOption.frequency_value
            ? getSyncFrequencyDisplayText(selectedOption)
            : selectedOption.displayText}
        </HdMenuTrigger>
      </div>
      <HdMenu
        target={`#frequency-menu-${id}`}
        open={openFrequencyMenu}
        onClose={() => setOpenFrequencyMenu(false)}
      >
        {frequencyMenuItems}
      </HdMenu>

      <ExecutionPolicyDialog
        onSubmit={syncFrequencyObj => {
          onFrequencyChange(syncFrequencyObj, true);
          setShowExecutionPolicyDialog(false);
        }}
        onClose={() => {
          setShowExecutionPolicyDialog(false);
        }}
        open={showExecutionPolicyDialog}
        entityDetails={{ execution_policy: syncFrequencyPolicy }}
        entity={HevoEntity.SYNC_FREQUENCY.value}
        initiator={SyncFrequencyInitiator.MIGRATE_PIPELINES}
      />
    </>
  );
}
