/* eslint-disable default-param-last */
/* eslint-disable no-shadow */
import { useEffect, useRef } from 'react';

import {
  DEFAULT_INCLUSION_LIST_STATE,
  InclusionItem,
  InclusionItemKey,
  InclusionList,
  InclusionListState
} from '../containers/core/models/inclusion-list-v2';
import useForceUpdate from './useForceUpdate';

export default function useInclusionList(
  key: InclusionItemKey,
  size: number,
  listUpdateCallback,
  inclusionListState: InclusionListState = DEFAULT_INCLUSION_LIST_STATE
) {
  const forceUpdate = useForceUpdate();
  const _changes = useRef(null);
  const inclusionList = useRef(
    new InclusionList(key, size, !inclusionListState ? undefined : inclusionListState)
  );

  useEffect(() => {
    const subscription = inclusionList.current.changes.subscribe(data => {
      if (listUpdateCallback) {
        listUpdateCallback(data);
      }
      _changes.current = { ...data };
      forceUpdate();
    });

    return () => {
      subscription.unsubscribe();
    };
  }, []);

  const setState = (state: InclusionListState) => {
    inclusionList.current.setState(state);
  };

  const setItems = (items: (InclusionItem | InclusionList)[]) => {
    inclusionList.current.setItems(items);
    forceUpdate();
  };

  const setSize = (updatedSize: number, emitChanges = true, updateParent = true) => {
    if (updatedSize === null && inclusionList.current.size === null) {
      return;
    }
    inclusionList.current.setSize(updatedSize, emitChanges, updateParent);
    forceUpdate();
  };

  const togglePageCheckedState = (emitChanges = true) => {
    inclusionList.current.togglePageCheckedState(emitChanges);
    forceUpdate();
  };

  const addItems = (items: (InclusionItem | InclusionList)[]) => {
    inclusionList.current.addItems(items);
    forceUpdate();
  };

  const clearSelection = () => {
    inclusionList.current.clearSelection();
  };

  const setCheckedState = (selected: boolean, emitChanges = true, updateParent = true) => {
    inclusionList.current.setCheckedState(selected, emitChanges, updateParent);
    forceUpdate();
  };

  return {
    inclusionList: inclusionList.current,
    setState,
    clearSelection,
    addItems,
    setItems,
    inclusionListSetSize: setSize,
    togglePageCheckedState,
    setCheckedState,
    inclusionListChanges: _changes.current
  };
}
