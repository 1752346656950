import { InclusionListState } from '../../../../core/models/inclusion-list-v2';
import { OptionsType } from '../../../../../components/UIElements/HdDropDown';
import { GenericKeyValueType } from '../../../../../generics';

export enum AmazonAdsReportCategory {
  STANDARD_REPORTS = 'standard_report',
  CUSTOM_REPORTS = 'custom_report'
}

export enum AmazonAdsFormFieldNames {
  PROFILES = 'profiles',
  REPORT_CATEGORY = 'reportCategory',
  STANDARD_REPORTS = 'standardReports',
  CUSTOM_REPORT_NAME = 'customReportName',
  BASE_METRICS = 'baseMetrics',
  GROUP_BY = 'groupBy',
  ADDITIONAL_METRICS = 'additionalMetrics',
  MANAGER_CUSTOMERS_MAP = 'managerCustomersMap',
  CORE_OBJECTS = 'coreObjects'
}

export interface AmazonAdsFormState extends GenericKeyValueType<string, any> {
  [AmazonAdsFormFieldNames.PROFILES]: InclusionListState;
  [AmazonAdsFormFieldNames.REPORT_CATEGORY]: AmazonAdsReportCategory;
  [AmazonAdsFormFieldNames.STANDARD_REPORTS]: OptionsType[];
  [AmazonAdsFormFieldNames.CORE_OBJECTS]: OptionsType[];
  [AmazonAdsFormFieldNames.CUSTOM_REPORT_NAME]: OptionsType | null;
  [AmazonAdsFormFieldNames.BASE_METRICS]: OptionsType[];
  [AmazonAdsFormFieldNames.GROUP_BY]: string;
  [AmazonAdsFormFieldNames.ADDITIONAL_METRICS]: OptionsType[];
  [AmazonAdsFormFieldNames.MANAGER_CUSTOMERS_MAP]: GenericKeyValueType<string, AmazonAdsCustomerProfileEntity[]>;
}

export interface AmazonAdsGroupBy {
  groupByName: string;
  additionalMetrics: GenericKeyValueType<string, boolean>;
}

export interface AmazonAdsCustomerProfileEntity {
  id: number;
  name: string;
  data: GenericKeyValueType<string, any>;
}

export interface AmazonAdsProfilesResponse {
  success: boolean;
  count: number;
  data: AmazonAdsProfile[];
}

export interface AmazonAdsProfile {
  regionName: string;
  displayRegionName: string;
  profiles?: {
    profileId: number;
    profileName: string;
    timezone?: string;
  }[];
}

export interface AmazonAdsConfigAPIPayload {
  oauth_token_id: number;
  selected_profiles: AmazonAdsSelectedProfile[];
  report_category: string;
  standard_reports: string[] | null;
  custom_reports: AmazonAdsCustomReport[];
  core_objects: string[];
}

export type AmazonAdsAPIConfigResponse = AmazonAdsConfigAPIPayload;

export interface AmazonAdsSelectedProfile {
  profileId: string;
  profileName: string;
  adsRegion: string;
  timezone?: string;
}

export interface AmazonAdsCustomReport {
  reportName: string;
  groupBy: string[];
  baseMetrics: string[];
  additionalMetrics: string[];
}

export interface AmazonAdsFormMetaData extends GenericKeyValueType<string, any> {
  reportOptions: OptionsType[];
  coreObjectOptions: OptionsType[];
}
