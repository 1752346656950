import clsx from 'clsx';
import React from 'react';
import { GlobalBlockingLoaderService } from '../../../containers/core/services/global-blocking-loader.service';
import { useObservableState } from '../../../hooks/useObservableState';
import useService from '../../../hooks/useService';
import styles from './styles.module.scss';

export function GlobalBlockingLoader() {
  const loaderService = useService(GlobalBlockingLoaderService);

  const [loaderState] = useObservableState(loaderService.loaderState);

  if (!loaderState?.show) {
    return null;
  }

  return (
    <div className={styles.dataLoading} id='data-loading'>
      <div className={clsx(styles.loadingContent, 'clearfix')}>
        <img
          alt=''
          src='https://res.cloudinary.com/hevo/image/upload/v1661186315/dashboard/ajax-loader-big_v01ubx.gif'
        />

        <div className={styles.loadingText}>
          <div>
            <b>LOADING YOUR DATA</b>
          </div>
          <div>Please wait...</div>
        </div>
      </div>
    </div>
  );
}
