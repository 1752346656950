import React, { useContext } from 'react';
import { calendarViews } from './nativeCode/shared/const';
import { DateTimeAdapter } from './adapter/date-time-adapter.class';

export const DateTimeAdapterContext = React.createContext({
  dateTimeAdapter: null
});

// Adding generics in context is messy hence handling it manually.
export const useDateTimeAdapter = <T>() => {
  const adapterContext = useContext(DateTimeAdapterContext);

  const dateTimeAdapter = adapterContext.dateTimeAdapter as DateTimeAdapter<T>;

  const comparators = {
    year: (date: T) => dateTimeAdapter.getYear(date),
    month: (date: T) => dateTimeAdapter.getMonth(date),
    day: (date: T) => dateTimeAdapter.getDate(date),
    hour: (date: T) => dateTimeAdapter.getHours(date),
    minute: (date: T) => dateTimeAdapter.getMinutes(date),
    second: (date: T) => dateTimeAdapter.getSeconds(date)
  };

  const checkIfDatesAreMatchingTill = (date1: T, date2: T, field) => {
    const comparatorsArr = Object.entries(comparators);
    let output = true;

    for (let i = 0; i < comparatorsArr.length; i += 1) {
      const [dateField, func] = comparatorsArr[i];
      output = output && func(date1) === func(date2);

      if (field === dateField) {
        return output;
      }
    }

    return output;
  };

  const splitDate = (date: T) => ({
    day: dateTimeAdapter.getDate(date),
    month: dateTimeAdapter.getMonth(date),
    year: dateTimeAdapter.getYear(date),
    hour: dateTimeAdapter.getHours(date),
    minutes: dateTimeAdapter.getMinutes(date),
    seconds: dateTimeAdapter.getSeconds(date)
  });

  const getFieldValue = (date: T, field: string) => comparators[field](date);

  const getDayRange = (date: T) => {
    const { day, month, year } = splitDate(date);
    return [
      dateTimeAdapter.createDate(year, month, day, 0, 0, 0),
      dateTimeAdapter.createDate(year, month, day, 23, 59, 59)
    ];
  };

  const getBeginPrevious = (rangeType: calendarViews, date: T): T => {
    switch (rangeType) {
      case calendarViews.MULTI_YEAR: {
        const year = dateTimeAdapter.getYear(date);
        const _year = year - (year % (7 * 3)) - 1;
        return dateTimeAdapter.createDate(_year, 0, 1, 0, 0, 0);
      }
      case calendarViews.YEAR: {
        const year = dateTimeAdapter.getYear(date) - 1;
        return dateTimeAdapter.createDate(year, 0, 1, 0, 0, 0);
      }
      case calendarViews.MONTH: {
        const _d = dateTimeAdapter.addCalendarMonths(date, -1);
        const { year, month } = splitDate(_d);
        return dateTimeAdapter.createDate(year, month, 1, 0, 0, 0);
      }
      default:
        throw new Error(`Invalid rangeType: ${rangeType}`);
    }
  };

  const getBeginNext = (rangeType: calendarViews, date: T): T => {
    switch (rangeType) {
      case calendarViews.MULTI_YEAR: {
        const year = dateTimeAdapter.getYear(date);
        const _year = year - (year % (7 * 3)) + 21;
        return dateTimeAdapter.createDate(_year, 0, 1, 0, 0, 0);
      }
      case calendarViews.YEAR: {
        const year = dateTimeAdapter.getYear(date) + 1;
        return dateTimeAdapter.createDate(year, 0, 1, 0, 0, 0);
      }
      case calendarViews.MONTH: {
        const _d = dateTimeAdapter.addCalendarMonths(date, 1);
        const { year, month } = splitDate(_d);
        return dateTimeAdapter.createDate(year, month, 1, 0, 0, 0);
      }
      default:
        throw new Error(`Invalid rangeType: ${rangeType}`);
    }
  };

  const getEndPrevious = (rangeType: calendarViews, date: T): T => {
    switch (rangeType) {
      case calendarViews.MULTI_YEAR: {
        const year = dateTimeAdapter.getYear(date);
        const _year = year - (year % (7 * 3)) - 1;
        return dateTimeAdapter.createDate(_year, 11, 31, 23, 59, 59);
      }
      case calendarViews.YEAR: {
        const year = dateTimeAdapter.getYear(date) - 1;
        return dateTimeAdapter.createDate(year, 11, 31, 23, 59, 59);
      }
      case calendarViews.MONTH: {
        const _d = dateTimeAdapter.addCalendarDays(date, dateTimeAdapter.getDate(date) * -1 - 1);
        const { year, month, day } = splitDate(_d);
        return dateTimeAdapter.createDate(year, month, day, 23, 59, 59);
      }
      default:
        throw new Error(`Invalid rangeType: ${rangeType}`);
    }
  };

  /**
   * Returns an array with the beginning and the end of a given range.
   *
   * @param {string} rangeType Range type (e.g. 'day')
   * @param {Date} date Date.
   */
  const getRange = (rangeType: calendarViews, date: T) => {
    const { month, year } = splitDate(date);
    switch (rangeType) {
      case calendarViews.MULTI_YEAR: {
        const _yearDecade = year - (year % (7 * 3)) - 1;
        const _yearDecadeEnd = year - (year % (7 * 3)) - 1;
        return [
          dateTimeAdapter.createDate(_yearDecade, 0, 1, 0, 0, 0),
          dateTimeAdapter.createDate(_yearDecadeEnd, 11, 31, 23, 59, 59)
        ];
      }
      case calendarViews.YEAR:
        return [
          dateTimeAdapter.createDate(year, 0, 1, 0, 0, 0),
          dateTimeAdapter.createDate(year, 11, 31, 23, 59, 59)
        ];
      case calendarViews.MONTH:
        return [
          dateTimeAdapter.createDate(year, month, 1, 0, 0, 0),
          dateTimeAdapter.createDate(year, month, 26, 23, 59, 59)
        ];
      case calendarViews.DAY:
        return getDayRange(date);
      default:
        throw new Error(`Invalid rangeType: ${rangeType}`);
    }
  };

  const isValueWithinRange = (value: T, range: T[]) =>
    dateTimeAdapter.compare(range[0], value) === -1 &&
    dateTimeAdapter.compare(range[1], value) === 1;

  const isRangeWithinRange = (greaterRange: T[], smallerRange: T[]) =>
    dateTimeAdapter.compare(greaterRange[0], smallerRange[0]) !== 1 &&
    dateTimeAdapter.compare(greaterRange[1], smallerRange[1]) !== -1;

  const doRangesOverlap = (range1: T[], range2: T[]) =>
    isValueWithinRange(range1[0], range2) || isValueWithinRange(range1[1], range2);

  const getRangeClassNames = (valueRange: T[], dateRange: T[], baseClassName: string) => {
    const isRange = doRangesOverlap(dateRange, valueRange);

    const classes = [];

    if (isRange) {
      classes.push(baseClassName);

      const isRangeStart = isValueWithinRange(valueRange[0], dateRange);
      const isRangeEnd = isValueWithinRange(valueRange[1], dateRange);

      if (isRangeStart) {
        classes.push(`${baseClassName}Start`);
      }

      if (isRangeEnd) {
        classes.push(`${baseClassName}End`);
      }

      if (isRangeStart && isRangeEnd) {
        classes.push(`${baseClassName}BothEnds`);
      }
    }

    return classes;
  };

  const getTileClasses = (args: { value?: T; date?: T; hover?: Date; dateType: calendarViews }) => {
    const className = 'react-calendar__tile';
    const classes = [className];

    if (!args) {
      return classes;
    }

    const { value, date, dateType, hover } = args;

    if (!date) {
      return classes;
    }

    if (!Array.isArray(date) && !dateType) {
      throw new Error(
        'getTileClasses(): Unable to get tile activity classes because one or more required arguments were not passed.'
      );
    }

    const now = dateTimeAdapter.now();

    const dateRange = getRange(dateType, date);

    if (isValueWithinRange(now, dateRange)) {
      classes.push(`${className}--now`);
    }

    if (!value) {
      return classes;
    }

    const valueRange = Array.isArray(value) ? value : getDayRange(value);

    if (isRangeWithinRange(valueRange, dateRange)) {
      classes.push(`${className}--active`);
    } else if (doRangesOverlap(valueRange, dateRange)) {
      classes.push(`${className}--hasActive`);
    }

    const valueRangeClassNames = getRangeClassNames(valueRange, dateRange, `${className}--range`);

    classes.push(...valueRangeClassNames);

    const valueArray = ([] as T[]).concat(value);

    if (hover && valueArray.length === 1) {
      const hoverRange = hover > valueRange[0] ? [valueRange[0], hover] : [hover, valueRange[0]];
      const hoverRangeClassNames = getRangeClassNames(hoverRange, dateRange, `${className}--hover`);

      classes.push(...hoverRangeClassNames);
    }

    return classes;
  };

  return {
    dateTimeAdapter,
    getFieldValue,
    getDayRange,
    getRange,
    isValueWithinRange,
    isRangeWithinRange,
    doRangesOverlap,
    getRangeClassNames,
    getTileClasses,
    checkIfDatesAreMatchingTill,
    splitDate,
    getBeginPrevious,
    getBeginNext,
    getEndPrevious
  };
};
