import { SourceTypeConfigHelper } from '../../../../../components/Node/source-settings-form/config-helper';
import { DEFAULT_WEBHOOK_EVENT_NAME_PATH, DEFAULT_WEBHOOK_PROPERTIES_PATH, WebhookAuthEnum } from './constants';

export class WebhookConfigHelper implements SourceTypeConfigHelper {
  constructor(
    public sourceType: string
  ) {
  }

  getConfigFromRawData(rawData: any) {
    const params = {};
    if (this.sourceType !== 'WEBHOOK') {
      return params;
    }

    if (rawData.authentication_method === WebhookAuthEnum.BASIC_AUTH) {
      params['accessKey'] = rawData.auth_access_key;
      params['secretKey'] = rawData.auth_secret_key;
    }

    return {
      ...params,
      eventNamePath: rawData.event_name_path,
      propertiesPath: rawData.event_properties_path,
      auth: rawData.authentication_method || WebhookAuthEnum.NO_AUTH
    };
  }

  getDefaultConfig() {
    if (this.sourceType !== 'WEBHOOK') {
      return {};
    }

    return {
      eventNamePath: DEFAULT_WEBHOOK_EVENT_NAME_PATH,
      propertiesPath: DEFAULT_WEBHOOK_PROPERTIES_PATH,
      auth: WebhookAuthEnum.NO_AUTH
    };
  }

  buildConnectionParams(data: any) {
    const params = {};
    if (this.sourceType !== 'WEBHOOK') {
      return params;
    }

    if (data.auth === WebhookAuthEnum.BASIC_AUTH) {
      params['auth_access_key'] = data.accessKey;
      params['auth_secret_key'] = data.secretKey;
    }

    return {
      ...params,
      event_name_path: data.eventNamePath,
      event_properties_path: data.propertiesPath,
      authentication_method: data.auth || WebhookAuthEnum.NO_AUTH
    };
  }
}
