import { fromEvent, merge, timer } from 'rxjs';
import { startWith, switchMap, take } from 'rxjs/operators';

export class InactivityService {
  private _activityEvents: Array<any>[] = [
    [ document, 'click' ],
    [ document, 'wheel' ],
    [ document, 'scroll' ],
    [ document, 'mousemove' ],
    [ document, 'keyup' ],
    [ window, 'resize' ],
    [ window, 'scroll' ],
    [ window, 'mousemove' ]
  ];

  onInactivity$(timeoutMS: number) {
    const activity$ = merge(...this._activityEvents.map((eventDef) => fromEvent(eventDef[0], eventDef[1])));

    return activity$.pipe(
      startWith(null),
      switchMap(() => timer(timeoutMS)),
      take(1)
    );
  }
}
