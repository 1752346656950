import { createBrowserHistory } from 'history';
import {
  Angulartics2Hubspot,
  Angulartics2Intercom,
  Angulartics2Mixpanel,
  Angulartics2Segment
} from '@angularticsCompat';
import React, { useEffect, useMemo } from 'react';
import { Provider } from 'react-redux';
import { createPath, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { Subject } from 'rxjs';
import { CompatRouter } from '../CompatRouter';
import { GlobalBlockingLoader } from '../components/Loader/GlobalBlockingLoader/GlobalBlocingLoader';
import { ReactPlayground } from '../components/ReactPlayground';
import { ToasterService } from '../components/Toaster/toaster.service';
import { ToasterContainer } from '../components/Toaster/ToasterContainer';
import useAnalyticsTracker from '../hooks/useAnalyticsTracker';
import useService from '../hooks/useService';
import { RootWrapper } from '../RootWrapper';
import { RouterServiceProvider } from '../RouterServiceProvider';
import ThemeWrapper from '../ThemeWrapper';
import { VersionPage } from './about/VersionPage';
import { AcceptInvite } from './auth/AcceptInvite';
import { AccountSetup } from './auth/AccountSetup';
import { EmpLoginAccountSelect } from './auth/EmpLoginAccountSelect';
import { ForgotPassword } from './auth/ForgotPassword';
import { GoogleLogin } from './auth/GoogleLogin';
import { Login } from './auth/Login';
import { LoginWithCode } from './auth/LoginWithCode';
import { RecoveryCodesPage } from './auth/RecoveryCodesPage';
import { ResetPassword } from './auth/ResetPassword';
import { RestAPIOAuth } from './auth/RestAPIOAuth';
import { SamlLogin } from './auth/SamlLogin';
import { SetupPersona } from './auth/SetupPersona';
import { SubscribeMarketplace } from './auth/SubscribeMarketplace';
import { TfaOtpPage } from './auth/TfaOtpPage';
import { TfaSetupPage } from './auth/TfaSetupPage';
import { VerifyEmail } from './auth/VerifyEmail';
import {
  OPEN_SUPPORT_ON_LOAD_QUERY_PARAM_MESSAGE,
  OPEN_SUPPORT_ON_LOAD_QUERY_PARAM_TRIGGER,
  TRACKER_EMAIL_CTA_APP_LOADED,
  UTM_CONTENT,
  UTM_MEDIUM
} from './core/constants';
import { CustomBrowserHistory } from './core/models/routing';
import { BodyInteractionService } from './core/services/body-interaction.service';
import { ClearCookieService } from './core/services/clear-cookie.service';
import { CourierService } from './core/services/courier.service';
import { DocLinkHandlerService } from './core/services/doc-link-handler.service';
import { AllowedActionsService } from './core/services/entity-actions.service';
import { IntercomService } from './core/services/intercom.service';
import { ReduxService, store } from './core/services/redux.service';
import { UnauthorisedEventService } from './core/services/unauthorised-event.service';
import { VWOService } from './core/services/vwo.service';
import { Dashboard } from './dashboard/Dashboard';
import { setHermesUIServices } from 'hermes-ui-lib';
import { UnsubscribeNotificationsPage } from './notification-subscription/UnsubscribeNotificationsPage';

declare var __DASHBOARD_VERSION__: string;
declare var __CORALOGIX_RUM_CONFIG__: Record<string, string>;
declare global {
  interface Window {
    CoralogixRum: any;
  }
}

const history = createBrowserHistory() as CustomBrowserHistory;
history['beforeListenersFired$']  = new Subject();

export function App() {
  const initCoralogix = () => {
    if (typeof __CORALOGIX_RUM_CONFIG__ !== 'undefined' && window.CoralogixRum) {
      window.CoralogixRum.init({
        ...__CORALOGIX_RUM_CONFIG__,
        version: __DASHBOARD_VERSION__.replace(/ /g, '_'),
        ignoreErrors: [/.*Promise Cancelled*./],
        sessionRecordingConfig: {
          enable: true,
          autoStartSessionRecording: true,
          recordConsoleEvents: true,
          sessionRecordingSampleRate: 100,
          maskInputOptions: { password: true }
        }
      });
    }
  };

  useEffect(() => {
    initCoralogix();
  });

  return (
    <CompatRouter history={history}>
      <RouterServiceProvider>
        <AppInner />
      </RouterServiceProvider>
    </CompatRouter>
  );
}

export function AppInner() {
  const navigate = useNavigate();
  const location = useLocation();
  const { eventTrack } = useAnalyticsTracker();

  const supportService = useService(IntercomService);
  const toasterService = useService(ToasterService);
  const unauthorisedEventService = useService(UnauthorisedEventService);
  const vwoService = useService(VWOService);
  const angularticsIntercomService = useService(Angulartics2Intercom);
  const angularticsHubspotService = useService(Angulartics2Hubspot);
  const angularticsSegmentService = useService(Angulartics2Segment);
  const angularticsMixpanelService = useService(Angulartics2Mixpanel);
  const allowedActionsService = useService(AllowedActionsService);

  /**
   * To initialise global reference of these services;
   */
  useService(ReduxService);
  useService(CourierService);
  useService(DocLinkHandlerService);

  /**
   * Initialise logic in constructor
   */
  useService(ClearCookieService);
  useService(BodyInteractionService);

  useMemo(() => {
    angularticsIntercomService.startTracking();
    angularticsHubspotService.startTracking();
    angularticsSegmentService.startTracking();
    angularticsMixpanelService.startTracking();

    vwoService.triggerWebsiteSignupConversion();

    setHermesUIServices({
      TOASTER_SERVICE: toasterService,
      UNAUTHORISED_EVENT_SERVICE: unauthorisedEventService,
      ALLOWED_ACTIONS: allowedActionsService,
      HISTORY_SERVICE: history
    });
  }, []);

  useEffect(() => {
    // supportService.refresh();
    document.dispatchEvent(new Event('initialLoad'));

    const searchParams = new URLSearchParams(location.search);

    const openSupportOnLoad = !!searchParams.get(OPEN_SUPPORT_ON_LOAD_QUERY_PARAM_TRIGGER);
    const openSupportOnLoadMessage = searchParams.get(OPEN_SUPPORT_ON_LOAD_QUERY_PARAM_MESSAGE);
    const utmMedium = searchParams.get(UTM_MEDIUM);
    const utmContent = searchParams.get(UTM_CONTENT);

    if (utmMedium === 'EMAIL' && utmContent) {
      const properties = JSON.parse(utmContent);

      eventTrack({
        action: TRACKER_EMAIL_CTA_APP_LOADED,
        properties: properties
      });
    }

    let replaceHistory = false;

    if (openSupportOnLoad) {
      replaceHistory = true;

      searchParams.delete(OPEN_SUPPORT_ON_LOAD_QUERY_PARAM_TRIGGER);
      searchParams.delete(OPEN_SUPPORT_ON_LOAD_QUERY_PARAM_MESSAGE);
    }

    if (utmMedium || utmContent) {
      replaceHistory = true;

      searchParams.delete(UTM_MEDIUM);
      searchParams.delete(UTM_CONTENT);
    }

    if (replaceHistory) {
      /**
       * Explicitly replacing location history entry to eagerly change the browser url.
       * This is done to avoid auth guard from using the initial navigation url for setting up
       * the next query param.
       */
      const url = createPath({
        pathname: location.pathname,
        hash: location.hash,
        search: searchParams.toString()
      });

      window.history.replaceState({}, '', url);
      navigate(url);
    }

    if (openSupportOnLoad) {
      supportService.show(openSupportOnLoadMessage);
    }
  }, []);

  return (
    <Provider store={store}>
      <ThemeWrapper>
        <RootWrapper>
          <GlobalBlockingLoader />

          <ToasterContainer />

          <Routes>
            <Route key='react-playground' path='/react-playground' element={<ReactPlayground />} />
            <Route
              key='unsubscribe-notification'
              path='/notification-preference/unsubscribe/:unsubscriptionType/:identifier'
              element={<UnsubscribeNotificationsPage />}
            />
            <Route key='about-version' path='/about/version' element={<VersionPage />} />
            <Route key='login-with-code' path='/login/code/:code' element={<LoginWithCode />} />

            <Route key='login' path='/login' element={<Login />} />

            <Route key='forgot-password' path='/forgot-password' element={<ForgotPassword />} />

            <Route
              key='emp-login-ac-select'
              path='/emp-login-ac-select'
              element={<EmpLoginAccountSelect />}
            />

            <Route key='reset' path='/reset' element={<ResetPassword />} />

            <Route key='accept-invite' path='/accept-invite' element={<AcceptInvite />} />

            <Route key='setup-account' path='/setup-account' element={<AccountSetup />} />

            <Route key='verify-email' path='/verify-email' element={<VerifyEmail />} />

            <Route key='saml-login' path='/saml-login' element={<SamlLogin />} />

            <Route key='google-login' path='/google-login' element={<GoogleLogin />} />

            <Route key='tfa-otp' path='/tfa/otp' element={<TfaOtpPage />} />

            <Route key='tfa-qr' path='/tfa/qr' element={<TfaSetupPage />} />

            <Route
              key='tfa-recovery-codes'
              path='/tfa/recovery-codes'
              element={<RecoveryCodesPage />}
            />

            <Route key='rest-api-oauth' path='/rest-api/oauth' element={<RestAPIOAuth />} />

            <Route key='setup-persona' path='/setup-persona' element={<SetupPersona />} />

            <Route
              key='subscribe-marketplace'
              path='/subscribe-marketplace'
              element={<SubscribeMarketplace />}
            />
            <Route key='dashboard' path='*' element={<Dashboard />} />
          </Routes>
        </RootWrapper>
      </ThemeWrapper>
    </Provider>
  );
}
