import criteoTimezones from '../../../../../../assets/json/criteo/timezones.json';
import criteoCurrencies from '../../../../../../assets/json/criteo/currencies.json';

import { SourceTypeConfigHelper } from '../../../../../components/Node/source-settings-form/config-helper';

export class CriteoConfigHelper implements SourceTypeConfigHelper {
  private _defaultTimezone = 'Etc/UTC';

  private _defaultCurrency = 'USD';

  getConfigFromRawData(rawData: any, metaData: any) {
    const params: any = {
      timezone: metaData.timezones.find(timeZone => timeZone.Value === rawData.report_timezone),
      currency: metaData.currencies.find(currency => currency.Value === rawData.report_currency)
    };

    if (!rawData.oauth_token_id) {
      params.clientId = rawData.client_id;
      params.clientSecret = rawData.client_secret;
    }

    return params;
  }

  getDefaultConfig(metaData: any) {
    return {
      timezone: metaData.timezones.find(timeZone => timeZone.Value === this._defaultTimezone),
      currency: metaData.currencies.find(currency => currency.Value === this._defaultCurrency)
    };
  }

  buildConnectionParams(data: any) {
    const params: any = {
      report_timezone: data.timezone?.Value,
      report_currency: data.currency?.Value
    };

    if (!data.oauthTokenId) {
      params.client_id = data.clientId;
      params.client_secret = data.clientSecret;
    }

    return params;
  }

  async staticDependenciesResolver() {
    return {
      timezones: criteoTimezones.map(timezone => ({
        ...timezone,
        name: timezone.Name,
        id: timezone.Value
      })),
      currencies: criteoCurrencies.map(currency => ({
        ...currency,
        name: currency.Currency,
        id: currency.Value
      }))
    };
  }
}
