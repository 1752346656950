/* eslint-disable no-empty-function */
/* eslint-disable class-methods-use-this */
/* eslint-disable import/no-cycle */
import { SourceTypeConfigHelper } from '../../../../../components/Node/source-settings-form/config-helper';
import { JobMode } from '../../../../../components/Node/source-type/job-mode';
import { GENERIC_SOURCE_META_DATA_RESOLVER } from './metaDataResolver';
import { GENERIC_SOURCE_TEMPLATES } from './templates';

export class GenericSourceConfigHelper implements SourceTypeConfigHelper {
  constructor(public sourceTypeIdentifier: string, jobMode: JobMode) {}

  getConfigFromRawData(rawData: any) {
    const config: any = {};
    const template = GENERIC_SOURCE_TEMPLATES[this.sourceTypeIdentifier];
    template.forEach(row => {
      row.forEach(cell => {
        if (cell.getConfigValue) {
          config[cell.key] = cell.getConfigValue(rawData[cell.buildParamsKey]);
        } else {
          config[cell.key] = rawData[cell.buildParamsKey];
        }
      });
    });

    return config;
  }

  getDefaultConfig() {}

  buildConnectionParams(data: any) {
    const params: any = {};
    const template = GENERIC_SOURCE_TEMPLATES[this.sourceTypeIdentifier];
    template.forEach(row => {
      row.forEach(cell => {
        if (cell.getRawValue) {
          params[cell.buildParamsKey] = cell.getRawValue(data[cell.key], data);
        } else {
          params[cell.buildParamsKey] = data[cell.key];
        }
      });
    });

    return params;
  }

  metaDataResolver(sourceType: string, authData: any, rawData: any) {
    const resolveMetaDataRequests = GENERIC_SOURCE_META_DATA_RESOLVER[sourceType];
    return typeof resolveMetaDataRequests === 'function'
      ? resolveMetaDataRequests(authData, rawData)
      : null;
  }
}
