// eslint-disable-next-line camelcase
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { AnsibleBoolean } from '../../core/models/ansible-config';
import { HdIcon, HdSwitch, HdTooltip } from '../../../components/UIElements';
import useDontMountAtFirst from '../../../hooks/useDontMountAtFirst';
import useListConductor from '../../../hooks/useListConductor';
import { getDataIdGenerator } from '../../../utils/generateDataId';
import TeamApi from '../TeamAPI';
import useForceUpdate from '../../../hooks/useForceUpdate';
import PaginationControl from '../../../components/PaginationControl';
import {
  FilterList,
  GetList,
  ListError,
  ListNextPage,
  ListPrevPage,
  ListResponse
} from '../../../components/pagination/list-conductor/list-actions';
import { AccountAccessHistory, AccountAccessStatus } from './models';
import { AccountAccessListShimmer, AccountAccessShimmer } from './shimmer';
import { ListConductor } from '../../../components/pagination/list-conductor/list-conductor';
import { OffsetPaginationStrategy } from '../../../components/pagination/list-conductor/offset-pagination-strategy';
import { EntityUIState } from '../../core/models/entitiy-ui-state';
import { HdTimeFormat } from '../../../components/UIElements/HdCalendar';
import SearchArea from '../../../components/SearchArea';
import styles from './styles.module.scss';
import RetryApiAlert from '../../../components/RetryApiAlert';
import useUserService from '../../../hooks/services/useUserService';
import useHasPermission from '../../../hooks/useHasPermission';
import { RbacPermissions } from '../../core/models/user';
import { RBAC_DISABLED_MESSAGE } from '../../core/models/rbac';
import { AccountAccessHistoryLogsFactory } from './utils';

declare let __PROD__: string;

export const dataIdGenerator = getDataIdGenerator('teamAccountAccess');

export function AccountAccess() {
  const paginationHelper = useRef(new OffsetPaginationStrategy(5));

  const { user } = useUserService();

  const { hasPermission } = useHasPermission();

  const [renderedLogs, setRenderedLogs] = useState<AccountAccessHistory[]>([]);
  const { changes, listConductor } = useListConductor({
    paginationStrategy: paginationHelper.current
  });
  const listConductorState = listConductor.getListState().state;
  const listConductorStateError = listConductor.getListState().error;
  const listConductorMeta = listConductor.getListMeta();
  const [consentCallError, setConsentCallError] = useState();

  const listConductorLoading =
    listConductorState === EntityUIState.LOADING ||
    listConductorState === EntityUIState.LOADING_MORE;

  const [consentStatus, setConsentStatus] = useState(true);
  const [uiState, setUiState] = useState<EntityUIState>(EntityUIState.LOADING);
  const forceUpdate = useForceUpdate();

  useDontMountAtFirst(() => {
    getListData(listConductor);
  }, [changes]);

  useEffect(() => {
    getInitData();
  }, []);

  const handleRefreshData = () => {
    listConductor.dispatch(new FilterList({ key: 'user_name', value: '' }));
    getInitData();
  };

  const getInitData = () => {
    setUiState(EntityUIState.LOADING);
    // fetch data initially
    listConductor.dispatch(new GetList());

    TeamApi.getCurrentAccountAccessConsentStatus()
      .then(res => {
        setUiState(EntityUIState.IDLE);
        processConsentStatus(res.data || {});
      })
      .catch(err => {
        setUiState(EntityUIState.ERRORED);
        setConsentCallError(err);
      });
  };

  const getStatusPrefixString = (entry: AccountAccessHistory) => {
    if (entry.consentStatus === AccountAccessStatus.GRANTED) {
      return 'Active until ';
    }

    if (entry.consentStatus === AccountAccessStatus.REVOKED) {
      return 'Revoked on ';
    }

    return 'Expired on ';
  };

  const processConsentStatus = ({ consent_status }) => {
    setConsentStatus(consent_status === AccountAccessStatus.GRANTED.toString());
  };

  const getListData = async (listConductorArg: ListConductor) => {
    try {
      const data = await TeamApi.getAccountAccessHistoryLogs({
        ...listConductorArg.getListMetaNetworkParams()
      });
      processHistoryLogsResponse(data);
    } catch (error) {
      listConductor.dispatch(new ListError(error));
      forceUpdate();
    }
  };

  const processHistoryLogsResponse = res => {
    let rawItems = [];

    if (res.data) {
      rawItems = res.data.consent_statuses;
    }

    const inclusionItems = AccountAccessHistoryLogsFactory(rawItems);

    listConductor.dispatch(
      new ListResponse({
        count: res.data?.total_count
      })
    );

    setRenderedLogs(inclusionItems);
  };

  const handleAccountAccessToggle = () => {
    TeamApi.toggleAccountAccessConsent().then(res => {
      processConsentStatus(res.data);
      listConductor.dispatch(new GetList());
    });
  };

  const onSearch = useCallback(search => {
    listConductor.dispatch(new FilterList({ key: 'user_name', value: search }));
  }, []);

  const onPrev = () => {
    listConductor.dispatch(new ListPrevPage());
  };

  const onNext = () => {
    listConductor.dispatch(new ListNextPage());
  };

  const isAccountAccessToggleDisabled =
    !hasPermission(RbacPermissions.TEAM_EDIT) ||
    (user.isHidden && __PROD__ === AnsibleBoolean.TRUE) ||
    (listConductorState !== EntityUIState.IDLE && listConductorState !== EntityUIState.NEW) ||
    uiState !== EntityUIState.IDLE;
  const showAccountAccessBox =
    uiState !== EntityUIState.ERRORED && listConductorState !== EntityUIState.ERRORED;
  const showLoadingShimmer = uiState === EntityUIState.LOADING;
  const showAccountAccessBoxBody = uiState === EntityUIState.IDLE;
  const showAccountAccessTable =
    listConductorState !== EntityUIState.NEW && uiState !== EntityUIState.LOADING;
  const showRetryConnection =
    uiState === EntityUIState.ERRORED || listConductorState === EntityUIState.ERRORED;
  const showEmpty = listConductorState === EntityUIState.EMPTY;

  return (
    <>
      {showAccountAccessBox ? (
        <div className='box' data-id={dataIdGenerator('box')}>
          <div className='box__header'>
            <span className='box__title'>Account Access</span>
          </div>

          {showLoadingShimmer ? <AccountAccessShimmer dataId={dataIdGenerator('shimmer')} /> : null}

          {showAccountAccessBoxBody ? (
            <div className='box__body' data-id={dataIdGenerator('box-body')}>
              <div className='d-flex justify-between'>
                <div>
                  <div className='mb-2 text-bold'>
                    Give Hevo support team access to your account
                  </div>

                  <div className='text-secondary'>
                    The Hevo support team may need temporary access to your account and data to
                    diagnose and resolve the issue you have raised.
                  </div>

                  <div className='text-secondary mt-1'>
                    The access automatically expires after 30 days.
                  </div>
                </div>

                <div>
                  <HdTooltip
                    title={RBAC_DISABLED_MESSAGE}
                    disabled={hasPermission(RbacPermissions.TEAM_EDIT)}
                  >
                    <span>
                      <HdSwitch
                        disabled={isAccountAccessToggleDisabled}
                        checked={consentStatus}
                        onChange={handleAccountAccessToggle}
                        dataId={dataIdGenerator('')}
                      />
                    </span>
                  </HdTooltip>
                </div>
              </div>
            </div>
          ) : null}

          {showAccountAccessTable ? (
            <table
              className={`${styles.boxTable} box-table`}
              data-id={dataIdGenerator('box-table')}
            >
              <thead className='border-top'>
                <tr>
                  <th>Approved on</th>

                  <th>Approved by</th>

                  <th>Status</th>

                  <th>
                    <div className='center-flex-row justify-end flex-1'>
                      <SearchArea
                        collapsible
                        autofocus
                        defaultExpanded={false}
                        onSearch={onSearch}
                        debounceInterval={500}
                        placeholder='Approver'
                        dataId={dataIdGenerator('')}
                      />

                      <PaginationControl
                        className='ml-4'
                        paginationData={listConductorMeta.pagination}
                        listState={listConductorState}
                        nextPage={onNext}
                        prevPage={onPrev}
                        dataId={dataIdGenerator('')}
                      />
                    </div>
                  </th>
                </tr>
              </thead>

              {listConductorState === EntityUIState.IDLE && (
                <tbody data-id={dataIdGenerator('table-rows')}>
                  {renderedLogs.map((entry, index) => (
                    <tr key={index} data-id={dataIdGenerator(`access-row-${index + 1}`)}>
                      <td>
                        <HdTimeFormat
                          value={entry.createdAt}
                          options={{ formats: 'DD/MM/YYYY hh:mm A' }}
                        />
                      </td>

                      <td>{entry.approvedByUser}</td>

                      <td
                        className={
                          entry.consentStatus === AccountAccessStatus.GRANTED.toString()
                            ? 'text-success'
                            : null
                        }
                      >
                        {getStatusPrefixString(entry)}

                        <HdTimeFormat
                          value={
                            entry.consentStatus === AccountAccessStatus.REVOKED
                              ? entry.updatedAt
                              : entry.expiresAt
                          }
                          options={{ formats: 'DD/MM/YYYY hh:mm A' }}
                        />

                        {entry.consentStatus === AccountAccessStatus.REVOKED
                          ? `, by ${entry.revokedByUser}`
                          : ''}
                      </td>

                      <td />
                    </tr>
                  ))}
                </tbody>
              )}

              {showEmpty ? (
                <tbody data-id={dataIdGenerator('empty-box')}>
                  <div className='no-item-box'>
                    <span className={styles.noItemBoxIconContainer}>
                      <HdIcon
                        name='search'
                        className={`no-item-box-icon ${styles.noItemBoxIcon}`}
                      />
                    </span>

                    <span className='no-item-box-title text-bold'>No records found 😞</span>

                    <span className='no-item-box-desc mb-1 px-1'>
                      There are no access requests matching the search criteria.
                    </span>
                  </div>
                </tbody>
              ) : null}

              {listConductorLoading && (
                <AccountAccessListShimmer dataId={dataIdGenerator('list-shimmer')} />
              )}
            </table>
          ) : null}
        </div>
      ) : null}

      {showRetryConnection ? (
        <RetryApiAlert
          actionHandler={handleRefreshData}
          error={consentCallError || listConductorStateError}
          dataId={dataIdGenerator('')}
        />
      ) : null}
    </>
  );
}

export default AccountAccess;
