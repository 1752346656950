import { SourceTypeConfigHelper } from '../../../../../components/Node/source-settings-form/config-helper';
import { OptionsType } from '../../../../../components/UIElements/HdDropDown';
import PipelinesAPI from '../../../PipelinesAPI';
import {
  AmazonAdsAPIConfigResponse,
  AmazonAdsConfigAPIPayload,
  AmazonAdsCustomReport,
  AmazonAdsFormFieldNames,
  AmazonAdsFormMetaData,
  AmazonAdsFormState,
  AmazonAdsReportCategory,
  AmazonAdsSelectedProfile
} from './models';
import { getSelectedProfilesFromListData, getSelectorListStateFromRawData } from './util';

function MetricFactory(metric: string) {
  return {
    id: metric,
    name: metric,
    disabled: true
  };
}

function ReportsFactory(report: string, options: OptionsType[]) {
  return {
    id: report,
    name: options.find((option) => option.id === report)?.name
  };
}

export class AmazonAdsConfigHelper implements SourceTypeConfigHelper {
  getConfigFromRawData(
    rawData: AmazonAdsAPIConfigResponse,
    metaData: AmazonAdsFormMetaData
  ): Partial<AmazonAdsFormState> {
    const customReport = (rawData?.custom_reports?.length ? rawData.custom_reports[0] : {}) as AmazonAdsCustomReport;
    const customReportName = metaData.reportOptions.find((option) => option.id === customReport?.reportName);
    const groupBy = customReport?.groupBy;

    const standardReports = rawData.standard_reports.map((report) => ReportsFactory(report, metaData.reportOptions));
    const coreObjects = rawData.core_objects.map((object) => ReportsFactory(object, metaData.coreObjectOptions));

    return {
      [AmazonAdsFormFieldNames.PROFILES]: {
        ...getSelectorListStateFromRawData(rawData.selected_profiles)
      },
      [AmazonAdsFormFieldNames.REPORT_CATEGORY]: rawData.report_category as AmazonAdsReportCategory,
      [AmazonAdsFormFieldNames.STANDARD_REPORTS]: standardReports,
      [AmazonAdsFormFieldNames.CORE_OBJECTS]: coreObjects,
      [AmazonAdsFormFieldNames.CUSTOM_REPORT_NAME]: customReportName || null,
      [AmazonAdsFormFieldNames.GROUP_BY]: Array.isArray(groupBy) && groupBy.length ? groupBy[0] : '',
      [AmazonAdsFormFieldNames.BASE_METRICS]: (customReport?.baseMetrics || []).map(MetricFactory),
      [AmazonAdsFormFieldNames.ADDITIONAL_METRICS]: (customReport?.additionalMetrics || []).map(MetricFactory)
    };
  }

  getDefaultConfig(metaData: AmazonAdsFormMetaData) {
    return {
      [AmazonAdsFormFieldNames.REPORT_CATEGORY]: AmazonAdsReportCategory.STANDARD_REPORTS,
      [AmazonAdsFormFieldNames.STANDARD_REPORTS]: metaData.reportOptions || [],
      [AmazonAdsFormFieldNames.CORE_OBJECTS]: metaData.coreObjectOptions || []
    };
  }

  buildConnectionParams(rawData: AmazonAdsFormState): AmazonAdsConfigAPIPayload {
    const selectedProfiles = getSelectedProfilesFromListData(
      rawData[AmazonAdsFormFieldNames.PROFILES],
      rawData[AmazonAdsFormFieldNames.MANAGER_CUSTOMERS_MAP]
    ) as AmazonAdsSelectedProfile[];

    const reportCategory = rawData[AmazonAdsFormFieldNames.REPORT_CATEGORY];

    let standardReports = [];
    let customReports: AmazonAdsCustomReport[] = [];
    let coreObjects = [];

    if (reportCategory === AmazonAdsReportCategory.STANDARD_REPORTS) {
      standardReports = rawData[AmazonAdsFormFieldNames.STANDARD_REPORTS].map(report => report.id);
      coreObjects = rawData[AmazonAdsFormFieldNames.CORE_OBJECTS].map(object => object.id);
    } else if (reportCategory === AmazonAdsReportCategory.CUSTOM_REPORTS) {
      customReports = [{
        reportName: rawData[AmazonAdsFormFieldNames.CUSTOM_REPORT_NAME].id,
        groupBy: [rawData[AmazonAdsFormFieldNames.GROUP_BY]],
        baseMetrics: (rawData[AmazonAdsFormFieldNames.BASE_METRICS] || []).map(option => option.name),
        additionalMetrics: (rawData[AmazonAdsFormFieldNames.ADDITIONAL_METRICS] || []).map(option => option.name)
      }];
    }

    return {
      oauth_token_id: rawData.oauthTokenId,
      selected_profiles: selectedProfiles,
      report_category: reportCategory,
      standard_reports: standardReports,
      custom_reports: customReports,
      core_objects: coreObjects
    };
  }

  async staticDependenciesResolver(sourceType: string) {
    const coreObjectsPromise = await PipelinesAPI.getAmazonAdsCoreObjects();
    const reportsPromise = await PipelinesAPI.getAmazonAdsReports();

    return Promise.all([coreObjectsPromise, reportsPromise])
      .then(([coreObjectsResponse, reportsResponse]) => {
        const coreObjects = coreObjectsResponse.data;
        const reports = reportsResponse.data;

        return {
          reportOptions: reports.map((report) => ({
            name: report.displayName,
            id: report.reportName
          })),
          coreObjectOptions: coreObjects.map((report) => ({
            name: report.displayName,
            id: report.name
          }))
        };
      })
      .catch((e) => {
        return {
          reportOptions: [],
          coreObjectOptions: []
        };
      });
  }
}
