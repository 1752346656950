import React, { lazy, Suspense } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { GenericPageShimmer } from '../../components/Shimmer/GenericPageShimmer';
import { LazyActivation } from '../activate/LazyActivation';
import styles from './styles.module.scss';

const routers = {
  Account: lazy(() => import('../account')),

  Target: lazy(() => import('../activate-target').then(module => ({ default: module.Target }))),

  Destination: lazy(() =>
    import('../destination').then(module => ({ default: module.Destination }))
  ),

  EnterpriseDestination: lazy(() =>
    import('../enterprise/Destination').then(module => ({
      default: module.Destination
    }))
  ),

  EnterprisePipeline: lazy(() =>
    import('../enterprise/Pipeline').then(module => ({
      default: module.Pipeline
    }))
  ),

  JobMonitoringDashboard: lazy(() =>
    import('../job-monitoring').then(module => ({
      default: module.JobMonitoringDashboard
    }))
  ),

  NotificationSettings: lazy(() =>
    import('../notification-settings/NotificationSettings').then(module => ({
      default: module.NotificationSettings
    }))
  ),

  Payment: lazy(() => import('../payment').then(module => ({ default: module.Payment }))),

  SetupBilling: lazy(() =>
    import('../payment/SetupBilling').then(module => ({
      default: module.SetupBilling
    }))
  ),

  Pipeline: lazy(() => import('../pipeline').then(module => ({ default: module.Pipeline }))),

  Team: lazy(() => import('../team').then(module => ({ default: module.Team }))),

  TransformModel: lazy(() =>
    import('../transform-model').then(module => ({ default: module.TransformModel }))
  ),

  Workflow: lazy(() => import('../workflow').then(module => ({ default: module.Workflow }))),

  AccountSuspended: lazy(() =>
    import('./AccountSuspended').then(module => ({
      default: module.AccountSuspended
    }))
  ),

  CreateEntityShortcut: lazy(() =>
    import('./CreateEntityShortcut').then(module => ({
      default: module.CreateEntityShortcut
    }))
  ),

  DownloadPage: lazy(() =>
    import('./DownloadPage').then(module => ({ default: module.DownloadPage }))
  ),

  EmailVerificationBlock: lazy(() =>
    import('./EmailVerificationBlock').then(module => ({
      default: module.EmailVerificationBlock
    }))
  ),

  TrailExpired: lazy(() =>
    import('./TrialExpired').then(module => ({
      default: module.TrialExpired
    }))
  )
};

function LazyComponent({ component }) {
  const Component = routers[component];
  return (
    <Suspense fallback={<GenericPageShimmer />}>
      <Component />
    </Suspense>
  );
}

export function DashboardRoutes() {
  return (
    <div className={styles.dashboardOutletContainer}>
      <Routes>
        <Route
          key='destination-edge'
          path='/destination/edge/*'
          element={<LazyComponent component='EnterpriseDestination' />}
        />
        <Route
          key='pipeline-edge'
          path='/pipeline/edge/*'
          element={<LazyComponent component='EnterprisePipeline' />}
        />
        <Route
          key='destination'
          path='/destination/*'
          element={<LazyComponent component='Destination' />}
        />
        <Route key='workflow' path='/workflow/*' element={<LazyComponent component='Workflow' />} />
        <Route key='pipeline' path='/pipeline/*' element={<LazyComponent component='Pipeline' />} />
        <Route key='model' path='/model/*' element={<LazyComponent component='TransformModel' />} />
        <Route
          key='activation'
          path='/activation/*'
          element={
            <Suspense fallback={<GenericPageShimmer />}>
              <LazyActivation />
            </Suspense>
          }
        />
        <Route key='target' path='/target/*' element={<LazyComponent component='Target' />} />
        <Route key='account' path='/account/*' element={<LazyComponent component='Account' />} />
        <Route key='team' path='/team/*' element={<LazyComponent component='Team' />} />
        <Route
          key='payment-setup-billing'
          path='/payment/setup-billing'
          element={<LazyComponent component='SetupBilling' />}
        />
        <Route key='payment' path='/payment/*' element={<LazyComponent component='Payment' />} />
        <Route
          key='alerts'
          path='/alerts/*'
          element={<LazyComponent component='NotificationSettings' />}
        />
        <Route
          key='trial-expired'
          path='/trial-expired'
          element={<LazyComponent component='TrailExpired' />}
        />
        <Route
          key='email-verification'
          path='/verify-email-block'
          element={<LazyComponent component='EmailVerificationBlock' />}
        />
        <Route
          key='download-page'
          path='/download/:feature/:filename'
          element={<LazyComponent component='DownloadPage' />}
        />
        <Route
          key='account-suspended'
          path='/account-suspended'
          element={<LazyComponent component='AccountSuspended' />}
        />
        <Route
          key='all-jobs'
          path='/all-jobs'
          element={<LazyComponent component='JobMonitoringDashboard' />}
        />
        <Route
          key=''
          path='/create-entity-shortcut/:entity'
          element={<LazyComponent component='CreateEntityShortcut' />}
        />
        <Route path='*' element={<Navigate to='/pipeline' replace />} />
      </Routes>
    </div>
  );
}
