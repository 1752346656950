import React from 'react';
import Dompurify from 'dompurify';
import { getCalendarDateWithTimezone } from '../../../legacy-utils/date';
import { SEVERITY_CLASS_MAP } from '../constants';
import { NotificationEntityType, Notification } from '../models';
import { HdLink, HdTooltip } from '../../UIElements';
import HdTimeAgo from '../../UIElements/HdTimeAgo';
import { ActivationView } from '../Views/ActivationView';
import { DestinationView } from '../Views/DestinationView';
import { ModelView } from '../Views/ModelView';
import { NotificationTableView } from '../Views/NotificationTableView';
import { PipelineView } from '../Views/PipelineView';
import { WorkflowView } from '../Views/WorkflowView';
import DbtView from '../Views/DbtView';
import styles from './styles.module.scss';

const severityClassMap: { [key: string]: string } = SEVERITY_CLASS_MAP;

interface NotificationProps {
  data: Notification;
  noBorder: boolean;
}

export function NotificationComponent({ data, noBorder }: NotificationProps) {
  return (
    <div className={`${styles.notificationContainer} ${noBorder ? styles.noBorder : ''}`}>
      <div className={styles.header}>
        <div
          className={`${styles.entityName} text-body-3 ${
            data.entityType !== NotificationEntityType.DBT_PROJECT ? 'text-uppercase' : ''
          }`}
        >
          {data.entityName}{' '}
          <HdLink tag='a' to={data.entityUrl} dataId={`${data.entityName}-${data.entityType}`}>
            #{data.entityId}
          </HdLink>
        </div>

        <HdTooltip title={getCalendarDateWithTimezone(data.createdTs)} placement='left'>
          <span className='created-time text-secondary text-caption-1'>
            <HdTimeAgo timestamp={parseInt(data.createdTs, 10)} />
          </span>
        </HdTooltip>
      </div>

      <div className='mt-1'>
        <Views data={data} />
      </div>

      <div className={`${styles.body} ${styles[severityClassMap[data.severity]]} mt-3`}>
        <div className='text' dangerouslySetInnerHTML={{ __html: Dompurify.sanitize(data.text) }} />

        {data.message ? (
          <div>
            {data.message}
            <br />
          </div>
        ) : null}

        {data.tableData ? (
          <div className='mt-3'>
            <NotificationTableView data={data.tableData} />
          </div>
        ) : null}
      </div>
    </div>
  );
}

function Views({ data }) {
  switch (data.entityType) {
    case NotificationEntityType.PIPELINE:
      return <PipelineView data={data.pipelineData} />;
    case NotificationEntityType.JOB:
      return <PipelineView data={data.pipelineData} />;
    case NotificationEntityType.MODEL:
      return <ModelView data={data.modelData} />;
    case NotificationEntityType.WORKFLOW:
      return <WorkflowView data={data.workflowData} />;
    case NotificationEntityType.DESTINATION:
      return <DestinationView data={data.destData} />;
    case NotificationEntityType.ACTIVATION:
      return <ActivationView data={data.activationData} />;
    case NotificationEntityType.ACTIVATION_TARGET:
      return <DestinationView data={data.activationTargetData} />;
    case NotificationEntityType.DBT_PROJECT:
      return <DbtView data={data.dbtProjectData} />;
    default:
      return null;
  }
}
