import { NodeTypeConfigHelper } from '../../../../../components/Node/node-config-helper';

export class ZendeskConfigHelper implements NodeTypeConfigHelper {
  getConfigFromRawData(rawData: any) {
    return {
      subDomain: rawData.subdomain,
      email: rawData.email,
      token: rawData.api_token
    };
  }

  getDefaultConfig() {
    return {};
  }

  buildConnectionParams(data) {
    return {
      subdomain: data.subDomain,
      email: data.email,
      api_token: data.token
    };
  }
}
