import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { Observable, Subscription } from 'rxjs';

export function useObservableState<T>(
  observable$: Observable<T>,
  initialValue?: T
): [T, Dispatch<SetStateAction<T>>, () => void] {
  const [state, setState] = useState(initialValue);
  const sub = useRef<Subscription>(Subscription.EMPTY);

  useEffect(() => {
    sub.current = observable$.subscribe(s => {
      setState(s);
    });

    return () => {
      sub.current.unsubscribe();
    };
  }, []);

  const clearSubscription = () => {
    sub.current.unsubscribe();
  };

  return [state, setState, clearSubscription];
}
