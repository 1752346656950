import dayjs from 'dayjs';

export function getBarGraphDisplayTime(start: string, end: string): string {
  const startTime = dayjs.utc(start).local(),
    endTime = dayjs.utc(end).local();

  if (startTime.isSame(endTime, 'day')) {
    return (
      startTime.format('MMM Do YYYY') + ' ' + startTime.format('LT') + ' - ' + endTime.format('LT')
    );
  }

  return startTime.format('MMM Do YYYY LT') + ' ' + ' - ' + endTime.format('MMM Do YYYY LT');
}
