import { addSuffixWhenNounPlural } from '../../../legacy-utils/string';


export class HistoricalLoadOption {
  value: number | string;
  unit: string;
  representation?: string;
  allData?: boolean;
  noData?: boolean;
  id: string;
  name: string;

  setRepresentation() {
    if (this.allData || this.noData) {
      this.representation = this.allData ? 'All Available Data' : 'No Historical Data';
    } else {
      const unit = this.unit.slice(0, 1) + this.unit.substr(1).toLowerCase();
      this.representation = this.value + ` ${ addSuffixWhenNounPlural(unit, Number(this.value)) }`;
    }

    this.id = this.representation;
    this.name = this.representation;
  }
}

/**
 *
 * @param rawOption - raw data to create historical load option
 */
export function HistoricalLoadOptionFactory(rawOption: any): HistoricalLoadOption {
  const option = new HistoricalLoadOption();

  option.value = rawOption.value;
  option.unit = rawOption.unit;

  if ('allData' in rawOption) {
    option.allData = rawOption.allData;
  }

  if ('noData' in rawOption) {
    option.noData = rawOption.noData;
  }

  option.setRepresentation();

  return option;
}

export function HistoricalLoadOptionsFactory(rawData: any): HistoricalLoadOption[] {
  if (!rawData || !rawData.length) {
    return [];
  }

  return rawData.filter(option => option.allAvailableData === false)
    .map((rawOption: any) => {
      return HistoricalLoadOptionFactory(rawOption);
    });
}

/**
 *
 * @param rawOption
 * @param relative - boolean value specifying if its relative all data option
 */
export function HistoricalLoadOptionAllData(rawOption: any, relative = false): HistoricalLoadOption {
  const option: any = {
    value: rawOption.value,
    unit: null,
    allData: true
  };

  if (relative === true) {
    option.unit = rawOption.unit;
  }

  return HistoricalLoadOptionFactory(option);
}

export function HistoricalLoadOptionNoData(): HistoricalLoadOption {
  const option: any = {
    value: 0,
    unit: null,
    noData: true
  };

  return HistoricalLoadOptionFactory(option);
}

/**
 * @returns milliseconds for the given option based on value and unit
 *
 * @param option - takes historical load option
 */
export function calculateSecondsFromValue(option: HistoricalLoadOption): number {
  const days = 24 * 60 * 60 * 1000;
  let seconds: number;

  switch (option.unit) {
    case 'DAY':
      seconds = (option.value as number) * days;
      break;

    case 'MONTH':
      seconds = (option.value as number) * 30 * days;
      break;

    case 'YEAR':
      seconds = (option.value as number) * 365 * days;
      break;
  }

  return seconds;
}

/**
 * @returns value of the historical load option
 *
 * @param duration - in milliseconds
 * @param unit - DAY | MONTH | YEAR
 */
export function calculateHistoricalLoadValue(duration: number, unit: string): number {
  const seconds = 24 * 60 * 60 * 1000;
  let value: number;

  switch (unit) {
    case 'DAY':
      value = duration / seconds;
      break;

    case 'MONTH':
      value = duration / (30 * seconds);
      break;

    case 'YEAR':
      value = duration / (365 * seconds);
      break;
  }

  return value;
}

/**
 * gives config data from the historical load option
 */
export function getConfigDataFromHistoricalOption(sourceType: string, data: any): any {
  const historicalLoadOption: HistoricalLoadOption = data.historicalSyncDuration;

  const historicalConfig: any = {};

  /**
   * Source type based override for getting historical option raw data.
   * This can be improved to get method from a registry if used in multiple source types.
   */
  // if (sourceType === 'FACEBOOK_ADS' && !data.customReport) {
  //   return historicalConfig;
  // }

  if (historicalLoadOption.unit) {
    historicalConfig['historical_load_duration'] = calculateSecondsFromValue(historicalLoadOption);
    historicalConfig['historical_load_unit'] = historicalLoadOption.unit;
  }

  if ('noData' in historicalLoadOption && historicalLoadOption.noData === true) {
    historicalConfig['historical_load_duration'] = 0;
  }

  historicalConfig['all_available_data'] = 'allData' in historicalLoadOption && historicalLoadOption.allData === true;

  return historicalConfig;
}

/**
 * gives historical load option from config data and creates a new if doesn't exit
 *
 * @param rawData - config data for source
 * @param sourceHistoricalLoadOptions - historical load options coming from API
 */
export function getHistoricalOptionFromConfigData(rawData: any, sourceHistoricalLoadOptions: any[]): any {

  // For all historical data option
  if ('all_available_data' in rawData && rawData.all_available_data === true) {
    return sourceHistoricalLoadOptions.find((option) => {
      return option.allData === true;
    });
  }

  // For no historical data option
  if (rawData.historical_load_duration === 0) {
    return sourceHistoricalLoadOptions.find((option) => {
      return option.noData === true;
    });
  }

  if ('historical_load_unit' in rawData) {
    // Get unit & duration
    const value = calculateHistoricalLoadValue(rawData.historical_load_duration, rawData.historical_load_unit);

    return sourceHistoricalLoadOptions.find((option) => {
      return option.value === value && option.unit === rawData.historical_load_unit;
    });
  }
}
