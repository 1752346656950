import { AppConfig } from '../core/app.config';
import { BACKGROUND_NETWORK_REQ_OPTIONS } from '../core/constants';
import { courier } from '../core/services/courier.service';

const appConfig = new AppConfig();

export const StatsAPI = {
  statsUrl: `${appConfig.getStatsURL()}`,

  getRateLimit() {
    const requestUrl = `${ this.statsUrl }/rate-limit/violations/source`;
    return courier.get(requestUrl, BACKGROUND_NETWORK_REQ_OPTIONS);
  }
};
