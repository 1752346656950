import { execIfDefined } from '../../../legacy-utils/exec-if-defined';
import { TrackerConfig, UITracker } from '../models/tracking';
import { User } from '../models/user';

declare var analytics: any;
declare var __SEGMENT_CONFIG__: TrackerConfig;

export class SegmentService implements UITracker {
  config = __SEGMENT_CONFIG__;

  init() {
  }

  identifyUser(user) {
    execIfDefined('analytics', () => {
      analytics.identify(user.email, {
        'userId': user.id,
        'company': user.teamName,
        'companyId': user.teamId,
        'env': user.clusterId,
        'email': user.email,
        'name': user.name,
        'created': user.createdTs
      });
    });
  }

  updateIdentity(data: User) {
    this.identifyUser(data);
  }

  updateUserProperties(user, data: any) {
    execIfDefined('analytics', () => {
      this.identifyUser({ ...user, ...data });
    });
  }

  reset() {
    execIfDefined('analytics', () => {
      analytics.reset();
    });
  }

  stop() {

  }
}
