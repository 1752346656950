import React, { useEffect, useState } from 'react';
import { Typography } from '@mui/material';
import Dompurify from 'dompurify';
import clsx from 'clsx';
import { getDataIdGenerator } from '../../../../utils/generateDataId';
import FormError from '../../../FormError';
import { HdHoverTracker } from '../../../Tracking/HoverTracker';
import { HdButton, HdIcon, HdIconButton } from '../../../UIElements';
import HdModal from '../../../UIElements/HdModal';
import { ConfirmDialogCommonProps, DialogCommonProps } from '../interface';
import { useConfirmDialog } from '../useConfirmDialog';
import styles from './styles.module.scss';
import useDontMountAtFirst from '../../../../hooks/useDontMountAtFirst';

export interface ConfirmDialogV1Config extends ConfirmDialogCommonProps {
  subtitle?: string;
  dataId?: string;
  alternateHeaderDesign?: boolean;
  isConditionalConfirm?: boolean;
}

export function ConfirmDialogV1({
  open,
  title = 'Alert',
  subtitle = null,
  body = 'Are you Sure?',
  positiveButtonText = 'Confirm',
  negativeButtonText = 'Cancel',
  onPositiveClickCallback,
  onPositiveClick,
  bodyComponentProps = {},
  BodyComponent = null,
  onClose,
  onPositiveClickSuccess = null,
  requiresExtraConfirmation = false,
  hoverTrackerAction,
  closeCTAHoverTrackerAction,
  negativeCTAHoverTrackerAction,
  positiveCTAHoverTrackerAction,
  modalProps,
  dataId,
  alternateHeaderDesign = false,
  isConditionalConfirm = false
}: ConfirmDialogV1Config & DialogCommonProps) {
  const [extraConfirmationStatus, setExtraConfirmationStatus] = useState(false);
  const [isPositiveButtonDisabled, setIsPositiveButtonDisabled] = useState(false);
  const { processing, onErrorMessage, onPositiveButtonClick, setError } = useConfirmDialog({
    onPositiveClick,
    onPositiveClickCallback,
    onPositiveClickSuccess,
    onClose
  });

  useDontMountAtFirst(() => {
    if (isConditionalConfirm) {
      setIsPositiveButtonDisabled(true);
    }
  }, [isConditionalConfirm]);

  useEffect(() => {
    if (open) {
      return;
    }

    if (onErrorMessage) {
      setError(null);
    }

    if (isConditionalConfirm) {
      setIsPositiveButtonDisabled(true);
    }

    setExtraConfirmationStatus(false);
  }, [open]);

  const dataIdGenerator = getDataIdGenerator(dataId, 'confirmDialogV1');

  return (
    <HdModal
      {...modalProps}
      dialogHoverTrackingAction={hoverTrackerAction}
      open={!!open}
      onClose={() => onClose()}
      dataId={dataIdGenerator('')}
    >
      <>
        <div
          className={clsx(
            'dialog-header',
            subtitle && styles.modalHeader,
            alternateHeaderDesign && 'border-bottom-0'
          )}
        >
          <div className='dialog-title'>{title}</div>

          <HdHoverTracker trackingAction={closeCTAHoverTrackerAction}>
            <HdIconButton
              verticalEdge='both'
              horizontalEdge='both'
              className='ml-2'
              onClick={() => onClose()}
              dataId={dataIdGenerator('close')}
            >
              <HdIcon name='close' size={3} />
            </HdIconButton>
          </HdHoverTracker>

          {subtitle ? (
            <>
              <div className={`${styles.lineBreak} ${!alternateHeaderDesign ? 'mt-1 mb-1' : ''}`} />

              <Typography variant={!alternateHeaderDesign ? 'caption' : 'body1'}>
                {subtitle}
              </Typography>
            </>
          ) : null}
        </div>

        <div className='dialog-body'>
          {!!BodyComponent && !!open ? (
            <BodyComponent
              {...bodyComponentProps}
              setExtraConfirmationStatus={setExtraConfirmationStatus}
              setIsPositiveButtonDisabled={setIsPositiveButtonDisabled}
            />
          ) : (
            <span dangerouslySetInnerHTML={{ __html: Dompurify.sanitize(body) }} />
          )}

          {onErrorMessage ? <FormError className='mt-5 mb-0'>{onErrorMessage}</FormError> : null}
        </div>

        <div className='dialog-footer'>
          <div>
            <HdHoverTracker trackingAction={negativeCTAHoverTrackerAction}>
              <HdButton
                onClick={() => onClose()}
                variation='outline'
                dataId={dataIdGenerator('cancel')}
              >
                {negativeButtonText}
              </HdButton>
            </HdHoverTracker>

            <HdHoverTracker trackingAction={positiveCTAHoverTrackerAction}>
              <HdButton
                onClick={onPositiveButtonClick}
                disabled={(requiresExtraConfirmation && !extraConfirmationStatus) || isPositiveButtonDisabled || processing}
                showProgress={processing}
                dataId={dataIdGenerator('confirm')}
              >
                {positiveButtonText}
              </HdButton>
            </HdHoverTracker>
          </div>
        </div>
      </>
    </HdModal>
  );
}
