import { Role } from '../models';
import { MemberActionables } from './models';
import { RbacPermissions } from '../../core/models/user';

export const resolverRolesToPermissions = (roles: Role[]): RbacPermissions[] =>
  roles.reduce(
    (arr, role) => [...arr, ...role.permissions.map(permission => permission.permission_name)],
    []
  );

export const getResolvedRoles = (allRoles: Role[], roles: Role[]) => {
  const resolvedPermissions = new Set(resolverRolesToPermissions(roles));

  const selectedRoles = [];

  const resolvedRolesSet = new Set<string>();
  const assignedRolesSet = new Set(roles.map(role => role.roleName));

  allRoles.forEach(role => {
    if (role.permissions.every(permission => resolvedPermissions.has(permission.permission_name))) {
      selectedRoles.push({
        ...role,
        isResolvedViaNesting: !assignedRolesSet.has(role.roleName)
      });

      if (!assignedRolesSet.has(role.roleName)) {
        resolvedRolesSet.add(role.roleName);
      }
    }
  });

  return {
    resolvedRolesSet,
    assignedRolesSet,
    allAvailableRolesSet: new Set([...resolvedRolesSet, ...assignedRolesSet])
  };
};

export const isOptionDisabled = (action: MemberActionables) =>
  action === MemberActionables.CAN_NOT_ACT_ON_ROLE || action === MemberActionables.IS_RESOLVED_ROLE;
