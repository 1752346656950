import { ColorMode } from '../containers/core/models/color-mode';
import { useActiveTheme } from '../utils/themeProvider';

export default function useEntityLogo(logo, darkModeLogo) {
  const colorMode = useActiveTheme();

  if (colorMode === ColorMode.DARK && darkModeLogo) {
    return darkModeLogo;
  }

  return logo;
}
