import React, { useState, useCallback, useMemo, useEffect } from 'react';
import HdModal from '../../../components/UIElements/HdModal';
import { HdButton, HdInputWithTags } from '../../../components/UIElements';
import RoundedIcon from '../../../components/RoundedIcon';
import { ROLES_PRIORITY_LIST } from '../factory';
import { Role } from '../models';
import RoleGroupContainer, { RoleGroup } from './RoleGroupContainer';
import { VALID_EMAIL_REGEX } from '../../core/constants';
import { RBAC_SCHEMA } from './constants';
import TeamAPI from '../TeamAPI';
import styles from './styles.module.scss';
import { RolesEnum } from './models';
import useDebounce from '../../../hooks/useDebounce';
import useAnalyticsTracker from '../../../hooks/useAnalyticsTracker';
import { MembersTrackingActions } from './tracking';
import { isOptionDisabled } from './utils';
import { useRolesResolver } from './useRolesResolver';
import { getDataIdGenerator } from '../../../utils/generateDataId';

interface InviteUserPopupProps {
  open: boolean;
  onCloseHandler: () => void;
  onInviteSuccess: () => void;
  roles: Role[];
}

export default function InviteUserPopup({
  open,
  onCloseHandler,
  roles,
  onInviteSuccess
}: InviteUserPopupProps) {
  const [invitedEmailIds, setInvitedEmailIds] = useState(new Set<string>());
  const [emailValidationError, setEmailValidationError] = useState('');
  const [selectedRoles, setSelectedRoles] = useState<Set<string>>(new Set([RolesEnum.OBSERVER]));
  const [sendingInvite, setSendingInvite] = useState(false);
  const [emailInput, setEmailInput] = useState('');
  const debouncedValue = useDebounce(emailInput, 500);
  const { eventTrack } = useAnalyticsTracker();

  const { checkForRolesAccessOnMember } = useRolesResolver(roles);

  useEffect(() => {
    if (!open) {
      setEmailValidationError('');
      setSelectedRoles(new Set([RolesEnum.OBSERVER]));
      setEmailInput('');
      setInvitedEmailIds(new Set<string>());
    }
  }, [open]);

  const schema: RoleGroup[] = useMemo(() => {
    const rbacRolesSchema = JSON.parse(JSON.stringify(RBAC_SCHEMA));

    roles.forEach(role => {
      if (role.roleName === RolesEnum.OBSERVER) {
        return;
      }
      rbacRolesSchema?.[role.category]?.roles.push(role);
    });

    return Object.values(rbacRolesSchema).map((category: RoleGroup) => {
      category.roles.sort(
        (role1, role2) =>
          ROLES_PRIORITY_LIST.indexOf(role1.roleName) - ROLES_PRIORITY_LIST.indexOf(role2.roleName)
      );
      return category;
    });
  }, [roles]);

  const rolesLookUp = useMemo(
    () =>
      (roles || []).reduce(
        (dict, role) => ({
          ...dict,
          [role.roleName]: role
        }),
        {}
      ),
    [roles]
  );

  const { allSelectedRoles, rolesActionsMap } = useMemo(
    () =>
      checkForRolesAccessOnMember(
        [...selectedRoles].filter(role => rolesLookUp[role]).map(role => rolesLookUp[role]),
        null
      ),
    [checkForRolesAccessOnMember, rolesLookUp, selectedRoles]
  );

  const resolvedRoles = new Set(allSelectedRoles.map(role => role.roleName));

  useEffect(() => {
    setEmailValidationError('');
    if (!debouncedValue) {
      return;
    }
    const validationError = validateEmail(debouncedValue);
    setEmailValidationError(validationError);
  }, [debouncedValue]);

  const validateEmail = useCallback(
    (email: string): string => {
      const trimmedEmail = email.trim();

      if (!VALID_EMAIL_REGEX.test(trimmedEmail)) {
        return 'Please enter a valid email';
      }

      if (invitedEmailIds.has(trimmedEmail)) {
        return 'This email is already present in the list';
      }

      return null;
    },
    [invitedEmailIds]
  );

  const emailChangeHandler = useCallback((emails: string[]) => {
    const emailsSet = new Set(emails);
    setInvitedEmailIds(emailsSet);
  }, []);

  const inputChangeHandler = input => {
    setEmailInput(input);
  };

  const inviteUserClickHandler = useCallback(async () => {
    const emails = [...invitedEmailIds];
    // User has not clicked enter
    if (emailInput.trim()) {
      emails.push(emailInput);
    }

    const payload = {
      emails: emails.map(email => email.trim()),
      rbac: { roles: [...selectedRoles] }
    };
    setSendingInvite(true);
    try {
      await TeamAPI.inviteUserWithRBAC(payload);
      onCloseHandler();
      onInviteSuccess();
      setSendingInvite(false);
      eventTrack({
        action: MembersTrackingActions.INVITE_USER_CONFIRM,
        properties: {
          roles: selectedRoles,
          inviteesCount: emails.length
        }
      });
    } catch ({ error: { message, error_message: errorMessage }, message: defaultMessage }) {
      onCloseHandler();
      setSendingInvite(false);
    }
  }, [invitedEmailIds, emailInput, selectedRoles, onCloseHandler, onInviteSuccess, eventTrack]);

  const setSelectedRolesHandler = (rolesSet: Set<string>) => {
    setSelectedRoles(
      new Set([...rolesSet].filter(role => !isOptionDisabled(rolesActionsMap[role])))
    );
  };

  const disableAddButton =
    (emailInput.trim() && !!validateEmail(emailInput)) ||
    (invitedEmailIds.size === 0 && !emailInput.trim()) ||
    sendingInvite;

  const dataIdGenerator = getDataIdGenerator('invite-user-popup');
  return (

    <HdModal open={open} onClose={onCloseHandler} variant='custom'>
      <div className={styles.inviteMemberPopupWrapper} data-id={dataIdGenerator('wrapper')}>
        <HdButton
          variation='flat'
          icon='close'
          className={styles.closeButton}
          onClick={onCloseHandler}
          dataId={dataIdGenerator('close')}
        >
          Cancel
        </HdButton>

        <RoundedIcon iconName='add-user' />

        <div className={styles.header}>Invite New Team Members</div>

        <div className={styles.subHeader}>
          Invited members will receive an email to join your team on Hevo.
        </div>

        <div className={styles.inviteBody}>
          <HdInputWithTags
            label='Email ID (s)'
            placeholder='Add Email ID'
            dataId={dataIdGenerator('')}
            validateFunction={email => !validateEmail(email)}
            error={!!emailValidationError}
            helperText={
              emailValidationError ? (
                <span className='error-transition mat-error'>{emailValidationError}</span>
              ) : (
                'A comma-separated email Ids of your new team members'
              )
            }
            onInputChange={inputChangeHandler}
            onChange={emailChangeHandler}
          />

          <div className={styles.accessHeader}>Assign Access</div>

          {schema.map(roleGroup => (
            <RoleGroupContainer
              key={roleGroup.id}
              roleGroup={roleGroup}
              rolesActionsMap={rolesActionsMap}
              selectedRoles={resolvedRoles}
              setSelectedRoles={setSelectedRolesHandler}
            />
          ))}
        </div>

        <div className={styles.buttonsSection}>
          <HdButton
            variation='outline'
            disabled={sendingInvite}
            icon='close'
            onClick={onCloseHandler}
            dataId={dataIdGenerator('cancel')}
          >
            Cancel
          </HdButton>

          <HdButton
            icon='email'
            showProgress={sendingInvite}
            disabled={disableAddButton}
            onClick={inviteUserClickHandler}
            dataId={dataIdGenerator('send-invite')}
          >
            Send Invite
          </HdButton>
        </div>
      </div>
    </HdModal>
  );
}
