import { AuthAccountEnvironment } from '../../../containers/pipeline/create/AuthSelect/AuthEnvironmentSelectionDialog/interface';
import { ConnectorAuthTypeEnum } from '../source-type/auth-type';

export abstract class AuthorizationAccount {
  id: number;
  provider: string;
  createdAt: Date;

  constructor(rawData: any) {
    this.constructFromRawData(rawData);
  }

  abstract getAccountType() : ConnectorAuthTypeEnum

  abstract getDisplayName() : string

  abstract constructFromRawData(rawData: any): void;
}

export class AuthorisedAccount extends AuthorizationAccount {
  accessToken: string;
  user: string;
  userId: number;
  disabled = false;
  environment: AuthAccountEnvironment;

  constructFromRawData(rawData: any): void {
    this.id = rawData.id;
    this.accessToken = rawData.access_token;
    this.user = rawData.authenticated_user;
    this.userId = rawData.user_id;
    this.provider = rawData.provider;
    this.disabled = !!rawData.is_disabled;
    this.createdAt = new Date(rawData.created_at);
    this.environment = rawData.environment;
  }

  getDisplayName(): string {
    return this.user;
  }

  getAccountType(): ConnectorAuthTypeEnum {
    return ConnectorAuthTypeEnum.OAUTH;
  }
}

export class ServiceAccount extends AuthorizationAccount {
  teamId: number;
  email: string;
  updatedAt: Date;

  constructFromRawData(rawData: any): void {
    this.id = Number(rawData.id);
    this.teamId = Number(rawData.team_id);
    this.email = rawData.email;
    this.provider = rawData.provider;
    this.createdAt = new Date(rawData.created_ts);
    this.updatedAt = new Date(rawData.updated_ts);
  }

  getDisplayName(): string {
    return this.email;
  }

  getAccountType(): ConnectorAuthTypeEnum {
    return ConnectorAuthTypeEnum.SERVICE;
  }
}
