import { BehaviorSubject } from 'rxjs';
import { ReactRouterService } from '../../containers/core/services/react-router.service';
import { ITour, TourStep } from './model';
import { TourOrchestratorService } from './tour-orchestrator.service';

export class TourConductorService {
  static deps = [
    TourOrchestratorService,
    ReactRouterService
  ];

  private _activeTourStep = new BehaviorSubject<TourStep>(null);
  private _tour = new BehaviorSubject<ITour>(null);
  currentStep = 0;
  private activeTour: ITour = null;

  activeTourStep$ = this._activeTourStep.asObservable();

  constructor(
    private _tourOrchestratorService: TourOrchestratorService,
    private _router: ReactRouterService
  ) {
    this.initConductor();
  }

  private initConductor() {
    this._tourOrchestratorService.activeTourId$.subscribe((tour) => {
      this.activeTour = tour;
      if (!tour) {
        this.resetTourConductor();
        return;
      }

      this.startTour(tour);
    });
  }

  private getStepsCount () {
    return this.activeTour.steps.length;
  }

  private startTour(tour: ITour) {

    this.currentStep = 0;
    this._tour.next(tour);
    this._activeTourStep.next(tour.steps[0]);
  }

  private getNextStep(): TourStep {
    if (this.currentStep >= this.getStepsCount() ) {
      return null;
    }

    return this.activeTour.steps[this.currentStep + 1];
  }

  private getPrevStep(): TourStep {
    if (this.currentStep <= 0 ) {
      return null;
    }

    return this.activeTour.steps[this.currentStep - 1];
  }

  public showNextStep(props: {
    navigateTo?: string;
  } = {}) {
    const nextStep = this.getNextStep();
    if (!nextStep) {
      return;
    }

    if (!!props.navigateTo) {
      this._router.navigate({ pathname: props.navigateTo }).then(() => {
        this.currentStep += 1;
        this._activeTourStep.next(nextStep);
      });
    } else {
      this.currentStep += 1;
      this._activeTourStep.next(nextStep);
    }

  }

  public showPrevStep(props: {
    navigateTo?: string;
  } = {}) {
    const prevStep = this.getPrevStep();
    if (!prevStep) {
      return;
    }

    if (!!props.navigateTo) {
      this._router.navigate({ pathname: props.navigateTo }).then(() => {
        this.currentStep -= 1;
        this._activeTourStep.next(prevStep);
      });
    } else {
      this.currentStep -= 1;
      this._activeTourStep.next(prevStep);
    }
  }

  private resetTourConductor() {
    this._activeTourStep.next(null);
    this._tour.next(null);
  }
}
