/* eslint-disable dot-notation */
import { TargetObjectMeta } from './models/target-object-meta';
import { TargetTypesEnum } from './models/target-type';
import { AppConfig } from '../core/app.config';
import {
  BACKGROUND_NETWORK_REQ_OPTIONS,
  BACKGROUND_NETWORK_REQ_UI_OPTIONS
} from '../core/constants';
import { HevoEntity } from '../core/models/hevo-entity';
import { NetworkRequestOptions, RequestPaginationData } from '../core/models/request';
import { courier, CourierNetworkRequestOptions } from '../core/services/courier.service';
import { _recentEntitiesService } from '../core/services/recent-entities.service';
import { createHttpParams } from '../../legacy-utils/request';
import { ConnectorAuthTypeEnum } from '../../components/Node/source-type/auth-type';

const appConfig = new AppConfig();

const ActivateTargetAPI = {
  targetsURL: `${appConfig.getActivationsURL()}/targets`,
  targetGoogleSheetsURL: `${appConfig.getActivationsURL()}/targets/google-sheets`,

  getTargets() {
    return courier.get(this.targetsURL, BACKGROUND_NETWORK_REQ_OPTIONS);
  },

  getTargetSyncInfo(targetId: number, paginationData: RequestPaginationData, networkRequestOptions = {}) {
    const baseUrl = `${this.targetsURL}/${targetId}/sync-info`;

    const options: NetworkRequestOptions = {
      uiOptions: BACKGROUND_NETWORK_REQ_UI_OPTIONS,
      networkOptions: {
        params: createHttpParams(paginationData)
      },
      ...networkRequestOptions,
    };

    return courier.get(baseUrl, options);
  },

  getTarget(targetSeqId: any, showLoading = true) {
    const requestUrl = this.targetsURL + `/${ targetSeqId }/details`;

    const options: NetworkRequestOptions = {
      uiOptions: {
        showSuccessMsg: false,
        showLoading: showLoading
      }
    };
    return courier.get(requestUrl, options);
  },

  testTargetConnection(
    targetType: TargetTypesEnum,
    targetName: string,
    targetConfig: any,
    targetId?: number
  ) {
    const params = {
      name: targetName,
      target_type: targetType,
      target_config: targetConfig
    };

    const requestUrl = `${this.targetsURL}/validate`;

    const options: NetworkRequestOptions = {
      uiOptions: BACKGROUND_NETWORK_REQ_UI_OPTIONS
    };

    if (targetId) {
      options.networkOptions = {
        params: createHttpParams({
          target_id: targetId
        })
      };
    }

    return courier.post(requestUrl, options, params);
  },

  createTarget(targetType: TargetTypesEnum, targetName: string, targetConfig: any) {
    const params = {
      name: targetName,
      target_type: targetType,
      target_config: targetConfig
    };

    const requestUrl = this.targetsURL;

    return courier.post(requestUrl, BACKGROUND_NETWORK_REQ_OPTIONS, params).then(res => {
      _recentEntitiesService.setRecent(HevoEntity.ACTIVATION_TARGET.value, res.data);
      return res;
    });
  },

  updateTargetConfig(targetId: number, targetName: string, targetConfig: any) {
    const body = {
      name: targetName,
      config: targetConfig
    };
    return this._updateTarget(targetId, body);
  },

  getTargetTypes() {
    const requestUrl = `${this.targetsURL}/get-types`;
    return courier.get(requestUrl, BACKGROUND_NETWORK_REQ_OPTIONS);
  },

  validateTargetName(targetName: string, config = {}) {
    const requestUrl = `${this.targetsURL}/update-validations`;
    const options: NetworkRequestOptions = {
      ...BACKGROUND_NETWORK_REQ_OPTIONS,
      ...config
    };

    const params = {
      name: targetName
    };

    return courier.post(requestUrl, options, params).then(res => {
      // TODO -  Reminder -  To get this thing fixed from backend and then remove .then from here. Api is returning 200 with error
      if (res.data && !res.data.valid) {
        throw new Error(res.data.error);
      }
    });
  },

  getGoogleSheetsFolders(accountId: number, authType: ConnectorAuthTypeEnum) {
    const requestUrl = `${this.targetGoogleSheetsURL}/drive/${accountId}/info`;
    const options: NetworkRequestOptions = {
      uiOptions: BACKGROUND_NETWORK_REQ_UI_OPTIONS,
      networkOptions: {
        params: createHttpParams({
          account_type: authType.toString()
        })
      }
    };
    return courier.get(requestUrl, options);
  },

  getGoogleSheetSpreadsheets(
    accountId: number,
    authType: ConnectorAuthTypeEnum,
    folderId?: string,
    config = {}
  ) {
    const requestUrl = `${this.targetGoogleSheetsURL}/spreadsheets/${accountId}/info`;

    const params = {
      account_type: authType.toString()
    };

    if (folderId) {
      params['folder_id'] = folderId;
    }

    const options: CourierNetworkRequestOptions = {
      uiOptions: BACKGROUND_NETWORK_REQ_UI_OPTIONS,
      networkOptions: {
        params: createHttpParams(params)
      },
      ...config
    };
    return courier.get(requestUrl, options);
  },

  createNewFolder(accountId: number, authType: ConnectorAuthTypeEnum, folderName: string) {
    const requestUrl = `${this.targetGoogleSheetsURL}/drive/${accountId}`;

    const options: NetworkRequestOptions = {
      uiOptions: BACKGROUND_NETWORK_REQ_UI_OPTIONS,
      networkOptions: {
        params: createHttpParams({
          account_type: authType.toString(),
          folder_name: folderName
        })
      }
    };

    return courier.post(requestUrl, options);
  },

  createNewSpreadsheet(
    accountId: number,
    authType: ConnectorAuthTypeEnum,
    spreadsheetName: string,
    folderId?: string
  ) {
    const requestUrl = `${this.targetGoogleSheetsURL}/spreadsheets/${accountId}`;

    const params = {
      spreadsheet_name: spreadsheetName,
      account_type: authType.toString()
    };

    if (folderId) {
      params['folder_id'] = folderId;
    }

    const options: NetworkRequestOptions = {
      uiOptions: BACKGROUND_NETWORK_REQ_UI_OPTIONS,
      networkOptions: {
        params: createHttpParams(params)
      }
    };

    return courier.post(requestUrl, options);
  },

  _updateTarget(targetId: number, params: any, options = BACKGROUND_NETWORK_REQ_OPTIONS) {
    const requestUrl = `${this.targetsURL}/${targetId}`;
    return courier.put(requestUrl, options, params);
  },

  deleteTarget(targetId: number) {
    return courier.delete(this.targetsURL + `/${ targetId }`);
  },

  updateTargetName(targetId: number, params: any) {
    const options: NetworkRequestOptions = {
      uiOptions: {
        ...BACKGROUND_NETWORK_REQ_UI_OPTIONS,
        showErrorMsg: true
      }
    };

    return this._updateTarget(targetId, params, options);
  },

  getTargetObjects(targetId: number): Promise<TargetObjectMeta[]> {
    const requestUrl = this.targetsURL + `/${ targetId }/objects`;

    const options: NetworkRequestOptions = {
      uiOptions: {
        ...BACKGROUND_NETWORK_REQ_UI_OPTIONS,
        showErrorMsg: true
      }
    };

    return courier.get(requestUrl, options).then(
      (res) => {
        return (res.data || []).map((object) => ({
          apiName: object.api_name,
          displayName: object.display_name,
          category: object.target_object_type.split('_').join(' '),
          modes: object.modes
        }));
      }
    );
  },

  getMailchimpTargetObjects(
    targetId: number,
    withSyncBehavior = false,
    limit: number = 1000,
    offset: number = 0,
    currentObjectList = []): Promise<TargetObjectMeta[]> {

    let requestUrl = this.targetsURL + `/mailchimp/${ targetId }/lists`;

    if (withSyncBehavior) {
      requestUrl += '/modes';
    }

    const options: NetworkRequestOptions = {
      uiOptions: {
        ...BACKGROUND_NETWORK_REQ_UI_OPTIONS,
        showErrorMsg: true
      },
      networkOptions: {
        params: createHttpParams({
          offset: offset,
          limit: limit
        })
      }
    };

    return courier.get(requestUrl, options).then((res) => {
        let updatedObjectList = currentObjectList.concat(res);

        if (res.length === limit ) {
          return this.getMailchimpTargetObjects(
            targetId,
            withSyncBehavior,
            limit,
            offset + limit,
            updatedObjectList
          );
        }

        if (withSyncBehavior) {
          updatedObjectList = updatedObjectList.map(({ target_object, modes }) => {
            return {
              ...target_object,
              modes
            };
          });
        }

        return (updatedObjectList).map((object) => ({
          apiName: object.api_name,
          displayName: object.display_name,
          category: object.target_object_type.split('_').join(' '),
          modes: object.modes
        }));
      }
    );
  },

  getTargetObjectFields(targetId: number, targetObjectName: string, activationId?: number) {
    const requestUrl = this.targetsURL + '/schema/fetch';

    const params = {
      target_id: targetId,
      object_name: targetObjectName,
      activation_id: activationId || null
    };

    const options: NetworkRequestOptions = {
      uiOptions: {
        ...BACKGROUND_NETWORK_REQ_UI_OPTIONS,
        showErrorMsg: true
      }
    };

    return courier.post(requestUrl, options, params);

  },

  refreshTargetSchema(targetId: number, showSuccessMsg = true) {
    const requestUrl = this.targetsURL + `/${ targetId }/refresh-schemas`;

    const options: NetworkRequestOptions = {
      uiOptions: {
        showLoading: false,
        showErrorMsg: true,
        showSuccessMsg: showSuccessMsg
      }
    };

    return courier.put(requestUrl, options);
  },

  getTargetObjectSyncModeIdentifiers(targetId: number, objectName: string) {
    const requestUrl = this.targetsURL + `/${ targetId }/objects/${ objectName }/sync_modes/identifiers`;

    const options: NetworkRequestOptions = {
      uiOptions: {
        ...BACKGROUND_NETWORK_REQ_UI_OPTIONS,
        showErrorMsg: true
      }
    };

    return courier.get(requestUrl, options).then(
      (res) => {
        if (!res?.data) {
          return {};
        }

        return res.data.mode_to_identifiers_map.reduce(
          (syncModeMap, { targetSyncMode, identifiersType, disabledFields, mandatoryFields }) => ({
            ...syncModeMap,
            [targetSyncMode]: {
              identifiersType,
              mandatoryFields,
              disabledFields
            }
          }),
          {}
        );
      }
    );
  }
};

export default ActivateTargetAPI;
