import { BehaviorSubject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AccountAccessStatus } from '../../team/AccountAccess/models';
import { DataPrivacyService } from './data-privacy.service';
import { UserService } from './user.service';

export class AccountAccessService {
  static deps = [
    DataPrivacyService,
    UserService
  ];

  private _accountAccessStatus$ = new BehaviorSubject<boolean>(null);

  constructor(
    private _dataPrivacyService: DataPrivacyService,
    private _userService: UserService
  ) {
  }

  getAccountAccessConsentStatus() {

    if (this._userService.getUser().isHidden) {
      return this._accountAccessStatus$.getValue();
    }

    return true;
  }

  fetchAccountAccessConsentStatus() {
    return this._dataPrivacyService
      .getCurrentAccountAccessConsentStatus().pipe(
      tap((res) => {
        this._accountAccessStatus$.next(res.data?.consent_status === AccountAccessStatus.GRANTED.toString());
      })
    );
  }
}
