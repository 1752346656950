import clsx from 'clsx';
import React, { FC, useEffect, useRef, useState } from 'react';
import { ShareDialogOptions } from '../../Dialog/InviteMembersDialog/interface';
import { ShareDocDialogService } from '../../Dialog/InviteMembersDialog/share-doc-dialog.service';
import { SetupGuideGoToSection } from '../../Node/nodes-settings/setup-guide-link';
import { SetupGuideCommService } from '../../../containers/core/services/setup-guide-comm.service';
import { CommunicationMessageEvents } from '../message-events';
import { SetupGuideTracking } from './tracking';
import useAnalyticsTracker from '../../../hooks/useAnalyticsTracker';
import useService from '../../../hooks/useService';
import useSettingsStorageService from '../../../hooks/services/useSettingsStorageService';
import { DocsIframe } from '../DocsIframe';
import { useDocs } from '../useDocs';
import styles from './styles.module.scss';

export interface SetupGuideDocsProps {
  className?: string;
  iframeClassName?: string;
  shareDialogOptions?: ShareDialogOptions;
  showShareSBSDocsButton?: boolean;
  areTabsRendered?: boolean;
  sourceTypeIdentifier?: string;
  loadingParent?: boolean;
  docLink?: string;
  queryParams?: Record<string, string>;
  autoScrollToSectionOnLoad?: boolean;
  scrollToSectionProps?: { sourceSection?: string; label?: string; properties?: {} };
  LoadingComponent?: FC;
  introMode?: boolean;
  onIntroModeLoad?: Function;
}

export function SetupGuideDocs(props: SetupGuideDocsProps) {
  return (
    <SetupGuideDocsInner {...props} />
  );
}

function SetupGuideDocsInner({
  className = '',
  iframeClassName = '',
  shareDialogOptions,
  showShareSBSDocsButton,
  areTabsRendered,
  sourceTypeIdentifier,
  loadingParent,
  docLink,
  queryParams,
  autoScrollToSectionOnLoad = false,
  scrollToSectionProps = {},
  LoadingComponent,
  introMode,
  onIntroModeLoad,
}: SetupGuideDocsProps) {
  const analyticsTracker = useAnalyticsTracker();
  const iframeRef = useRef(null);

  const [blurredSBSDocs, setBlurredSBSDocs] = useState(false);

  const shareDialogService = useService(ShareDocDialogService);
  const setupGuideCommService = useService(SetupGuideCommService);
  const settingsStorageService = useSettingsStorageService();

  useEffect(() => {
    const goToSectionSub = setupGuideCommService.goToSection$.subscribe(
      ({ link, scrollStrategy, highlightTitle, scrollOffset }: SetupGuideGoToSection) => {
        if (link) {
          const data = { link, scrollOffset, highlightTitle };

          if (scrollStrategy === 'auto') {
            sendMessageToIframe(CommunicationMessageEvents.DASH_SUBTEXT_LINK_CLICK, data);
          }

          if (scrollStrategy === 'cta') {
            sendMessageToIframe(CommunicationMessageEvents.DASH_SKIP_TO_SECTION, data);
          }
        }
      }
    );

    return () => {
      goToSectionSub.unsubscribe();
    };
  }, []);

  const onMessageFromIframe = e => {
    handleSBSMessages(e);
    trackDocEvents(e);
  };

  const onIframeLoadError = () => {
    analyticsTracker.eventTrack({
      action: SetupGuideTracking.DOCS_ERRORED,
      properties: {
        setupGuideLink: docLink
      }
    });
  };

  const onIframeLoadSuccess = () => {
    analyticsTracker.eventTrack({
      action: SetupGuideTracking.DOCS_LOADED,
      properties: {
        setupGuideLink: docLink
      }
    });

    onLoadSuccess();
  };

  const handleSBSMessages = message => {
    const { event, data } = message;

    if (event === CommunicationMessageEvents.DOCS_SHARE_BUTTON_ClICK) {
      openShareSBSDialog();
    }

    if (event === CommunicationMessageEvents.DASH_LOAD_DOCS && showShareSBSDocsButton) {
      sendMessageToIframe(CommunicationMessageEvents.DASH_LOAD_SHARE_BUTTON, {});
    }

    if (event === CommunicationMessageEvents.DASH_GET_INTRO_MODE_DIMENSIONS && onIntroModeLoad) {
      onIntroModeLoad(data?.height || 0);
    }
  };

  const {
    error,
    iframeSourceURL,
    isIframeLoading,
    onIframeLoadEvent,
    onIframeLoadErrorEvent,
    sendMessageToIframe
  } = useDocs({
    loadMessage: CommunicationMessageEvents.DASH_LOAD_DOCS,
    loadedMessage: CommunicationMessageEvents.DASH_LOAD_DOCS,
    iframeRef,
    docLink,
    areTabsRendered,
    queryParams,
    onMessage: onMessageFromIframe,
    onIframeLoadError,
    onIframeLoadSuccess
  });

  const trackDocEvents = e => {
    const { event, data } = e;
    let action;
    const properties = { ...data };

    if (areTabsRendered && sourceTypeIdentifier) {
      properties.sourceType = sourceTypeIdentifier;
    }

    switch (event) {
      case CommunicationMessageEvents.DOCS_LINK_CLICK:
        action = SetupGuideTracking.DOCS_LINK_CLICK;
        break;

      case CommunicationMessageEvents.DOCS_SCROLL_EVENT:
        action = SetupGuideTracking.DOCS_SCROLL;
        break;

      case CommunicationMessageEvents.DOCS_SCROLL_TOP_ClICK:
        action = SetupGuideTracking.DOCS_SCROLL_TOP_CLICK;
        break;

      case CommunicationMessageEvents.DOCS_SHARE_BUTTON_ClICK:
        action = SetupGuideTracking.DOCS_SHARE_BUTTON_CLICK;
        break;

      case CommunicationMessageEvents.DOCS_SKIP_TO_SECTION_CLICK:
        action = SetupGuideTracking.DOCS_SKIP_TO_SECTION_CLICK;
        break;

      case CommunicationMessageEvents.DOCS_HEADER_CLICK:
        action = SetupGuideTracking.DOCS_HEADER_CLICK;
        break;

      case CommunicationMessageEvents.DOCS_TABS_LOADED:
        action = SetupGuideTracking.DOCS_TABS_LOADED;
        break;

      case CommunicationMessageEvents.DOCS_TABS_INTERACTION:
        action = SetupGuideTracking.DOCS_TABS_INTERACTION;
        break;

      default:
        action = null;
    }

    if (action) {
      analyticsTracker.eventTrack({ action, properties });
    }
  };

  const openShareSBSDialog = () => {
    shareDialogService.show(shareDialogOptions);
  };

  const onLoadSuccess = () => {
    if (autoScrollToSectionOnLoad) {
      setTimeout(() => {
        setupGuideCommService.goToSection(docLink, {
          medium: 'program',
          scrollStrategy: 'auto',
          sourceSection: scrollToSectionProps.sourceSection,
          label: scrollToSectionProps.label
        });
      }, 200);
    }

    if (introMode) {
      setTimeout(() => {
        sendMessageToIframe(CommunicationMessageEvents.DASH_GET_INTRO_MODE_DIMENSIONS, {});
      }, 200);
    }
  };

  return (
    <div className={`h-100 ${className}`}>
      <DocsIframe
        id='setup-guide-section-tour'
        ref={iframeRef}
        className={clsx(styles.iframeContainer, blurredSBSDocs && styles.backdrop, iframeClassName)}
        error={error}
        docLink={docLink}
        iframeSourceURL={iframeSourceURL}
        isIframeLoading={isIframeLoading}
        loadingParent={loadingParent}
        LoadingComponent={LoadingComponent}
        onIframeLoadEvent={onIframeLoadEvent}
        onIframeLoadErrorEvent={onIframeLoadErrorEvent}
        sendMessageToIframe={sendMessageToIframe}
      />
    </div>
  );
}
