import clsx from 'clsx';
import Dompurify from 'dompurify';
import React, { useCallback } from 'react';
import { getDataIdGenerator } from '../../../../utils/generateDataId';
import FormError from '../../../FormError';
import RoundedIcon, { RoundedIconProps } from '../../../RoundedIcon';
import { HdHoverTracker } from '../../../Tracking/HoverTracker';
import { HdButton, HdIcon, HdIconButton } from '../../../UIElements';
import HdModal from '../../../UIElements/HdModal';
import { ConfirmDialogCommonProps, DialogCommonProps } from '../interface';
import { useConfirmDialog } from '../useConfirmDialog';
import styles from './styles.module.scss';

export interface ConfirmDialogV2Config extends ConfirmDialogCommonProps {
  hideTitle?: boolean;
  iconName?: string;
  iconContainerClass?: string;
  iconProps?: Partial<RoundedIconProps>;
  showConfirmationSubtext?: boolean;
}

export function ConfirmDialogV2({
  open,
  title = 'Alert',
  hideTitle = false,
  iconName,
  iconContainerClass,
  iconProps,
  positiveButtonVariation,
  negativeButtonVariation,
  body = 'Are you Sure?',
  positiveButtonText = 'Confirm',
  negativeButtonText = 'Cancel',
  onPositiveClickCallback,
  onPositiveClick,
  bodyComponentProps = {},
  BodyComponent = null,
  onClose,
  negativeButtonClass,
  negativeButtonOrder,
  positiveButtonClass,
  positiveButtonPalette,
  onPositiveClickSuccess = null,
  showConfirmationSubtext,
  hoverTrackerAction,
  closeCTAHoverTrackerAction,
  negativeCTAHoverTrackerAction,
  positiveCTAHoverTrackerAction,
  modalProps
}: ConfirmDialogV2Config & DialogCommonProps) {
  const { processing, onErrorMessage, onPositiveButtonClick } = useConfirmDialog({
    onPositiveClick,
    onPositiveClickCallback,
    onPositiveClickSuccess,
    onClose
  });

  const dataIdGenerator = useCallback(getDataIdGenerator('confirmDialogV2'), []);

  return (
    <HdModal
      {...modalProps}
      open={!!open}
      onClose={() => onClose()}
      dialogHoverTrackingAction={hoverTrackerAction}
      styling={{ width: '478px' }}
    >
      <>
        <div className='dialog-header dialog-header--absolute' tabIndex={0}>
          <HdHoverTracker trackingAction={closeCTAHoverTrackerAction}>
            <HdIconButton onClick={() => onClose()} dataId={dataIdGenerator('close')}>
              <HdIcon name='close' />
            </HdIconButton>
          </HdHoverTracker>
        </div>

        <div className={`dialog-body ${styles.dialogBody}`}>
          <div className='center-flex-row justify-center mb-5'>
            <RoundedIcon iconName={iconName} containerBg={iconContainerClass} {...iconProps} />
          </div>

          {!hideTitle && <div className={styles.confirmationTitle}>{title}</div>}

          <div className={styles.confirmationBody}>
            {BodyComponent ? (
              <BodyComponent {...bodyComponentProps} />
            ) : (
              <span dangerouslySetInnerHTML={{ __html: Dompurify.sanitize(body) }} />
            )}
          </div>

          {!!showConfirmationSubtext && (
            <div className={styles.confirmationSubText}>
              <span className='text-bold'>{positiveButtonText}</span> if you still want to proceed
            </div>
          )}

          {onErrorMessage ? <FormError className='mt-5 mb-0'>{onErrorMessage}</FormError> : null}

          <div className='d-flex align-items-start mt-5'>
            <HdHoverTracker trackingAction={negativeCTAHoverTrackerAction}>
              <HdButton
                onClick={() => onClose(false)}
                className={clsx(negativeButtonOrder > 0 ? 'ml-3' : 'mr-3', negativeButtonClass)}
                style={{ order: negativeButtonOrder || 0 }}
                variation={negativeButtonVariation === 'solid' ? undefined : (negativeButtonVariation || 'outline')}
                dataId={dataIdGenerator('cancel')}
              >
                {negativeButtonText}
              </HdButton>
            </HdHoverTracker>

            <HdHoverTracker trackingAction={positiveCTAHoverTrackerAction}>
              <HdButton
                onClick={onPositiveButtonClick}
                className={positiveButtonClass}
                palette={positiveButtonPalette}
                disabled={processing}
                variation={positiveButtonVariation}
                showProgress={processing}
                dataId={dataIdGenerator('confirm')}
              >
                {positiveButtonText}
              </HdButton>
            </HdHoverTracker>
          </div>
        </div>
      </>
    </HdModal>
  );
}
