import React, { useEffect, useMemo, useRef, useState } from 'react';
import clsx from 'clsx';
import { FormikProps } from 'formik';
import styles from '../styles.module.scss';
import { SchedulerHeader } from '../components/SchedulerHeader';
import { SchedulerOptions } from '../SchedulerOptions/schedulerOptions';
import { HevoEntity } from '../../core/models/hevo-entity';
import { SchedulerType, SyncFrequencyPolicyProps } from '../models';
import useScheduler from '../useScheduler';
import {
  SYNC_FREQUENCY_CHIPS,
  SYNC_FREQUENCY_POLICY_MESSAGE_PREFIX
} from '../ExecutionPolicyDialog/constants';
import ExecutionPolicyMessage from '../ExecutionPolicyMessage';
import {
  ExecutionPolicyContext,
  getDefaultCronTimeList,
  getExecutionPolicyFromRawData,
  getPolicyMessage,
  getPolicyRawData
} from '../utils';
import Scheduler from '..';
import { HdButton } from '../../../components/UIElements';
import { PolicyTypeEnum } from '../ExecutionPolicyDialog/models';
import { SOURCE_TYPES } from '../../../components/Node/source-type/source-type';
import { FullLoadObjectExecutionPolicy } from '../PipelineExecutionPolicyDialog/FullLoadObjectExecutionPolicy';
import PipelinesAPI from '../../pipeline/PipelinesAPI';

export enum SyncFrequencyInitiator {
  PIPELINE_CREATE = 'Create Pipeline ',
  MIGRATE_PIPELINES = 'Migrate Pipelines',
  GENERIC = 'Generic'
}

const CTA_TITLES = {
  [SyncFrequencyInitiator.PIPELINE_CREATE]: {
    title: 'Set up Pipeline Sync Schedule',
    confirmButtonDisplayText: 'UPDATE'
  },
  [SyncFrequencyInitiator.MIGRATE_PIPELINES]: {
    title: 'Set up Pipeline Sync Schedule',
    confirmButtonDisplayText: 'UPDATE'
  },
  [SyncFrequencyInitiator.GENERIC]: {
    title: 'Change the Pipeline Sync Schedule',
    confirmButtonDisplayText: 'SCHEDULE'
  }
};

const DEFAULT_TIME = 6; // 6hrs

export function SyncFrequencyDialog({
  onClose,
  entityDetails,
  onSubmit,
  initiator = SyncFrequencyInitiator.GENERIC
}: SyncFrequencyPolicyProps) {
  const [showFullLoadFrequencyScheduler, setShowFullLoadFrequencyScheduler] = useState(false);
  const [fullLoadPolicyObj, setFullLoadPolicyObj] = useState(null);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);
  const formikRef = useRef<FormikProps<{}>>();
  const fullLoadFormikRef = useRef<FormikProps<{}>>();
  const {
    selectedOption,
    policyObj,
    setPolicyObj,
    isCustom,
    handleOptionSelection,
    tooltipData,
    setTooltipData
  } = useScheduler({
    policy: getExecutionPolicyFromRawData(
      entityDetails?.execution_policy,
      entityDetails?.sync_frequency_config,
      SYNC_FREQUENCY_CHIPS,
      SYNC_FREQUENCY_CHIPS
    ),
    entity: HevoEntity.SYNC_FREQUENCY.value,
    schedulerType: SchedulerType.MultiCronType,
    formikRef
  });

  useEffect(() => {
    const canShowFullLoadFrequencyScheduler =
      isFullLoadScheduleAllowed && policyObj.type !== PolicyTypeEnum.CRON;

    setShowFullLoadFrequencyScheduler(canShowFullLoadFrequencyScheduler);
  }, [policyObj.type]);

  useEffect(() => {
    if (policyObj.type === PolicyTypeEnum.NO_SCHEDULE) {
      setPolicyObj(prev => ({
        ...prev,
        frequencyValue: DEFAULT_TIME,
        type: PolicyTypeEnum.FREQUENCY,
        suggestedCronTimeList: getDefaultCronTimeList()
      }));
    }
  }, []);

  const policyMessage = useMemo(() => getPolicyMessage(policyObj), [policyObj]);
  const [savingSchedule, setSavingSchedule] = useState(false);

  const handleSubmit = () => {
    setSavingSchedule(true);
    if (initiator === SyncFrequencyInitiator.GENERIC) {
      apiCallForGenericFlow();
    }
    if (typeof onSubmit === 'function') {
      onSubmit(getPolicyRawData(policyObj));
    }
  };

  const apiCallForGenericFlow = () => {
    setSavingSchedule(true);
    PipelinesAPI.schedulePipeline(
      entityDetails.id,
      getPolicyRawData(policyObj),
      showFullLoadFrequencyScheduler && fullLoadPolicyObj
        ? getPolicyRawData(fullLoadPolicyObj)
        : null
    )
      .then(() => {
        onClose(true);
      })
      .finally(() => {
        setSavingSchedule(false);
      });
  };

  const CTATitles = CTA_TITLES[initiator];

  const isFullLoadScheduleAllowed =
    SOURCE_TYPES[entityDetails?.source_type]?.hasFullLoadFrequency || false;

  const onFullLoadObjectChange = policy => {
    setFullLoadPolicyObj(policy);
  };

  return (
    <div className={clsx('dialog-content', styles.dialogContent)}>
      <SchedulerHeader
        title={CTATitles.title}
        subTitle='Define the frequency or time(s) at which the Pipeline must sync the data from the Source to the Destination. The schedule applies to all the objects in the Pipeline.'
        dataIdGenerator={() => {}}
        onClose={onClose}
      />

      <div className={clsx('dialog-body', styles.dialogBody)}>
        <div className='text-body-2 mb-6'>Sync Schedule</div>
        <SchedulerOptions
          entity={HevoEntity.SYNC_FREQUENCY.value}
          options={policyObj.displayedFrequencies}
          onScheduleOptionSelected={handleOptionSelection}
          selectedOption={selectedOption}
        />
        <ExecutionPolicyContext.Provider
          value={{
            tooltipData,
            setTooltipData
          }}
        >
          <Scheduler
            schedulerType={SchedulerType.MultiCronType}
            entity={HevoEntity.SYNC_FREQUENCY.value}
            policyObj={policyObj}
            setPolicyObj={setPolicyObj}
            ref={formikRef}
            handleSubmit={handleSubmit}
            isCustom={isCustom}
          />
        </ExecutionPolicyContext.Provider>

        {policyMessage ? (
          <ExecutionPolicyMessage
            className='mb-3 mt-7'
            policyMessage={policyMessage}
            messagePrefix={SYNC_FREQUENCY_POLICY_MESSAGE_PREFIX}
          />
        ) : null}

        {showFullLoadFrequencyScheduler && (
          <FullLoadObjectExecutionPolicy
            entityDetails={entityDetails}
            fullLoadFormikRef={fullLoadFormikRef}
            policyObj={policyObj}
            disableSubmit={disable => {
              setIsSubmitDisabled(disable);
            }}
            handleSubmit={handleSubmit}
            onChange={onFullLoadObjectChange}
            dataIdGenerator={() => {}}
          />
        )}
      </div>

      <div className='dialog-footer'>
        <HdButton dataId='' variation='outline' disabled={savingSchedule} onClick={() => onClose()}>
          Cancel
        </HdButton>

        <HdButton
          dataId=''
          disabled={savingSchedule || isSubmitDisabled}
          showProgress={savingSchedule}
          type='submit'
          onClick={() => formikRef.current.submitForm()}
        >
          {CTATitles.confirmButtonDisplayText}
        </HdButton>
      </div>
    </div>
  );
}
