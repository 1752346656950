import React from 'react';
import { OWL_DAYJS_DATE_TIME_EXTENDED_FORMATS } from '../../adapter/dayjs-adapter/dayjs-date-time-format-extended.class';

import { calendarViews } from '../shared/const';
import { HdIconButton } from '../../../HdIconButton';
import HdIcon from '../../../HdIcon';
import { useDateTimeAdapter } from '../../useDateTimeAdapter';

const className = 'react-calendar__navigation';

interface NavigationProps<T> {
  activeStartDate: T;
  drillUp: React.MouseEventHandler<HTMLButtonElement>;
  maxDate: T;
  minDate: T;
  setActiveStartDate: (date: T, action: string) => void;
  view: calendarViews;
  viewsMetaData: { [key: string]: any };
}

export default function Navigation<T>({
  activeStartDate,
  drillUp,
  maxDate,
  minDate,
  setActiveStartDate,
  view,
  viewsMetaData
}: NavigationProps<T>) {
  const { getBeginNext, getBeginPrevious, getEndPrevious, dateTimeAdapter } =
    useDateTimeAdapter<T>();
  const drillUpAvailable = !!viewsMetaData[view].drillUpView;

  const previousActiveStartDate = getBeginPrevious(view, activeStartDate);

  const nextActiveStartDate = getBeginNext(view, activeStartDate);

  const prevButtonDisabled = (() => {
    if (dateTimeAdapter.getYear(previousActiveStartDate) < 0) {
      return true;
    }
    const previousActiveEndDate = getEndPrevious(view, activeStartDate);
    return minDate && minDate >= previousActiveEndDate;
  })();

  const nextButtonDisabled = maxDate && maxDate < nextActiveStartDate;

  function onClickPrevious() {
    setActiveStartDate(previousActiveStartDate, 'prev');
  }

  function onClickNext() {
    setActiveStartDate(nextActiveStartDate, 'next');
  }

  function renderLabel(date: T) {
    const label = (() => {
      switch (view) {
        case calendarViews.MULTI_YEAR:
          return dateTimeAdapter.getYearName(date);
        case calendarViews.YEAR:
          return dateTimeAdapter.getYearName(date);
        case calendarViews.MONTH:
          return dateTimeAdapter.format(date, OWL_DAYJS_DATE_TIME_EXTENDED_FORMATS.monthYearLabel);
        default:
          throw new Error(`Invalid view: ${view}.`);
      }
    })();

    return label;
  }

  function renderButton() {
    const labelClassName = `${className}__label`;
    return (
      <div className='react-calendar__control-content'>
        <button
          className='react-calendar__control-content-button'
          type='button'
          aria-label=''
          disabled={!drillUpAvailable}
          onClick={drillUp}
        >
          <span className={`${labelClassName}__labelText ${labelClassName}__labelText--from`}>
            {renderLabel(activeStartDate)}
            <HdIcon
              name='solid-dropdown'
              style={{
                transform: `rotate(${view === calendarViews.MONTH ? 0 : 180}deg)`
              }}
            />
          </span>
        </button>
      </div>
    );
  }

  return (
    <div className={className}>
      {view !== calendarViews.MULTI_YEAR && (
        <HdIconButton
          aria-label={`previous ${view}`}
          disabled={prevButtonDisabled}
          className={`${className}__arrow ${className}__prev-button`}
          onClick={() => onClickPrevious()}
          dataId='date-picker-navigation-prev'
        >
          <HdIcon name='left-arrow' />
        </HdIconButton>
      )}
      {renderButton()}
      {view !== calendarViews.MULTI_YEAR && (
        <HdIconButton
          aria-label={`next ${view}`}
          className={`${className}__arrow ${className}__next-button`}
          disabled={nextButtonDisabled}
          onClick={() => onClickNext()}
          dataId='date-picker-navigation-next'
        >
          <HdIcon name='right-arrow' />
        </HdIconButton>
      )}
    </div>
  );
}
