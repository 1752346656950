/**
 * dayjs-date-time-adapter.class
 */

import dayjs from 'dayjs';
import { DateTimeAdapter } from '../date-time-adapter.class';

export interface OwlDayjsDateTimeAdapterOptions {
  /**
   * Turns the use of utc dates on or off.
   * Changing this will change how the DateTimePicker output value.
   * {@default false}
   */
  useUtc: boolean;
}

/** Creates an array and fills it with values. */
function range<T>(length: number, valueFunction: (index: number) => T): T[] {
  const valuesArray = Array(length);
  for (let i = 0; i < length; i++) {
    valuesArray[i] = valueFunction(i);
  }
  return valuesArray;
}

export class DayjsDateTimeAdapter extends DateTimeAdapter<dayjs.Dayjs> {
  isWeekend(date: dayjs.Dayjs): boolean {
    const dayOfWeek = date.day();
    return dayOfWeek === 6 || dayOfWeek === 0; // 6 = Saturday, 0 = Sunday
  }

  private _localeData: {
    longMonths: string[];
    shortMonths: string[];
    longDaysOfWeek: string[];
    shortDaysOfWeek: string[];
    narrowDaysOfWeek: string[];
    dates: string[];
  };

  constructor(private owlDateTimeLocale: string, private options?: OwlDayjsDateTimeAdapterOptions) {
    super();
    this.setLocale(owlDateTimeLocale || dayjs.locale());
  }

  public setLocale(locale: string) {
    super.setLocale(locale);

    const dayjsLocaleData = dayjs(locale).localeData();
    this._localeData = {
      longMonths: dayjsLocaleData.months(),
      shortMonths: dayjsLocaleData.monthsShort(),
      longDaysOfWeek: dayjsLocaleData.weekdays(),
      shortDaysOfWeek: dayjsLocaleData.weekdaysShort(),
      narrowDaysOfWeek: dayjsLocaleData.weekdaysMin(),
      dates: range(31, i => this.createDate(2017, 0, i + 1).format('D'))
    };
  }

  public getYear(date: dayjs.Dayjs): number {
    return this.clone(date).year();
  }

  public getMonth(date: dayjs.Dayjs): number {
    return this.clone(date).month();
  }

  public getDay(date: dayjs.Dayjs): number {
    return this.clone(date).day();
  }

  public getDate(date: dayjs.Dayjs): number {
    return this.clone(date).date();
  }

  public getHours(date: dayjs.Dayjs): number {
    return this.clone(date).hour();
  }

  public getMinutes(date: dayjs.Dayjs): number {
    return this.clone(date).minute();
  }

  public getSeconds(date: dayjs.Dayjs): number {
    return this.clone(date).second();
  }

  public getTime(date: dayjs.Dayjs): number {
    return this.clone(date).valueOf();
  }

  public getNumDaysInMonth(date: dayjs.Dayjs): number {
    return this.clone(date).daysInMonth();
  }

  public differenceInCalendarDays(dateLeft: dayjs.Dayjs, dateRight: dayjs.Dayjs): number {
    return this.clone(dateLeft).diff(dateRight, 'days');
  }

  public getYearName(date: dayjs.Dayjs): string {
    return this.clone(date).format('YYYY');
  }

  public getMonthNames(style: 'long' | 'short' | 'narrow'): string[] {
    return style === 'long' ? this._localeData.longMonths : this._localeData.shortMonths;
  }

  public getDayOfWeekNames(style: 'long' | 'short' | 'narrow'): string[] {
    if (style === 'long') {
      return this._localeData.longDaysOfWeek;
    }
    if (style === 'short') {
      return this._localeData.shortDaysOfWeek;
    }
    return this._localeData.narrowDaysOfWeek;
  }

  public getDateNames(): string[] {
    return this._localeData.dates;
  }

  public toIso8601(date: dayjs.Dayjs): string {
    return this.clone(date).format();
  }

  public isEqual(dateLeft: dayjs.Dayjs, dateRight: dayjs.Dayjs): boolean {
    if (dateLeft && dateRight) {
      return this.clone(dateLeft).isSame(this.clone(dateRight));
    }

    return dateLeft === dateRight;
  }

  public isSameDay(dateLeft: dayjs.Dayjs, dateRight: dayjs.Dayjs): boolean {
    if (dateLeft && dateRight) {
      return this.clone(dateLeft).isSame(this.clone(dateRight), 'day');
    }

    return dateLeft === dateRight;
  }

  public isValid(date: dayjs.Dayjs): boolean {
    return this.clone(date).isValid();
  }

  public invalid(): dayjs.Dayjs {
    return dayjs(null);
  }

  public isDateInstance(obj: any): boolean {
    return dayjs.isDayjs(obj);
  }

  public addCalendarYears(date: dayjs.Dayjs, amount: number): dayjs.Dayjs {
    return this.clone(date).add(dayjs.duration({ years: amount }));
  }

  public addCalendarMonths(date: dayjs.Dayjs, amount: number): dayjs.Dayjs {
    return this.clone(date).add(dayjs.duration({ months: amount }));
  }

  public addCalendarDays(date: dayjs.Dayjs, amount: number): dayjs.Dayjs {
    return this.clone(date).add(dayjs.duration({ days: amount }));
  }

  public setHours(date: dayjs.Dayjs, amount: number): dayjs.Dayjs {
    return this.clone(date).hour(amount);
  }

  public setMinutes(date: dayjs.Dayjs, amount: number): dayjs.Dayjs {
    return this.clone(date).minute(amount);
  }

  public setSeconds(date: dayjs.Dayjs, amount: number): dayjs.Dayjs {
    return this.clone(date).second(amount);
  }

  public createDate(year: number, month: number, date: number): dayjs.Dayjs;
  public createDate(
    year: number,
    month: number,
    date: number,
    hours: number = 0,
    minutes: number = 0,
    seconds: number = 0
  ): dayjs.Dayjs {
    if (month < 0 || month > 11) {
      throw Error(`Invalid month index "${month}". Month index has to be between 0 and 11.`);
    }

    if (date < 1) {
      throw Error(`Invalid date "${date}". Date has to be greater than 0.`);
    }

    if (hours < 0 || hours > 23) {
      throw Error(`Invalid hours "${hours}". Hours has to be between 0 and 23.`);
    }

    if (minutes < 0 || minutes > 59) {
      throw Error(`Invalid minutes "${minutes}". Minutes has to between 0 and 59.`);
    }

    if (seconds < 0 || seconds > 59) {
      throw Error(`Invalid seconds "${seconds}". Seconds has to be between 0 and 59.`);
    }

    const result = this.createDayjs({ year, month, date, hours, minutes, seconds }).locale(
      this.locale
    );

    if (typeof this.utcOffset === 'string') {
      result.set({ year, month, date, hours, minutes, seconds });
    }

    // If the result isn't valid, the date must have been out of bounds for this month.
    if (!result.isValid()) {
      throw Error(`Invalid date "${date}" for month with index "${month}".`);
    }

    return result;
  }

  public clone(date: dayjs.Dayjs): dayjs.Dayjs {
    return this.cloneDayjs(date).locale(this.locale);
  }

  public now(): dayjs.Dayjs {
    return this.createDayjs().locale(this.locale);
  }

  public format(date: dayjs.Dayjs, displayFormat: any): string {
    date = this.clone(date);
    if (!this.isValid(date)) {
      throw Error('DayjsDateTimeAdapter: Cannot format invalid date.');
    }
    return date.format(displayFormat);
  }

  public parse(value: any, parseFormat: any): dayjs.Dayjs | null {
    if (value && typeof value === 'string') {
      return this.createDayjsFromString(value, parseFormat, this.locale, true);
    }
    return value ? this.createDayjs(value, parseFormat).locale(this.locale) : null;
  }

  /**
   * Returns the given value if given a valid Dayjs or null. Deserializes valid ISO 8601 strings
   * (https://www.ietf.org/rfc/rfc3339.txt) and valid Date objects into valid Dayjss and empty
   * string into null. Returns an invalid date for all other values.
   */
  deserialize(value: any): dayjs.Dayjs | null {
    let date;
    if (value instanceof Date) {
      date = this.createDayjs(value);
    }
    if (typeof value === 'string') {
      if (!value) {
        return null;
      }
      date = this.createDayjsFromString(value).locale(this.locale);
    }
    if (date && this.isValid(date)) {
      return date;
    }
    return super.deserialize(value);
  }

  /** Creates a Dayjs instance while respecting the current UTC settings. */
  private createDayjs(...args: any[]): dayjs.Dayjs {
    if (typeof this.utcOffset === 'string') {
      return dayjs.utc(...args).utcOffset(this.utcOffset);
    }

    return this.options && this.options.useUtc ? dayjs.utc(...args) : dayjs(...args);
  }

  private cloneDayjs(...args: any[]) {
    return (this.options && this.options.useUtc ? dayjs.utc(...args) : dayjs(...args)).clone();
  }

  private createDayjsFromString(...args: any[]): dayjs.Dayjs {
    if (typeof this.utcOffset === 'string') {
      const offsetMins = dayjs().utcOffset(this.utcOffset).utcOffset();
      return dayjs
        .utc(...args)
        .subtract(offsetMins, 'minutes')
        .utcOffset(this.utcOffset);
    }

    return this.options && this.options.useUtc ? dayjs.utc(...args) : dayjs(...args);
  }
}
