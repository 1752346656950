/* eslint-disable class-methods-use-this */
import { GCS_FILE_FORMATS } from './constants';
import { SourceTypeConfigHelper } from '../../../../../components/Node/source-settings-form/config-helper';

export class GcsConfigHelper implements SourceTypeConfigHelper {
  getConfigFromRawData(rawData: any) {
    return {
      bucket: rawData.gcs_bucket,
      prefix: rawData.path_prefix,
      splitByFolders: !!rawData.split_by_folders,
      fileFormat: GCS_FILE_FORMATS.find((format: any) => format.value === rawData.file_format),
      delimiter: rawData.delimiter,
      compressedFiles: rawData.process_compressed_files,
      firstRowColumnHeaders:
        'first_line_as_header' in rawData ? rawData.first_line_as_header : true,
      oauthTokenId: rawData.oauth_token_id,
      authorisedAccount: rawData.authenticated_user,
      createEventsFromChildNodes: rawData.file_format === 'XML' ? rawData.xml_depth === 1 : null,
      parseStringsAsDatetime: !!rawData.parse_strings_as_datetime
    };
  }

  getDefaultConfig() {
    const config: any = {
      prefix: '/',
      delimiter: ',',
      firstRowColumnHeaders: true,
      createEventsFromChildNodes: true,
      parseStringsAsDatetime: false
    };

    return config;
  }

  buildConnectionParams(data: any) {
    const params: any = {
      gcs_bucket: data.bucket,
      path_prefix: data.prefix || undefined,
      split_by_folders: !!data.splitByFolders,
      file_format: data.fileFormat?.value,
      process_compressed_files: data.compressedFiles,
      delimiter: data.delimiter,
      first_line_as_header: data.firstRowColumnHeaders,
      parse_strings_as_datetime: data.parseStringsAsDatetime
    };

    if (data.fileFormat?.value === 'XML') {
      params.xml_depth = data.createEventsFromChildNodes ? 1 : 0;
    }

    return params;
  }
}
