/**
 * dayjs-date-time-format.class
 */
import { OwlDateTimeFormats } from '../date-time-format.class';
import { OWL_DAYJS_DATE_TIME_FORMATS } from './dayjs-date-time-format.class';

export const OWL_DAYJS_DATE_TIME_EXTENDED_FORMATS: OwlDateTimeFormats = {
  ...OWL_DAYJS_DATE_TIME_FORMATS,
  fullPickerInput: 'DD-MM-YYYY HH:mm:ss',
  parseFullPickerInput: 'DD-MM-YYYY HH:mm:ss',
  parseDatePickerInput: 'DD-MM-YYYY',
  parseTimePickerInput: 'HH:mm:ss',
  datePickerInput: 'DD-MM-YYYY'
};
