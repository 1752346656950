import { compose, createStore, Store } from 'redux';
import rootReducer from '../../../reducers';
import { ColorMode } from '../models/color-mode';
import { User } from '../models/user';
import { LocalStorageService } from './local-storage.service';


export let store: Store;

export class ReduxService {
  static deps = [
    LocalStorageService
  ];

  constructor(
    private _localStorageService: LocalStorageService
  ) {
    const composeEnhancers =
      (window['__REDUX_DEVTOOLS_EXTENSION_COMPOSE__'] as typeof compose) || compose;
    const enhancer = composeEnhancers();
    // other store enhancers if any

    store = createStore(
      rootReducer,
      {
        global: {
          theme: this._getColorMode(),
          isLoggedIn: this._getLoggedInState(),
          userData: this._getUserData()
        }
      },
      enhancer
    );
  }

  private _getColorMode(): ColorMode {
    return this._localStorageService.get('colorMode') as ColorMode;
  }

  private _getLoggedInState(): boolean {
    return this._localStorageService.get('userData') !== null;
  }

  private _getUserData(): User {
    try {
      return JSON.parse(this._localStorageService.get('userData'));
    } catch {
      return null;
    }
  }
}
