import { EntityStatus } from '../../core/models/entity-status';
import { EntityIndependentRunStatus } from '../../core/models/hevo-entity';
import { Policy } from '../../scheduler/ExecutionPolicyDialog/models';
import { MappingRow } from '../../../components/MappingBox/model';
import { Target } from '../../activate-target/models/target';
import { SyncHistoryItem } from '../ActivationSyncHistory/models/sync-history-item';
import { TargetObjectSettings } from './target-object-settings';
import { Policy as ExecutionPolicy } from '../../scheduler/models';


export class Activation {
  id: number;
  seqId: number;
  name: string;
  status: EntityStatus;
  state: EntityStatus;
  executionPolicy: Policy | ExecutionPolicy;
  target: Target;
  lastRun?: SyncHistoryItem;
  failureMsg: string;
  query: string;
  warehouse: Warehouse;
  config: any = {};
  targetSyncConfig: TargetObjectSettings;
  draftQuery?: string;
  deleted?: boolean;
  independentRunStatus: EntityIndependentRunStatus;
}

export class Warehouse {
  id: number;
  name: string;
  typeDisplay?: string;
  warehouseType: string;
  warehouseTypeLogoUrl: string;
  warehouseTypeDarkModeLogoUrl: string;

  constructor(rawData: any) {
    this.id = rawData.id;
    this.name = rawData.name;
    this.warehouseType = rawData.type;
    this.warehouseTypeLogoUrl = rawData.type_logo_url;
    this.warehouseTypeDarkModeLogoUrl = rawData.type_dark_mode_logo_url ? rawData.type_dark_mode_logo_url : rawData.type_logo_url;

    if (rawData.type_display_name) {
      this.typeDisplay = rawData.type_display_name;
    }
  }
}

export type MappingRowWithExtraInfo = MappingRow & { [key: string]: any };
