import { HevoCustomer, HevoTestimonial } from '../containers/core/models/customers';

export function HevoCustomersFactory(rawData: any[]) {
  if (!rawData?.length) {
    return [];
  }

  return rawData.map((data) => HevoCustomerFactory(data));
}

export function HevoCustomerFactory(rawData: any): HevoCustomer {
  return {
    name: rawData.name,
    logoWhite: rawData.logo_white
  };
}

export function HevoTestimonialsFactory(rawData: any[]) {
  if (!rawData?.length) {
    return [];
  }

  return rawData.map((data) => HevoTestimonialFactory(data));
}

export function HevoTestimonialFactory(rawData: any): HevoTestimonial {
  return {
    desc: rawData.comment,
    author: rawData.name,
    company: rawData.customer.name,
  };
}
