/* eslint-disable default-param-last */
import { useEffect, useRef } from 'react';
import { of } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { EntityUIState, EntityUIStateWrapper } from '../containers/core/models/entitiy-ui-state';
import { ListActions } from '../components/pagination/list-conductor/list-actions';
import {
  ListConductor,
  ListConductorOptions
} from '../components/pagination/list-conductor/list-conductor';
import { ReactRouterService } from '../containers/core/services/react-router.service';
import useForceUpdate from './useForceUpdate';
import useService from './useService';

export default function useListConductor(
  options: ListConductorOptions = {},
  onListUIStateChanges?: (state: EntityUIStateWrapper) => void
) {
  const _router = useService(ReactRouterService);
  const forceUpdate = useForceUpdate();
  const _changes = useRef(null);

  const listConductor = useRef(
    new ListConductor(
      {
        ...options
      },
      _router
    )
  );

  useEffect(() => {
    const subscription = listConductor.current.changes$
      .pipe(
        switchMap(changes => {
          _changes.current = changes;
          forceUpdate();
          return of(null);
        })
      )
      .subscribe();
    return () => {
      subscription.unsubscribe();
    };
  }, []);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    // only subscribe when it's required i.e only when onListUIStateChanges is defined
    if (onListUIStateChanges) {
      const subscription = listConductor.current.listState$
        .pipe(
          tap(listChanges => {
            if (onListUIStateChanges) {
              onListUIStateChanges(listChanges);
            }
          })
        )
        .subscribe();
      return () => {
        subscription.unsubscribe();
      };
    }
  }, []);

  const setListState = (state: EntityUIStateWrapper) => {
    listConductor.current.setListState(state);
    forceUpdate();
  };

  const dispatchListAction = (action: ListActions) => {
    listConductor.current.dispatch(action);
    forceUpdate();
  };

  const isListConductorLoading = () => {
    const listConductorState = listConductor.current.getListState().state;
    return (
      listConductorState === EntityUIState.LOADING ||
      listConductorState === EntityUIState.LOADING_MORE ||
      listConductorState === EntityUIState.REFRESHING
    );
  };

  return {
    listConductor: listConductor.current,
    changes: _changes.current,
    setListConductorState: setListState,
    dispatchListConductorAction: dispatchListAction,
    isListConductorLoading
  };
}
