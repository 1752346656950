import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import clsx from 'clsx';
import { FormikProps } from 'formik';
import styles from '../styles.module.scss';
import { HdButton, HdDropDown, HdRadio, HdRadioGroup, HdTooltip } from '../../../components/UIElements';
import Scheduler from '../index';
import { HevoEntity } from '../../core/models/hevo-entity';
import { SchedulerOptions } from '../SchedulerOptions/schedulerOptions';
import useScheduler from '../useScheduler';
import { getPolicyMessage, getPolicyRawData } from '../utils';
import { MODEL_POLICY_MESSAGE_PREFIX } from '../ExecutionPolicyDialog/constants';
import ExecutionPolicyMessage from '../ExecutionPolicyMessage';
import { getDataIdGenerator } from '../../../utils/generateDataId';
import { SchedulerType } from '../models';
import { PolicyTypeEnum } from '../ExecutionPolicyDialog/models';
import TransformModelAPI from '../../transform-model/TransformModelAPI';
import { Model } from '../../transform-model/models';
import DestinationsAPI from '../../destination/DestinationsAPI';
import useAnalyticsTracker from '../../../hooks/useAnalyticsTracker';
import {
  SCHEDULE_MODEL_SUBMIT_CLICK,
  SCHEDULE_MODEL_SUBMIT_FAILURE,
  SCHEDULE_MODEL_SUBMIT_SUCCESS
} from '../../transform-model/constants';
import { SchedulerHeader } from '../components/SchedulerHeader';

export default function ModelExecutionPolicyDialog({
  onClose,
  entityDetails
}: { onClose: Function, entityDetails: Model }) {
  const formikRef = useRef<FormikProps<{}>>();
  const { eventTrack } = useAnalyticsTracker();
  const [savingSchedule, setSavingSchedule] = useState(false);
  const [schemaList, setSchemaList] = useState<string[]>([]);
  const [fetchingSchemaList, setFetchingSchemaList] = useState(false);
  const [isSchemaDropdownInvalid, setIsSchemaDropdownInvalid] = useState(false);

  const {
    selectedOption,
    policyObj,
    setPolicyObj,
    isCustom,
    handleOptionSelection
  } = useScheduler({
    policy: entityDetails.executionPolicy,
    entity: HevoEntity.MODEL.value,
    schedulerType: SchedulerType.CronType,
    formikRef
  });

  useEffect(() => {
    setPolicyObj((prev) => ({
      ...prev,
      type: policyObj.type === PolicyTypeEnum.NO_SCHEDULE ? PolicyTypeEnum.FREQUENCY : prev.type,
      destinationId: policyObj.type !== PolicyTypeEnum.LOAD ?
        entityDetails.destination.id : prev.destinationId
    }));
  }, []);

  const handleSubmit = () => {
    eventTrack({
      action: SCHEDULE_MODEL_SUBMIT_CLICK
    });

    if (policyObj.type === PolicyTypeEnum.LOAD && !policyObj.destTables?.length) {
      setIsSchemaDropdownInvalid(true);
      return;
    }

    setSavingSchedule(true);
    TransformModelAPI.schedule(
      entityDetails.id,
      getPolicyRawData(policyObj)
    ).then(() => {
      eventTrack({
        action: SCHEDULE_MODEL_SUBMIT_SUCCESS
      });
      onClose(true);
    }).catch(() => {
      eventTrack({
        action: SCHEDULE_MODEL_SUBMIT_FAILURE
      });
    }).finally(() => {
      setSavingSchedule(false);
    });
  };

  const fetchSchemas = () => {
    if (schemaList.length) {
      return;
    }

    setFetchingSchemaList(true);
    DestinationsAPI.getTables(policyObj.destinationId).then((res) => {
      const schemas = res.data.map((table: any) => table.name);
      setSchemaList(schemas);
    }).finally(() => setFetchingSchemaList(false));
  };

  const setSchemaDropdownValidation = () => {
    if (!policyObj.destTables?.length) {
      setIsSchemaDropdownInvalid(true);
    } else {
      setIsSchemaDropdownInvalid(false);
    }
  };

  const policyMessage = useMemo(() => getPolicyMessage(policyObj), [policyObj]);

  const dataIdGenerator = getDataIdGenerator('ModelExecutionPolicyDialog');

  const DropdownOption = useCallback(({ option }) => (
    <HdTooltip title={option} enableOnTextOverflow>
      <span className='text-medium text-ellipsis'>{ option }</span>
    </HdTooltip>
  ), []);

  return (
    <div className={clsx('dialog-content', styles.dialogContent)}>
      <SchedulerHeader
        title='Change Model Schedule'
        subTitle='Schedule this Model to run at a fixed frequency. You can also run this Model
          daily or on certain days of the week. Additionally, you can schedule it to run when
          relevant Pipelines load Events to the table.'
        dataIdGenerator={dataIdGenerator}
        onClose={onClose}
      />

      <div className={clsx('dialog-body', styles.dialogBody)}>
        <HdRadioGroup
          name='loadType'
          onChange={(e: string) => {
            setIsSchemaDropdownInvalid(false);
            setPolicyObj((prev) => ({
              ...prev,
              type: e === 'false' ? (prev.type !== PolicyTypeEnum.CRON ?
                PolicyTypeEnum.FREQUENCY : prev.type) : PolicyTypeEnum.LOAD
            }));
          }}
        >
          <div className='flex-col w-100'>
            <div className='center-flex-row justify-between'>
              <HdRadio
                dataId={dataIdGenerator(PolicyTypeEnum.LOAD)}
                value='true'
                checked={policyObj.type === PolicyTypeEnum.LOAD}
              >
                <div className='text-body-2 text-default ml-2'>
                  When relevant Pipelines load Events to the table

                  <div className='text-body-1 text-secondary mt-1'>
                    At most once every hour
                  </div>
                </div>
              </HdRadio>

              <HdDropDown
                placeholder='Select table'
                dataId={dataIdGenerator('schemas')}
                id='schema-list'
                className={styles.schemaDropdown}
                size='small'
                showLoading={fetchingSchemaList}
                options={schemaList}
                onOpen={fetchSchemas}
                selected={policyObj.destTables?.[0]}
                onChangeEventHandler={(option: string) => {
                  setIsSchemaDropdownInvalid(!option);

                  setPolicyObj((prev) => ({
                    ...prev,
                    destTables: option ? [ option ] : []
                  }));
                }}
                onBlur={setSchemaDropdownValidation}
                error={isSchemaDropdownInvalid}
                disabled={policyObj.type !== PolicyTypeEnum.LOAD}
                required
                CustomOption={DropdownOption}
              />
            </div>

            <div className='mt-8'>
              <HdRadio
                dataId={dataIdGenerator('other')}
                value='false'
                checked={policyObj.type !== PolicyTypeEnum.LOAD}
                className={clsx(
                  policyObj.type !== PolicyTypeEnum.LOAD ? 'mb-6' : null
                )}
              >
                <div className='text-body-2 text-default ml-2'>
                  Runs Every
                </div>
              </HdRadio>
            </div>

            {policyObj.type !== PolicyTypeEnum.LOAD ? (
              <>
                <SchedulerOptions
                  entity={HevoEntity.MODEL.value}
                  options={policyObj.suggestedFrequencies}
                  onScheduleOptionSelected={handleOptionSelection}
                  selectedOption={selectedOption}
                />

                <Scheduler
                  schedulerType={SchedulerType.CronType}
                  entity={HevoEntity.MODEL.value}
                  policyObj={policyObj}
                  setPolicyObj={setPolicyObj}
                  ref={formikRef}
                  handleSubmit={handleSubmit}
                  isCustom={isCustom}
                />
              </>
            ) : null}

            {policyMessage ? (
              <ExecutionPolicyMessage
                className='mb-5 mt-7'
                policyMessage={policyMessage}
                messagePrefix={MODEL_POLICY_MESSAGE_PREFIX}
              />
            ) : null}
          </div>
        </HdRadioGroup>
      </div>

      <div className='dialog-footer'>
        <HdButton
          dataId={dataIdGenerator('cancel')}
          variation='outline'
          disabled={savingSchedule}
          onClick={() => onClose()}
        >
          Cancel
        </HdButton>

        <HdButton
          dataId={dataIdGenerator('submit')}
          disabled={savingSchedule}
          showProgress={savingSchedule}
          onClick={() => formikRef.current?.submitForm() || handleSubmit()}
        >
          Schedule
        </HdButton>
      </div>
    </div>
  );
}
