import { SourceTypeConfigHelper } from '../../../../../components/Node/source-settings-form/config-helper';

export class MockLoadConfigHelper implements SourceTypeConfigHelper {
  getConfigFromRawData(rawData: any) {
    const config: any = {
      numSourceObjects: rawData.num_so,
      waitBetween: rawData.wait_between,
      maxRunTime: rawData.max_run_time,
      numFields: rawData.num_fields,
      fieldWeight: rawData.field_weight,
      typesPerSourceObject: rawData.types_per_so
    };
    return config;
  }

  getDefaultConfig() {
    const config: any = {
      numSourceObjects: 10,
      waitBetween: 1000,
      maxRunTime: 600,
      numFields: 50,
      fieldWeight: 100,
      typesPerSourceObject: 1
    };
    return config;
  }

  buildConnectionParams(data: any) {
    return {
      num_so: data.numSourceObjects,
      wait_between: data.waitBetween,
      max_run_time: data.maxRunTime,
      num_fields: data.numFields,
      field_weight: data.fieldWeight,
      types_per_so: data.typesPerSourceObject
    };
  }
}
