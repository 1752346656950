import { SourceTypeConfigHelper } from '../../../../../components/Node/source-settings-form/config-helper';

export class TwitterAdsConfigHelper implements SourceTypeConfigHelper {
  getConfigFromRawData(rawData: any) {
    return {
      apiKey: rawData.consumer_api_key,
      secretKey: rawData.consumer_api_secret_key,
      accessToken: rawData.access_token,
      accessTokenSecret: rawData.access_token_secret,
      enableCountryReports: rawData.enable_country_reports,
      accounts: (rawData.accounts || [])
        .map(selectedAccount => ({
          name: selectedAccount.account_name,
          id: selectedAccount.account_id
        }))
        .filter(item => item),
      countries: (rawData.countries || [])
        .map(country => ({
          name: country.target_name,
          targetingValue: country.target_value
        }))
        .filter(item => item)
    };
  }

  getDefaultConfig() {}

  buildConnectionParams(data: any) {
    return {
      consumer_api_key: data.apiKey,
      consumer_api_secret_key: data.secretKey,
      access_token: data.accessToken,
      access_token_secret: data.accessTokenSecret,
      enable_country_reports: !!data.enableCountryReports,
      accounts: (data.accounts || []).map(account => ({
        account_name: account.name,
        account_id: account.id
      })),
      countries: data.enableCountryReports
        ? (data.countries || []).map(country => ({
            target_name: country.name,
            target_value: country.targetingValue
          }))
        : undefined
    };
  }
}
