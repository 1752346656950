import React, { HTMLAttributes, useCallback, useContext, useEffect, useLayoutEffect, useRef, useState } from 'react';
import useResizeObserver from 'use-resize-observer';

export interface HdCoordinatedHeightAnchorProps {
  children: React.ReactNode;
  Component?: React.ElementType;
  className?: string;
  heightOffset?: number;
}

export const CoordinatedHeightAnchorContext =
  React.createContext<{ anchorEl: HTMLElement; heightOffset: number }>(null);

export const HdCoordinatedHeightAnchor = React.forwardRef<any, HdCoordinatedHeightAnchorProps & HTMLAttributes<HTMLElement>>(({
  children,
  Component = 'div',
  className = '',
  heightOffset = 0,
  ...htmlAttributes
}: HdCoordinatedHeightAnchorProps & HTMLAttributes<HTMLElement>, ref: any) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const coordinatedHeightTargetRef = useRef(null);

  useEffect(() => {
    if (coordinatedHeightTargetRef.current) {
      setAnchorEl(coordinatedHeightTargetRef.current);
    }
  }, [coordinatedHeightTargetRef.current]);

  return (
    <CoordinatedHeightAnchorContext.Provider value={{ anchorEl, heightOffset }}>
      <Component
        className={className}
        ref={(e) => {
          coordinatedHeightTargetRef.current = e;
          if (ref) {
            ref(e);
          }
        }}
        {...htmlAttributes}
      >
        {children}
      </Component>
    </CoordinatedHeightAnchorContext.Provider>
  );
});

export function HdCoordinatedHeight({ elRef, fixedHeight = false, children }) {
  const reactCoordinatedHeightAnchorContext = useContext(CoordinatedHeightAnchorContext);

  const anchorEl = reactCoordinatedHeightAnchorContext?.anchorEl;

  const heightOffset = reactCoordinatedHeightAnchorContext?.heightOffset;

  const setHeight = () => {
    const anchorHeight = anchorEl.clientHeight - heightOffset;

    const property = !fixedHeight && (elRef.offsetHeight < anchorHeight) ? 'maxHeight' : 'height';

    if (property === 'maxHeight') {
      elRef.style.height = 'auto';
    } else {
      elRef.style.maxHeight = 'none';
    }

    elRef.style[property] = `${anchorHeight}px`;
  };

  useLayoutEffect(() => {
    if (!anchorEl || !elRef) {
      return;
    }

    setHeight();
  }, [anchorEl, elRef]);

  const resizeObserver = useResizeObserver<HTMLElement>({
    ref: anchorEl,
    onResize: ({ width, height }) => {
      setHeight();
    }
  });

  return <>{children}</>;
}

export function useCoordinatedHeightElement() {
  const [ element, setElement ] = useState(null);

  const setElementRef = useCallback((el) => {
    setElement(el);
  }, []);

  return [ element, setElementRef ];
}
