/* eslint-disable camelcase */
import { S3_FILE_FORMATS } from '../S3Base/constants';
import { SourceTypeConfigHelper } from '../../../../../components/Node/source-settings-form/config-helper';
import {
  _getFolderSelectorRawDataFromInclusionState,
  _getSelectorInclusionStateFromRawData
} from '../../../../../components/FolderSelector/util';

export class AzureBlobConfigHelperReact implements SourceTypeConfigHelper {
  getDefaultConfig() {
    const config: any = {
      delimiter: ',',
      pathPrefix: ['/'],
      firstRowColumnHeaders: true,
      createEventsFromChildNodes: true,
      parseStringsAsDatetime: false
    };

    return config;
  }

  getConfigFromRawData(rawData: any) {
    return {
      connectionString: rawData.connection_string,
      containerName: rawData.container_name,
      pathPrefix: (rawData?.path_prefixes || []).map(prefix => (prefix === '' ? '/' : prefix)),
      pathPrefixSelector: rawData?.path_prefixes
        ? _getSelectorInclusionStateFromRawData(rawData.path_prefixes)
        : null,
      splitByFolders: !!rawData.split_by_folders,
      fileFormat: S3_FILE_FORMATS.map(elem => ({ ...elem, id: elem.value })).find(
        (format: any) => format.value === rawData.file_format
      ),
      delimiter: rawData.delimiter,
      compressedFiles: rawData.process_compressed_files,
      firstRowColumnHeaders:
        'first_line_as_header' in rawData ? rawData.first_line_as_header : true,
      createEventsFromChildNodes: rawData.file_format === 'XML' ? rawData.xml_depth === 1 : null,
      parseStringsAsDatetime: !!rawData.parse_strings_as_datetime
    };
  }

  buildConnectionParams(data: any) {
    let delimiter = null;
    let xml_depth;
    if (data.fileFormat?.value === 'XML') {
      xml_depth = data.createEventsFromChildNodes ? 1 : 0;
    }

    if (data.fileFormat?.value === 'CSV') {
      delimiter = data.delimiter;
    } else if (data.fileFormat?.value === 'TSV') {
      delimiter = '\\t';
    }

    return {
      path_prefixes: data.folderSelectorFailed
        ? (data.pathPrefix || []).map(prefix => (prefix === '/' ? '' : prefix))
        : _getFolderSelectorRawDataFromInclusionState(data.pathPrefixSelector)?.entities,
      connection_string: data.connectionString,
      container_name: data.containerName,
      file_format: data.fileFormat?.value,
      delimiter,
      process_compressed_files: data.compressedFiles,
      xml_depth,
      split_by_folders: !!data.splitByFolders,
      first_line_as_header: data.firstRowColumnHeaders,
      parse_strings_as_datetime: data.parseStringsAsDatetime
    };
  }
}
