import React, { useContext } from 'react';
import { ClipboardService } from '../../containers/core/services/clipboard.service';
import useService from '../useService';
import useToaster from '../useToaster';

/**
 * This is required for cases like modal, which restricts focus on elements within the modal,
 * in such case we need to render the textarea inside the modal.
 */
export const ClipboardTextareaContainer = React.createContext<HTMLElement>(null);

export default function useClipboard() {
  const defaultClipboardContainer = useContext(ClipboardTextareaContainer) || undefined;
  const toaster = useToaster();

  const clipboardService = useService(ClipboardService);

  return {
    copy: (content: string, successMessage?: string, container: HTMLElement = defaultClipboardContainer) => {
      /**
       * container is optional param which defines the parent element
       * which can hold the fake textarea element added by clipboard service.
       */
      const copyResult = clipboardService.copyFromContent(content, container);

      // call destroy only when container is supplied to make sure we remove temporary textarea if any exists.
      if (container) {
        clipboardService.destroy(container);
      }

      if (copyResult) {
        if (successMessage?.length) {
          toaster.pop('success', undefined, successMessage);
        }
      }
    },
    isSupported: clipboardService?.isSupported
  };
}
