import { Angulartics2, Angulartics2Token, ReactRouterTracking, RouterlessTracking } from '@angularticsCompat';
import { AskSupportDialogService } from '../components/Dialog/AskSupportDialog/ask-support-dialog.service';
import { DestinationConfigHelperService } from '../components/Node/destination-settings-form/destination-config-helper.service';
import { SourceConfigHelperService } from '../components/Node/source-settings-form/source-config-helper.service';
import { TourConductorService } from '../components/ProductTourV2/tour-conductor.service';
import { TourOrchestratorService } from '../components/ProductTourV2/tour-orchestrator.service';
import { TourRegisterService } from '../components/ProductTourV2/tour-register.service';
import { ToasterService } from '../components/Toaster/toaster.service';
import { TargetConfigHelperService } from './activate-target/services/target-config-helper.service';
import { TargetEditService } from './activate-target/services/target-edit.service';
import { AppConfig } from './core/app.config';
import { SupportService } from './core/models/support';
import { AccountAccessService } from './core/services/account-access.service';
import { ActivationStatusService } from './core/services/activation-status.service';
import { AuthService } from './core/services/auth.service';
import { AutoMappingService } from './core/services/auto-mapping.service';
import { BeaconService } from './core/services/beacon.service';
import { BodyInteractionService } from './core/services/body-interaction.service';
import { CalendlyService } from './core/services/calendly.service';
import { ClarityService } from './core/services/clarity.service';
import { ClearCookieService } from './core/services/clear-cookie.service';
import { ClipboardService } from './core/services/clipboard.service';
import { ColorModeService } from './core/services/color-mode.service';
import { CookieService } from './core/services/cookie.service';
import { CourierService } from './core/services/courier.service';
import { CreateActivateAngularticsService } from './core/services/create-activate-angulartics.service';
import { CreateModelAngularticsService } from './core/services/create-model-angulartics.service';
import { CreatePipelineAngularticsService } from './core/services/create-pipeline-angulartics.service';
import { CurrentPlanService } from './core/services/current-plan.service';
import { DataPrivacyService } from './core/services/data-privacy.service';
import { DbtModelService } from './core/services/dbt-model.service';
import { DestinationService } from './core/services/destination.service';
import { DocLinkHandlerService } from './core/services/doc-link-handler.service';
import { EmailVerificationService } from './core/services/email-verification.service';
import { AllowedActionsService } from './core/services/entity-actions.service';
import { GlobalBlockingLoaderService } from './core/services/global-blocking-loader.service';
import { GlobalConfigService } from './core/services/global-config.service';
import { GrowthBookService } from './core/services/growth-book.service';
import { HeadwayService } from './core/services/headway.service';
import { HubspotService } from './core/services/hubspot.service';
import { InactivityService } from './core/services/inactivity.service';
import { IntercomService } from './core/services/intercom.service';
import { LocalStorageService } from './core/services/local-storage.service';
import { MixpanelService } from './core/services/mixpanel.service';
import { ModelCountService } from './core/services/model-count.service';
import { ModelListService } from './core/services/model-list.service';
import { ModelService } from './core/services/model.service';
import { MultiRegionService } from './core/services/multi-region.service';
import { NotificationService } from './core/services/notification.service';
import { NPSService } from './core/services/nps.service';
import { PaymentService } from './core/services/payment.service';
import { PlanChatSupportService } from './core/services/plan-chat-support.service';
import { PostPCSNotifierService } from './core/services/post-pcs-notifier.service';
import { ProductTourAngularticsService } from './core/services/product-tour-angulartics.service';
import { RbacService } from './core/services/rbac.service';
import { RecentEntitiesService } from './core/services/recent-entities.service';
import { ReduxService } from './core/services/redux.service';
import { RefreshService } from './core/services/refresh.service';
import { RxRequestService } from './core/services/rx-request.service';
import { RxTaskService } from './core/services/rx-task.service';
import { SegmentService } from './core/services/segment.service';
import { SessionDataUpdateService } from './core/services/session-data-update.service';
import { SessionStorageService } from './core/services/session-storage.service';
import { SessionTrackingAngularticsService } from './core/services/session-tracking-angulartics.service';
import { SettingsStorageService } from './core/services/settings-storage.service';
import { SetupGuideCommService } from './core/services/setup-guide-comm.service';
import { SmartlookService } from './core/services/smartlook.service';
import { SourceObjectService } from './core/services/source-object.service';
import { SourceService } from './core/services/source.service';
import { SSHService } from './core/services/ssh.service';
import { TeamSettingsService } from './core/services/team-settings.service';
import { TeamService } from './core/services/team.service';
import { TrackingService } from './core/services/tracking.service';
import { TrialDetailsService } from './core/services/trial-details.service';
import { TroubleshootGuideService } from './core/services/troubleshoot-guide.service';
import { UnauthorisedEventService } from './core/services/unauthorised-event.service';
import { UsageAlertService } from './core/services/usage-alert.service';
import { UserTimezoneService } from './core/services/user-timezone.service';
import { UserService } from './core/services/user.service';
import { VersionService } from './core/services/version.service';
import { VWOService } from './core/services/vwo.service';
import { WorkflowService } from './core/services/workflow.service';
import { DestinationCommService } from './destination/services/destination-comm.service';
import { DrawerRoutingService } from './drawer/drawer-routing.service';
import { ApiQuotaWarningService } from './pipeline/services/api-quota-warning.service';
import { GlobalSearchCreateNewEntityService } from './pipeline/services/global-search-create-new-entity.service';
import { PCNASupportService } from './pipeline/services/pcna-support.service';
import { RedirectToSourceSettingService } from './pipeline/services/redirect-to-source-setting.service';
import { ShortcutsDialogHandlerReactService } from './shortcuts/service/shortcuts-dialog-handler-react.service';
import { WorkflowUpdateService } from './workflow/WorkflowPreview/services/workflow-update.service';
import {
  Angulartics2Hubspot,
  Angulartics2Intercom,
  Angulartics2Mixpanel,
  Angulartics2Segment
} from '@angularticsCompat';

export const rootProviders = [
  DestinationCommService,
  AccountAccessService,
  ActivationStatusService,
  ApiQuotaWarningService,
  AppConfig,
  AskSupportDialogService,
  AuthService,
  AutoMappingService,
  BeaconService,
  BodyInteractionService,
  CalendlyService,
  ClearCookieService,
  ColorModeService,
  CourierService,
  CreateActivateAngularticsService,
  CreateModelAngularticsService,
  CreatePipelineAngularticsService,
  CurrentPlanService,
  DataPrivacyService,
  DbtModelService,
  DestinationConfigHelperService,
  DestinationService,
  DocLinkHandlerService,
  DrawerRoutingService,
  EmailVerificationService,
  AllowedActionsService,
  ClarityService,
  GlobalBlockingLoaderService,
  GlobalConfigService,
  GlobalSearchCreateNewEntityService,
  GrowthBookService,
  HeadwayService,
  HubspotService,
  InactivityService,
  IntercomService,
  {
    provide: SupportService,
    useExisting: IntercomService
  },
  LocalStorageService,
  MixpanelService,
  ModelCountService,
  ModelListService,
  ModelService,
  MultiRegionService,
  NotificationService,
  NPSService,
  PaymentService,
  PCNASupportService,
  PlanChatSupportService,
  PostPCSNotifierService,
  ProductTourAngularticsService,
  RbacService,
  RecentEntitiesService,
  RedirectToSourceSettingService,
  ReduxService,
  RefreshService,
  RxRequestService,
  RxTaskService,
  SegmentService,
  SessionDataUpdateService,
  SessionStorageService,
  SettingsStorageService,
  SetupGuideCommService,
  ShortcutsDialogHandlerReactService,
  SmartlookService,
  SourceConfigHelperService,
  SourceObjectService,
  SourceService,
  SSHService,
  TargetConfigHelperService,
  TargetEditService,
  TeamSettingsService,
  TeamService,
  ToasterService,
  TourConductorService,
  TourOrchestratorService,
  TourRegisterService,
  TrackingService,
  TrialDetailsService,
  TroubleshootGuideService,
  UnauthorisedEventService,
  UsageAlertService,
  UserTimezoneService,
  UserService,
  VersionService,
  VWOService,
  WorkflowUpdateService,
  WorkflowService,
  CookieService,
  { provide: Angulartics2Token, useValue: { settings: {} } },
  { provide: RouterlessTracking, useClass: ReactRouterTracking },
  Angulartics2,
  Angulartics2Hubspot,
  Angulartics2Intercom,
  Angulartics2Mixpanel,
  Angulartics2Segment,
  { provide: SessionTrackingAngularticsService, useExisting: CreatePipelineAngularticsService },
  ClipboardService
];
