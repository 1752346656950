import { DirectedGraph } from '../../../components/FlowChart/flow-chart-base/directed-graph';
import { FlowChartData } from '../../../components/FlowChart/flow-chart-base/models';
import { FlowChartConnection, FlowChartNode, NodeMetaData } from '../../../components/FlowChart/flow-chart-base/node';
import { Workflow } from '../models/workflow';
import {
  WorkflowConditionStep,
  WorkflowConditionStepBooleanOperation,
  WorkflowConditionStepCondition, WorkflowConditionStepConditionOperator,
  WorkflowConditionStepOperand,
  WorkflowConditionStepOperandType,
  WorkflowEdge,
  WorkflowEntityStep,
  WorkflowStep, WorkflowStepExecutionStatus,
  WorkflowStepType
} from '../models/workflow-step';

const DBT_STEP_TYPES = ['DBT_PROJECT', 'DBT_MODEL'];

export function WorkflowStepsFactory(rawData: any[]) {
  if (!rawData || !rawData.length) {
    return [];
  }

  return rawData.map(rawStep => WorkflowStepFactory(rawStep));
}

export function WorkflowStepFactory(rawData: any) {
  if (!rawData) {
    return;
  }

  const step = rawData.type === WorkflowStepType.SWITCH_CONDITION
    ? WorkflowSwitchConditionStepFactory(rawData)
    : WorkflowEntityStepFactory(rawData);

  WorkflowStepBaseFactory(step, rawData);

  return step;
}

function WorkflowStepBaseFactory(step: WorkflowStep, rawData: any) {
  step.id = rawData.id;
  step.stopOnFailure = rawData.stop_on_failure;
  step.lastJob = rawData.last_step_job ? {
    status: rawData.last_step_job.status,
    failureMessage: rawData.last_step_job.failure_message
  } : null;
  step.vertexId = rawData.vertex_id;
  step.dependsOnVertices = (rawData.depends_on_steps || []).map(edge => ({ vertexId: edge.vertex_id, type: edge.type }));
  step.status = !step.lastJob ? WorkflowStepExecutionStatus.EDIT : WorkflowStepExecutionStatus[step.lastJob.status];
}

function WorkflowSwitchConditionStepFactory(rawData: any) {
  const step = new WorkflowConditionStep();

  step.name = rawData.name;
  step.condition = WorkflowSwitchConditionStepOperandFactory(rawData.config?.condition);
  step.hasIncompleteConfig = !step.name || !step.condition;
  step.stepType = rawData.type;

  return step;
}

function WorkflowSwitchConditionStepOperandFactory(rawData: any) {
  if (!rawData) {
    return null;
  }

  if (rawData.type === WorkflowConditionStepOperandType.CONDITION) {
    return {
      type: WorkflowConditionStepOperandType.CONDITION,
      tableName: rawData.table_name,
      destinationId: rawData.destination_id,
      rowCount: rawData.row_count,
      operator: rawData.operator === WorkflowConditionStepConditionOperator.GEQ && rawData.row_count === 0
        ? WorkflowConditionStepConditionOperator.GREATER_THAN_ZERO
        : rawData.operator
    };
  }

  return {
    type: WorkflowConditionStepOperandType.BOOL_OPERATION,
    left: WorkflowSwitchConditionStepOperandFactory(rawData.left),
    right: WorkflowSwitchConditionStepOperandFactory(rawData.right),
    operator: rawData.operator
  };
}

function WorkflowEntityStepFactory(rawData: any) {
  const step = new WorkflowEntityStep();

  step.entityId = rawData.entity_id;
  step.vertexNodeData = rawData.entity ? JSON.parse(JSON.stringify(rawData.entity)) : null;
  step.allowIndependentRuns = rawData.allow_independent_runs;
  step.hasIncompleteConfig = !step.entityId;
  step.stepType = rawData.type;

  if(DBT_STEP_TYPES.includes(step.stepType)) {
    step.stepType = WorkflowStepType.MODEL;
    step.isDbtProject = true;
    step.vertexNodeData.isDbtProject = true;

    if(rawData.type === 'DBT_MODEL') {
      step.vertexNodeData.selectedDbtModel = {id: step.vertexNodeData.id}
    }
  }

  return step;
}

export function getWorkflowStepsFromFlowChartData(
  workflow: Workflow,
  directedGraph: DirectedGraph,
  flowChartNodes: FlowChartNode[],
  connections: FlowChartConnection[]
) {
  return flowChartNodes.map((flowChartNode: FlowChartNode) => {
    const stepRef = workflow.steps.find((existingStep) => {
      return existingStep.vertexId === `vertex-${ flowChartNode.id }`;
    });

    const step = new WorkflowStep();

    step.stopOnFailure = !!flowChartNode.node.stopOnFailure;
    step.vertexId = getStepVertexIdFromFlowChartNodeId(flowChartNode.id);
    step.stepType = flowChartNode.node.stepType;

    step.dependsOnVertices = [
      ...directedGraph.vertices.get(flowChartNode.id).incomingVertices
    ].map((id) => {
      const connectionRef = connections.find((connection) => {
        return connection.start.node.id === id && connection.end.node.id === flowChartNode.id;
      });

      return {
        vertexId: getStepVertexIdFromFlowChartNodeId(id),
        type: connectionRef.data?.type || 'DEFAULT'
      };
    });

    if (step.stepType === WorkflowStepType.SWITCH_CONDITION) {
      const conditionStep = step as WorkflowConditionStep;

      conditionStep.condition = flowChartNode.node.condition;
      conditionStep.name = flowChartNode.node.name;
      conditionStep.hasIncompleteConfig = !conditionStep.name || !conditionStep.condition;
    } else {
      const entityStep = step as WorkflowEntityStep;

      entityStep.vertexNodeData = flowChartNode.node.vertexNodeData
        ? JSON.parse(JSON.stringify(flowChartNode.node.vertexNodeData))
        : null;
      entityStep.entityId = flowChartNode.node.entityId;
      entityStep.allowIndependentRuns = !!flowChartNode.node.allowIndependentRuns;
      entityStep.hasIncompleteConfig = !entityStep.entityId;
    }

    if (stepRef && stepRef.id) {
      step.id = stepRef.id;
    }

    return step;
  });
}

export function getFlowChartDataFromWorkflowSteps(steps: WorkflowStep[]): FlowChartData {
  const nodesData = new Map<number, { metaData?: NodeMetaData, node: any }>();
  const connectionsData = [];

  steps.forEach((step) => {
    nodesData.set(getFlowChartNodeIdFromStepVertexId(step.vertexId), { node: step, metaData: { draggable: true } });
    if (step.dependsOnVertices?.length) {
      step.dependsOnVertices.forEach((edge) => {
        connectionsData.push({
          start: getFlowChartNodeIdFromStepVertexId(edge.vertexId),
          end: getFlowChartNodeIdFromStepVertexId(step.vertexId),
          metaData: {
            label: edge.type
          },
          data: {
            type: edge.type
          }
        });
      });
    }
  });

  return {
    nodes: nodesData,
    connections: connectionsData
  };
}

export function getWorkflowStepsRawData(workflowSteps: WorkflowStep[]) {
  return workflowSteps.map((step: WorkflowStep) => {
    const params: any = {
      stop_on_failure: step.stopOnFailure,
      depends_on_steps: step.dependsOnVertices.map((vertex) => getWorkflowEdgeRawData(vertex)),
      step_id: step.id,
      vertex_id: step.vertexId,
      type: step.stepType
    };

    if (step.stepType === WorkflowStepType.SWITCH_CONDITION) {
      const conditionStep = step as WorkflowConditionStep;

      params.name = conditionStep.name;

      params.condition = getWorkflowConditionRawData(conditionStep.condition);

    } else {
      const entityStep = step as WorkflowEntityStep;

      params.entity_id = entityStep.vertexNodeData?.project_id || entityStep.entityId;

      params.allow_independent_runs = entityStep.allowIndependentRuns;

      if(entityStep.vertexNodeData?.isDbtProject) {
        if(entityStep.vertexNodeData.selectedDbtModel) {
          params.type = 'DBT_MODEL';
          params.entity_id = entityStep.vertexNodeData.selectedDbtModel.id;
        } else {
          params.type = 'DBT_PROJECT';
        }
      }
    }

    return params;
  });
}

function getWorkflowConditionRawData(operand: WorkflowConditionStepOperand) {
  if (!operand) {
    return null;
  }

  if (operand.type === WorkflowConditionStepOperandType.CONDITION) {
    const condition = operand as WorkflowConditionStepCondition;

    return {
      type: operand.type,
      table_name: condition.tableName,
      destination_id: condition.destinationId,
      row_count: condition.operator === WorkflowConditionStepConditionOperator.GREATER_THAN_ZERO
        ? 0
        : condition.rowCount,
      operator: condition.operator === WorkflowConditionStepConditionOperator.GREATER_THAN_ZERO
        ? WorkflowConditionStepConditionOperator.GEQ
      : condition.operator
    };
  }

  const booleanOperation = operand as WorkflowConditionStepBooleanOperation;

  return {
    type: operand.type,
    left: getWorkflowConditionRawData(booleanOperation.left),
    right: getWorkflowConditionRawData(booleanOperation.right),
    operator: booleanOperation.operator
  };
}

function getWorkflowEdgeRawData(edge: WorkflowEdge) {
  return {
    vertex_id: edge.vertexId,
    type: edge.type
  };
}

export function getStepVertexIdFromFlowChartNodeId(flowCharNodeId: number) {
  return `vertex-${ flowCharNodeId }`;
}

export function getFlowChartNodeIdFromStepVertexId(vertexId: string) {
  return parseInt(vertexId.split('-')[1], 10);
}
