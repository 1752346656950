import clsx from 'clsx';
import React, { useState } from 'react';
import { DateTimeAdapter } from '../adapter/date-time-adapter.class';
import { useDateTimeAdapter } from '../useDateTimeAdapter';
import Navigation from './Calendar/Navigation';
import DecadeView from './DecadeView';
import MonthView from './MonthView';

import { calendarViews, viewsMetaData } from './shared/const';
import { TimeView } from './TimeView';
import YearView from './YearView';

const baseClassName = 'react-calendar';

interface ClampDateParams<T> {
  minDate: T;
  maxDate: T;
  dateTimeAdapter: DateTimeAdapter<T>;
  value: T;
}

function getValue<T>(value: T, dateTimeAdapter: DateTimeAdapter<T>) {
  if (!value || !dateTimeAdapter.isValid(value)) {
    return null;
  }

  return value;
}

function clampDate<T>({ value, minDate, maxDate, dateTimeAdapter }: ClampDateParams<T>): T {
  const intermediateValue = getValue<T>(value, dateTimeAdapter);

  if (!intermediateValue) {
    return null;
  }

  return dateTimeAdapter.clampDate(intermediateValue, minDate, maxDate);
}

function getActiveStartDate<T>(props: ClampDateParams<T>) {
  const { maxDate, minDate, value, dateTimeAdapter } = props;

  return clampDate({
    value: value || dateTimeAdapter.now(),
    minDate,
    maxDate,
    dateTimeAdapter
  });
}

export default function Calendar<T>(props: CalendarProps<T>) {
  const {
    minDate,
    maxDate,
    onChange,
    showTime,
    className,
    defaultActiveStartDate,
    value: valueProps
  } = props;

  const { dateTimeAdapter } = useDateTimeAdapter<T>();
  const [state, setState] = useState<CalendarState<T>>({
    activeStartDate:
      defaultActiveStartDate ||
      getActiveStartDate<T>({ minDate, maxDate, value: valueProps, dateTimeAdapter }),
    value: valueProps,
    view: calendarViews.MONTH
  });

  const { activeStartDate, view } = state;

  const value = (() => {
    const { value: valueState } = state;

    return valueProps !== undefined ? valueProps : valueState;
  })();

  const drillDownAvailable = !!viewsMetaData[view]?.drillDownView;
  const drillUpAvailable = !!viewsMetaData[view]?.drillUpView;

  /**
   * Gets current value in a desired format.
   */
  const clampValueWithConstraints = (valueForProcesing: T) =>
    clampDate<T>({
      value: valueForProcesing,
      dateTimeAdapter,
      minDate,
      maxDate
    });

  const updateState = (nextState: any) => {
    setState(prevState => ({
      ...prevState,
      ...nextState
    }));
  };

  /**
   * Called when the user uses navigation buttons.
   */
  const setActiveStartDate = (nextActiveStartDate: T, action: string) => {
    updateState({
      activeStartDate: nextActiveStartDate
    });
  };

  const drillDown = (nextActiveStartDate: Date) => {
    if (!drillDownAvailable) {
      return;
    }

    const nextView = viewsMetaData[view].drillDownView;

    updateState({
      activeStartDate: nextActiveStartDate,
      view: nextView
    });
  };

  const drillUp = () => {
    if (!drillUpAvailable) {
      return;
    }

    const nextView = viewsMetaData[view].drillUpView as calendarViews;

    updateState({
      activeStartDate,
      view: nextView
    });
  };

  const handleOnTimeChange = (selectedValue: T) => {
    handleOnChange(selectedValue);
  };

  const handleDateChange = (selectedValue: T, event?: any) => {
    handleOnChange(selectedValue, true);
  };

  const handleOnChange = (selectedValue: T, isDateChangeEvent = false) => {
    if (showTime && isDateChangeEvent) {
      // eslint-disable-next-line no-param-reassign
      selectedValue = dateTimeAdapter.createDate(
        dateTimeAdapter.getYear(selectedValue),
        dateTimeAdapter.getMonth(selectedValue),
        dateTimeAdapter.getDate(selectedValue),
        dateTimeAdapter.getHours(value),
        dateTimeAdapter.getMinutes(value),
        dateTimeAdapter.getSeconds(value)
      );
    }

    const nextValue = clampValueWithConstraints(selectedValue);

    const nextActiveStartDate = getActiveStartDate({
      ...props,
      dateTimeAdapter,
      value: nextValue
    });

    updateState({
      activeStartDate: nextActiveStartDate,
      value: nextValue
    });

    onChange(nextValue);
  };

  const renderContent = () => {
    const onClick = drillDownAvailable ? drillDown : handleDateChange;

    const commonProps = {
      activeStartDate,
      maxDate,
      minDate,
      onClick,
      dateTimeAdapter,
      value
    };

    switch (view) {
      case calendarViews.MULTI_YEAR: {
        return <DecadeView<T> {...commonProps} />;
      }
      case calendarViews.YEAR: {
        return <YearView<T> {...commonProps} />;
      }
      case calendarViews.MONTH: {
        return <MonthView<T> {...commonProps} />;
      }
      default:
        throw new Error(`Invalid view: ${view}.`);
    }
  };

  const renderNavigation = () => (
    <Navigation
      activeStartDate={activeStartDate}
      drillUp={drillUp}
      maxDate={maxDate}
      minDate={minDate}
      setActiveStartDate={setActiveStartDate}
      view={view}
      viewsMetaData={viewsMetaData}
    />
  );

  return (
    <div className={clsx(baseClassName, className)}>
      {renderNavigation()}
      <div className={`${baseClassName}__viewContainer`}>{renderContent()}</div>

      {showTime && (
        <TimeView<T>
          value={activeStartDate}
          minDate={minDate}
          maxDate={maxDate}
          handleChange={handleOnTimeChange}
        />
      )}
    </div>
  );
}

interface CalendarProps<T> {
  className?: string | string[];
  defaultActiveStartDate?: T;
  defaultView?: string;
  value?: T;
  maxDate: T;
  showTime?: boolean;
  minDate: T;
  onChange: Function;
}

interface CalendarState<T> {
  value?: T;
  view: calendarViews;
  activeStartDate: T;
}
