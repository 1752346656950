import { createPath } from 'react-router-dom';
import { Observable, Observer } from 'rxjs';
import { startWith } from 'rxjs/operators';
import { ReactRouterService } from '../../containers/core/services/react-router.service';
import { Angulartics2Settings } from './angulartics2-config';

import { RouterlessTracking, TrackNavigationEnd } from './routerless';

/**
 * Track Route changes for applications using Angular's
 * default router
 *
 * @link https://angular.io/api/router/Router
 */
export class ReactRouterTracking implements RouterlessTracking {
  static deps = [
    ReactRouterService
  ];

  constructor(private router: ReactRouterService) {}

  trackLocation(settings: Angulartics2Settings): Observable<TrackNavigationEnd> {
    return new Observable((observer: Observer<TrackNavigationEnd>) => {
      this.router.listen(({ location }) => {
        if (!settings.developerMode) {
          observer.next({
            url: createPath(location)
          });
        }
      });
    }).pipe(
      startWith({
        url: this.router.url
      })
    );
  }

  prepareExternalUrl(url: string): string {
    return url;
  }
}
