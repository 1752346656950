import { InclusionListState } from '../../../../core/models/inclusion-list-v2';
import {
  GoogleAdsIdType,
  GoogleAdsManagersFormFieldType,
  GoogleAdsReportCategory,
  SelectedCustomer
} from './models';

export const isCustomReportCategory = category =>
  category === GoogleAdsReportCategory.CUSTOM_REPORTS;
export const isStandardReportCategory = category =>
  category === GoogleAdsReportCategory.STANDARD_REPORTS;

export const getInclusionListStateFromRawData = (
  selectedCustomers: SelectedCustomer[]
): InclusionListState => {
  const managerEntities = selectedCustomers.map(customer => {
    const childEntities = [];

    customer.included_list.forEach(id => childEntities.push({ key: id, selected: true }));
    customer.excluded_list.forEach(id => childEntities.push({ key: id, selected: false }));

    return {
      selected: customer.selected,
      key: customer.customer_id,
      ...(customer.can_manage ? { entities: childEntities } : {})
    };
  });

  return {
    selected: false,
    entities: managerEntities
  };
};

export const getSelectedCustomersFromRawData = (
  data: InclusionListState,
  managers: GoogleAdsManagersFormFieldType
): SelectedCustomer[] => {
  /**
   * When All the entities are selected via root selectors
   * */
  if (data.selected && data.entities.length === 0) {
    return managers.map(manager => CustomerFactory(manager, true));
  }

  const selectedCustomers = data.entities.map(entity => {
    const included = [];
    const excluded = [];
    let canManage = false;

    if ('entities' in entity) {
      canManage = !!(entity.entities && entity.size);

      entity.entities.forEach(item => {
        if (item.selected) {
          included.push(item.key);
        } else {
          excluded.push(item.key);
        }
      });
    }

    return {
      selected: entity.selected,
      customer_id: entity.key,
      included_list: included,
      excluded_list: excluded,
      can_manage: canManage
    };
  });

  const allSelected = !!(data.selected && data.entities.length > 0);

  managers.forEach(manager => {
    /**
     * when allSelected is true -> isManagerSelected is excluded item
     * and if allSelected is false -> isManagerSelected is included item
     * */
    const isManagerSelected = !!selectedCustomers.find(
      customer => customer.customer_id === manager.id
    );

    if (!isManagerSelected) {
      selectedCustomers.push({ ...CustomerFactory(manager, allSelected) });
    }
  });

  return selectedCustomers;
};

function CustomerFactory(manager: { id: GoogleAdsIdType; canManage: boolean }, selected = true) {
  return {
    customer_id: manager.id,
    can_manage: manager.canManage,
    selected,
    included_list: [],
    excluded_list: []
  };
}
