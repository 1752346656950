/* eslint-disable no-empty-function */
/* eslint-disable class-methods-use-this */
import { SourceTypeConfigHelper } from '../../../../../components/Node/source-settings-form/config-helper';

export class KafkaConfigHelper implements SourceTypeConfigHelper {
  constructor(public sourceTypeIdentifier: string) {}

  getDefaultConfig() {
    return {
      topicType: 'select_all'
    };
  }

  getConfigFromRawData(rawData: any) {
    const config: any = {
      hosts: rawData.hosts,
      topics: rawData.topics,
      topicRegexPattern: rawData.topic_regex_pattern,
      topicType: rawData.topic_selection_type,
      apiKey: rawData.api_key,
      apiSecret: rawData.api_secret,
      useSSL: false
    };

    if (rawData.security_protocol === 'SSL' && this.sourceTypeIdentifier === 'KAFKA') {
      config.useSSL = true;

      if ('ssl_ca' in rawData) {
        config.sslCA = { name: rawData.ssl_ca_file_name, content: rawData.ssl_ca };
      }

      if ('ssl_client_key' in rawData) {
        config.sslClientKey = {
          name: rawData.ssl_client_key_file_name,
          content: rawData.ssl_client_key
        };
      }

      if ('ssl_client_cert' in rawData) {
        config.sslClientCert = {
          name: rawData.ssl_client_cert_file_name,
          content: rawData.ssl_client_cert
        };
      }

      config.sslLoadAllCA = rawData.ssl_load_all_ca || false;
    }

    if (this.sourceTypeIdentifier === 'CONFLUENT_CLOUD' && 'ssl_ca' in rawData) {
      config.optionalSSL = true;
      config.sslCA = { name: rawData.ssl_ca_file_name, content: rawData.ssl_ca };
      config.sslLoadAllCA = rawData?.ssl_load_all_ca || false;
    }

    return config;
  }

  buildConnectionParams(data: any) {
    const params: any = {
      hosts: data.hosts,
      topics: data.topics || undefined,
      topic_regex_pattern: data.topicRegexPattern || undefined,
      topic_selection_type: data.topicType,
      api_key: data.apiKey || undefined,
      api_secret: data.apiSecret || undefined,
      security_protocol: 'PLAINTEXT'
    };

    if (data.useSSL && this.sourceTypeIdentifier === 'KAFKA') {
      params.security_protocol = 'SSL';
      params.ssl_ca = data.sslCA?.content;
      params.ssl_ca_file_name = data.sslCA?.name;
      params.ssl_client_key = data.sslClientKey?.content;
      params.ssl_client_key_file_name = data.sslClientKey?.name;
      params.ssl_client_cert = data.sslClientCert?.content;
      params.ssl_client_cert_file_name = data.sslClientCert?.name;
      params.ssl_load_all_ca = data.sslLoadAllCA
    } else if (data.optionalSSL && this.sourceTypeIdentifier === 'CONFLUENT_CLOUD') {
      params.ssl_ca = data.sslCA?.content;
      params.ssl_ca_file_name = data.sslCA?.name;
      params.ssl_load_all_ca = data.sslLoadAllCA
    }

    if (this.sourceTypeIdentifier === 'CONFLUENT_CLOUD') {
      params.security_protocol = 'SASL_SSL';
    }

    return params;
  }
}
