import { Injector } from 'injection-js';
import { NodeTypeConfigHelper, NodeTypeConfigHelperConstructor } from '../../../components/Node/node-config-helper';
import { NodeConfigHelperService } from '../../../components/Node/node-config-helper.service';
import { ViewLibrary } from '../../core/models/view-library';
import { TARGET_TYPES, TargetTypesEnum } from '../models/target-type';


export class TargetConfigHelperService extends NodeConfigHelperService {
  static deps = [
    Injector
  ];

  constructor(
    protected _injector: Injector
  ) {
    super(_injector);
  }

  getConfigHelperClass(nodeTypeIdentifier: string): NodeTypeConfigHelperConstructor {
    return TARGET_TYPES[nodeTypeIdentifier].configHelper;
  }

  getRenderingLibrary(nodeTypeIdentifier: string): string {
    return ViewLibrary.REACT;
  }

  getConfigHelperClassInstance(nodeTypeIdentifier: string): NodeTypeConfigHelper {
    const configHelperClass = this.getConfigHelperClass(nodeTypeIdentifier);

    if (nodeTypeIdentifier === TargetTypesEnum.GOOGLE_SHEETS) {
      return new configHelperClass();
    }

    return new configHelperClass();
  }
}
