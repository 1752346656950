import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useHistory } from '@useHistory/useHistoryCompat';
import { HdHoverTracker } from '../../../components/Tracking/HoverTracker';
import useService from '../../../hooks/useService';
import { ChatSupportCurrentPlan, PlanChatSupportService } from '../../core/services/plan-chat-support.service';
import useAnalyticsTracker from '../../../hooks/useAnalyticsTracker';
import { UsageAlertBannerTrackingActions } from './tracking';
import { BannerColorPalette, BannerContentColorPalette } from '../../../components/Banner/interface';
import { UsageAlertService } from '../../core/services/usage-alert.service';
import { Banner } from '../../../components/Banner';
import { HdRbacButton } from '../../../components/UIElements';
import { RbacPermissions } from '../../core/models/user';
import useUserService from '../../../hooks/services/useUserService';
import { UsageAlertData, UsageAlertPrimaryAction } from '../../payment/models/usage-status';
import { PLAN_OWNER_GUARD_MSG, USAGE_STATUS_ALERT_TYPES } from '../../payment/constants';
import { canCloseAlert, getUsageAlertActions } from '../../payment/utils/usage-alert';
import { GOTO_ON_DEMAND_CREDIT_SECTION_QUERY_PARAM } from '../../payment/OnDemandCredit/constants';

export default function UsageAlertBanner({ data, close }) {
  const { isCurrentUserOwnerWithRbac } = useUserService();
  const history = useHistory();
  const location = useLocation();
  const analyticsTracker = useAnalyticsTracker();
  const [currentPlan, setCurrentPlan] = useState<ChatSupportCurrentPlan>();
  const [availableActions, setAvailableActions] = useState<UsageAlertPrimaryAction[]>([]);
  const _planChatSupportService = useService(PlanChatSupportService);
  const usageAlertData: UsageAlertData = data.usageData;
  const _usageAlertService = useService(UsageAlertService);

  const bannerPalette =
    USAGE_STATUS_ALERT_TYPES[usageAlertData.status] === 'warn'
      ? BannerColorPalette.WARN_FADED
      : BannerColorPalette.ERROR_FADED;

  const bannerContentPalette =
    USAGE_STATUS_ALERT_TYPES[usageAlertData.status] === 'warn'
      ? BannerContentColorPalette.WARN
      : BannerContentColorPalette.ERROR;

  useEffect(() => {
    analyticsTracker.eventTrack({
      action: UsageAlertBannerTrackingActions.open,
      properties: _getCTATrackingData()
    });

    const currentPlanTierSub = _planChatSupportService.currentPlan$.subscribe(currPlan => {
      setCurrentPlan(currPlan);
      setAvailableActions(getUsageAlertActions(currPlan, usageAlertData));
    });

    return () => {
      currentPlanTierSub.unsubscribe();
    };
  }, []);

  const isActive = path => location.pathname.startsWith(path);

  const canClose = canCloseAlert(usageAlertData.status);

  const onActionClick = (action: UsageAlertPrimaryAction) => {
    switch (action) {
      case UsageAlertPrimaryAction.UPGRADE_PLAN: {
        _upgradePlanClick();
        break;
      }
      case UsageAlertPrimaryAction.REDEEM_ON_DEMAND_CREDIT: {
        _redeemOnDemandCreditClick();
        break;
      }
      case UsageAlertPrimaryAction.SET_ON_DEMAND_CREDIT: {
        _setOnDemandCreditClick();
        break;
      }
      case UsageAlertPrimaryAction.HIKE_ON_DEMAND_CREDIT: {
        _hikeOnDemandCreditClick();
        break;
      }
      default:
        break;
    }
  };

  // eslint-disable-next-line consistent-return
  const getCTAHoverTrackingAction = (action: UsageAlertPrimaryAction) => {
    switch (action) {
      case UsageAlertPrimaryAction.UPGRADE_PLAN: {
        return UsageAlertBannerTrackingActions.upgradePlanHover;
      }
      case UsageAlertPrimaryAction.REDEEM_ON_DEMAND_CREDIT: {
        return UsageAlertBannerTrackingActions.redeemOnDemandCreditHover;
      }
      case UsageAlertPrimaryAction.SET_ON_DEMAND_CREDIT: {
        return UsageAlertBannerTrackingActions.setOnDemandCreditHover;
      }
      case UsageAlertPrimaryAction.HIKE_ON_DEMAND_CREDIT: {
        return UsageAlertBannerTrackingActions.hikeOnDemandCreditHover;
      }
      default:
    }
  };

  const _getCTATrackingData = () => ({
    usageStatus: usageAlertData.status,
    hasAssignedCredit: usageAlertData.hasAssignedCredit,
    hasCreditExhausted: usageAlertData.hasCreditExhausted,
    hasReachedEarlyCreditConsumptionThreshold:
      usageAlertData.hasReachedEarlyCreditConsumptionThreshold,
    hasAssignedMaxCredit: usageAlertData.hasAssignedMaxCredit
  });

  const onCloseClick = () => {
    analyticsTracker.eventTrack({
      action: UsageAlertBannerTrackingActions.closeClick,
      properties: {
        usageStatus: usageAlertData.status
      }
    });

    _usageAlertService.storeStatusLocally();

    close();
  };

  const _upgradePlanClick = () => {
    analyticsTracker.eventTrack({
      action: UsageAlertBannerTrackingActions.upgradePlanClick,
      properties: _getCTATrackingData()
    });

    const searchParams = new URLSearchParams([['editing', 'true']]);

    history.push({
      pathname: '/payment/plan-details',
      search: `?${searchParams.toString()}`
    });
  };

  const _setOnDemandCreditClick = () => {
    analyticsTracker.eventTrack({
      action: UsageAlertBannerTrackingActions.setOnDemandCreditClick,
      properties: _getCTATrackingData()
    });

    _goToOnDemandCreditSection();
  };

  const _redeemOnDemandCreditClick = () => {
    analyticsTracker.eventTrack({
      action: UsageAlertBannerTrackingActions.redeemOnDemandCreditClick,
      properties: _getCTATrackingData()
    });

    _goToOnDemandCreditSection();
  };

  const _hikeOnDemandCreditClick = () => {
    analyticsTracker.eventTrack({
      action: UsageAlertBannerTrackingActions.hikeOnDemandCreditClick,
      properties: _getCTATrackingData()
    });

    _goToOnDemandCreditSection();
  };

  const _goToOnDemandCreditSection = () => {
    if (isActive('/payment/plan-details')) {
      _usageAlertService.goToCreditSection();
    }

    const searchParams = new URLSearchParams([[GOTO_ON_DEMAND_CREDIT_SECTION_QUERY_PARAM, 'true']]);

    history.push({
      pathname: '/payment/plan-details',
      search: `?${searchParams.toString()}`
    });
  };

  if (!currentPlan) {
    return null;
  }

  return (
    <Banner
      dataId='usage-alert-banner'
      open
      canClose={canClose}
      color={bannerPalette}
      hoverTrackingAction={UsageAlertBannerTrackingActions.hover}
      closeHoverTrackingAction={UsageAlertBannerTrackingActions.closeHover}
      onClose={onCloseClick}
      icon='warning'
      iconClass={`text-${bannerContentPalette}`}
      Title={usageAlertData.alertTitle}
      Action={
        <div className='ml-3 center-flex-row'>
          {availableActions.map((action, index) => (
            <HdHoverTracker trackingAction={getCTAHoverTrackingAction(action)} key={index}>
              <HdRbacButton
                dataId={`usage-alert-banner-action-${action}`}
                className={index === 0 ? 'ml-1' : 'ml-2'}
                disabled={!isCurrentUserOwnerWithRbac}
                disabledToolTipContent={PLAN_OWNER_GUARD_MSG}
                onMouseOver={() => getCTAHoverTrackingAction(action)}
                rbacPermission={RbacPermissions.BILLING_EDIT}
                variation={index === 0 ? 'faded' : 'outline'}
                size='sm'
                palette={bannerContentPalette}
                inlineText
                onClick={() => onActionClick(action)}
              >
                {action === UsageAlertPrimaryAction.UPGRADE_PLAN && <> Upgrade Plan </>}

                {action === UsageAlertPrimaryAction.HIKE_ON_DEMAND_CREDIT && (
                  <> Raise On-Demand Credit Limit </>
                )}

                {action === UsageAlertPrimaryAction.REDEEM_ON_DEMAND_CREDIT && (
                  <> Pay and Redeem Credit </>
                )}

                {action === UsageAlertPrimaryAction.SET_ON_DEMAND_CREDIT && (
                  <> Set On-Demand Credit Limit </>
                )}
              </HdRbacButton>
            </HdHoverTracker>
          ))}
        </div>
      }
    >
      {usageAlertData.alertMessage}
    </Banner>
  );
}
