import { getExecutionPolicyFromRawData } from '../scheduler/utils';
import { EntityIndependentRunStatusFactory } from '../../legacy-utils/entity';
import { MappingRow, MappingStatus, MappingSummary } from '../../components/MappingBox/model';
import { MappingRowFactory } from '../../components/MappingBox/utils';
import { TargetTypesEnum } from '../activate-target/models/target-type';
import { TargetFactory } from '../activate-target/utils';
import { SyncHistoryItemFactory } from './ActivationSyncHistory/factory';
import { IntercomTagTargetObjectSettings } from './IntercomFieldMapping/target-object-settings';
import { SheetsMappingRowFactory } from './GoogleSheetsActivation/utils';
import { Activation, MappingRowWithExtraInfo, Warehouse } from './models/activation';
import { ActivationStateEnum, ActivationStatusEnum } from './models/activation-status';
import { ACTIVATION_TARGET_META_DATA } from './models/activation-target-meta-data';
import { ActivationIdentifierType } from './models/identifier-type';
import { ActivationSyncBehaviour, INTERCOM_TAGS_SYNC_BEHAVIOUR_TYPES } from './models/sync-behaviour';
import { DEFAULT_CUSTOM_FIELD_TYPE, PROFILE_DEFAULT_CUSTOM_FIELD_TYPE } from './models/target-object-field-type';
import { GenericTargetObjectSettings } from './models/target-object-settings';


export function ActivationsFactory(rawData: any[]): Activation[] {
  if (!rawData || !rawData.length) {
    return [];
  }

  return rawData.map((item) => {
    return ActivationFactory(item);
  });
}

export function ActivationFactory(rawData: any): Activation {
  if (!rawData) {
    return;
  }

  const activation = new Activation();
  activation.id = rawData.id;
  activation.seqId = rawData.seq_id;
  activation.name = rawData.name;
  activation.executionPolicy = getExecutionPolicyFromRawData(rawData.execution_policy);
  activation.failureMsg = rawData.failure_message;
  activation.config = rawData.config;
  activation.target = TargetFactory(rawData.target);
  activation.warehouse = new Warehouse(rawData.warehouse);
  activation.state = ActivationStateEnum[rawData.state];
  activation.deleted = rawData.deleted;
  activation.draftQuery = rawData?.draft_config && rawData.draft_config?.query
    ? rawData.draft_config.query
    : undefined;

  if (rawData.warehouse_poll_config.mode === 'QUERY') {
    activation.query = rawData.warehouse_poll_config.query;
  } else {
    activation.query = '';
  }

  if (rawData.status === 'OK') {
    activation.status = ActivationStatusEnum[rawData.state];
  } else {
    activation.status = ActivationStatusEnum[rawData.status];
  }

  if ('last_run' in rawData) {
    activation.lastRun = SyncHistoryItemFactory(rawData.last_run);
  }

  activation.targetSyncConfig = getTargetObjectSettingsObj(activation.target.targetType, rawData);
  activation.targetSyncConfig.setConfigFromRawData(rawData.target_sync_config);
  activation.independentRunStatus = EntityIndependentRunStatusFactory(rawData.independent_run_status);

  return activation;
}

function getTargetObjectSettingsObj(targetType: TargetTypesEnum, rawData: any) {
  if (targetType === TargetTypesEnum.INTERCOM &&
    (rawData.target_sync_config.sync_mode === ActivationSyncBehaviour.ATTACH_TAG.value
      || rawData.target_sync_config.sync_mode === ActivationSyncBehaviour.DETACH_TAG.value)
  ) {
    return new IntercomTagTargetObjectSettings();
  }

  const targetObjectsSettingsRef = ACTIVATION_TARGET_META_DATA[targetType].targetObjectSettingsHelper;
  return new targetObjectsSettingsRef();
}

/*
 * Mapping data transformation utils
 * */
export function getMappingSummaryFromRawData(rawData, targetType: TargetTypesEnum) {
  const mapping = new MappingSummary();

  let mappingRowFactoryFunc = MappingRowFactory;
  if (TargetTypesEnum.GOOGLE_SHEETS === targetType) {
    mappingRowFactoryFunc = SheetsMappingRowFactory;
  }

  mapping.mappingStatus = MappingStatus[rawData.mapping_status];
  mapping.fields = rawData.field_mappings.map((object) => mappingRowFactoryFunc(object));

  return mapping;
}

export function transformMappingRowsToRawData(
  rows: MappingRowWithExtraInfo[], targetType?: TargetTypesEnum
) {
  if (!rows || 0 === rows.length) {
    return [];
  }

  return rows.map(row => transformMappingRowToRawData(row, targetType));
}

export function transformMappingRowToRawData(row: MappingRowWithExtraInfo, targetType?: TargetTypesEnum) {
  if (!row) {
    return;
  }

  const rawMappingRow: any = {
    source_field: row.source.name,
    target_field: row.destination ? row.destination.name : '',
    ignored: row.destination ? row.status === MappingStatus.SKIPPED : true
  };

  if (!row.identifierField && row.mandatoryField) {
    rawMappingRow.mandatory = row.mandatoryField;
  }

  let metaProperties = {};
  if (row.destination?.is_custom) {
    if (row.destinationCustomType) {
      metaProperties = {
        field_type: row.destinationCustomType.value
      };
    }

    if (targetType === TargetTypesEnum.KLAVIYO && !row.destinationCustomType) {
      metaProperties = {
        field_type: PROFILE_DEFAULT_CUSTOM_FIELD_TYPE
      };
    }

    if (targetType === TargetTypesEnum.CLEVERTAP) {
      metaProperties = {
        field_type: DEFAULT_CUSTOM_FIELD_TYPE
      };
    }
  }

  if (row.destination?.custom_field_type === DEFAULT_CUSTOM_FIELD_TYPE) {
    metaProperties = {
      field_type: DEFAULT_CUSTOM_FIELD_TYPE
    };
  }

  if (row.parseJsonArray) {
    metaProperties = {
      ...metaProperties,
      json_array: true
    };
  }

  rawMappingRow['meta_properties'] = Object.keys(metaProperties).length ? metaProperties : null;
  return rawMappingRow;
}

export function getUniqueIdentifiersListFromMappingData(mappingRows: MappingRow[]) {
  if (!mappingRows) {
    return [];
  }

  return mappingRows
  .filter((row) => row.destination?.name && row.identifierField)
  .map((row) => row.destination.name);
}

export function addUniqueIdentifierPropToMappingData(mappingRows: MappingRow[], uniqueIdentifierKeys: string[]) {
  if (!mappingRows || !mappingRows.length) {
    return;
  }

  return mappingRows.map((mappingRow) => addUniqueIdentifierPropToMappingRow(mappingRow, uniqueIdentifierKeys));
}

export function addUniqueIdentifierPropToMappingRow(mappingRow: MappingRow, uniqueIdentifierKeys: string[]) {
  if (!mappingRow) {
    return;
  }

  if (mappingRow.status === MappingStatus.MAPPED && mappingRow.destination?.name && uniqueIdentifierKeys) {
    const isFieldMarkedAsIdentifier = uniqueIdentifierKeys.includes(mappingRow.destination.name);

    if (isFieldMarkedAsIdentifier) {
      mappingRow.identifierField = true;
    }
  }

  return mappingRow;
}

export function isTargetObjectIntercomTagsType(syncBehaviourModes) {
  return INTERCOM_TAGS_SYNC_BEHAVIOUR_TYPES.every((syncBehaviour) => {
    return syncBehaviourModes.find((intercomSyncBehaviour) => syncBehaviour === intercomSyncBehaviour);
  });
}

export function isSyncBehaviourIntercomTagsType(targetObjectSettings: GenericTargetObjectSettings) {
  const activationSyncBehaviour = targetObjectSettings?.syncBehaviour;

  return INTERCOM_TAGS_SYNC_BEHAVIOUR_TYPES.find((syncBehaviour) =>
    syncBehaviour === activationSyncBehaviour.value);
}

export function getIdentifierInfo(identifierType: ActivationIdentifierType): {
  name: string,
  iconName: string
} {
  if (identifierType === ActivationIdentifierType.UNIQUE || identifierType === ActivationIdentifierType.COMPOSITE) {
    return {
      name: 'Unique',
      iconName: 'unique-identifier'
    };
  }

  if (identifierType === ActivationIdentifierType.MATCHING) {
    return {
      name: 'Matching',
      iconName: 'matching-identifier'
    };
  }
}

export function getIdentifierName(identifierType: ActivationIdentifierType) {
  return getIdentifierInfo(identifierType).name;
}

export function getIdentifierIcon(identifierType: ActivationIdentifierType) {
  return getIdentifierInfo(identifierType).iconName;
}
