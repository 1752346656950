import { BehaviorSubject, Subject } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';
import { EntityUIState, EntityUIStateWrapper } from '../../../containers/core/models/entitiy-ui-state';
import { ReactRouterService } from '../../../containers/core/services/react-router.service';
import { isEqual } from '../../../legacy-utils/equality';
import { FILTER_ALL } from '../../ListFilter/constants';
import { getQueryParamkey, groupPropertiesByKey } from '../utils/query-params';
import { ListActions, ListActionType } from './list-actions';
import { PaginationStrategy } from './pagination-strategy';


export interface ListConductorOptions {
  sortKeys?: string[];
  filterKeys?: string[];
  handleQueryParams?: boolean;
  queryParamPrefix?: string;
  paginationStrategy?: PaginationStrategy<any>;
  defaultListMeta?: ListMetaData;
}

export enum SortDirection {
  ASC = 'asc',
  DESC = 'desc'
}

export interface ListMetaData<T = any> {
  filters?: { [key: string]: any };
  sort?: { [key: string]: SortDirection };
  pagination?: T;
}

export interface ListChanges {
  previousValue?: ListMetaData;
  currentValue?: ListMetaData;
}

export class ListConductor {
  /**
   * Used to clear all subscription when the list conductor is disconnected by calling disconnect().
   */
  private _destroyed$ = new Subject<void>();
  /**
   * Stores meta data related to list like sort, filter and pagination data. Use this to
   * set state of widgets that control sorting, filtering and pagination. The meta data gives information
   * on the list that is required to be fetched.
   */
  private _listMeta$ = new BehaviorSubject<ListMetaData>(this._getDefaultListMeta());
  /**
   * Use this subject when you need to fetch new data.
   */
  private _changes$ = new BehaviorSubject<ListChanges>({});
  /**
   * Stores ui state of list e.g LOADING, IDLE, ERRORED etc. You can use this state to decide the content
   * to show in view. E.g if state is LOADING you can show a loading shimmer.
   */
  private _listState$ = new BehaviorSubject<EntityUIStateWrapper>({
    state: EntityUIState.NEW
  });
  private _action$ = new Subject<ListActions>();

  /**
   * Public interface for private subjects
   */
  public changes$ = this._changes$.asObservable().pipe(
    filter((changes) => !!changes.currentValue)
  );
  public listMeta$ = this._listMeta$.asObservable();
  public listState$ = this._listState$.asObservable();

  constructor(
    public _options: ListConductorOptions,
    private _router: ReactRouterService
  ) {
    if (this._options.handleQueryParams) {
      this._handleQueryParams();
    }
    this._subscribeActions();
  }

  dispatch(action: ListActions) {
    this._action$.next(action);
  }

  private _subscribeActions() {
    this._action$.pipe(
      takeUntil(this._destroyed$),
    ).subscribe((action: ListActions) => {
      this.handleAction(action);
    });
  }

  handleAction(action: ListActions) {
    let listMeta = { ...this._listMeta$.getValue() };

    switch (action.type) {
      /**
       * Called when request to get page data
       * returned error, we set list state to errored and
       * store the error
       */
      case ListActionType.ERROR:
        this._listState$.next({
          state: EntityUIState.ERRORED,
          error: action.error
        });
        return;
      /**
       * Called on page data response. Update pagination
       * data for current pagination strategy, update list meta data
       * and list state.
       *
       * if total items are zero and no filters are applied we set list state
       * to NEW else, if items count is zero set list state to EMPTY, else
       * set state to IDLE
       */
      case ListActionType.RESPONSE:
        if (typeof this._options.paginationStrategy !== 'undefined') {
          listMeta.pagination = this._options.paginationStrategy.applyAction(action.type, listMeta.pagination, action.data);
        }
        this._updateListMeta(listMeta);
        this._listState$.next({ state: this._getListStateFromResponse(action.data.count) });
        return;
      /**
       * This action resets the listMeta to default value
       * It also triggers change$ explicitly as it won't be
       * triggered if query params are handled and the params
       * didn't change after reset.
       */
      case ListActionType.RESET:
        listMeta = this._getDefaultListMeta();
        if (this._options.handleQueryParams) {
          this._setQueryParams(listMeta);
        }
        this._updateListMeta(listMeta);
        this._listState$.next({ state: EntityUIState.LOADING });
        this.onChanges(listMeta);
        return;
      /**
       * Trigger change$ explicitly as it won't be triggered
       * if query params are handled as no params are actually
       * changed in this action.
       */
      case ListActionType.GET:
        this.onChanges(listMeta);
        this._listState$.next({ state: EntityUIState.LOADING });
        return;
      case ListActionType.REFRESH:
        if (typeof this._options.paginationStrategy !== 'undefined') {
          if (action.resetPagination) {
            listMeta.pagination = this._options.paginationStrategy.getDefaultPaginationData();
          } else {
            listMeta.pagination = this._options.paginationStrategy.applyAction(action.type, listMeta.pagination);
          }
        }
        if (this._options.handleQueryParams) {
          this._setQueryParams(listMeta);
        }
        this._updateListMeta(listMeta);
        this._listState$.next({ state: EntityUIState.REFRESHING });
        this.onChanges(listMeta);
        return;
      /**
       * Update list meta data with given sort value
       */
      case ListActionType.SORT:
        if (listMeta.sort && listMeta.sort[action.data.key] === action.data.order) {
          return;
        }

        listMeta.sort = {
          ...listMeta.sort,
          [action.data.key]: action.data.order
        };
        if (typeof this._options.paginationStrategy !== 'undefined') {
          listMeta.pagination = this._options.paginationStrategy.getDefaultPaginationData(listMeta.pagination);
        }
        this._listState$.next({ state: EntityUIState.LOADING });

        break;
      /**
       * Update list meta data with given filter value
       */
      case ListActionType.FILTER:
        if (listMeta.filters && listMeta.filters[action.data.key] === action.data.value) {
          return;
        }

        if (!action.data.value) {
          listMeta.filters = {
            ...listMeta.filters
          };
          delete listMeta.filters[action.data.key];
        } else {
          listMeta.filters = {
            ...listMeta.filters,
            [action.data.key]: action.data.value
          };
        }
        if (typeof this._options.paginationStrategy !== 'undefined') {
          listMeta.pagination = this._options.paginationStrategy.getDefaultPaginationData(listMeta.pagination);
        }
        this._listState$.next({ state: EntityUIState.LOADING });

        break;
      case ListActionType.FILTER_MULTIPLE:
        if (listMeta.filters && action.data) {
          const filtersChanged = Object.entries(action.data).some(([key, value]) => {
            return listMeta.filters[key] !== value;
          });

          if (!filtersChanged) {
            return;
          }
        }

        listMeta.filters = {
          ...listMeta.filters,
          ...action.data
        };

        if (typeof this._options.paginationStrategy !== 'undefined') {
          listMeta.pagination = this._options.paginationStrategy.getDefaultPaginationData(listMeta.pagination);
        }
        this._listState$.next({ state: EntityUIState.LOADING });

        break;
      /**
       * If pagination strategy is defined get new pagination data
       * from strategy based on action and update list meta data.
       */
      case ListActionType.NEXT:
      case ListActionType.PREVIOUS:
        if (typeof this._options.paginationStrategy !== 'undefined') {
          listMeta.pagination = this._options.paginationStrategy.applyAction(action.type, listMeta.pagination);
        }
        this._listState$.next({ state: EntityUIState.LOADING_MORE });
        break;

      case ListActionType.UPDATE_PAGE_META:
      case ListActionType.UPDATE_PAGE_SIZE:
        if (typeof this._options.paginationStrategy !== 'undefined') {
          listMeta.pagination = this._options.paginationStrategy.applyAction(action.type, listMeta.pagination, action.data);
        }

        this._updateListMeta(listMeta);
        return;
    }

    /**
     * If query params are handled update query params
     * this will in turn trigger change$ if query params changed.
     * Else, set list meta data with updated value and trigger
     * change$ manually.
     */

    if (this._options.handleQueryParams) {
      this._setQueryParams(listMeta);
    } else {
      this._updateListMeta(listMeta);
      this.onChanges(listMeta);
    }
  }

  disconnect() {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  private _handleQueryParams() {
    this._router.queryParams$.pipe(
      takeUntil(this._destroyed$),
      map((params) => this._getListMetaFromQueryParams(Object.fromEntries(params))),
      filter(meta => this._didQueryParamsChange(meta))
    )
    .subscribe((meta) => {
      this._updateListMeta(meta);
      this.onChanges(this._listMeta$.getValue());
    });
  }

  private _setQueryParams(listMeta: ListMetaData) {
    const listParams = this._getQueryParamsFromListMeta(listMeta);

    const searchParams = this._router.getMergedQueryParams(listParams);

    this._router.navigate({
      pathname: this._router.pathname,
      search: `?${searchParams.toString()}`
    }, {
      replace: true
    });
  }

  private _getQueryParamsFromListMeta(listMeta) {
    const queryParamPrefix = this._options.queryParamPrefix;
    const sortKeys = this._options.sortKeys;
    const filterKeys = this._options.filterKeys;

    let params = {
      ...(filterKeys || []).reduce((dict, key) => {
        return Object.assign(
          dict,
          {
            [getQueryParamkey(queryParamPrefix, key)]: listMeta.filters[key]
          }
        );
      }, {}),
      ...(sortKeys || []).reduce((dict, key) => {
        return Object.assign(
          dict,
          {
            [getQueryParamkey(queryParamPrefix, key)]: listMeta.sort[key]
          }
        );
      }, {})
    };

    if (this._options.paginationStrategy) {
      params = {
        ...params,
        ...this._options.paginationStrategy.getQueryParamsFromData(this._options.queryParamPrefix, listMeta.pagination)
      };
    }

    return params;
  }

  getListMetaNetworkParams() {

    const listMetaData = this._listMeta$.getValue();
    let networkParams = {
      ...listMetaData.filters,
      ...listMetaData.sort
    };
    if (this._options.paginationStrategy) {
      networkParams = {
        ...networkParams,
        ...this._options.paginationStrategy.getNetworkParams(listMetaData.pagination)
      };
    }

    return networkParams;
  }

  getListMeta() {
    return this._listMeta$.getValue();
  }

  getListState() {
    return this._listState$.getValue();
  }

  setListState(state: EntityUIStateWrapper) {
    this._listState$.next(state);
  }

  isFirstPage(listMeta: ListMetaData) {
    if (!listMeta) {
      return true;
    }

    if (this._options.paginationStrategy) {
      return this._options.paginationStrategy.isFirstPage(listMeta.pagination);
    } else {
      return true;
    }
  }

  private _getListMetaFromQueryParams(params) {
    const listMeta: ListMetaData = {};
    listMeta.filters = groupPropertiesByKey(params, this._options.queryParamPrefix, this._options.filterKeys);
    listMeta.sort = groupPropertiesByKey(params, this._options.queryParamPrefix, this._options.sortKeys);
    if (this._options.paginationStrategy) {
      listMeta.pagination = this._options.paginationStrategy.getDataFromQueryParams(
        this._options.queryParamPrefix,
        params,
        this._listMeta$.getValue().pagination
      );
    }

    const pageQueryParamKey = `${ this._options.queryParamPrefix }_page`;
    const pageQueryParam = params[pageQueryParamKey];

    if (pageQueryParam && parseInt(pageQueryParam, 10) < 0) {
      const listParams = {
        ...params,
        [pageQueryParamKey]: 0
      };

      const searchParams = this._router.getMergedQueryParams(listParams);

      this._router.navigate({
        pathname: this._router.pathname,
        search: `?${searchParams.toString()}`
      }, {
        replace: true
      });
    }
    return listMeta;
  }

  private _updateListMeta(listMeta: ListMetaData) {
    this._listMeta$.next(listMeta);
  }

  private onChanges(listMeta: ListMetaData) {
    this._changes$.next({
      previousValue: this._changes$.getValue().currentValue,
      currentValue: listMeta
    });
  }

  private _didQueryParamsChange(meta: ListMetaData): boolean {
    const prevVal = this._listMeta$.getValue();
    const equal = isEqual(this._getQueryParamsFromListMeta(prevVal), this._getQueryParamsFromListMeta(meta));
    return !equal;
  }

  private _getListStateFromResponse(total: number) {
    const listMeta = this._listMeta$.getValue();

    const filtersLength = listMeta.filters ? Object.keys(listMeta.filters).filter((key: string) => {
      return listMeta.filters[key] && listMeta.filters[key] !== FILTER_ALL.value;
    }).length : 0;

    if (this._options.paginationStrategy) {
      return this._options.paginationStrategy.getListState(filtersLength, total);
    }

    if (filtersLength === 0 && total === 0) {
      return EntityUIState.NEW;
    }

    if (total === 0) {
      return EntityUIState.EMPTY;
    }

    return EntityUIState.IDLE;
  }

  private _getDefaultListMeta() {
    const meta: ListMetaData = { filters: {}, sort: {} };
    meta.pagination = this._options.paginationStrategy ? this._options.paginationStrategy.getDefaultPaginationData() : {};
    return {
      ...meta,
      ...this._options.defaultListMeta
    };
  }
}
