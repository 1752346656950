import { getBarGraphDisplayTime } from '../../../legacy-utils/bar-graph';
import { getTimeDifferenceInMinutes } from '../../../legacy-utils/date';


export interface EventsBarGraphDataPoint {
  historicalEvents: number;
  billableEvents: number;
  events: number[];
  startTime: string;
  endTime: string;
  displayString: string;
}

export interface EntityEventsBarGraphCumulativeData extends Omit<EventsBarGraphDataPoint, 'events' | 'displayString'> {
  totalEvents?: number;
}

export interface EntityEventsBarGraph {
  totalEvents: number;
  dataPoints: EventsBarGraphDataPoint[];
  cumulativeData?: EntityEventsBarGraphCumulativeData;
  displayString?: string;
}

export function EventsBarGraphFactory(rawData: any[]): any[] {
  const iconClasses: String[] = [ 'hevo-database', 'hevo-transformation', 'hevo-schema-mapper', 'hevo-destinations' ];

  return rawData.map((stage, index) => {
    let totalMinutesInGivenRange = 1;

    const points = stage.points.map((point) => {
      return EventsBarGraphDataPointFactory(point);
    });

    const throughputStartTime = points[0].startTime;
    const throughputEndTime = points[points.length - 1].endTime;

    if (throughputStartTime && throughputEndTime) {
      const minutes = Math.floor(getTimeDifferenceInMinutes(throughputStartTime, throughputEndTime));
      totalMinutesInGivenRange = minutes || 1;
    }

    let throughput = stage.total_events / totalMinutesInGivenRange;
    let useMinimumThroughput = false;
    if (throughput > 0 && throughput < 0.01) {
      throughput = 0.01;
      useMinimumThroughput = true;
    }

    const timelineString = getBarGraphDisplayTime(stage.start_time, stage.end_time);
    return {
      ...stage,
      points,
      throughput,
      useMinimumThroughput,
      timelineString,
      icon: iconClasses[index]
    };
  });
}

export function EntitiesEventsBarGraphFactory(entities: (number | string)[], rawData: any, maxPoints: number) {
  return entities.reduce((dict, key) => {
    const { pointsData, additionalData } = rawData[key];

    return {
      ...dict,
      [key]: EntityEventsBarGraphFactory(pointsData, maxPoints, additionalData)
    };
  }, {});
}

export function EntityEventsBarGraphFactory(points: any[], maxPoints: number, cumulativeData?: any): EntityEventsBarGraph {
  const timeline: EntityEventsBarGraph = {
    totalEvents: 0,
    dataPoints: [],
    cumulativeData: null
  };

  if (!points) {
    timeline.dataPoints = constructEmptyTimeline(maxPoints);
  }

  if (cumulativeData) {
    timeline.cumulativeData = EntityEventsBarGraphCumulativeDataFactory(cumulativeData);

    timeline.displayString =
      getBarGraphDisplayTime(timeline.cumulativeData.startTime, timeline.cumulativeData.endTime);
  }

  timeline['dataPoints'] = points.map((point) => {
    timeline.totalEvents += point.historical_events + point.billable_events;
    return EventsBarGraphDataPointFactory(point);
  });

  return timeline;
}

function EventsBarGraphDataPointFactory(rawDataPoint: any): EventsBarGraphDataPoint {
  let displayString: string;

  if (rawDataPoint.start_time != null && rawDataPoint.end_time != null) {
    displayString = getBarGraphDisplayTime(rawDataPoint.start_time, rawDataPoint.end_time);
  }

  return {
    startTime: rawDataPoint.start_time,
    endTime: rawDataPoint.end_time,
    historicalEvents: rawDataPoint.historical_events,
    billableEvents: rawDataPoint.billable_events,
    events: [rawDataPoint.historical_events, rawDataPoint.billable_events],
    displayString: displayString
  };
}

function constructEmptyTimeline(noOfDataPoints) {
  const dataPoints: EventsBarGraphDataPoint[] = [];

  for (let index = 0; index < noOfDataPoints; index++) {
    dataPoints.push({
      startTime: null,
      endTime: null,
      historicalEvents: 0,
      billableEvents: 0,
      events: [ 0, 0 ],
      displayString: undefined
    });
  }

  return dataPoints;
}

function EntityEventsBarGraphCumulativeDataFactory(rawData): EntityEventsBarGraphCumulativeData {
  if (!rawData) {
    return null;
  }

  return {
    startTime: rawData.start_time,
    endTime: rawData.end_time,
    historicalEvents: rawData.total_historical_events,
    billableEvents: rawData.total_billable_events,
    totalEvents: rawData.total_events
  };
}
