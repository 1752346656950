import {
  CLEVERTAP_REGIONS,
  DEFAULT_CLEVERTAP_REGION
} from './constants';
import { SourceTypeConfigHelper } from '../../../../../components/Node/source-settings-form/config-helper';

export class ClevertapConfigHelper implements SourceTypeConfigHelper {
  getConfigFromRawData(rawData: any) {
    return {
      accountId: rawData.account_id,
      passcode: rawData.passcode,
      events: rawData.events,
      eventsFile: rawData.file_name ? {
        name: rawData.file_name,
        content: `event name\n${rawData.events.replaceAll(',', '\n')}`
      } : null,
      region: CLEVERTAP_REGIONS.find(region => region.value === rawData.region) || DEFAULT_CLEVERTAP_REGION
    };
  }

  getDefaultConfig() {
    return {
      region: DEFAULT_CLEVERTAP_REGION
    };
  }

  buildConnectionParams(data: any) {
    return {
      account_id: data.accountId,
      passcode: data.passcode,
      events: this.getEvents(data),
      region: data.region?.value,
      file_name: data.eventsFile?.name
    };
  }

  getEvents(data: any) {
    const { eventsFile } = data;
    const content = eventsFile?.content ? eventsFile.content : '';

    if (content.length) {
      let contentArray = content.split('\n');
      contentArray = contentArray.filter(value => value.length);

      const indexOfEventName = contentArray[0].toLowerCase().split(',').indexOf('event name');
      if (indexOfEventName > -1) {
        const eventNames = [];

        // eslint-disable-next-line no-plusplus
        for (let index = 1; index < contentArray.length; index++) {
          eventNames.push(contentArray[index].split(',')[indexOfEventName]);
        }

        return eventNames.join(',');
      }
    }
    return undefined;
  }
}
