import { DestinationTypeConfigHelper } from '../../../../../components/Node/destination-settings-form/destination-type-config-helper';

export class DatabricksConfigHelper implements DestinationTypeConfigHelper {
  getDefaultConfig() {
    return {
      port: 443,
      schemaName: 'default'
    };
  }

  getConfigFromRawData(rawData: any) {
    const schemaNameParts = rawData.db_name.split('.');
    let catalogName = rawData.catalog_name;
    let schemaName = schemaNameParts.join('.');

    if (schemaNameParts.length > 1 && !catalogName) {
      catalogName = schemaNameParts[0];
      schemaName = schemaNameParts.slice(1).join('.');
    }

    return {
      serverHostname: rawData.server_hostname,
      port: rawData.port,
      personalAccessToken: rawData.personal_access_token,
      httpPath: rawData.http_path,
      externalLocation: rawData.external_location,
      externalLocationToggle: !!rawData.external_location,
      schemaName,
      catalogName,
      sanitizeName: rawData.sanitize_name,
      vacuumTables: rawData.vacuum,
      optimizeTables: rawData.optimize
    };
  }

  getConfigFromInitialData(rawData: any) {
    return {
      serverHostname: rawData.server_hostname,
      port: rawData.port,
      personalAccessToken: rawData.personal_access_token,
      httpPath: rawData.http_path,
      partnerConnectConnectionId: rawData.partner_connect_connection_id,
      catalogName: rawData.catalog_name,
      externalLocation: rawData.external_location,
      externalLocationToggle: !!rawData.external_location,
    };
  }

  getInitialFrozenFields() {
    return {
      serverHostname: true,
      port: true,
      personalAccessToken: true,
      httpPath: true,
      partnerConnectConnectionId: true,
      catalogName: true,
      externalLocation: true,
      externalLocationToggle: true
    };
  }

  buildConnectionParams(data: any) {
    const params: any = {
      server_hostname: data.serverHostname,
      port: data.port,
      db_name: data.schemaName,
      http_path: data.httpPath,
      personal_access_token: data.personalAccessToken,
      external_location: data.externalLocation,
      sanitize_name: data.sanitizeName,
      optimize: data.optimizeTables,
      vacuum: data.vacuumTables,
      catalog_name: data.catalogName,
      partner_connect_connection_id: data.partnerConnectConnectionId
    };

    return params;
  }
}
