import React, { useState } from 'react';
import dayjs from 'dayjs';
import { DEFAULT_CALENDAR_FORMATS } from './constants';
import { timezoneAbbr } from '../../../legacy-utils/tz-abbr';
import useInterval from '../../../hooks/useInterval';
import { getSecondsUntilUpdate } from '../../../utils/dayjsUtils';

export interface HdCalendarOptions {
  formats?: any;
  hideTimezone?: boolean;
  referenceTime?: number | Date | dayjs.Dayjs;
}

export interface HdCalendarProps {
  value: number | Date | dayjs.Dayjs;
  options?: HdCalendarOptions;
}

export default function HdCalendar({ value, options = {} }: HdCalendarProps) {
  const getDisplayTime = () => {
    const formats = options.formats || DEFAULT_CALENDAR_FORMATS;
    const { hideTimezone } = options;
    const { referenceTime } = options;

    let op = dayjs(value).calendar(referenceTime, formats);

    if (!hideTimezone) {
      let date;

      if (value instanceof Date) {
        date = value;
      } else if (dayjs.isDayjs(value)) {
        date = value.toDate();
      } else if (typeof value === 'string' || typeof value === 'number') {
        date = new Date(value);
      } else {
        return op;
      }

      op += ` (${timezoneAbbr(date)})`;
    }

    return op;
  };

  const [displayTime, setDisplayTime] = useState(getDisplayTime());

  const dayjsInstance = dayjs(value);
  const timeToUpdate = getSecondsUntilUpdate(dayjsInstance) * 1000;

  useInterval(() => {
    setDisplayTime(getDisplayTime());
  }, timeToUpdate);

  return <div>{displayTime}</div>;
}

export interface TimeFormatOptions {
  formats?: string;
}

interface HdTimeFormatProps {
  value: number | Date | dayjs.Dayjs;
  options?: TimeFormatOptions;
}

export function HdTimeFormat({ value, options = {} }: HdTimeFormatProps) {
  return <>{timeFormatter(value, options)}</>;
}

export function timeFormatter(value, options: TimeFormatOptions = {}) {
  const formats = options.formats || 'MMM DD, YYYY';
  return dayjs(value).format(formats);
}
