import { Provider } from 'injection-js';
import React from 'react';
import { useRegisterProviders } from '../../hooks/useService';
import { AngularDIContext } from './di-context';

export function AngularDiProviders({ providers, children }: { providers: Provider[]; children: any }) {
  const injectorWithProviders = useRegisterProviders(providers);

  return (
    <AngularDIContext.Provider value={injectorWithProviders}>
      {children}
    </AngularDIContext.Provider>
  );
}
