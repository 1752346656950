/* eslint-disable import/no-cycle */
import { filter, map } from 'rxjs/operators';
import { _teamService } from '../../../../core/services/team.service';
import { DestinationFormData } from '../../../../../components/Node/destination-settings-form/destination-form-data';
import { DestinationTypeConfigHelper } from '../../../../../components/Node/destination-settings-form/destination-type-config-helper';
import {
  fetchBucketsList,
  fetchDatasetList,
  getBigQueryAuthAccountInfo,
  getBucketRequestBody,
  groupResourceListOnLocationInReactFormat
} from './utils';
import DestinationsAPI from '../../../DestinationsAPI';
import { AUTH_FORM_FIELD_NAME, HEVO_GCS_BUCKET_OPTION } from './model';
import { ConnectorAuthTypeEnum } from '../../../../../components/Node/source-type/auth-type';
import { BIGQUERY_CLUSTER_REGION_MAPPING } from './constants';

export class BigQueryConfigHelperReact implements DestinationTypeConfigHelper {
  private _clusterId: string;

  private teamDetailsPromise: Promise<void>;

  constructor() {
    this.teamDetailsPromise = _teamService
      .getTeamDetails()
      .pipe(
        filter(res => !!res.data),
        map(res => res.data)
      )
      .toPromise()
      .then(data => {
        this._clusterId = data.cluster_id;
      });
  }

  getDropdownList() {
    return {};
  }

  getConfigFromRawData(rawData: any, metadata: any) {
    let bucket = metadata.buckets.find(ele => ele.id === rawData.bucket);

    if (rawData.enable_hevo_managed_gcs_bucket) {
      bucket = HEVO_GCS_BUCKET_OPTION;
    }

    let accountType;
    let id;
    if ('security_service_account_id' in rawData) {
      accountType = ConnectorAuthTypeEnum.SERVICE;
      id = rawData.security_service_account_id;
    }

    if ('oauth_token_id' in rawData) {
      accountType = ConnectorAuthTypeEnum.OAUTH;
      id = rawData.oauth_token_id;
    }

    const config: any = {
      auth: {
        type: accountType,
        id,
        email: rawData.authenticated_user
      },
      projectId: metadata.projects.find(project => project.id === rawData.project_id),
      dataset: metadata.datasets.find(dataset => dataset.id === rawData.dataset_name),
      bucket,
      sanitizeName: rawData.sanitize_name,
      streamingWrites: rawData.streaming_writes,
      loadedAt: rawData.loaded_at
    };

    return config;
  }

  getDefaultConfig() {
    return {
      sanitizeName: true
    };
  }

  buildConnectionParams(data: any) {
    const ignoreBucket = data.streamingWrites || data.bucket?.internalBucket;
    const params: any = {
      project_id: data.projectId?.id,
      dataset_name: data.datasetName || data.dataset?.id,
      bucket: ignoreBucket ? '' : data.bucketName || data.bucket?.id,
      sanitize_name: data.sanitizeName,
      preferred_location: data.dataset?.apiLocation || this._getCusterRegion(),
      loaded_at: data.loadedAt,
      streaming_writes: data.streamingWrites,
      enable_hevo_managed_gcs_bucket: data.bucket?.internalBucket,
      authenticated_user: data.authorisedAccount
    };

    if ('oauthTokenId' in data) {
      params.oauth_token_id = data.oauthTokenId;
    }

    if ('serviceAccountId' in data) {
      params.security_service_account_id = data.serviceAccountId;
    }

    if ('preferredLocation' in data) {
      params.preferred_location = data.preferredLocation;
    }

    if (!params.authenticated_user) {
      params.authenticated_user = data[AUTH_FORM_FIELD_NAME]?.email;
      if (data[AUTH_FORM_FIELD_NAME]?.type === ConnectorAuthTypeEnum.OAUTH) {
        params.oauth_token_id = data[AUTH_FORM_FIELD_NAME]?.id;
      } else {
        params.security_service_account_id = data[AUTH_FORM_FIELD_NAME]?.id;
      }
    }

    return params;
  }

  metaDataResolver(destinationType: string, rawData: any) {
    const config = rawData.id ? rawData.config : rawData;

    const { type: accountType, id: accountId } = getBigQueryAuthAccountInfo(
      config,
      AUTH_FORM_FIELD_NAME
    );

    if (!accountType && !accountId) {
      return Promise.resolve({});
    }

    const projectId: string = config.project_id || config.projectId?.id;
    const requests = [
      this.teamDetailsPromise,
      DestinationsAPI.getBigqueryProjectIDList(accountType, accountId)
    ];

    if (projectId) {
      requests.push(fetchDatasetList(accountType, accountId, projectId));
      const params = getBucketRequestBody(rawData, accountType, accountId, projectId);
      requests.push(fetchBucketsList(params));
    }

    return Promise.allSettled(requests).then(
      ([, projectsResponse, datasetsResponse, bucketsResponse]) => {
        let projects = [];
        if (projectsResponse.status === 'fulfilled') {
          projects = projectsResponse.value || [];
        }
        const buckets =
          (bucketsResponse?.status === 'fulfilled' &&
            groupResourceListOnLocationInReactFormat(bucketsResponse.value)) ||
          [];
        if (buckets.length === 0 || config.enable_hevo_managed_gcs_bucket) {
          buckets.push(HEVO_GCS_BUCKET_OPTION);
        }
        return {
          clusterRegion: this._getCusterRegion(),
          projects: (projects || []).map(x => ({ id: x.id, name: x.display_name })),
          datasets:
            (datasetsResponse?.status === 'fulfilled' &&
              groupResourceListOnLocationInReactFormat(datasetsResponse.value)) ||
            [],
          buckets
        };
      }
    );
  }

  testDestinationDependenciesResolver(formData: DestinationFormData) {
    return this._createDatasetAndBucketIfNotExisting(formData);
  }

  saveDestinationDependenciesResolver(formData: DestinationFormData) {
    return this._createDatasetAndBucketIfNotExisting(formData);
  }

  private _createDatasetAndBucketIfNotExisting(formData: DestinationFormData) {
    const createDataset =
      formData.dataset.id !== formData?.datasetName &&
      !!formData.dataset &&
      !formData.dataset.existing;

    const createBucket =
      formData.bucket?.id !== formData?.bucketName &&
      !!formData.bucket &&
      !formData.bucket.existing;

    const ignoreBucket = formData.streamingWrites || formData.bucket?.internalBucket;
    const datasetName = formData.dataset.id;
    const bucketName = !ignoreBucket ? formData.bucket.id : '';
    const projectId = formData.projectId.id;

    /*
     * Existing Dataset & Existing Bucket
     * */
    if (!createDataset && !createBucket) {
      return Promise.resolve({
        datasetName,
        bucketName
      });
    }

    const { type: accountType, id: accountId } = getBigQueryAuthAccountInfo(
      formData,
      AUTH_FORM_FIELD_NAME
    );
    let region: string;

    let params = {
      ...getBucketRequestBody(formData, accountType, accountId, projectId),
      bucket: bucketName
    };

    /*
     * New Dataset & Existing Bucket
     * */
    if (createDataset && !createBucket) {
      region = formData.bucket?.apiLocation || this._getCusterRegion();

      return DestinationsAPI.createNewDataset(
        accountType,
        accountId,
        projectId,
        datasetName,
        region
      ).then(() => ({
        datasetName,
        bucketName
      }));
    }

    /*
     * Existing Dataset & New Bucket
     * */
    if (!createDataset && createBucket) {
      if (ignoreBucket) {
        return Promise.resolve({
          datasetName,
          bucketName: ''
        });
      }
      region = formData.dataset?.apiLocation || this._getCusterRegion();
      params = {
        ...params,
        preferred_location: region
      };

      return DestinationsAPI.createNewBucket(params).then(() => ({
        datasetName,
        bucketName
      }));
    }

    /*
     * New Dataset & New Bucket
     * */
    region = this._getCusterRegion();

    const requests$ = [
      DestinationsAPI.createNewDataset(accountType, accountId, projectId, datasetName, region)
    ];

    if (ignoreBucket) {
      requests$.push(Promise.resolve(''));
    } else {
      params = {
        ...params,
        preferred_location: region
      };
      requests$.push(DestinationsAPI.createNewBucket(params).then(() => bucketName));
    }

    return Promise.all(requests$).then(([_, bucketNameResp]) => ({
      datasetName,
      bucketName: bucketNameResp
    }));
  }

  private _getCusterRegion() {
    return BIGQUERY_CLUSTER_REGION_MAPPING[this._clusterId] || 'US';
  }
}
