import { EntityStatus } from '../../core/models/entity-status';
import { Policy } from '../../scheduler/ExecutionPolicyDialog/models';
import { WorkflowLayout } from './workflow-layout';
import { WorkflowRunHistoryItem } from './workflow-run-history';
import { WorkflowStep } from './workflow-step';

export enum WorkflowVersion {
  V2 = 2
}

export class Workflow {
  name: string;
  id: any;
  seqId: any;
  history: WorkflowRunHistoryItem;
  executionPolicy: Policy;
  status: EntityStatus;
  steps: WorkflowStep[];
  layout: WorkflowLayout;
  createdTs: number;
  version: WorkflowVersion;

  getLastRunTs(): number {
    return this.history ? this.history.startedTs : undefined;
  }

  updateSteps(steps: WorkflowStep[]) {
    this.steps = steps;
  }

  updateLayout(layout: any) {
    this.layout = layout;
  }

  updateStatus(status: EntityStatus) {
    this.status = status;
  }

  canShowPlayBtn() {
    return this.executionPolicy.type !== 'NONE';
  }

  isPaused() {
    return this.executionPolicy.status === 'PAUSED';
  }
}
