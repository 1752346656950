import React from 'react';
import clsx from 'clsx';
import { OWL_DAYJS_DATE_TIME_EXTENDED_FORMATS } from '../../adapter/dayjs-adapter/dayjs-date-time-format-extended.class';
import { useDateTimeAdapter } from '../../useDateTimeAdapter';

import Tile from '../Tile';

import { TitleProps } from '../shared/interfaces';
import { calendarViews } from '../shared/const';

const className = 'react-calendar__year-view__months__month';

export default function Month<T>({ classes, point, ...otherProps }: TitleProps<T>) {
  const { dateTimeAdapter } = useDateTimeAdapter<T>();

  const monthNames = dateTimeAdapter.getMonthNames('short');

  const { date } = otherProps;

  return (
    <Tile
      point={point}
      {...otherProps}
      classes={clsx(classes, className)}
      formatAbbr={dateTimeAdapter.format(date, OWL_DAYJS_DATE_TIME_EXTENDED_FORMATS.monthYearLabel)}
      view={calendarViews.YEAR}
    >
      <>{monthNames[point]}</>
    </Tile>
  );
}
