import { BehaviorSubject, of, timer } from 'rxjs';
import { catchError, distinctUntilChanged, filter, share, startWith, switchMap, tap } from 'rxjs/operators';
import { OVERAGE_DATA_POLL_INTERVAL, OVERAGE_HIDDEN_KEY } from '../constants';
import { OverageData } from '../models/overage-data';
import { muteFirst } from '../../../legacy-utils/rxjs-observables';
import { PaymentService } from './payment.service';
import { SettingsStorageService } from './settings-storage.service';


export class OverageDataService {
  static deps = [
    PaymentService,
    SettingsStorageService
  ];

  private _overageData$ = new BehaviorSubject<OverageData>(null);
  private _needOverageData$ = new BehaviorSubject(true);

  private _mockData$ = of({
    data: {
      base_sub_amount: 100,
      base_sub_next_payment_attempt: 1584887054814,
      overage_sub_amount: 100,
      overage_sub_next_payment_attempt: 1584887054814
    }
  });

  private _overageDataEffect$ = this._needOverageData$.pipe(
    filter(require => require),
    switchMap(() => {
      return timer(0, OVERAGE_DATA_POLL_INTERVAL).pipe(
        switchMap(() => {
          return this._paymentService.getOverageDetails().pipe(
            tap((rawData: any) => {
              this._overageData$.next({
                baseSubAmount: rawData.data.base_sub_amount,
                baseSubNextPaymentAttempt: rawData.data.base_sub_next_payment_attempt,
                overageSubAmount: rawData.data.overage_sub_amount,
                overageSubNextPaymentAttempt: rawData.data.overage_sub_next_payment_attempt
              });
            }),
            catchError((err) => {
              return of(err);
            })
          );
        })
      );
    }),
    startWith(null),
    share()
  );

  public overage$ = muteFirst(
    this._overageDataEffect$,
    this._overageData$
  );

  public showOverage$ = this.overage$.pipe(
    filter((data) => !!data),
    distinctUntilChanged((a, b) => {
      return a.overageSubAmount === b.overageSubAmount;
    }),
    filter((data) => {
      return data.overageSubAmount && data.overageSubAmount > 0 && this._getOverageVisibilityState();
    })
  );

  constructor(
    private _paymentService: PaymentService,
    private _storageService: SettingsStorageService
  ) {
  }

  public hideTillNextPayment() {
    const overageData = this._overageData$.getValue();
    this._hideOverageBanner(overageData.overageSubNextPaymentAttempt);
  }

  private _getOverageVisibilityState(): boolean {
    const hiddenTill = this._storageService.getSettings(OVERAGE_HIDDEN_KEY);

    return !(hiddenTill && hiddenTill > new Date().getTime());
  }

  private _hideOverageBanner(hideTill: number) {
    return this._storageService.applySettings(OVERAGE_HIDDEN_KEY, hideTill);
  }
}
