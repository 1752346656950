/* eslint-disable import/no-cycle */
import { parse } from 'lossless-json';
import { AppConfig } from '../core/app.config';
import {
  BACKGROUND_NETWORK_REQ_OPTIONS,
  BACKGROUND_NETWORK_REQ_UI_OPTIONS,
  BACKGROUND_NETWORK_REQ_UI_OPTION_HANDLE_TIMEOUT
} from '../core/constants';
import {
  EntitiesEventsBarGraphFactory,
  EventsBarGraphFactory
} from '../core/models/events-bar-graph-data-point';
import { NetworkRequestOptions, RequestPaginationData } from '../core/models/request';
import { courier, CourierNetworkRequestOptions } from '../core/services/courier.service';
import { _sshService } from '../core/services/ssh.service';
import { groupStatsRawDataOnObjectKey } from '../../legacy-utils/events-bar-graph-data-point';
import { PageContext } from '../../components/DestinationSyncTasks/models/table';
import { SchemasFactory } from './factory.ts/utils';
import { GraphConstants } from '../../components/Graph/EventBarGraph/constants';
import { _globalConfigService } from '../core/services/global-config.service';
import { createHttpParams } from '../../legacy-utils/request';
import { DESTINATION_TYPES } from '../../components/Node/destination-type/model';
import { DraftPipelinePayload } from '../pipeline/model';
import { ManagedWarehouseTypes } from './create/SetupManagedWarehouseForm/model';
import { ConnectorAuthTypeEnum } from '../../components/Node/source-type/auth-type';
import { retainSafeNumber } from '../../utils/lossless-json-utils';
import { DESTINATION_SCHEMA_PAGE_LIMIT } from './constants';
import {
  getAutoCreateBucketName,
  getAutoCreateDatasetName
} from './create/ConfigDestinationType/BigQuery/utils';
import { DestinationTable } from './models/destinations-tables';

export const DESTINATION_STATS_TIME_INTERVAL = 1440;

const appConfig = new AppConfig();
const DestinationsAPI = {
  destinationUrl: appConfig.getDestinationsURL(),
  draftDestinationUrl: appConfig.getDraftDestinationsURL(),
  pipelinesUrl: appConfig.getIntegrationsURL(),
  managedWarehouseUrl: appConfig.getMWHURL(),
  bigQueryUrl: `${appConfig.getDestinationsURL()}/bigquery`,
  biToolUrl: `${appConfig.getMWHURL()}/visualizations`,
  statsUrl: appConfig.getStatsURL(),
  activationWarehouseUrl: appConfig.getWarehousesURL(),

  getDestinationTypes(showLoading = true) {
    const requestUrl = `${this.destinationUrl}/get-types`;

    const options: NetworkRequestOptions = {
      uiOptions: {
        showSuccessMsg: false,
        showLoading
      }
    };

    return courier.get(requestUrl, options);
  },

  getSourceTypeMetadata(sourceType: string) {
    const requestUrl = `${this.pipelinesUrl}/display-meta`;

    const options: NetworkRequestOptions = {
      uiOptions: BACKGROUND_NETWORK_REQ_UI_OPTIONS,
      networkOptions: {
        params: createHttpParams({
          source_type: sourceType
        })
      }
    };
    return courier.get(requestUrl, options);
  },

  getBigqueryProjectIDList(
    accountType: ConnectorAuthTypeEnum,
    accountId: number,
    config: any = {},
    cursor: string = '',
    projectsList = []
  ) {
    const requestUrl = `${this.bigQueryUrl}/${accountType}/${accountId}/projects`;

    const paramsOption = {
      limit: 500,
      page_cursor: cursor
    };

    const options: NetworkRequestOptions = {
      uiOptions: BACKGROUND_NETWORK_REQ_UI_OPTIONS,
      networkOptions: {
        params: createHttpParams(paramsOption)
      },
      ...config
    };

    return courier.get(requestUrl, options).then(res => {
      if (!res || !res.data) {
        return Promise.resolve(projectsList);
      }

      const newList = res.data?.projects || [];
      const updatedList = projectsList.concat(newList);

      if (res.data?.page_cursor) {
        return this.getBigqueryProjectIDList(
          accountType,
          accountId,
          config,
          res.data.page_cursor,
          updatedList
        );
      }

      return Promise.resolve(updatedList);
    });
  },

  getBigqueryDatasetListV2(
    accountType: ConnectorAuthTypeEnum,
    accountId: number,
    projectId: string,
    config: any = {},
    cursor: string = '',
    datasetList = []
  ) {
    const requestUrl = `${this.bigQueryUrl}/${accountType}/${accountId}/projects/${projectId}/datasets-info/v2`;

    const paramsOption = {
      limit: 100,
      page_cursor: cursor
    };

    const options: NetworkRequestOptions = {
      uiOptions: BACKGROUND_NETWORK_REQ_UI_OPTIONS,
      networkOptions: {
        params: createHttpParams(paramsOption)
      },
      ...config
    };

    return courier.get(requestUrl, options).then(res => {
      if (!res || !res.data) {
        return Promise.resolve(datasetList);
      }

      const newList = res.data?.datasets || [];
      const updatedList = datasetList.concat(newList);

      if (res.data?.next_page_token) {
        return this.getBigqueryDatasetListV2(
          accountType,
          accountId,
          projectId,
          config,
          res.data.next_page_token,
          updatedList
        );
      }

      return Promise.resolve(updatedList);
    });
  },

  createNewDataset(
    accountType: ConnectorAuthTypeEnum,
    accountId: number,
    projectId: string,
    datasetName: string,
    location: string
  ) {
    const requestUrl = `${this.bigQueryUrl}/${accountType}/${accountId}/projects/${projectId}/dataset`;

    const options = {
      ...BACKGROUND_NETWORK_REQ_OPTIONS,
      networkOptions: {
        params: createHttpParams({
          dataset_name: datasetName,
          preferred_location: location
        })
      }
    };

    return courier.post(requestUrl, options);
  },

  createNewBucket(params: any) {
    const requestUrl = `${this.bigQueryUrl}/project/bucket`;

    const body = {
      config: params
    };

    return courier.post(requestUrl, BACKGROUND_NETWORK_REQ_OPTIONS, body);
  },

  getBigqueryDatasetIDList(
    accountType: ConnectorAuthTypeEnum,
    accountId: number,
    projectId: string,
    config: any = {}
  ) {
    const requestUrl = `${this.bigQueryUrl}/${accountType}/${accountId}/projects/${projectId}/datasets-info`;
    const options = {
      ...BACKGROUND_NETWORK_REQ_OPTIONS,
      ...config
    };

    return courier.get(requestUrl, options);
  },

  getBigqueryGCSBucketList(params: any, config: any = {}) {
    const requestUrl = `${this.bigQueryUrl}/project/buckets`;

    const body = {
      config: params
    };

    const options = {
      ...BACKGROUND_NETWORK_REQ_OPTIONS,
      ...config
    };

    return courier.post(requestUrl, options, body);
  },

  getBigqueryCreateDatasetPermission(
    accountType: ConnectorAuthTypeEnum,
    accountId: number,
    projectId: string = '',
    config: any = {}
  ) {
    const requestUrl = `${this.bigQueryUrl}/${accountType}/${accountId}/projects/${projectId}/datasets/test`;

    const options = {
      ...BACKGROUND_NETWORK_REQ_OPTIONS,
      networkOptions: {
        params: createHttpParams({
          dataset_name: `${getAutoCreateDatasetName(projectId)}__123`,
          preferred_location: 'US'
        })
      },
      ...config
    };

    return courier.get(requestUrl, options);
  },

  getBigqueryCreateBucketPermission(params: any, config = {}) {
    const requestUrl = `${this.bigQueryUrl}/project/buckets/test`;

    const body = {
      config: {
        ...params,
        bucket: `${getAutoCreateBucketName(params.project_id).substr(0, 58)}__123`,
        preferred_location: 'US'
      }
    };

    const options = {
      ...BACKGROUND_NETWORK_REQ_OPTIONS,
      ...config
    };

    return courier.post(requestUrl, options, body);
  },

  validateDestinationName(destinationName: string, config = {}) {
    const requestUrl = `${this.destinationUrl}/pre-validation`;

    const options: NetworkRequestOptions = {
      uiOptions: BACKGROUND_NETWORK_REQ_UI_OPTIONS,
      ...config
    };

    const params: any = {
      destination_name: destinationName
    };

    return courier.put(requestUrl, options, params);
  },

  getExistingDestinations() {
    const requestUrl = `${this.destinationUrl}`;

    const options: NetworkRequestOptions = {
      uiOptions: {
        showSuccessMsg: false,
        showLoading: false
      },
      networkOptions: {
        params: createHttpParams({
          'display-details': true
        })
      }
    };
    return courier.get(requestUrl, options);
  },

  getSampleDestinations() {
    const requestUrl = `${this.destinationUrl}/samples`;

    const options: NetworkRequestOptions = {
      uiOptions: {
        showLoading: false
      }
    };
    return courier.get(requestUrl, options);
  },

  getFireboltDatabasesAndEngines(username: string, password: string, destId?: number) {
    const requestUrl = `${this.destinationUrl}/firebolt/engines-and-dbs`;

    const params = {
      username,
      password,
      destination_id: destId
    };

    return courier.post(requestUrl, BACKGROUND_NETWORK_REQ_OPTIONS, params);
  },

  getFireboltDefaultEngine(
    username: string,
    password: string,
    databaseName: string,
    destId?: number
  ) {
    const requestUrl = `${this.destinationUrl}/firebolt/engines/default-by-db`;

    const params = {
      username,
      password,
      db_name: databaseName,
      destination_id: destId
    };

    return courier.post(requestUrl, BACKGROUND_NETWORK_REQ_OPTIONS, params);
  },

  getBiToolCredentials(visualizationType: string) {
    const requestUrl = `${this.biToolUrl}/configurations`;

    const options: NetworkRequestOptions = {
      uiOptions: BACKGROUND_NETWORK_REQ_UI_OPTIONS,
      networkOptions: {
        params: createHttpParams({
          visualizer_type: visualizationType,
          warehouse_type: ManagedWarehouseTypes.BIGQUERY
        })
      }
    };

    return courier.get(requestUrl, options);
  },

  createManagedWarehouse(
    name: string,
    managedWarehouseType: ManagedWarehouseTypes,
    managedWarehouseDestinationType: string
  ) {
    return this.configureManagedWarehouse(managedWarehouseType).then(({ data }) =>
      this.addManagedWarehouse(
        {
          ...data,
          name
        },
        managedWarehouseDestinationType
      )
    );
  },

  configureManagedWarehouse(managedWarehouseType: ManagedWarehouseTypes) {
    const requestUrl = `${this.managedWarehouseUrl}/${managedWarehouseType}/configurations`;
    return courier.post(requestUrl, BACKGROUND_NETWORK_REQ_OPTIONS);
  },

  addManagedWarehouse(data: any, managedWarehouseDestinationType: string) {
    const requestUrl = `${this.destinationUrl}/${managedWarehouseDestinationType}`;

    const connectionParams = new DESTINATION_TYPES[
      managedWarehouseDestinationType
    ].configHelper().buildConnectionParams(data);
    const params = {
      name: data.name,
      config: connectionParams
    };

    return courier.post(requestUrl, BACKGROUND_NETWORK_REQ_OPTIONS, params);
  },

  getBIToolTypes() {
    const requestUrl = `${this.biToolUrl}/types`;

    return courier.get(requestUrl, BACKGROUND_NETWORK_REQ_OPTIONS);
  },

  validateDestinationTablePrefix(
    destinationType: string,
    destinationTablePrefix: string,
    networkOptions
  ) {
    const requestUrl = `${this.pipelinesUrl}/${destinationType}/validate-destination-prefix`;

    const options: NetworkRequestOptions = {
      uiOptions: BACKGROUND_NETWORK_REQ_UI_OPTIONS,
      networkOptions: {
        params: createHttpParams({
          destination_prefix: destinationTablePrefix
        })
      },
      ...networkOptions
    };

    return courier.get(requestUrl, options);
  },

  validateDestinationDirectoryStructure(
    destinationDirectory: string,
    sourceType: string,
    networkOptions: NetworkRequestOptions
  ) {
    const requestUrl = `${this.destinationUrl}/s3/validate-prefix`;

    const options: NetworkRequestOptions = {
      uiOptions: BACKGROUND_NETWORK_REQ_UI_OPTIONS,
      networkOptions: {
        params: createHttpParams({})
      },
      ...networkOptions
    };

    return courier.post(requestUrl, options, {
      prefix: destinationDirectory,
      sourceType
    });
  },

  getAllDestinationTables(
    searchTerm: string,
    cursor: string,
    abortSignal: AbortSignal
  ): Promise<{
    cursor: string;
    destinationsTables: DestinationTable[];
  }> {
    const requestUrl = `${this.destinationUrl}/schemas`;

    return courier
      .get(requestUrl, {
        uiOptions: BACKGROUND_NETWORK_REQ_UI_OPTIONS,
        networkOptions: {
          params: createHttpParams({
            search_name: searchTerm,
            starting_after: cursor,
            limit: 100
          }),
          signal: abortSignal
        }
      })
      .then(res => ({
        cursor: res.data.starting_after,
        destinationsTables: res.data.schemas.map(schema => {
          const destination = res.data.destinations[schema.destination_id];

          return {
            destinationId: schema.destination_id,
            tableName: schema.name,
            destinationLogo: destination.dest_type_logo_url,
            darkModeDestinationLogo: destination.dest_type_dark_mode_logo_url,
            destinationName: destination.name
          };
        })
      }));
  },

  getSnowflakeWarehouses(formData, config = {}) {
    const requestUrl = `${this.destinationUrl}/snowflake/warehouses`;

    const params = {
      db_password: formData.password,
      db_user: formData.user,
      region_id: formData.region,
      isPrivateLink: formData.isPrivateLink,
      account_name: formData.accountName
    };

    const options: NetworkRequestOptions = {
      ...BACKGROUND_NETWORK_REQ_OPTIONS,
      ...config
    };

    return courier.post(requestUrl, options, params);
  },

  getSnowflakeDatabases(formData, config = {}) {
    const requestUrl = `${this.destinationUrl}/snowflake/databases`;

    const params = {
      db_password: formData.password,
      db_user: formData.user,
      region_id: formData.region,
      isPrivateLink: formData.isPrivateLink,
      account_name: formData.accountName
    };

    const options: NetworkRequestOptions = {
      ...BACKGROUND_NETWORK_REQ_OPTIONS,
      ...config
    };

    return courier.post(requestUrl, options, params);
  },

  getSnowflakeSysAdminPermission(formData, config = {}) {
    const requestUrl = `${this.destinationUrl}/snowflake/check-role-permission/SYSADMIN`;

    const params = {
      db_password: formData.password,
      db_user: formData.user,
      region_id: formData.region,
      isPrivateLink: formData.isPrivateLink,
      account_name: formData.accountName
    };

    const options: NetworkRequestOptions = {
      ...BACKGROUND_NETWORK_REQ_OPTIONS,
      ...config
    };

    return courier.post(requestUrl, options, params);
  },

  createSnowflakeDatabase(formData, databaseName) {
    const requestUrl = `${this.destinationUrl}/snowflake/resource/database/`;
    const params = {
      resource_name: databaseName,
      db_password: formData.password,
      db_user: formData.user,
      region_id: formData.region,
      isPrivateLink: formData.isPrivateLink,
      account_name: formData.accountName
    };
    const options: NetworkRequestOptions = {
      uiOptions: {
        ...BACKGROUND_NETWORK_REQ_UI_OPTIONS,
        showErrorMsg: true
      }
    };

    return courier.post(requestUrl, options, params);
  },

  createSnowflakeWarehouse(formData, warehouseName) {
    const requestUrl = `${this.destinationUrl}/snowflake/resource/warehouse`;
    const params = {
      resource_name: warehouseName,
      db_password: formData.password,
      db_user: formData.user,
      region_id: formData.region,
      isPrivateLink: formData.isPrivateLink,
      account_name: formData.accountName
    };

    const options: NetworkRequestOptions = {
      uiOptions: {
        ...BACKGROUND_NETWORK_REQ_UI_OPTIONS,
        showErrorMsg: true
      }
    };

    return courier.post(requestUrl, options, params);
  },

  getSnowflakeSchemas(formData, database, config = {}) {
    const requestUrl = `${this.destinationUrl}/snowflake/schemas/${database}`;

    const params = {
      db_password: formData.password,
      db_user: formData.user,
      region_id: formData.region,
      isPrivateLink: formData.isPrivateLink,
      account_name: formData.accountName
    };

    return courier.post(requestUrl, { ...BACKGROUND_NETWORK_REQ_OPTIONS, ...config }, params);
  },

  dedupDestinationTable(id: number, tableName: string, enable: boolean) {
    const requestUrl = `${this.destinationUrl}/${id}/dedup-status`;
    const params = {
      enable_dedup: enable,
      dedup_update_request: {
        type: 'SINGLE_TABLE',
        table_name: tableName
      }
    };

    return courier.put(requestUrl, {}, params);
  },

  getTables(destinationId: number, abortSignal?: AbortSignal): Promise<any> {
    const requestUrl = `${this.destinationUrl}/${destinationId}/schema-names`;
    const options: CourierNetworkRequestOptions = {
      uiOptions: {
        showLoading: false
      },
      signal: abortSignal
    };
    return courier.get(requestUrl, options);
  },

  getDraftDestinationList() {
    const requestUrl = this.draftDestinationUrl;
    const options: NetworkRequestOptions = {
      uiOptions: {
        ...BACKGROUND_NETWORK_REQ_UI_OPTIONS
      }
    };

    return courier.get(requestUrl, options);
  },

  getDestinationStats(
    destinationId: number,
    minutes = DESTINATION_STATS_TIME_INTERVAL,
    maxPoints = 1,
    config = {}
  ): Promise<any> {
    const requestUrl = `${this.statsUrl}/destination-summary/${destinationId}`;

    const options: NetworkRequestOptions = {
      uiOptions: BACKGROUND_NETWORK_REQ_UI_OPTIONS,
      networkOptions: {
        params: createHttpParams({
          since_minutes: minutes,
          max_points: maxPoints
        })
      },
      ...config
    };

    return courier.get(requestUrl, options).then(res => EventsBarGraphFactory(res.data.stats));
  },

  getLatency(id: any, minutes: any, maxPoints: any, config = {}) {
    const requestUrl = `${this.statsUrl}/destinations/${id}/latencies`;

    const options: NetworkRequestOptions = {
      uiOptions: BACKGROUND_NETWORK_REQ_UI_OPTIONS,
      networkOptions: {
        params: createHttpParams({
          since_minutes: minutes,
          max_points: maxPoints
        })
      },
      ...config
    };

    return courier.get(requestUrl, options).then(res => res.data);
  },

  getDestinationSyncTasksStats(
    destinationId: number,
    pipelineId: number,
    minutes: number,
    maxPoints: number,
    tables: string[],
    pageContext: PageContext
  ) {
    const path =
      pageContext === PageContext.DESTINATION
        ? 'v2/destination-tables-timeline'
        : 'destination-tables-timeline';
    const requestUrl = `${this.statsUrl}/${path}/${destinationId}`;

    const options: NetworkRequestOptions = {
      networkOptions: {
        params: createHttpParams({
          since_minutes: minutes,
          max_points: maxPoints,
          integration_id: pipelineId,
          table_name: tables
        })
      },
      uiOptions: BACKGROUND_NETWORK_REQ_UI_OPTIONS
    };

    return courier.get(requestUrl, options).then(res => {
      const objectStatsWithAdditionalData = res.data.stats.reduce(groupStatsRawDataOnObjectKey, {});

      return EntitiesEventsBarGraphFactory(
        tables,
        objectStatsWithAdditionalData,
        GraphConstants.eventsBarCountOld
      );
    });
  },

  testActivateWarehouseConnection(
    destinationType: string,
    destId: number,
    networkRequestOptions = {}
  ) {
    const requestUrl = `${this.destinationUrl}/${destinationType}/test`;

    const options: NetworkRequestOptions = {
      uiOptions: {
        ...BACKGROUND_NETWORK_REQ_UI_OPTIONS,
        ...BACKGROUND_NETWORK_REQ_UI_OPTION_HANDLE_TIMEOUT
      },
      networkOptions: {
        params: createHttpParams({
          destination_id: destId,
          force: false,
          is_activation: true,
          test_existing_connection: true
        })
      },
      ...networkRequestOptions
    };

    return courier.post(requestUrl, options);
  },

  refreshSchema(destinationId) {
    const requestUrl = `${this.destinationUrl}/${destinationId}/refresh-schemas`;

    const options: NetworkRequestOptions = {
      uiOptions: {
        showLoading: false
      }
    };

    return courier.put(requestUrl, options);
  },

  getDestinations() {
    const options: NetworkRequestOptions = {
      uiOptions: {
        showSuccessMsg: false,
        showLoading: false
      }
    };
    return courier.get(this.destinationUrl, options);
  },

  prepareDestinationOptions(
    destOptions: any,
    showLoading = true,
    includeCursor = false
  ): NetworkRequestOptions {
    let networkParams: any = {
      'display-details': true,
      schemas: !!destOptions?.schemas,
      relations: !!destOptions?.relations
    };

    if (!!destOptions?.schemas && includeCursor) {
      const cursor = destOptions.cursor || null;

      networkParams = {
        ...networkParams,
        limit: DESTINATION_SCHEMA_PAGE_LIMIT,
        cursor
      };
    }

    return {
      uiOptions: {
        showSuccessMsg: false,
        showLoading
      },
      networkOptions: {
        params: createHttpParams(networkParams)
      }
    };
  },

  prepareDestinationSchemas(schemaList: any[], filterReservedFields = false): any[] {
    const schemas = SchemasFactory(schemaList);

    if (filterReservedFields) {
      const reservedFields = _globalConfigService.getConfig(
        'destination_field_mappings_not_allowed_list'
      );

      schemas.forEach(schema => {
        schema.fields = schema.fields.filter(field => !reservedFields.includes(field.name));
      });
    }

    return schemas;
  },

  getDestination(
    destinationId: number,
    destOptions: any,
    showLoading = true,
    currentSchemaList = [],
    abortSignal?: AbortSignal
  ): any {
    const requestUrl = `${this.destinationUrl}/${destinationId}`;

    const options: CourierNetworkRequestOptions = this.prepareDestinationOptions(
      destOptions,
      showLoading,
      true
    );

    options.signal = abortSignal;

    return courier.get(requestUrl, options).then(res => {
      if (!res || !res.data) {
        return res;
      }

      const newSchemaList = res.data?.schemas || [];
      const updatedSchemaList = currentSchemaList.concat(newSchemaList);

      if (destOptions.schemas && res.data?.pagination_schema_cursor.has_more === true) {
        destOptions = {
          ...destOptions,
          cursor: res.data?.pagination_schema_cursor.next_cursor
        };

        return this.getDestination(destinationId, destOptions, showLoading, updatedSchemaList);
      }

      const schemas = this.prepareDestinationSchemas(
        updatedSchemaList,
        !!destOptions.filterReserved
      );

      return {
        ...res,
        data: {
          ...res.data,
          schemas,
          display_config: _sshService.filterSSHDisplayConfig(res.data.display_config)
        }
      };
    });
  },

  getDestinationBySeqId(seqId: any, destOptions: any, showLoading = true) {
    const requestUrl = `${this.destinationUrl}/by-seq-id/${seqId}`;

    const options = this.prepareDestinationOptions(destOptions, showLoading);

    return courier.get(requestUrl, options).then(res => {
      if (!res || !res.data) {
        return res;
      }

      const schemas = this.prepareDestinationSchemas(
        res.data.schemas,
        !!destOptions.filterReserved
      );

      return {
        ...res,
        data: {
          ...res.data,
          schemas
        }
      };
    });
  },

  getQueryPreview(destinationId: number, destSchemaName: string) {
    const requestUrl = `${this.destinationUrl}/${destinationId}/schemas/${destSchemaName}/preview`;

    const options: NetworkRequestOptions = {
      uiOptions: {
        showLoading: false
      },
      networkOptions: {
        responseType: 'text'
      }
    };

    return courier.get(requestUrl, options);
  },

  getQueryExecutionTaskId(destinationId: any, query: string) {
    const params = {
      query,
      source_destination_id: destinationId
    };

    const requestUrl = `${this.destinationUrl}/execute`;

    return courier
      .post(requestUrl, BACKGROUND_NETWORK_REQ_OPTIONS, params)
      .then((res: any) => res.data.task_id);
  },

  getQueryExecutionTaskResponse(taskId: number) {
    const requestUrl = `${this.destinationUrl}/execute/${taskId}`;

    return courier.get(requestUrl, {
      ...BACKGROUND_NETWORK_REQ_OPTIONS,
      networkOptions: {
        responseType: 'text'
      }
    }).then((res: string) => parse(res, null, retainSafeNumber));
  },

  stopQueryExecution(taskId: number) {
    const requestUrl = `${this.destinationUrl}/execution/${taskId}`;

    return courier.delete(requestUrl, BACKGROUND_NETWORK_REQ_OPTIONS);
  },

  getIntegrationsForAllDestinations() {
    const requestUrl = `${this.destinationUrl}/integrations-display-details`;
    return courier.get(requestUrl, BACKGROUND_NETWORK_REQ_OPTIONS);
  },

  getPendingEventsAndLatency(destinationId: number, tables: string[]) {
    const requestUrl = `${this.statsUrl}/${destinationId}/sync-infos`;

    const options: NetworkRequestOptions = {
      uiOptions: {
        showLoading: false,
        showErrorMsg: false
      },
      networkOptions: {
        params: createHttpParams({
          table_name: tables
        })
      }
    };

    return courier.get(requestUrl, options);
  },

  getRecommendedTableList(destinationId: number, paginationData: RequestPaginationData) {
    const requestUrl = `${this.destinationUrl}/${destinationId}/recommendations`;

    const options: NetworkRequestOptions = {
      uiOptions: BACKGROUND_NETWORK_REQ_UI_OPTIONS,
      networkOptions: {
        params: createHttpParams(paginationData)
      }
    };

    return courier.get(requestUrl, options);
  },

  getDestinationSyncTasksList(
    destinationId: number,
    pipelineId: number,
    paginationData: RequestPaginationData
  ) {
    const destTablesSyncInfoUrl = `${this.destinationUrl}/${destinationId}/sync-info`;

    const options: NetworkRequestOptions = {
      uiOptions: BACKGROUND_NETWORK_REQ_UI_OPTIONS,
      networkOptions: {
        params: createHttpParams({
          ...(pipelineId && { integration_id: pipelineId }),
          ...paginationData
        })
      }
    };

    return courier.get(destTablesSyncInfoUrl, options);
  },

  getDestinationSyncTask(destId: number, tableName: string) {
    const requestUrl = `${this.destinationUrl}/${destId}/table-sync-info/${tableName}`;

    return courier.get(requestUrl, BACKGROUND_NETWORK_REQ_OPTIONS);
  },

  getActivationsForAllWarehouses() {
    const requestUrl = `${this.activationWarehouseUrl}/activations-display-details`;

    return courier.get(requestUrl, BACKGROUND_NETWORK_REQ_OPTIONS);
  },

  loadEventsNowDestination(destId: number, tableName?: string) {
    const requestUrl = `${this.destinationUrl}/${destId}/trigger-load-job`;

    let options: NetworkRequestOptions = {
      uiOptions: {
        showLoading: false
      }
    };

    if (tableName) {
      options = {
        ...options,
        networkOptions: {
          params: createHttpParams({
            table_name: tableName
          })
        }
      };
    }

    return courier.put(requestUrl, options);
  },

  putAppendOnlyRecommendations(destinationId: number, data: any) {
    const requestUrl = `${this.destinationUrl}/${destinationId}/recommendations/bulk`;

    const options = {
      uiOptions: {
        ...BACKGROUND_NETWORK_REQ_UI_OPTIONS,
        showErrorMsg: true
      }
    };

    return courier.put(requestUrl, options, data);
  },

  hasDestinationRecommendations(destinationId: number) {
    const requestUrl = `${this.destinationUrl}/${destinationId}/recommendations/status`;

    return courier.get(requestUrl, BACKGROUND_NETWORK_REQ_OPTIONS);
  },

  getDatabricksPartnerConnectionDetails(code: string) {
    const requestUrl = `${this.destinationUrl}/databricks/partner-connect-details?code=${code}`;

    return courier.get(requestUrl, BACKGROUND_NETWORK_REQ_OPTIONS);
  },

  getDestinationByIdWithoutExtras(id: number) {
    const paramsOption: any = {
      'display-details': false,
      schemas: false,
      relations: false
    };

    const options: NetworkRequestOptions = {
      uiOptions: BACKGROUND_NETWORK_REQ_UI_OPTIONS,
      networkOptions: {
        params: createHttpParams(paramsOption)
      }
    };

    const requestUrl = `${this.destinationUrl}/${id}`;

    return courier.get(requestUrl, options);
  },

  getIntegrationsForDestination(destinationId: number) {
    const requestUrl = `${this.destinationUrl}/${destinationId}/integrations-display-details`;

    return courier.get(requestUrl, BACKGROUND_NETWORK_REQ_OPTIONS);
  },

  deleteDestination(id: number) {
    const requestUrl = `${this.destinationUrl}/${id}`;

    return courier.delete(requestUrl);
  },

  schedule(destId: number, body: any) {
    const requestUrl = `${this.destinationUrl}/${destId}/schedule`;

    const options: NetworkRequestOptions = {
      uiOptions: {
        showLoading: false,
        showErrorMsg: false
      }
    };

    return courier.put(requestUrl, options, body);
  },

  createDraftDestination(params: DraftPipelinePayload) {
    const requestUrl = this.draftDestinationUrl;

    return courier.post(requestUrl, BACKGROUND_NETWORK_REQ_OPTIONS, params);
  },

  updateDraftDestination(id: number, params: DraftPipelinePayload) {
    const requestUrl = `${this.draftDestinationUrl}/${id}`;

    return courier.put(requestUrl, BACKGROUND_NETWORK_REQ_OPTIONS, params);
  }
};

export default DestinationsAPI;
