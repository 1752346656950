import { AbExperiment, AbExperimentConfig, AbExperimentsConfig, DEFAULT_EXPERIMENT_VARIATION } from '../models/ab-experiments';
import { AnsibleBoolean } from '../models/ansible-config';
import { CookieService } from './cookie.service';

declare const _vis_opt_register_conversion: any;
declare const __AB_TEST_ENABLED__: AnsibleBoolean;
declare const __AB_EXPERIMENTS_CONFIG__: AbExperimentsConfig<AbExperimentConfig>;

export class VWOService {
  static deps = [
    CookieService
  ]

  constructor(private _cookieService: CookieService) {
  }

  isExperimentEnabled(experimentName: AbExperiment) {
    return __AB_TEST_ENABLED__ === AnsibleBoolean.TRUE
      && __AB_EXPERIMENTS_CONFIG__?.[experimentName]?.enabled === true;
  }

  getExperimentVariant<T = string>(experimentName: AbExperiment): T {
    if (!this.isExperimentEnabled(experimentName)) {
      return;
    }

    return window[experimentName] || DEFAULT_EXPERIMENT_VARIATION;
  }

  isExperimentVariant(experimentName: AbExperiment, variant: string): boolean {
    return this.getExperimentVariant(experimentName) === variant;
  }

  getExperimentTrackingData(experimentName) {
    if (!this.isExperimentEnabled(experimentName)) {
      return {};
    }

    return {
      experimentName: experimentName,
      experimentVariant: this.getExperimentVariant(experimentName)
    };
  }

  activateExperiment(experimentName) {
    if (!window['VWO']) {
      return;
    }

    if (this.isExperimentEnabled(experimentName)) {
      const campaignId = __AB_EXPERIMENTS_CONFIG__[experimentName].campaignId;

      window['VWO'].push([ 'activate', false, [ campaignId ], true ]);
    }
  }

  triggerExperimentConversion(experimentName: AbExperiment) {
    if (!this.isExperimentEnabled(experimentName)) {
      return;
    }

    const experimentConfig = __AB_EXPERIMENTS_CONFIG__[experimentName];

    if (!experimentConfig?.goalId) {
      return;
    }

    this.triggerConversion(experimentConfig.goalId, experimentConfig.campaignId);
  }

  triggerConversion(goalId: number, testId?: number) {
    window['_vis_opt_queue'] = window['_vis_opt_queue'] || [];
    window['_vis_opt_queue'].push(function () {
      if (testId) {
        _vis_opt_register_conversion(goalId, testId);
      } else {
        _vis_opt_register_conversion(goalId);
      }
    });
  }

  triggerWebsiteSignupConversion() {
    if (__AB_TEST_ENABLED__ !== AnsibleBoolean.TRUE) {
      return;
    }

    Object.values(__AB_EXPERIMENTS_CONFIG__).filter(
      (experiment) => experiment.enabled && experiment.signupConversion
    ).forEach((experiment) => {
      const dependentGoalsTriggered = this._checkExperimentTriggeredGoals(
        experiment.campaignId,
        experiment.dependentGoalIds
      );

      if (dependentGoalsTriggered) {
        this.triggerConversion(experiment.goalId, experiment.campaignId);
      }
    });
  }

  private _checkExperimentTriggeredGoals(campaignId: number, dependentGoalIds: number[]) {
    if (!dependentGoalIds?.length) {
      return true;
    }

    return dependentGoalIds.every((goalId) => {
      return !!this._cookieService.get(`_vis_opt_exp_${campaignId}_goal_${goalId}`);
    });
  }
}
