import { DestinationTypeConfigHelper } from '../../../../../components/Node/destination-settings-form/destination-type-config-helper';
import {
  DEFAULT_SNOWFLAKE_SCHEMA_NAME,
  SNOWFLAKE_DEFAULT_REGION_ID,
  SNOWFLAKE_HOST_URL_PREFIX,
  SNOWFLAKE_HOST_URL_PRIVATE_LINK_FRAGMENT,
  SNOWFLAKE_HOST_URL_SUFFIX,
  SnowflakeAuthenticationType
} from './constants';
import { extractAccountAndRegionFromSnowflakeHostURL } from './utils';

export class SnowflakeConfigHelper implements DestinationTypeConfigHelper {
  getDefaultConfig() {
    const authType = SnowflakeAuthenticationType.KEY_PAIR;

    return {
      schemaName: DEFAULT_SNOWFLAKE_SCHEMA_NAME,
      sanitizeName: true,
      isPrivateLink: false,
      authType
    };
  }

  getConfigFromRawData(rawData: any) {
    const privateLinkComponent = rawData.is_private_link
      ? `.${SNOWFLAKE_HOST_URL_PRIVATE_LINK_FRAGMENT}`
      : '';
    const accountUrl =
      rawData.account_name && rawData.region_id
        ? `${SNOWFLAKE_HOST_URL_PREFIX}${rawData.account_name}.${rawData.region_id}${privateLinkComponent}${SNOWFLAKE_HOST_URL_SUFFIX}`
        : undefined;

    const config: any = {
      user: rawData.db_user,
      password: rawData.db_password,
      passphrase: rawData?.private_key_passphrase || '',
      databaseName: rawData.db_name,
      schemaName: rawData.schema_name,
      warehouseName: rawData.warehouse,
      isTableTypeTransient: rawData.is_table_type_transient,
      sanitizeName: rawData.sanitize_name,
      accountUrl,
      accountName: rawData.account_name,
      region: rawData.region_id,
      isPrivateLink: rawData.is_private_link,
      authType: !!rawData.private_key || rawData.private_key === ''
        ? SnowflakeAuthenticationType.KEY_PAIR
        : SnowflakeAuthenticationType.ACCESS_CREDENTIALS
    };

    if ('private_key' in rawData && rawData.private_key !== '') {
      config.privateKey = { content: rawData?.private_key };
    }

    return config;
  }

  buildConnectionParams(data: any) {
    const params: any = {
      db_user: data.user,
      db_name: data.databaseName,
      warehouse: data.warehouseName,
      sanitize_name: data.sanitizeName,
      is_table_type_transient: data.isTableTypeTransient
    };

    if (data.authType === SnowflakeAuthenticationType.ACCESS_CREDENTIALS) {
      params.db_password = data.password;
    } else {
      params.private_key = data.privateKey.content;
      params.private_key_passphrase = data.passphrase || '';
    }

    if (data.schemaName) {
      params.schema_name = data.schemaName;
    }

    if (data.accountName && data.region) {
      params.account_name = data.accountName;
      params.region_id = data.region;
      params.is_private_link = data.isPrivateLink;
    } else {
      const { accountName, regionId, isPrivateLink } = extractAccountAndRegionFromSnowflakeHostURL(
        data.accountUrl
      );

      params.account_name = accountName;
      params.region_id = regionId || SNOWFLAKE_DEFAULT_REGION_ID;
      params.is_private_link = isPrivateLink;
    }

    return params;
  }

  metaDataResolver() {
    return {};
  }
}
