import { Angulartics2 } from '@angularticsCompat';
import { StorageKey } from '../../pipeline/models/storage-key';

import { PIPELINE_SESSION_ID_KEY } from '../constants';
import { SessionTrackingAngularticsService } from './session-tracking-angulartics.service';
import { SettingsStorageService } from './settings-storage.service';


export class CreatePipelineAngularticsService extends SessionTrackingAngularticsService {
  static deps = [
    Angulartics2,
    SettingsStorageService
  ];

  constructor(
    protected _angulartics2: Angulartics2,
    protected _settingsStorageService: SettingsStorageService
  ) {
    super(
      _angulartics2,
      _settingsStorageService,
      PIPELINE_SESSION_ID_KEY
    );
  }

  getEventTrackingProperties(data: any) {
    return {
      ...super.getEventTrackingProperties(data),
      sourceId: this.getDraftSourceId(),
      destinationId: this.getDraftDestinationId()
    };
  }

  getDraftSourceId() {
    return this._settingsStorageService.getSettings(StorageKey.CREATE_DRAFT_PIPELINE_SOURCE)?.id;
  }

  getDraftDestinationId() {
    return this._settingsStorageService.getSettings(StorageKey.CREATE_DRAFT_PIPELINE_DESTINATION)?.id;
  }
}
