import { AnsibleBoolean } from './models/ansible-config';

declare const __STRIPE_KEY__: any;
declare const __BASTION_IPS__: any;
declare const __RECAPTCHA_V3_KEY__: string;
declare const __RECAPTCHA_V2_INVISIBLE_KEY__: string;
declare const __SCHEDULER_IN_CREATE_PIPELINE__: AnsibleBoolean;

const SSH_RSA_PUBLIC_KEY = 'ssh-rsa AAAAB3NzaC1yc2EAAAADAQABAAABAQDyfYljMfLMVD3erbKdslUDzbEUpcxAJgH33HWPtLgVjNAEMeMb2mJe4Zt72gDZnDmO/tMxbPfjIqnR7n2Jkc4XsoS3StvwEeGQ3AuwbWo1NQaDg8P8fHOOgY6z0VglYEZHDIhnZyA/UDLWEUAmQeuZm0tgBZtj/tm+qzdftX6cKzgFNSnimTivBXVmNdHKz+42Wpc/2XRRo6DqHgWnhKR47qcsVfasLd1RdqaJ3Sn4sDDC17KQ/3AMrvF47J0RIwVue/KHdfVi0HmI2Yv4goENokSrVuus7uUPI4bT8pvfb11ugVOGXS61IPyOZYaUQW5dfb00itm9tMBUFdD/nJNL tunnel@hevo.io';
const SSH_ECDSA_PUBLIC_KEY = 'ecdsa-sha2-nistp256 AAAAE2VjZHNhLXNoYTItbmlzdHAyNTYAAAAIbmlzdHAyNTYAAABBBNFGdhUlYGCMHPrY2HK1KZX+wRYmLv2oNl8mPNQjvSHVKc6GDgQEXMQIVSUTjNoRXzBZEiqQaFbKJ+UXSTedew0= tunnel@hevodata.com';

export type SSHKeyConfig = {
  label: string;
  key: string;
  isRecommended?: boolean;
}


export class AppConfig {
  config = {
    authURL: '/api/security',
    configURL: '/api/config',
    hermesURL: '/h20/api/config',
    notificationURL: '/api/notifications',
    // tslint:disable-next-line:max-line-length
    bastionSSHKeyList: this.getBastionSSHKeyList(),
    stripeKey: __STRIPE_KEY__,
    bastionSSHIP: typeof __BASTION_IPS__ !== 'undefined' ? __BASTION_IPS__ : undefined,
    recaptchaV3Key: typeof __RECAPTCHA_V3_KEY__ !== 'undefined' ? __RECAPTCHA_V3_KEY__ : undefined,
    recaptchaV2InvisibleKey: typeof __RECAPTCHA_V2_INVISIBLE_KEY__ !== 'undefined' ? __RECAPTCHA_V2_INVISIBLE_KEY__ : undefined,
  };

  constructor() {}

  getSecurityServiceURL() {
    return this.config.authURL + '/v2.0';
  }

  /**
   * Marking this as private to facilitate piecewise migration to microservices,
   * exposing this directly can cause errors due to reference of wrong base url.
   * Instead base URLs should be referred from their corresponding features.
   * @private
   */
  private _getConfigURL() {
    return this.config.configURL + '/v1.0';
  }

  private _getHermesURL() {
    return this.config.hermesURL;
  }

  /**
   * Marking this as private to facilitate piecewise migration to microservices,
   * exposing this directly can cause errors due to reference of wrong base url.
   * Instead base URLs should be referred from their corresponding features.
   * @private
   */
  private _getConfigV2URL() {
    return this.config.configURL + '/v2.0';
  }

  getNotificationURL() {
    return this.config.notificationURL + '/v1.0/dashboard-notifications';
  }

  getNotificationV2URL() {
    return this.config.notificationURL + '/v2.0/dashboard-notifications';
  }

  getAlertsV2URL() {
    return this._getHermesURL() + '/v2.0/alerts-service/notifications';
  }

  getIntegrationsURL() {
    return this._getConfigURL() + '/integrations';
  }

  getSourceObjectsURL() {
    return this._getConfigURL() + '/source-objects';
  }

  getSourcesURL() {
    return this._getConfigURL() + '/sources';
  }

  getDraftSourcesURL() {
    return this._getConfigURL() + '/draft-sources';
  }

  getStatsURL() {
    return this._getConfigURL() + '/stats';
  }

  getSidelineURL() {
    return this._getConfigURL() + '/sideline';
  }

  getDestinationsURL() {
    return this._getConfigURL() + '/destinations';
  }

  getDraftDestinationsURL() {
    return this._getConfigURL() + '/draft-destinations';
  }

  getActivityLogsURL() {
    return this._getConfigURL() + '/activity-logs';
  }

  getActivationsURL() {
    return this._getConfigURL() + '/activations';
  }

  getModelsURL() {
    return this._getConfigURL() + '/models';
  }

  getModelsV2URL() {
    return this._getConfigV2URL() + '/models';
  }

  getMWHURL() {
    return this._getConfigURL() + '/mwh';
  }

  getWorkflowsURL() {
    return this._getConfigV2URL() + '/workflows';
  }

  getIronBankURL() {
    return this._getConfigURL() + '/ironbank';
  }

  getWarehousesURL() {
    return this._getConfigURL() + '/warehouses';
  }

  getActivationRunsURL() {
    return this._getConfigURL() + '/activation-runs';
  }

  getAlertPreferencesURL() {
    return this._getConfigURL() + '/notification-preference';
  }

  getAlertExternalPreferencesV2URL() {
    return this.getSecurityServiceURL() + '/notification-external';
  }

  getMapperURL() {
    return this._getConfigURL() + '/mapper';
  }

  getTransformersURL() {
    return this._getConfigURL() + '/transformers';
  }

  getTeamSettingsURL() {
    return this._getConfigURL() + '/team-settings';
  }

  getPrivilegeURL() {
    return this._getConfigURL() + '/privilege';
  }

  getGlobalConfigURL() {
    return this._getConfigURL() + '/global-config';
  }

  getDownloadConfigURL() {
    return this._getConfigURL() + '/file-download';
  }

  getUtilityURL() {
    return this._getConfigURL() + '/utility';
  }

  getScheduledJobsURL() {
    return this._getConfigURL() + '/scheduled-jobs';
  }

  getSpikeAlertURL() {
    return this._getConfigURL() + '/integrations_spike_alert'
  }

  getWebhookEvents() {
    return this._getConfigURL() + '/webhook-events';
  }

  getJobsURL() {
    return this._getConfigURL() + '/jobs'
  }

  /**
   * Dont use this method unless backend is providing the remaining path of the URL.
   */
  getDynamicURL() {
    return this._getConfigURL();
  }

  getDbtURL() {
    return this._getConfigURL() + '/dbt';
  }

  getAllowedActionsURL() {
    return this._getHermesURL() + '/v2.0/state';
  }

  getBastionIPs(): string[] {
    if (this.config.bastionSSHIP) {
      return this.config.bastionSSHIP.split(',');
    }
  }

  getBastionSSHKeyList(): SSHKeyConfig[]{
    return [
      {
        label: "ECDSA Public Key",
        key: SSH_ECDSA_PUBLIC_KEY,
        isRecommended: true
      },
      {
        label: "RSA Public Key",
        key: SSH_RSA_PUBLIC_KEY
      }
    ]
  }
}

export function isSchedulerInPipelineEnabled() {
  return typeof __SCHEDULER_IN_CREATE_PIPELINE__ !== 'undefined'
    && __SCHEDULER_IN_CREATE_PIPELINE__ === AnsibleBoolean.TRUE;
}
