import { DEFAULT_FREQUENCY_CHIPS, DEFAULT_FREQUENCY_CHIPS_WORKFLOWS_V2 } from '../../scheduler/ExecutionPolicyDialog/constants';
import { Policy } from '../../scheduler/ExecutionPolicyDialog/models';
import { AnchorDirection } from '../../../components/FlowChart/flow-chart-base/node/anchor';
import { Workflow, WorkflowVersion } from '../models/workflow';
import { WorkflowStatusEnum } from '../models/workflow-status';
import { WorkflowStep } from '../models/workflow-step';
import { WorkflowRunHistoryItemFactory } from './workflow-run-history';
import { WorkflowStepsFactory } from './workflow-step';

export function WorkflowsFactory(rawData: any[]) {
  if (!rawData || !rawData.length) {
    return [];
  }

  return rawData.map((item) => WorkflowFactory(item));
}

export function WorkflowFactory(rawData: any) {
  if (!rawData) {
    return undefined;
  }

  const workflow = new Workflow();

  workflow.name = rawData.name;
  workflow.id = rawData.id;
  workflow.seqId = rawData.seq_id;
  workflow.history = WorkflowRunHistoryItemFactory(rawData.last_job);
  workflow.status = WorkflowStatusEnum[rawData.display_status];
  workflow.steps = WorkflowStepsFactory(rawData.steps);

  workflow.layout = rawData.layout || {
    connections: [],
    vertices: []
  };

  workflow.layout.connections.forEach((connection) => {
    connection.sourceAnchor = connection.sourceAnchor.toLowerCase() as AnchorDirection;
    connection.destAnchor = connection.destAnchor.toLowerCase() as AnchorDirection;
  });

  workflow.createdTs = rawData.created_ts;
  workflow.version = rawData.config?.version;
  workflow.executionPolicy = new Policy(
    rawData.execution_policy,
    null,
    workflow.version === WorkflowVersion.V2 ? DEFAULT_FREQUENCY_CHIPS_WORKFLOWS_V2 : DEFAULT_FREQUENCY_CHIPS,
    DEFAULT_FREQUENCY_CHIPS_WORKFLOWS_V2
  );

  removeDeletedEntitySteps(workflow);

  return workflow;
}

function removeDeletedEntitySteps(workflow: Workflow) {
  if (!workflow.layout || !workflow.layout.vertices) {
    return;
  }

  let i = 0;

  while (i < workflow.layout.vertices.length) {
    const vertex: any = workflow.layout.vertices[i];

    const step: any = workflow.steps.find((step: WorkflowStep) => {
      return step.vertexId === vertex.id;
    });


    if (!step) {
      workflow.layout.vertices.splice(i, 1);
      continue;
    }

    vertex.node = step;

    i++;
  }
}

