import { BehaviorSubject } from 'rxjs';

export class ShortcutsDialogHandlerReactService {
  private _keyboardShortcutsDialogOpen$ = new BehaviorSubject(false);
  private _advancedGlobalSearchDialogOpen$  = new BehaviorSubject(false);

  isKeyboardShortcutDialogOpen() {
    return this._keyboardShortcutsDialogOpen$;
  }

  closeKeyboardShortcutDialog() {
    this._keyboardShortcutsDialogOpen$.next(false);
  }

  openKeyboardShortcutDialog() {
    this._keyboardShortcutsDialogOpen$.next(true);
  }

  closeGlobalSearchDialog() {
    this._advancedGlobalSearchDialogOpen$.next(false);
  }

  openGlobalSearchDialog() {
    this._advancedGlobalSearchDialogOpen$.next(true);
  }

  isAdvancedGlobalSearchDialogOpen() {
    return this._advancedGlobalSearchDialogOpen$;
  }
}
