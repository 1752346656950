import { User } from '../core/models/user';
import { getCalendarDateWithTimezone } from '../../legacy-utils/date';
import { REAUTHORISE_TARGET_CONFIRMATION_BODY } from './constants';
import { Target } from './models/target';
import { TargetObjectMeta } from './models/target-object-meta';
import { TargetStatusEnum } from './models/target-status';
import { TargetTable, TargetTableWarehouseInfo } from './models/target-table';
import { TARGET_TYPES, TargetTypesEnum } from './models/target-type';
import { TargetTypeInfo } from './models/target-type-info';


export function TargetsFactory(rawData: any[]): Target[] {
  if (!rawData || !rawData.length) {
    return [];
  }

  return rawData.map((item) => {
    return TargetFactory(item);
  });
}

export function TargetFactory(rawItem: any): Target {
  if (!rawItem) {
    return;
  }

  const target = new Target();
  target.id = rawItem.id;
  target.seqId = rawItem.seq_id;
  target.name = rawItem.name;
  target.targetType = TargetTypesEnum[rawItem.type];
  target.targetTypeDisplay = rawItem.type_display_name
    ? rawItem.type_display_name
    : TargetTypesEnum[rawItem.type].displayName;
  target.targetTypeLogoUrl = rawItem.type_logo_url;
  target.targetTypeDarkModeLogoUrl = rawItem.type_dark_mode_logo_url
    ? rawItem.type_dark_mode_logo_url
    : rawItem.type_logo_url;
  target.lastSyncedTs = rawItem.last_synced_ts;
  target.failureMessage = rawItem.failure_message;
  target.config = rawItem.config ? rawItem.config : {};
  target.displayConfig = rawItem.display_config ? rawItem.display_config : {};
  target.isEditable = rawItem.editable || false;

  if (rawItem.status) {
    target.status = TargetStatusEnum[rawItem.status];
  }

  if (rawItem.oauth_user_id) {
    target.oauthUserId = rawItem.oauth_user_id;
  }

  return target;
}

// Factory function for target object

export function TargetObjectsFactory(rawData: any[]): TargetTable[] {
  if (!rawData || !rawData.length) {
    return [];
  }

  return rawData.map((item) => TargetObjectFactory(item));
}

export function TargetObjectFactory(rawItem: any): TargetTable {
  if (!rawItem) {
    return;
  }

  const lastSyncedDisplayTime = getCalendarDateWithTimezone(rawItem.last_synced_ts);

  return {
    name: rawItem.target_object,
    targetId: rawItem.target_id,
    lastSyncedTs: rawItem.last_synced_ts,
    lastSyncedDisplayTime: lastSyncedDisplayTime,
    warehouseInfo: TargetObjectWarehousesFactory(rawItem.warehouse_infos)
  };
}

// Factory function for warehouse info of a target object

export function TargetObjectWarehousesFactory(rawData: any[]): TargetTableWarehouseInfo[] {
  if (!rawData || !rawData.length) {
    return [];
  }

  return rawData.map((item) => TargetObjectWarehouseFactory(item));
}

export function TargetObjectWarehouseFactory(rawItem: any): TargetTableWarehouseInfo {
  if (!rawItem) {
    return;
  }

  return {
    id: rawItem.warehouse_id,
    name: rawItem.warehouse_name,
    type: rawItem.warehouse_type,
    logoUrl: rawItem.logo_url
  };
}

export function TargetTypesFactory(rawData: any[]): TargetTypeInfo[] {
  if (!rawData || 0 === rawData.length) {
    return [];
  }

  return rawData.map((item) => TargetTypeFactory(item));
}

export function TargetTypeFactory(rawData: any): TargetTypeInfo {
  if (!rawData) {
    return;
  }

  return {
    type: TargetTypesEnum[rawData.type],
    logoURL: rawData.type_logo_url,
    logoURLDarkMode: rawData.type_dark_mode_logo_url,
    typeDisplay: rawData.type_display_name || TARGET_TYPES[rawData.type].displayName,
    provider: rawData.type_provider_name || TARGET_TYPES[rawData.type].authProvider
  };
}

export function canReauthoriseOauthAccount(target: Target) {
  return target.config && target.config.oauth_token_id;
}

export function getReauthoriseOauthAccountParams(target: Target) {
  return {
    confirmBody: REAUTHORISE_TARGET_CONFIRMATION_BODY,
    nodeType: target.targetType,
    authProvider: TARGET_TYPES[target.targetType].authProvider,
    oAuthTokenId: target.config.oauth_token_id,
    oAuthUserId: target.oauthUserId
  };
}

export function targetEditReauthRequired(target: Target, user: User) {
  return target.config?.oauth_token_id && target.oauthUserId !== user.id;
}

export function isTargetStatusFailed(target: Target) {
  return target.status === TargetStatusEnum.FAILED;
}

export function findTargetObjectInGroupedList(targetObjectGroups, selectedTargetObject: string | TargetObjectMeta) {
  return targetObjectGroups
  .reduce((targetObjects, group) => [ ...targetObjects, ...group.children ], [])
  .find((targetObject: TargetObjectMeta) => {
    if (typeof selectedTargetObject === 'string') {
      return targetObject.apiName === selectedTargetObject;
    }

    return targetObject.apiName === selectedTargetObject.apiName;
  });
}

export function groupTargetObjectsOnType(targetObjectsList: any[]) {
  return targetObjectsList.reduce((targetObjectGroup, currentTargetObject) => {
    const { target_object_type, api_name, display_name } = currentTargetObject;
    const groupName = target_object_type ? target_object_type.split('_').join(' ') : '';

    const objectData: TargetObjectMeta = {
      apiName: api_name,
      displayName: display_name
    };

    if (currentTargetObject.modes) {
      objectData.modes = currentTargetObject.modes;
    }

    const groupIndex =
      targetObjectGroup.findIndex((objectGroup) => objectGroup.value === groupName);

    if (groupIndex >= 0) {
      targetObjectGroup[groupIndex].children = [
        ...targetObjectGroup[groupIndex].children,
        objectData
      ];
    } else {
      targetObjectGroup = [
        ...targetObjectGroup,
        {
          value: groupName,
          children: [
            objectData
          ]
        }
      ];
    }

    return targetObjectGroup;
  }, []);
}
