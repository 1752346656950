import { EntityStatus } from '../core/models/entity-status';
import { EntityIndependentRunStatus } from '../core/models/hevo-entity';
import { Policy, PolicyTypeEnum } from '../scheduler/ExecutionPolicyDialog/models';
import { ModelStatusEnum } from './model-status';
import { QueryHistoryStatusEnum } from './query-history-status';
import { Policy as ExecutionPolicy } from '../scheduler/models';

export enum ModelActionsEnum {
  PAUSE,
  PLAY,
  DELETE,
  STOP_QUERY,
  RUN_NOW,
  UPDATE_FREQUENCY,
  EDIT_CONFIG
}

export class QueryHistoryItem {
  id: number;
  seqId: number;
  startedTs: number;
  createdTs: number;
  runTime: number;
  finishedTs: number;
  query: string;
  columns: string[];
  outputTable: string;
  destinationId: number;
  failureMsg: string;
  status: EntityStatus;

  getBadgeType() {
    if (this.failureMsg) {
      return 'error';
    }

    return this.status.colorType;
  }

  getStatusInfoIcon() {
    if (this.failureMsg) {
      return 'error-filled';
    }

    return this.status.value === QueryHistoryStatusEnum.QUEUED.value ? 'info' : null;
  }

  isErrored() {
    return typeof this.failureMsg !== 'undefined' && this.failureMsg !== null;
  }

  get columnsString() {
    if (!this.columns || !this.columns.length) {
      return undefined;
    }
    return this.columns.join(', ');
  }
}

export class Model {
  id: number;
  seqId: number;
  name: string;
  status: EntityStatus;
  executionPolicy: Policy | ExecutionPolicy;
  destination: any;
  source: any;
  createdBy: any;
  updatedBy: any;
  lastRunTs: number;
  failureMsg: string;
  failureReason: string;
  destinationSchemaId: number;
  destinationSchemaName: string;
  query: string;
  truncateAndLoad: boolean;
  columns: string;
  history: QueryHistoryItem[];
  actions: ModelActionsEnum[] = [];
  version: number;
  independentRunStatus: EntityIndependentRunStatus;

  setActions() {
    this.actions = [
      ModelActionsEnum.DELETE,
      ModelActionsEnum.EDIT_CONFIG,
      ModelActionsEnum.UPDATE_FREQUENCY
    ];

    if (ModelStatusEnum.isQueryRunScheduled(this.status)) {
      this.actions.push(ModelActionsEnum.STOP_QUERY);
    } else if (this.executionPolicy && this.executionPolicy.type !== PolicyTypeEnum.NO_SCHEDULE) {
      if (this.executionPolicy.status === ModelStatusEnum.PAUSED.value) {
        this.actions.push(ModelActionsEnum.PLAY);
      } else {
        this.actions.push(ModelActionsEnum.PAUSE);
      }
    }

    if (this.status.value !== ModelStatusEnum.RUNNING.value) {
      this.actions.push(ModelActionsEnum.RUN_NOW);
    }
  }

  setLastRunTs() {
    if (!Array.isArray(this.history) || !this.history.length) {
      this.lastRunTs = undefined;
      return;
    }

    this.lastRunTs = this.history[0].startedTs;
  }

  setFailureMsg() {
    if (!Array.isArray(this.history) || !this.history.length) {
      this.failureMsg = undefined;
      return;
    }

    this.failureMsg = this.history[0].failureMsg;
  }

  getAction(modelActionEnum: ModelActionsEnum) {
    return this.actions.indexOf(modelActionEnum) >= 0;
  }

  canPlayPause() {
    return this.getAction(ModelActionsEnum.PLAY)
      || this.getAction(ModelActionsEnum.PAUSE);
  }

  paused(): boolean {
    return this.executionPolicy && this.executionPolicy.status === 'PAUSED';
  }

  getLastExecutionId() {
    if (!Array.isArray(this.history) || !this.history.length) {
      return undefined;
    }

    return this.history[0].id;
  }
}

export enum ModelType {
  SQL = 'SQL',
  DBT = 'DBT'
}

export enum DrawerTab {
  SQL = 0,
  DBT = 1
}
