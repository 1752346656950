import { RbacPermissions } from '../core/models/user';

export const MOD_KEY_CLASSES = {
  shift: 'hevo-shift',
  cmd: 'hevo-cmd',
  ctrl: 'hevo-ctrl',
  enter: 'hevo-enter'
};

export const COMMON_REFRESH_KEY = 'common.refresh';

export const KEYBOARD_SHORTCUT_RBAC_MAPPING = {
  'pipeline.create': RbacPermissions.PIPELINE_CREATE,
  'pipeline.testTransformationCode': RbacPermissions.PIPELINE_EDIT,
  'pipeline.replayQueue': RbacPermissions.PIPELINE_EDIT,
  'pipeline.deployTransformationCode': RbacPermissions.PIPELINE_EDIT,
  'destination.add': RbacPermissions.DESTINATION_CREATE,
  'destination.runSourceQuery': RbacPermissions.DESTINATION_EDIT,
  'activation.create': RbacPermissions.ACTIVATION_CREATE,
  'activation.runSourceQuery': RbacPermissions.ACTIVATION_EDIT,
  'activation.runQueryAndContinue': RbacPermissions.ACTIVATION_EDIT,
  'target.create': RbacPermissions.ACTIVATION_CREATE,
  'model.create': RbacPermissions.MODELS_AND_WORKFLOWS_CREATE,
  'model.sourceQuery': RbacPermissions.MODELS_AND_WORKFLOWS_EDIT,
  'model.runSourceQuery': RbacPermissions.MODELS_AND_WORKFLOWS_EDIT,
  'workflow.create': RbacPermissions.MODELS_AND_WORKFLOWS_CREATE
};
