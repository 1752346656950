import { InclusionListState } from '../../../../core/models/inclusion-list-v2';
import { GenericKeyValueType } from '../../../../../generics';

export enum GoogleSheetFieldNames {
  REFRESH_SHEETS_EVERYDAY = 'refreshSheetsEveryday',
  SPREAD_SHEETS = 'spreadsheets',
  AUTH_TYPE = 'authType',
  SHEET_WORKSHEETS_MAP = 'sheetWorksheetsMap',
  AUTO_SKIP_BLANK_HEADERS_ROWS = 'autoSkipBlankHeadersRows',
  CREATE_EVENT_TYPES_FROM_DIFFERENT_SHEETS = 'createEventTypesFromDifferentSheets',
  CREATE_EVENT_TYPES_FROM_SPREADSHEETS = 'createEventTypesFromSpreadsheets',
  HEADER_ROW_INDEX = 'headerRowIndex',
  DATA_ROW_INDEX = 'dataRowIndex'
}

export interface GoogleSheetFormState {
  [GoogleSheetFieldNames.AUTH_TYPE]?: string;
  [GoogleSheetFieldNames.REFRESH_SHEETS_EVERYDAY]: boolean;
  [GoogleSheetFieldNames.AUTO_SKIP_BLANK_HEADERS_ROWS]?: boolean;
  [GoogleSheetFieldNames.CREATE_EVENT_TYPES_FROM_DIFFERENT_SHEETS]?: boolean;
  [GoogleSheetFieldNames.CREATE_EVENT_TYPES_FROM_SPREADSHEETS]?: boolean;
  [GoogleSheetFieldNames.HEADER_ROW_INDEX]?: number;
  [GoogleSheetFieldNames.DATA_ROW_INDEX]?: number;
  [GoogleSheetFieldNames.SPREAD_SHEETS]: InclusionListState;
  [GoogleSheetFieldNames.SHEET_WORKSHEETS_MAP]?: GenericKeyValueType<
    string,
    { id: string; name: string }[]
  >;
}

export interface SheetsAccountsSheet {
  id: string;
  name: string;
  link: string;
  modifiedTime: string;
  owner: string;
}

export interface SheetsAccounts {
  page: string;
  sheets: SheetsAccountsSheet[];
}

export interface Worksheet {
  id: string | number;
  name: string;
}

export interface SheetsAccountsResponse {
  count: number;
  success: boolean;
  data: SheetsAccounts;
}

export interface WorksheetResponse {
  count: number;
  success: boolean;
  data: Worksheet[];
}

export interface GoogleSheetsSpreadsheets {
  selected: boolean;
  included_list: string[];
  excluded_list: string[];
  worksheet_config: GenericKeyValueType<string, string[]>;
  filters: any;
}

export interface GoogleSheetsConfigPayload {
  spreadsheets: GoogleSheetsSpreadsheets;
  account_type: string;
  refresh_sheets_everyday: boolean;
  header_row_index?: number;
  data_row_index?: number;
  auto_skip_blank_header_rows?: boolean;
  create_new_event_types_from_different_sheets?: boolean;
  create_new_event_types_from_spreadsheets?: boolean;
}

export interface GoogleSheetsConfigResponse {
  spreadsheets: string;
  json_parsing_strategy?: string;
  account_type: string;
  oauth_token_id: string;
  refresh_sheets_everyday: boolean;
  header_row_index?: number;
  data_row_index?: number;
  auto_skip_blank_header_rows?: boolean;
  create_new_event_types_from_different_sheets?: boolean;
  create_new_event_types_from_spreadsheets?: boolean;
}
