import { appendQueryParams } from './url';
import { createHttpParams } from './request';
import { User } from '../containers/core/models/user';

export function appendTrackingParams(path: string, section: string, user: User, properties?: { [key: string]: any }) {
  const params = createHttpParams(properties || {});
  params['ui_section'] = section;
  params['app_user'] = user.email;
  return appendQueryParams(path, new URLSearchParams(params).toString());
}
