import { DestinationTypeConfigHelper } from '../../../../../components/Node/destination-settings-form/destination-type-config-helper';
import { coerceBooleanProperty } from '../../../../../legacy-utils/coercion';
import { S3_BUCKET_REGIONS } from '../../../../pipeline/create/ConfigSourceType/S3Base/constants';
import { S3_NEW_DEST_COMPRESSION_SUPPORTED, S3DestFileFormat } from './constants';
import { AWSAuthenticationType } from '../../../../pipeline/create/ConfigSourceType/AWS/model';

export class DestinationS3NewConfigHelper implements DestinationTypeConfigHelper {
  getConfigFromRawData(rawData: any) {
    return {
      authType: rawData.auth_type,
      amazonResourceName: rawData.aws_iam_role_arn,
      externalId: rawData.aws_iam_role_external_id,
      awsAccessKey: rawData.aws_access_key,
      awsSecretAccessKey: rawData.aws_secret_key,
      bucket: rawData.s3_bucket,
      prefix: rawData.s3_path_prefix,
      bucketRegion: S3_BUCKET_REGIONS.find(
        (region: any) => region.value === rawData.s3_bucket_region
      ),
      compressionSupported: coerceBooleanProperty(rawData.should_gzip_file)
        ? S3_NEW_DEST_COMPRESSION_SUPPORTED[2]
        : S3_NEW_DEST_COMPRESSION_SUPPORTED.find(
            (format: any) => format.value === rawData.compression_codec
          ),
      fileFormat: rawData.file_type
    };
  }

  getDefaultConfig() {
    return {
      authType: AWSAuthenticationType.IAM_ROLE,
      gzip: true,
      fileFormat: S3DestFileFormat.JSON
    };
  }

  buildConnectionParams(data: any) {
    let shouldGzipFile = false;
    if (data.fileFormat && data.fileFormat === S3DestFileFormat.JSON) {
      if (
        data.compressionSupported &&
        data.compressionSupported.value === S3_NEW_DEST_COMPRESSION_SUPPORTED[2].value
      ) {
        shouldGzipFile = true;
      }
    }
    const params: any = {
      auth_type: data.authType,
      aws_iam_role_arn: data.amazonResourceName,
      aws_iam_role_external_id: data.externalId,
      aws_access_key: data.awsAccessKey,
      aws_secret_key: data.awsSecretAccessKey,
      s3_bucket: data.bucket,
      s3_bucket_region: data.bucketRegion?.value,
      should_gzip_file: shouldGzipFile,
      file_type: data.fileFormat ? data.fileFormat : undefined,
      s3_path_prefix: data.prefix,
      compression_codec:
        !data.compressionSupported ||
        data.compressionSupported.value === S3_NEW_DEST_COMPRESSION_SUPPORTED[2].value
          ? undefined
          : data.compressionSupported.value
    };

    return params;
  }
}
