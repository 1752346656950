import React, { useEffect } from 'react';
import {
  catchError,
  distinctUntilChanged,
  exhaustMap,
  filter,
  map,
  share,
  startWith,
  switchMap,
  take,
  tap
} from 'rxjs/operators';
import { EMPTY, combineLatest, of, timer } from 'rxjs';
import { DestinationService } from '../../../containers/core/services/destination.service';
import { BannerColorPalette } from '../interface';
import { NPSService } from '../../../containers/core/services/nps.service';
import { PlanChatSupportService } from '../../../containers/core/services/plan-chat-support.service';
import { NPSBanner } from '../../../containers/dashboard/NPSBanner';
import { BillingProvider, MarketplaceProviders } from '../../../containers/payment/models/payment';
import { StatsAPI } from '../../../containers/stats/StatsAPI';
import useService from '../../../hooks/useService';
import { HeadwayService } from '../../../containers/core/services/headway.service';
import useBanner from '../../../hooks/useBanner';
import NewFeaturesBanner from '../../../containers/dashboard/NewFeaturesBanner';
import EmailVerifiedBanner from '../../../containers/dashboard/EmailVerifiedBanner';
import { EmailVerificationService } from '../../../containers/core/services/email-verification.service';

import { VersionService } from '../../../containers/core/services/version.service';
import VersionUpdateBannerMessage from '../../../containers/dashboard/VersionUpdateBannerMessage';
import useAnalyticsTracker from '../../../hooks/useAnalyticsTracker';
import {
  TRACKER_DPA_CLOSE,
  TRACKER_DPA_CLOSE_CLICK,
  TRACKER_DPA_OPEN,
  TRACKER_OVERAGE_CLOSE,
  TRACKER_OVERAGE_CLOSE_CLICK,
  TRACKER_OVERAGE_OPEN,
  TRACKER_RATE_LIMIT_BANNER_OPEN,
  TRACKER_VERSION_UPDATE_OPEN
} from '../../../containers/dashboard/constants';
import DefaultBanner from '../DefaultBanner';
import { TrialDetailsService } from '../../../containers/core/services/trial-details.service';
import DpaBannerMessage from '../../../containers/dashboard/DpaBannerMessage';
import { AuthService } from '../../../containers/core/services/auth.service';
import {
  DPA_ACCEPTED_BY_USER_ID_KEY,
  RATE_LIMIT_POLL_INTERVAL,
  SNOWFLAKE_BANNER_LAST_CLOSED_KEY,
  SNOWFLAKE_BANNER_MESSAGE,
  TRIAL_REMINDER_THRESHOLD_DAYS
} from '../../../containers/core/constants';
import { OverageDataService } from '../../../containers/core/services/overage-data.service';
import OverageBannerMessage from '../../../containers/dashboard/OverageBannerMessage';
import RateLimitBannerMessage from '../../../containers/dashboard/RateLimitBannerMessage';
import {
  RateLimitViolation,
  getRateLimitViolationTitle
} from '../../../containers/core/models/rate-limit-violation';
import { PaymentService } from '../../../containers/core/services/payment.service';
import PaymentPendingBanner from '../../../containers/dashboard/PaymentPendingBanner';
import TrialExpiryReminderBanner from '../../../containers/dashboard/TrialExpiryReminderBanner';
import { getDaysFromTimestamp } from '../../../legacy-utils/date';
import { UsageAlertService } from '../../../containers/core/services/usage-alert.service';
import UsageAlertBanner from '../../../containers/dashboard/UsageAlertBanner';
import {
  canShowUsageAlert,
  hasUsageAlertStatusChanged
} from '../../../containers/payment/utils/usage-alert';
import { UsageStatus } from '../../../containers/payment/models/usage-status';
import { BannerData } from '../DefaultBanner/interface';

enum DashboardBannerIdMappingEnum {
  RATE_LIMIT_ID = 'rate-limit',
  VERSION_UPDATE_ID = 'version-update',
  NEW_FEATURES_ID = 'new-features',
  TRIAL_EXPIRY_ID = 'trial-expiry',
  EMAIL_VERIFIED_ID = 'email-verified',
  NPS_ID = 'nps',
  DPA_ID = 'dpa',
  USAGE_STATUS_ID = 'usage-status',
  OVERAGE_ID = 'overage',
  PAYMENT_PENDING_ID = 'payment-pending',
  GOOGLE_ADS_DEPRECATE_ID = 'google-ads-deprecate-id',
  SNOWFLAKE_PASSWORD_BASED_DESTINATION_ID = 'snowflake-password-based-destination'
}

export function BannerOutlet() {
  const headwayService = useService(HeadwayService);
  const emailVerificationService = useService(EmailVerificationService);
  const versionService = useService(VersionService);
  const trialDetailsService = useService(TrialDetailsService);
  const authService = useService(AuthService);
  const overageService = useService(OverageDataService);
  const paymentService = useService(PaymentService);
  const usageAlertService = useService(UsageAlertService);
  const npsService = useService(NPSService);
  const planChatSupportService = useService(PlanChatSupportService);
  const destinationService = useService(DestinationService);
  const analyticsTracker = useAnalyticsTracker();

  const { registerNotifiers, notify, notificationData, NotificationComponent, removeNotification } =
    useBanner();

  useEffect(() => {
    registerNotifiers([
      {
        id: DashboardBannerIdMappingEnum.EMAIL_VERIFIED_ID,
        component: EmailVerifiedBanner,
        priority: 70
      },
      {
        id: DashboardBannerIdMappingEnum.RATE_LIMIT_ID,
        component: DefaultBanner,
        priority: 70
      },
      {
        id: DashboardBannerIdMappingEnum.NEW_FEATURES_ID,
        component: NewFeaturesBanner,
        priority: 80
      },
      {
        id: DashboardBannerIdMappingEnum.SNOWFLAKE_PASSWORD_BASED_DESTINATION_ID,
        component: DefaultBanner,
        priority: 82
      },
      {
        id: DashboardBannerIdMappingEnum.OVERAGE_ID,
        component: DefaultBanner,
        priority: 85
      },
      {
        id: DashboardBannerIdMappingEnum.DPA_ID,
        component: DefaultBanner,
        priority: 90
      },
      {
        id: DashboardBannerIdMappingEnum.TRIAL_EXPIRY_ID,
        component: TrialExpiryReminderBanner,
        priority: 90
      },
      {
        id: DashboardBannerIdMappingEnum.USAGE_STATUS_ID,
        component: UsageAlertBanner,
        priority: 98
      },
      {
        id: DashboardBannerIdMappingEnum.NPS_ID,
        component: NPSBanner,
        priority: 95
      },
      {
        id: DashboardBannerIdMappingEnum.PAYMENT_PENDING_ID,
        component: PaymentPendingBanner,
        priority: 100
      },
      {
        id: DashboardBannerIdMappingEnum.VERSION_UPDATE_ID,
        component: DefaultBanner,
        priority: 101
      }
    ]);

    const _newFeatures$ = headwayService.unreadCountSubject.pipe(
      tap(count => {
        if (count <= 0) {
          return;
        }
        notify({
          id: DashboardBannerIdMappingEnum.NEW_FEATURES_ID,
          data: {}
        });
      }),
      catchError(() => EMPTY),
      share()
    );

    const _emailVerified$ = emailVerificationService.emailVerified$.pipe(
      filter((data: any) => data.verified === false && !data.blocked),
      take(1),
      tap(data => {
        notify({
          id: DashboardBannerIdMappingEnum.EMAIL_VERIFIED_ID,
          data: {}
        });
      }),
      startWith(null),
      catchError(() => EMPTY),
      share()
    );

    const _versionUpdate$ = versionService.getVersionUpdateObservable().pipe(
      tap(() => {
        notify<BannerData>({
          id: DashboardBannerIdMappingEnum.VERSION_UPDATE_ID,
          data: {
            color: BannerColorPalette.INFO,
            bannerTitle: 'Time for a Refresh!',
            message: VersionUpdateBannerMessage,
            canClose: false,
            thumbnailSrc:
              'https://res.cloudinary.com/hevo/image/upload/v1661186305/dashboard/version-update_kecs5g.svg',
            openCallback: () => {
              analyticsTracker.eventTrack({
                action: TRACKER_VERSION_UPDATE_OPEN
              });
            }
          }
        });
      }),
      startWith(null),
      catchError(() => EMPTY),
      share()
    );

    const _dpa$ = trialDetailsService.trialDetails$.pipe(
      filter(data => {
        const user = authService.getUser();
        if (user && user.email.includes('@hevodata.com')) {
          return false;
        }

        return data && data.config && !data.config[DPA_ACCEPTED_BY_USER_ID_KEY];
      }),
      take(1),
      tap(() => {
        notify<BannerData>({
          id: DashboardBannerIdMappingEnum.DPA_ID,
          data: {
            color: BannerColorPalette.DEFERRED,
            bannerTitle: 'Data Privacy Update!',
            message: DpaBannerMessage,
            canClose: true,
            thumbnailName: 'security-check',
            openCallback: () => {
              analyticsTracker.eventTrack({
                action: TRACKER_DPA_OPEN
              });
            },
            closeClickCallback: () => {
              analyticsTracker.eventTrack({
                action: TRACKER_DPA_CLOSE_CLICK
              });
            },
            closeCallback: () => {
              analyticsTracker.eventTrack({
                action: TRACKER_DPA_CLOSE
              });
            }
          }
        });
      }),
      startWith(null),
      catchError(() => EMPTY),
      share()
    );

    const _overage$ = planChatSupportService.currentPlan$.pipe(
      filter(p => p && !MarketplaceProviders.includes(p.paymentSource as BillingProvider)),
      switchMap(() => overageService.showOverage$),
      tap(data => {
        notify<BannerData>({
          id: DashboardBannerIdMappingEnum.OVERAGE_ID,
          data: {
            color: BannerColorPalette.DEFERRED,
            bannerTitle: 'Events Quota Surpassed!',
            message: OverageBannerMessage,
            messageContext: data,
            canClose: true,
            thumbnailName: 'info',
            openCallback: () => {
              analyticsTracker.eventTrack({
                action: TRACKER_OVERAGE_OPEN
              });
            },
            closeClickCallback: () => {
              analyticsTracker.eventTrack({
                action: TRACKER_OVERAGE_CLOSE_CLICK
              });
            },
            closeCallback: () => {
              analyticsTracker.eventTrack({
                action: TRACKER_OVERAGE_CLOSE
              });

              overageService.hideTillNextPayment();
            }
          }
        });
      }),
      startWith(null),
      catchError(() => EMPTY),
      share()
    );

    const _rateLimit$ = of([]).pipe(
      filter(() => {
        // TODO: Why is this filtered?
        const user = authService.getUser();
        return user && user.email.includes('@hevodata.com');
      }),
      switchMap(() =>
        timer(0, RATE_LIMIT_POLL_INTERVAL).pipe(
          exhaustMap(() => StatsAPI.getRateLimit()),
          tap((res: any) => {
            const rateLimitViolation: RateLimitViolation = res.data;
            if (rateLimitViolation && rateLimitViolation.ok === false) {
              notify<BannerData>({
                id: DashboardBannerIdMappingEnum.RATE_LIMIT_ID,
                data: {
                  color: BannerColorPalette.DEFERRED,
                  bannerTitle: getRateLimitViolationTitle(rateLimitViolation),
                  message: RateLimitBannerMessage,
                  messageContext: rateLimitViolation,
                  canClose: false,
                  openCallback: () => {
                    analyticsTracker.eventTrack({
                      action: TRACKER_RATE_LIMIT_BANNER_OPEN
                    });
                  }
                }
              });
            }
          })
        )
      ),
      startWith(null),
      catchError(() => EMPTY),
      share()
    );

    const _paymentPending$ = paymentService.getPendingInvoicesDetails().pipe(
      tap(res => {
        const { data } = res;
        if (authService.getUser().isOwner && data?.show_alert) {
          notify({
            id: DashboardBannerIdMappingEnum.PAYMENT_PENDING_ID,
            data
          });
        }
      }),
      startWith(null),
      catchError(() => EMPTY),
      share()
    );

    const _trialExpiry$ = trialDetailsService.trialDetails$.pipe(
      distinctUntilChanged((prev, curr) => prev.getDaysLeft() === curr.getDaysLeft()),
      map(details => details.trialEndTs),
      tap(trialEndTs => {
        const trialPeriodLeft = trialEndTs - new Date().getTime();
        if (
          trialPeriodLeft > 0 &&
          getDaysFromTimestamp(trialPeriodLeft) <= TRIAL_REMINDER_THRESHOLD_DAYS
        ) {
          notify({
            id: DashboardBannerIdMappingEnum.TRIAL_EXPIRY_ID,
            data: {
              trialPeriodLeft
            }
          });
        }
      }),
      startWith(null),
      catchError(() => EMPTY),
      share()
    );

    const _snowflakePasswordBasedDestination$ = destinationService
      .getDestinations({
        type: 'SNOWFLAKE',
        'active-only': true
      })
      .pipe(
        map((res: any) => {
          if (!res || !res.data) {
            return [];
          }
          return res.data.filter((item: any) => !item.config?.private_key);
        }),
        filter(destinations => {
          if (!destinations.length) {
            return false;
          }
          // Need to show banner only if it is closed more than 24 hours ago
          const lastClosed = localStorage.getItem(SNOWFLAKE_BANNER_LAST_CLOSED_KEY);
          return !lastClosed || Date.now() - parseInt(lastClosed, 10) > 24 * 60 * 60 * 1000;
        }),
        tap(() => {
          notify({
            id: DashboardBannerIdMappingEnum.SNOWFLAKE_PASSWORD_BASED_DESTINATION_ID,
            data: {
              message: SNOWFLAKE_BANNER_MESSAGE,
              canClose: true,
              closeCallback: () => {
                localStorage.setItem(SNOWFLAKE_BANNER_LAST_CLOSED_KEY, Date.now().toString());
              }
            }
          });
        }),
        startWith(null),
        catchError(() => EMPTY),
        share()
      );

    const _showNps$ = npsService.npsStatus$.pipe(
      filter(data => data.request_feedback),
      tap(data => {
        notify({
          id: DashboardBannerIdMappingEnum.NPS_ID,
          data
        });
      }),
      startWith(null),
      catchError(() => EMPTY),
      share()
    );

    /**
     * TODO remove alert filter for AWS once backend supports it
     */
    const _usageAlert$ = planChatSupportService.currentPlan$.pipe(
      filter(p => p && !MarketplaceProviders.includes(p.paymentSource as BillingProvider)),
      switchMap(() => usageAlertService.usageAlert$),
      filter(usageAlertData => {
        if (!canShowUsageAlert(usageAlertData)) {
          removeNotification(DashboardBannerIdMappingEnum.USAGE_STATUS_ID);
          return false;
        }

        if (
          usageAlertData.status === UsageStatus.EARLY_CONSUMPTION &&
          usageAlertService.getStoredStatus() === UsageStatus.EARLY_CONSUMPTION
        ) {
          return false;
        }

        if (usageAlertData.status !== UsageStatus.EARLY_CONSUMPTION) {
          usageAlertService.storeStatusLocally();
        }

        return true;
      }),
      distinctUntilChanged((a, b) => hasUsageAlertStatusChanged(a, b)),
      tap(usageAlertData => {
        notify({
          id: DashboardBannerIdMappingEnum.USAGE_STATUS_ID,
          data: {
            usageData: usageAlertData
          }
        });
      }),
      startWith(null),
      catchError(() => EMPTY),
      share()
    );

    const subscription = combineLatest([
      _newFeatures$,
      _emailVerified$,
      _versionUpdate$,
      _dpa$,
      _overage$,
      _rateLimit$,
      _paymentPending$,
      _trialExpiry$,
      _usageAlert$,
      _showNps$,
      _snowflakePasswordBasedDestination$
    ]).subscribe();

    return () => {
      subscription.unsubscribe();
    };
  }, []);

  const onBannerClose = () => {
    removeNotification(notificationData.id);
  };

  return (
    <div>
      {NotificationComponent && (
        <NotificationComponent {...notificationData} close={onBannerClose} />
      )}
    </div>
  );
}
