import { SourceTypeConfigHelper } from '../../../../../components/Node/source-settings-form/config-helper';
import { PendoRegion } from './models';

export class PendoConfigHelper implements SourceTypeConfigHelper {
  getDefaultConfig() {
    return {
      region: PendoRegion.US
    };
  }

  getConfigFromRawData(rawData: any) {
    const config: any = {
      region: rawData.region,
      integrationKey: rawData.pendo_integration_key
    };
    return config;
  }

  buildConnectionParams(data: any) {
    return {
      region: data.region,
      pendo_integration_key: data.integrationKey
    };
  }
}
