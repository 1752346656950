import dayjs from 'dayjs';

export function getLatencyFormattedTime(milliseconds: number) {
  const hours = dayjs.utc(milliseconds).hour();
  const minutes = dayjs.utc(milliseconds).minute();
  const seconds = dayjs.utc(milliseconds).second();

  return { hours, minutes, seconds, milliseconds };
}

export function getLatencyTimeLabel(milliseconds: number) {
  const { hours, minutes, seconds } = getLatencyFormattedTime(milliseconds);

  if (hours > 0 && minutes > 0) {
    return `${hours} hr ${minutes} mins`;
  }

  if (hours > 0 && minutes <= 0) {
    return `${hours} hr`;
  }

  if (minutes > 0 && seconds > 0) {
    return `${minutes} min ${seconds} sec`;
  }

  if (minutes > 0 && seconds <= 0) {
    return `${minutes} min`;
  }

  if (seconds > 0 && milliseconds % 1000 !== 0) {
    return `${seconds} sec ${Math.abs(seconds * 1000 - milliseconds)} ms`;
  }

  return `${milliseconds} ms`;
}

export const getSecondsFromMins = mins => mins * 60;
