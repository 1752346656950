import { NetworkRequestOptions } from '../core/models/request';
import { AppConfig } from '../core/app.config';
import { courier } from '../core/services/courier.service';
import {
  BACKGROUND_NETWORK_REQ_OPTIONS,
  BACKGROUND_NETWORK_REQ_UI_OPTIONS,
  DEFAULT_NETWORK_REQ_OPTIONS
} from '../core/constants';
import { _authService } from '../core/services/auth.service';
import { createHttpParams } from '../../legacy-utils/request';
import { _userServiceInstance } from '../core/services/user.service';

const appConfig = new AppConfig();
const AccountsAPI = {
  authURL: `${appConfig.getSecurityServiceURL()}/auth`,
  userDetailsURL: `${appConfig.getSecurityServiceURL()}/users/me`,
  changePasswordUrl: `${appConfig.getSecurityServiceURL()}/auth/change-password`,
  externalKeysUrl: `${appConfig.getSecurityServiceURL()}/external-keys`,
  tfaAuthURL: `${appConfig.getSecurityServiceURL()}/tfa`,
  loginURL: `${appConfig.getSecurityServiceURL()}/authenticate`,

  changePassword(currentPassword: string, newPassword: string) {
    const requestUrl = this.changePasswordUrl;

    const params = {
      old_password: currentPassword,
      new_password: newPassword
    };

    const options: NetworkRequestOptions = {
      uiOptions: {
        ...BACKGROUND_NETWORK_REQ_UI_OPTIONS,
        showSuccessMsg: true
      }
    };

    return courier.put(requestUrl, options, params);
  },

  logoutAfterPasswordChanged() {
    return _authService
      .logout(true)
      .pipe()
      .subscribe(() => {});
  },

  createApiKey() {
    const options: NetworkRequestOptions = {
      uiOptions: {
        showLoading: false
      }
    };

    return courier.post(this.externalKeysUrl, options);
  },

  deleteApiKey(accessKey: string) {
    const requestUrl = `${this.externalKeysUrl}/${accessKey}`;

    const options: NetworkRequestOptions = {
      uiOptions: {
        showLoading: false
      }
    };

    return courier.delete(requestUrl, options);
  },

  getApiKeys() {
    const options: NetworkRequestOptions = {
      uiOptions: {
        showSuccessMsg: false,
        showLoading: false
      }
    };

    return courier.get(this.externalKeysUrl, options);
  },

  getTfaQR(handleUnauthorisedResponse = true) {
    const requestUrl = `${this.tfaAuthURL}/qr`;

    const options: NetworkRequestOptions = {
      uiOptions: {
        showLoading: false,
        showErrorMsg: false,
        handleUnauthorisedResponse
      }
    };

    return courier.get(requestUrl, options);
  },

  validateTfaToken(params: any, handleUnauthorisedResponse = true, showErrorMsg = true) {
    const requestUrl = `${this.tfaAuthURL}/setup`;

    const options: NetworkRequestOptions = {
      uiOptions: {
        showLoading: false,
        showErrorMsg,
        showSuccessMsg: false,
        handleUnauthorisedResponse
      }
    };

    return courier.post(requestUrl, options, params);
  },

  submitOTP(otp: any) {
    const requestUrl = `${this.loginURL}/otp`;

    const params = { otp };

    const options: NetworkRequestOptions = {
      uiOptions: {
        ...BACKGROUND_NETWORK_REQ_UI_OPTIONS,
        handleUnauthorisedResponse: false
      }
    };

    return courier.post(requestUrl, options, params);
  },

  getTfaRecoveryCodes() {
    const requestUrl = `${this.tfaAuthURL}/recovery-codes`;

    return courier.get(requestUrl, BACKGROUND_NETWORK_REQ_OPTIONS);
  },

  regenerateCodes() {
    const requestUrl = `${this.tfaAuthURL}/recovery-codes`;

    const options: NetworkRequestOptions = {
      uiOptions: {
        showLoading: false
      }
    };

    return courier.post(requestUrl, options);
  },

  disableTFA(userId?: number) {
    let requestUrl = `${this.tfaAuthURL}/disable`;

    if (userId) {
      requestUrl += `/${userId}`;
    }

    return courier.post(requestUrl);
  },

  getLoggedInUser(updateThirdPartyData = true) {
    const options: NetworkRequestOptions = DEFAULT_NETWORK_REQ_OPTIONS;
    options.uiOptions.showSuccessMsg = false;
    options.uiOptions.showErrorMsg = false;

    return courier
      .get(this.userDetailsURL, options)
      .then((res: any) => {
        _userServiceInstance.setUser(res.data);

        if (updateThirdPartyData) {
          _userServiceInstance.updateThirdPartyData(res.data);
        }
      })
      .catch(error => {
        if (error.status === 404) {
          this.onUnAuthorisedEvent(false);
        }
        throw error;
      });
  },

  onUnAuthorisedEvent(handleNext = true) {
    return _authService
      .logout(handleNext)
      .pipe()
      .subscribe(() => {});
  },

  getAuthenticationConfig(
    email: string,
    fetchConfigDetails = false,
    handleUnauthorisedResponse = true,
    showLoading = true,
    showErrorMsg = true
  ) {
    const requestUrl = `${this.authURL}/auth-configurations`;

    const options: NetworkRequestOptions = {
      uiOptions: {
        handleUnauthorisedResponse,
        showLoading,
        showErrorMsg
      },
      networkOptions: {
        params: createHttpParams({
          email,
          display_config_details: fetchConfigDetails
        })
      }
    };

    return courier.get(requestUrl, options);
  }
};

export default AccountsAPI;
