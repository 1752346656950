import { SourceTypeConfigHelper } from '../../../../../components/Node/source-settings-form/config-helper';
import PipelinesAPI from '../../../PipelinesAPI';

export class SnapchatAdsConfigHelper implements SourceTypeConfigHelper {
  getDefaultConfig(metaData: any) {
    return {
      accounts: metaData.accountList,
      organizations: metaData.organizationList,
      swipeAttributionWindow: metaData.advancedSettingsOptions?.swipeAttributionWindows?.[0],
      viewAttributionWindow: metaData.advancedSettingsOptions?.viewAttributionWindows?.[0]
    };
  }

  getConfigFromRawData(rawData: any, metaData: any) {
    return {
      swipeAttributionWindow: rawData.swipe_attribution_window,
      viewAttributionWindow: rawData.view_attribution_window,
      organizations: this.fetchListFromRawData(rawData.ad_orgs || [], metaData.organizationList),
      accounts: this.fetchListFromRawData(rawData.ad_accounts || [], metaData.accountList)
    };
  }

  fetchListFromRawData(selectedList: string[], rawList: any[]) {
    return (selectedList || []).map((id: string) => rawList.find((obj: any) => obj.id === id));
  }

  buildConnectionParams(data: any) {
    // logic for removing an organization if no account is select related to that organization.

    const orgs = (data.organizations || [])
      .filter((org: any) => {
        const includedAccounts = org.accounts.filter((account: any) =>
          data.accounts.some((selectedAccount: any) => account.id === selectedAccount.id)
        );

        return includedAccounts.length > 0;
      })
      .map((org: any) => org.id);

    return {
      ad_orgs: orgs,
      ad_accounts: (data.accounts || []).map((selectedAccount: any) => selectedAccount.id),
      swipe_attribution_window: data.swipeAttributionWindow,
      view_attribution_window: data.viewAttributionWindow
    };
  }

  metaDataResolver(sourceType: string, authData: any) {
    return Promise.all([
      PipelinesAPI.getSnapchatAdsMetadata(authData.oauthTokenId),
      PipelinesAPI.getSnapchatAdsSettings()
    ]).then(res => ({
      accountList: res[0].reduce((acc: any, org: any) => [...acc, ...org.accounts], []),
      organizationList: res[0],
      advancedSettingsOptions: {
        swipeAttributionWindows: res[1].swipe_attribution_windows,
        viewAttributionWindows: res[1].view_attribution_windows
      }
    }));
  }
}
