import { FilterOption } from '../components/ListFilter/models/filter-option';
import { TargetTypeInfo } from '../containers/activate-target/models/target-type-info';


export function buildOptionsFromDestinations(destinations: any): FilterOption<any>[] {
  return destinations.map((dest: any) => {
    return {
      name: dest.name,
      value: dest.id,
      img: dest.dest_type_logo_url,
      darkModeImg: dest.dest_type_dark_mode_logo_url,
      type: dest.dest_type
    };
  });
}

export function buildOptionsFromTargets(targets: any): FilterOption<any>[] {
  return targets.map((target: any) => {
    return {
      name: target.name,
      value: target.id,
      img: target.type_logo_url,
      darkModeImg: target.type_dark_mode_logo_url,
      type: target.type
    };
  });
}

export function buildOptionsFromTargetTypes(targetTypes: TargetTypeInfo[]): FilterOption<any>[] {
  return targetTypes.map((targetType: TargetTypeInfo) => {
    return {
      name: targetType.typeDisplay,
      value: targetType.type,
      img: targetType.logoURL,
      darkModeImg: targetType.logoURLDarkMode,
      type: targetType.type
    };
  });
}


export function buildFilterOptionsFromSourceTypes(sources: any): FilterOption<any>[] {
  return sources.map((source: any) => {
    return {
      name: source.source_type_display,
      value: source.source_id,
      img: source.source_type_logo_url,
      darkModeImg: source.source_type_dark_mode_logo_url,
      type: source.source_type
    };
  });
}
