import { NodeTypeConfigHelper } from '../../../../../components/Node/node-config-helper';

export class MarketoConfigHelper implements NodeTypeConfigHelper {
  getConfigFromRawData(rawData) {
    return {
      clientID: rawData.client_id,
      clientSecret: rawData.client_secret,
      subDomain: rawData.subdomain
    };
  }

  getDefaultConfig() {
    return {};
  }

  buildConnectionParams(data) {
    return {
      client_id: data.clientID,
      client_secret: data.clientSecret,
      subdomain: data.subDomain
    };
  }
}
