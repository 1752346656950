import { CreatePipelineStepPaths } from '../../../containers/pipeline/create/state-machine/step-paths';

export interface JobMode {
  name?: string;
  desc?: string;
  value: string;
  historicalMode?: boolean;
  settingsPath?: string;
  showHistoricalModeWarning?: boolean;
  canMergeTables?: boolean;
  canProvideMultipleDbNames?: boolean;
  multipleDbSelectionDisabled?: boolean;
  canShowRedoLogAdvancedSettings?: boolean;
  hideSchemaName?: boolean;
  isRecommended?: boolean;
  icon?: string;
  showAdvancedSettings?: boolean;
  showSetupGuide?: boolean;
  canSkipNewTables?: boolean;
  virtualValue?: string;
}

export class JobModeEnum {
  static TABLE: JobMode = {
    name: 'Table',
    value: 'table',
    desc: 'Data is ingested by running SQL queries on your tables. ' +
      'This mode provides change data capture through modified/updated timestamp columns. ' +
      'Deletes are not replicated to the Destination.',
    icon: 'table',
    showAdvancedSettings: false,
    showSetupGuide: true,
    canSkipNewTables: true,
    historicalMode: false
  };
  static QUERY: JobMode = {
    name: 'Custom SQL',
    value: 'query',
    desc: 'Data is ingested by running a custom SQL query provided by you on the database. ' +
      'With this mode, you can replicate database views or selective data sets queried through the SQL query. ' +
      'Deletes are not replicated to the Destination.',
    settingsPath: CreatePipelineStepPaths.CUSTOM_SQL_SETTINGS,
    icon: 'custom-sql',
    showAdvancedSettings: false,
    showSetupGuide: true
  };
  static COLLECTION: JobMode = {
    name: 'Collections',
    desc: 'Hevo polls all of your collections individually at a fixed frequency. ' +
      'Use this mode when you are looking to fetch data from multiple collections in your database.',
    value: 'collection'
  };
  static OPLOG: JobMode = {
    name: 'OpLog',
    desc: 'Data is ingested through Change Data Capture (CDC) performed using the MongoDB OpLog.',
    value: 'oplog',
    historicalMode: true,
    canMergeTables: true,
    canProvideMultipleDbNames: true,
    showAdvancedSettings: true,
    showSetupGuide: true,
    canSkipNewTables: true,
    isRecommended: true
  };
  static CHANGE_STREAMS: JobMode = {
    name: 'Change Streams',
    desc: 'Data is ingested through Change Data Capture (CDC) performed using Change Streams introduced in MongoDB version 4.0.',
    value: 'change_streams',
    historicalMode: true,
    canMergeTables: true,
    canProvideMultipleDbNames: true,
    showAdvancedSettings: true,
    multipleDbSelectionDisabled: true,
    showSetupGuide: true,
    canSkipNewTables: true
  };
  static BINLOG: JobMode = {
    name: 'BinLog',
    desc: 'Data is ingested using the MySQL BinLog. ' +
      'This is the most efficient way to set up real-time change data capture. ' +
      'Deletes are also replicated to the Destination.',
    value: 'wal',
    historicalMode: true,
    canMergeTables: true,
    canProvideMultipleDbNames: true,
    isRecommended: true,
    icon: 'binlog',
    showAdvancedSettings: true,
    showSetupGuide: true,
    canSkipNewTables: true
  };

  static BINLOG_AURORA: JobMode = {
    name: 'BinLog',
    desc: 'Data is ingested using the Aurora BinLog. ' +
      'This is the most efficient way to set up real-time change data capture. ' +
      'Deletes are also replicated to the Destination.',
    value: 'wal',
    historicalMode: true,
    canMergeTables: true,
    canProvideMultipleDbNames: true,
    isRecommended: true,
    icon: 'binlog',
    showAdvancedSettings: true,
    showSetupGuide: true,
    canSkipNewTables: true
  };
  static LOGIC_REPL: JobMode = {
    name: 'Logical Replication',
    value: 'wal',
    desc: 'Data is ingested using PostgreSQL Write Ahead Logs (WALs). ' +
      'This is the most efficient way to set up real-time change data capture. ' +
      'Deletes are also replicated to the Destination.',
    historicalMode: true,
    canMergeTables: true,
    hideSchemaName: true,
    isRecommended: true,
    showAdvancedSettings: true,
    icon: 'extract',
    canSkipNewTables: true
  };

  static XMIN: JobMode = {
    name: 'XMIN',
    value: 'xmin',
    desc: 'Incrementally reads insert and updates via the XMIN system column. ' +
      'We cannot replicate deletes via this mode.',
    icon: 'xmin',
    historicalMode: true,
    canMergeTables: true,
    hideSchemaName: true,
    showAdvancedSettings: true,
    canSkipNewTables: true
  };

  static REDO_LOG: JobMode = {
    name: 'RedoLog',
    value: 'wal',
    desc: 'Data is ingested using the Oracle\'s logminer utility. ' +
      'This is the most efficient way to set up real-time change data capture. ' +
      'Deletes are also replicated to the Destination.',
    historicalMode: true,
    canMergeTables: true,
    hideSchemaName: true,
    canShowRedoLogAdvancedSettings: true,
    isRecommended: true,
    showAdvancedSettings: true,
    icon: 'extract',
    canSkipNewTables: true
  };
  static CHANGE_TRACKING: JobMode = {
    name: 'Change Tracking',
    value: 'table',
    virtualValue: 'change_tracking',
    desc: 'Data is ingested using MSSQL’s Change Tracking mode. ' +
      'To use this, you will need to enable Change Tracking on tables.',
    icon: 'change-stream',
    showAdvancedSettings: false,
    showSetupGuide: true,
    canSkipNewTables: true,
    isRecommended: true,
    historicalMode: false
  };
  static APPSFLYER: JobMode = {
    value: 'appsflyer'
  };
  static FBA: JobMode = {
    value: 'fba'
  };
  static ELASTICSEARCH: JobMode = {
    value: 'es',
    canSkipNewTables: true,
    historicalMode: true
  };
  static DYNAMODB: JobMode = {
    value: 'dynamodb',
    canSkipNewTables: true
  };
  static SALESFORCE: JobMode = {
    value: 'salesforce',
    canSkipNewTables: true
  };
}

