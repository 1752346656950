import { useEffect, useState } from 'react';
import { RecaptchaService } from './recaptcha.ts/recaptcha.service';

export function useRecaptchaService(container) {
  const [ isRecaptchaReady, setIsRecaptchaReady ] = useState(false);
  const [ recaptchaService, setRecaptchaService ] = useState<RecaptchaService>(null);

  useEffect(() => {
    const service = new RecaptchaService(container);

    service.onReady(() => {
      setIsRecaptchaReady(true);
    });

    setRecaptchaService(service);
  }, []);

  return {
    isRecaptchaReady,
    validateWithRecaptchaCommon: recaptchaService?.validateWithRecaptchaCommon.bind(recaptchaService)
  };
}
