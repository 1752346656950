import { interval, Observable, of, Subject, Subscription, throwError } from 'rxjs';
import { catchError, exhaustMap, map, tap } from 'rxjs/operators';
import { onUserLogout } from '../../../actions/global';
import { coerceBooleanProperty } from '../../../legacy-utils/coercion';
import { POLL_USER_DATA_INTERVAL } from '../../auth/constants';
import { RestrictedTokenType } from '../../auth/models';

import { AuthorisedAccount } from '../../../components/Node/models/models';
import { AppConfig } from '../app.config';
import {
  BACKGROUND_NETWORK_REQ_OPTIONS,
  BACKGROUND_NETWORK_REQ_UI_OPTIONS,
  DEFAULT_NETWORK_REQ_OPTIONS,
  SESSION_KEY
} from '../constants';
import { NetworkRequestOptions } from '../models/request';
import { User } from '../models/user';
import { createHttpParams } from '../../../legacy-utils/request';
import { CookieService } from './cookie.service';
import { ReactRouterService } from './react-router.service';
import { store } from './redux.service';
import { LocalStorageService } from './local-storage.service';
import { RxRequestService } from './rx-request.service';
import { UserService } from './user.service';


export let _authService: AuthService;

export class AuthService {
  static deps = [
    AppConfig,
    RxRequestService,
    UserService,
    CookieService,
    LocalStorageService,
    ReactRouterService
  ];

  constructor(
    private _appConfig: AppConfig,
    private _rxRequestService: RxRequestService,
    private _userService: UserService,
    private _cookieService: CookieService,
    private _localStorageService: LocalStorageService,
    private _router: ReactRouterService
  ) {
    this.subscribeUnauthorisedEvent();
    _authService = this;
  }

  private _securityServiceURL = this._appConfig.getSecurityServiceURL();

  authURL = this._securityServiceURL + '/auth';
  multiRegionURL = this._securityServiceURL + '/multi-region';
  loginURL = this.authURL + '/authenticate';
  userDetailsURL = this._securityServiceURL + '/users/me';
  oAuthURL: string = this._securityServiceURL + '/oauth';
  serviceAuthURL: string = this._securityServiceURL + '/service-accounts';

  logoutSubject = new Subject<void>();
  beforeLogoutSubject = new Subject<void>();

  private _unauthorisedErrorSub: Subscription = Subscription.EMPTY;

  getLoggedInUser(
    options: NetworkRequestOptions = DEFAULT_NETWORK_REQ_OPTIONS,
    updateThirdPartyData = true,
  ): Observable<any> {

    options.uiOptions.showSuccessMsg = false;
    options.uiOptions.showErrorMsg = false;

    return this._rxRequestService.get(this.userDetailsURL, options).pipe(
      tap((res: any) => {
        this._userService.setUser(res.data);

        if (updateThirdPartyData) {
          this._userService.updateThirdPartyData(res.data);
        }
      }),
      catchError((err: any) => {
        if (err.status === 404) {
          this.onUnAuthorisedEvent(false).subscribe();
        }
        return throwError(err);
      })
    );
  }

  pollUserData(): Observable<any> {
    return interval(POLL_USER_DATA_INTERVAL).pipe(
      exhaustMap(() => {
        const requestOptions: NetworkRequestOptions = {
          uiOptions: {
            showLoading: false
          }
        };
        return this.getLoggedInUser(requestOptions, false);
      })
    );
  }

  loginWithSessionToken(token: any) {
    const requestUrl = this.multiRegionURL + '/session';
    const options: NetworkRequestOptions = {
      uiOptions: BACKGROUND_NETWORK_REQ_UI_OPTIONS,
      networkOptions: {
        params: createHttpParams({
          temp_session_token: token
        })
      }
    };
    return this._rxRequestService.get(requestUrl, options);
  }

  logout(handleNext = false, redirect = true): Observable<any> {
    const requestUrl = this.authURL + '/logout';
    const options: NetworkRequestOptions = {
      uiOptions: {
        showSuccessMsg: false,
        showErrorMsg: coerceBooleanProperty(this._localStorageService.get(SESSION_KEY))
      }
    };

    store.dispatch(onUserLogout());
    this.beforeLogoutSubject.next();

    return this._rxRequestService.post(requestUrl, options).pipe(
      tap(() => {
        this.afterLogout(handleNext, redirect);
      }),
      catchError(() => {
        this.afterLogout(handleNext, redirect);
        this.forceRemoveToken();
        return of(null);
      })
    );
  }

  afterLogout(handleNext = false, redirect = true) {
    this._userService.clearUser();

    if (this._localStorageService.get(SESSION_KEY)) {
      this._localStorageService.remove(SESSION_KEY);
    }

    let queryParams: any;

    if (handleNext) {
      queryParams = {
        next: window.location.pathname + window.location.search
      };
    }

    if (!redirect || window.location.pathname.includes('login')) {
      this.logoutSubject.next();
      return;
    }

    this._router
      .navigate({
        pathname: '/login',
        search: `?${new URLSearchParams(queryParams).toString()}`
      })
      .then(() => {
        /**
         * Defer pushing logout events until navigation is complete. This is
         * done to avoid race conditions. If a component needs to listen
         * to logout subject, it can use beforeLogoutSubject instead.
         */
        this.logoutSubject.next();
      });
  }

  forceRemoveToken() {
    this._cookieService.delete('ht');
  }

  getUser(): User {
    return this._userService.getUser();
  }

  getTokenDetails(provider: string, accountId: number) {
    const requestUrl = this.oAuthURL + `/token/${provider}/${accountId}`;

    const options: NetworkRequestOptions = {
      uiOptions: {
        showLoading: false
      }
    };

    return this._rxRequestService.get(requestUrl, options).pipe(
      map((res: any) => {
        return new AuthorisedAccount(res.data);
      })
    );
  }

  subscribeUnauthorisedEvent() {
    this._unauthorisedErrorSub = this._rxRequestService.unauthorisedErrorObs
      .pipe(
        exhaustMap((handleNext: boolean) => {
          return this.onUnAuthorisedEvent(handleNext);
        })
      )
      .subscribe();
  }

  onUnAuthorisedEvent(handleNext = true): Observable<any> {
    return this.logout(handleNext);
  }

  resendVerificationLink(showLoading = false) {
    const requestUrl: string = this._securityServiceURL + '/users/resend-email-verification';

    const options: NetworkRequestOptions = {
      uiOptions: {
        showLoading: showLoading,
        showErrorMsg: showLoading,
        showSuccessMsg: showLoading
      }
    };

    return this._rxRequestService.post(requestUrl, options);
  }

  reAuthoriseAccount(provider: string, accountId: number, next: string, cancel: string) {
    const requestUrl = `${this.oAuthURL}/reconnect/${accountId}/${provider}`;

    const options: NetworkRequestOptions = {
      networkOptions: {
        params: createHttpParams({
          next: next,
          cancel: cancel
        })
      }
    };

    return this._rxRequestService.get(requestUrl, options);
  }

  // ------- Service Account APIs ---------
  fetchServiceAccounts(provider: string): Observable<any> {
    const requestUrl = this.serviceAuthURL + `/provider/${provider}`;

    const options: NetworkRequestOptions = {
      uiOptions: {
        ...BACKGROUND_NETWORK_REQ_UI_OPTIONS,
        showErrorMsg: true
      }
    };

    return this._rxRequestService.get(requestUrl, options);
  }

  getOAuthAccountFromMarketPlaceAuthCode(provider: string, authCode: string) {
    return this._rxRequestService.post(
      `${this.oAuthURL}/token-marketplace/${provider}?auth_code=${authCode}`,
      BACKGROUND_NETWORK_REQ_OPTIONS
    );
  }
}
