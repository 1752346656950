import { execIfDefined } from '../../../legacy-utils/exec-if-defined';
import { UITracker } from '../models/tracking';
import { User } from '../models/user';

declare var smartlook: any;

export class SmartlookService implements UITracker {
  init(trackingConfig) {
    execIfDefined('smartlook', () => {
      smartlook('resume');
    });
  }

  identifyUser(user: User) {
    execIfDefined('smartlook', () => {
      smartlook(
        'identify',
        user.email,
        {
          userId: user.id,
          name: user.name,
          email: user.email
        }
      );
    });
  }

  updateIdentity(user: User) {
    this.identifyUser(user);
  }

  updateUserProperties (user: User, data: any) {
  }

  reset() {
  }

  stop() {
    execIfDefined('smartlook', () => {
      smartlook('pause');
    });
  }
}
