import { EntityIndependentRunStatus, HevoEntityItem } from '../containers/core/models/hevo-entity';

export function getEntityErrorMessage(data: { entity: HevoEntityItem, reason: string, entitySeqId: number, region?: string }) {
  return `Hey I am getting following error in ${data.entity.name} ${!!data.region ? `${data.region}-` : ''}#${data.entitySeqId} \`${data.reason}\`. Can you help?`;
}

export function getNextEntityNameForDefaultName(defaultNamePrefix: string, existingList: any[], nameSelector: (e) => string) {
  const regex = new RegExp(`^${defaultNamePrefix} ?(\\d*)$`, 'i');

  const existingId = (existingList || []).map((e) => {
    const match = nameSelector(e).replace(/\s\s+/g, ' ').trim().match(regex);
    
    return match
      ? !!match[1]
        ? parseInt(match[1], 10)
        : 0
      : null;
  }).filter((match) => {
    return Number.isInteger(match);
  }).sort((a, b) => {
    return b - a;
  })[0];

  const nextId = Number.isInteger(existingId) ? existingId + 1 : null;

  return `${defaultNamePrefix}${nextId ? ' ' + nextId : ''}`;
}

export function EntityIndependentRunStatusFactory(rawData: any): EntityIndependentRunStatus {
  if (!rawData) {
    return {
      runsOnlyInWorkflows: false,
        workflowIds: [],
        totalCount: 0
    };
  }

  return {
    runsOnlyInWorkflows: !rawData.enabled,
    workflowIds: rawData.sample_workflows,
    totalCount: rawData.total
  };
}
