export const TRACKER_INTRODUCTION_CREATE_PIPELINE_CLICK = '[Pipelines Introduction] create pipeline click';
export const TRACKER_CREATE_PREFERRED_SOURCES_PIPELINE_CLICK = '[Pipelines] create preferred sources pipeline click';
export const TRACKER_CREATE_PREFERRED_SOURCES_PIPELINE_CLICK_BLOCKED = '[Pipelines] create preferred source blocked with dialog on click';

export const TRACKER_CREATE_PIPELINE_CLICK = '[Pipelines] create pipeline click';
export const TRACKER_CREATE_DESTINATION_CLICK = '[Destinations] create destination click';
export const TRACKER_CREATE_ENTERPRISE_DESTINATION_CLICK = '[Destinations] create edge destination click';

export const TRACKER_CREATE_PIPELINE_INIT = '[Pipelines] create pipeline init';
export const TRACKER_CREATE_PIPELINE_BACK_CLICK = '[Pipelines] create pipeline back click';
export const TRACKER_CREATE_PIPELINE_CANCEL_CLICK = '[Pipelines] create pipeline cancel click';

export const TRACKER_PIPELINE_FINAL_SETTING_SUBMIT_FORM_ERROR = '[Pipeline Final Settings] form error';
export const TRACKER_CREATE_PIPELINE_FINAL_CTA = '[Pipelines] create pipeline final CTA';
export const TRACKER_CREATE_PIPELINE_SUCCESS = '[Pipelines] create pipeline success';
export const TRACKER_CREATE_PIPELINE_API_ERROR = '[Pipelines] create pipeline api error';

export const TRACKER_SOURCE_TYPE_CATEGORY_CLICK = '[Source Type] category click';
export const TRACKER_SOURCE_TYPE_SEARCH_RESULT_UPDATE = '[Source Type] search result update';
export const TRACKER_SOURCE_TYPE_SEARCH = '[Source Type] on search';
export const TRACKER_SOURCE_TYPE_EMPTY_RESULT = '[Source Type] empty search result';
export const TRACKER_SUGGEST_SOURCE_CLICK = '[Source Type] suggest source click';
export const TRACKER_SOURCE_TYPE_CLICK = '[Source Type] source type click';
export const TRACKER_STANDARD_SOURCE_TYPE_CLICK = '[Source Type] standard source type click';
export const TRACKER_EDGE_SOURCE_TYPE_CLICK = '[Source Type] edge source type click';
export const TRACKER_DISABLED_DRIVE_ADD_ACCOUNT_CLICK = '[Source Type] disabled drive add account clicked';
export const TRACKER_DISABLED_DRIVE_CREATE_REAUTHORISE_CLICK = '[Source Type] disabled drive create reauthorise clicked';
export const TRACKER_DISABLED_DRIVE_FORM_REAUTHORISE_CLICK = '[Source Type] disabled drive form reauthorise clicked';
export const TRACKER_DISABLED_DRIVE_HEADER_REAUTHORISE_CLICK = '[Source Type] disabled drive header reauthorise clicked';
export const TRACKER_SELECT_SOURCE_TYPE = '[Source Type] select type';
export const TRACKER_SELECT_DISABLED_SOURCE_TYPE = '[Source Type] select disabled type';
export const TRACKER_SELECT_UNUSED_SOURCES_CATEGORY = '[Source Type] select unused sources category';

export const TRACKER_SELECT_SOURCE_VERSION = '[Source config] select version';

export const TRACKER_SOURCE_CONFIG_UPDATE_JOB_MODE = '[Source config] update job mode';
export const TRACKER_SOURCE_CONFIG_JOB_MODES_SEE_MORE_OPTIONS_CLICK = '[Source config] job modes see more options click';
export const TRACKER_SOURCE_CONFIG_SUBMIT_CLICK = '[Source config] submit click';
export const TRACKER_SOURCE_CONFIG_SUBMIT_START = '[Source config] submit start';
export const TRACKER_SOURCE_CONFIG_SUBMIT_SUCCESS = '[Source config] submit success';
export const TRACKER_SOURCE_CONFIG_SUBMIT_FAILED = '[Source config] submit failed';
export const TRACKER_SOURCE_CONFIG_SUBMIT_FORM_ERROR = '[Source config] submit form error';
export const TRACKER_SOURCE_CONFIG_TEST_CLICK = '[Source config] test connection click';
export const TRACKER_SOURCE_CONFIG_TEST_START = '[Source config] test connection start';
export const TRACKER_SOURCE_CONFIG_TEST_SUCCESS = '[Source config] test connection success';
export const TRACKER_SOURCE_CONFIG_TEST_FORM_ERROR = '[Source config] test connection form error';
export const TRACKER_SOURCE_CONFIG_TEST_FAIL = '[Source config] test connection fail';
export const TRACKER_SOURCE_CONFIG_SUBMIT_FORM_ERROR_CLICK = '[Source config] submit form error click';
export const TRACKER_SOURCE_CONFIG_SUBMIT_FORM_ERROR_SUPPORT_CLICK = '[Source config] submit form error support click';
export const TRACKER_SOURCE_CONFIG_INVITE_MEMBER_LINK_CLICK = '[Source config] invite member link click';
export const TRACKER_SOURCE_CONFIG_ON_FORM_FIELD_INTERACTION = '[Source config] form field interaction';
export const TRACKER_SOURCE_CONFIG_ON_FORM_FIELD_FOCUSIN = '[Source config] form field focusin';
export const TRACKER_SOURCE_CONFIG_TROUBLESHOOT_BUTTON_CLICK = '[Source Config] troubleshoot button click';
export const TRACKER_SOURCE_CONFIG_PAGE_ERROR = '[Source config] page error';
export const TRACKER_SOURCE_CONFIG_PAGE_ERROR_DIAGNOSE = '[Source config] page error diagnose';
export const TRACKER_SOURCE_CONFIG_TROUBLESHOOT_DIAGNOSE_BUTTON_CLICK = '[Source config] page error diagnose button click';

export const TRACKER_OBJECT_SELECTION_SKIP_CLICK = '[Object Selection] skip click';
export const TRACKER_OBJECT_SELECTION_SKIP_SHOWN = '[Object Selection] skip shown';
export const TRACKER_OBJECT_SELECTION_OBJECTS_SHOWN = '[Object Selection] objects shown';
export const TRACKER_OBJECT_SELECTION_SELECT_ALL_CLICK = '[Object Selection] select all click';
export const TRACKER_OBJECT_SELECTION_CONTINUE_CLICK = '[Object Selection] continue click';

export const TRACKER_FOLDER_SELECTION_FALLBACK_FIELD_SHOWN = '[Folder Selection] fallback field shown';
export const TRACKER_FOLDER_SELECTION_FOLDERS_SHOWN = '[Folder Selection] folders shown';
export const TRACKER_FOLDER_SELECTION_SELECT_ALL_CLICK = '[Folder Selection] select all click';

export const TRACKER_OBJECT_CONFIGURE_SKIP_CLICK = '[Object Configure] skip click';
export const TRACKER_OBJECT_CONFIGURE_SKIP_SHOWN = '[Object Configure] skip shown';

export const TRACKER_SELECT_DEST_TYPE = '[Dest Type] select type';
export const TRACKER_SUGGEST_DESTINATION_CLICK = '[Dest Type] suggest destination click';
export const TRACKER_DESTINATION_TYPE_EMPTY_RESULT = '[Dest Type] empty result';
export const TRACKER_SELECT_EXISTING_DEST_TYPE = '[Dest Type] select existing type';
export const TRACKER_CLICK_DISABLED_DESTINATION = '[Destination List] click disabled';
export const TRACKER_DESTINATION_LIST_EMPTY_RESULT = '[Destination List] empty result';
export const TRACKER_ADD_MANAGED_WAREHOUSE_CLICK = '[Dest Type] add managed warehouse click';
export const TRACKER_MANAGED_WAREHOUSE_CARD_CLICK = '[Dest Type] managed warehouse card click';
export const TRACKER_SHOW_ALL_DESTINATION_TYPES_CLICK = '[Dest Type] show all destination types click';
export const TRACKER_ADD_NEW_DESTINATION_CLICK = '[Dest Type] select add destination button click';
export const TRACKER_DESTINATION_PERMISSION_VALIDATION_RETRY_CLICK = '[Dest Type] permission validation retry click';

export const TRACKER_SELECT_AUTH_TYPE = '[Auth] select auth type';
export const TRACKER_AUTH_ACCOUNT_CLICK = '[Auth] account click';
export const TRACKER_ADD_MORE_AUTH_ACCOUNT_CLICK = '[Auth] add more account click';
export const TRACKER_ADD_FIRST_AUTH_ACCOUNT_CLICK = '[Auth] add first account click';
export const TRACKER_ADD_AUTH_ACCOUNT_CLICK = '[Auth] add account click';
export const TRACKER_NEW_AUTH_ACCOUNT = '[Auth] new account added';
export const TRACKER_AUTH_ACCOUNT_CONTINUE_CLICK = '[Auth] continue click';
export const TRACKER_AUTH_ACCOUNT_CONTINUE = '[Auth] continue';
export const TRACKER_NEW_SERVICE_ACCOUNT_SUCCESS = '[Auth] new service account success';
export const TRACKER_NEW_SERVICE_ACCOUNT_FAILED = '[Auth] new service account failed';
export const TRACKER_SELECT_AUTH_TYPE_INVITE_MEMBER_LINK_CLICK = '[Auth] invite member link click';
export const TRACKER_VALID_ADS_ACCOUNT_FAILED = '[Auth] ads account error';

export const TRACKER_DEST_CONFIG_SUBMIT = '[Dest config] submit click';
export const TRACKER_DEST_CONFIG_SUBMIT_START = '[Dest config] submit start';
export const TRACKER_DEST_CONFIG_SUBMIT_SUCCESS = '[Dest config] submit success';
export const TRACKER_DEST_CONFIG_SUBMIT_FAILED = '[Dest config] submit failed';
export const TRACKER_DEST_CONFIG_SUBMIT_FORM_ERROR = '[Dest config] submit form error';
export const TRACKER_DEST_CONFIG_TEST = '[Dest config] test connection click';
export const TRACKER_DEST_CONFIG_TEST_START = '[Dest config] test connection start';
export const TRACKER_DEST_CONFIG_TEST_SUCCESS = '[Dest config] test connection success';
export const TRACKER_DEST_CONFIG_TEST_FORM_ERROR = '[Dest config] test connection form error';
export const TRACKER_DEST_CONFIG_TEST_FAIL = '[Dest config] test connection fail';
export const TRACKER_DEST_CONFIG_CONNECTION_SUCCESS = '[Dest config] connection success';
export const TRACKER_DEST_CONFIG_ON_FORM_FIELD_INTERACTION = '[Dest config] form field interaction';
export const TRACKER_DEST_CONFIG_ON_FORM_FIELD_FOCUSIN = '[Dest config] form field focusin';
export const TRACKER_DEST_CONFIG_SUBMIT_FORM_ERROR_SUPPORT_CLICK = '[Dest config] submit form error support click';

export const TRACKER_ADD_MANAGED_WAREHOUSE_CONTINUE_CLICK = '[Add Managed Warehouse] continue click';
export const TRACKER_ADD_MANAGED_WAREHOUSE_SUBMIT_FORM_ERROR = '[Add Managed Warehouse] submit form error';
export const TRACKER_ADD_MANAGED_WAREHOUSE_SUBMIT_FAILED = '[Add Managed Warehouse] submit failed';
export const TRACKER_ADD_MANAGED_WAREHOUSE_SUBMIT_SUCCESS = '[Add Managed Warehouse] submit success';

export const TRACKER_SELECT_BI_TOOL = '[BI tool] select';
export const TRACKER_BI_TOOL_SKIP = '[BI tool] skip';

export const TRACKER_BI_CREDENTIALS_DOWNLOAD_CLICK = '[BI credentials] download click';
export const TRACKER_BI_CREDENTIALS_CONTINUE_CLICK = '[BI credentials] continue click';

export const TRACKER_DELETE_PIPELINE_CLICK = '[Pipelines] delete pipeline click';
export const TRACKER_DELETE_PIPELINE_CONFIRM = '[Pipelines] delete pipeline confirm';
export const TRACKER_DELETE_PIPELINE_CANCEL = '[Pipelines] delete pipeline cancel';
export const TRACKER_DELETE_BLOCKED_PIPELINE_CONFIRM = '[Pipelines] delete blocked pipeline confirm';
export const TRACKER_DELETE_BLOCKED_PIPELINE_CLICK = '[Pipelines] delete blocked pipeline click';

export const TRACKER_ADD_DESTINATION_CLICK = '[Destinations] add destination click';
export const TRACKER_DELETE_DESTINATION_CLICK = '[Destinations] delete destination click';
export const TRACKER_DELETE_DESTINATION_CONFIRM = '[Destinations] delete destination confirm';

export const TRACKER_CONNECTION_TYPE_CLICK = '[Mongodb] connection type click';
export const TRACKER_SWITCH_TO_ATLAS_CLICK = '[Mongodb] switch to atlas click';
export const TRACKER_DATABASE_DROPDOWN_VISIBLE = '[Sql] database dropdown visible';
export const TRACKER_SCHEMA_DROPDOWN_VISIBLE = '[Sql] schema dropdown visible';
export const TRACKER_SQL_HOST_NAME_BLUR = '[Sql] host name blur';
export const TRACKER_DESTINATION_SQL_HOST_NAME_BLUR = '[Destination Sql] host name blur';
export const TRACKER_DESTINATION_AZURE_SYNAPSE_HOST_NAME_BLUR = '[Destination Azure Synapse] host name blur';
export const TRACKER_KAFKA_HOST_NAME_BLUR = '[Kafka] host name blur';
export const TRACKER_DESTINATION_REDSHIFT_CONNECTION_STRING = '[Destination Redshift] connection type click';
export const  TRACKER_DESTINATION_AZURE_SYNAPSE_CONNECTION_STRING = '[Destination Azure Synapse] connection type click';

export const TRACKER_TARGET_CONFIG_SUBMIT_CLICK = '[Activation Target config] submit click';
export const TRACKER_TARGET_CONFIG_SUBMIT_SUCCESS = '[Activation Target config] submit success';
export const TRACKER_TARGET_CONFIG_SUBMIT_FAILED = '[Activation Target config] submit failed';
export const TRACKER_TARGET_CONFIG_SUBMIT_FORM_ERROR = '[Activation Target config] submit form error';
export const TRACKER_TARGET_CONFIG_TEST_CLICK = '[Activation Target config] test connection click';
export const TRACKER_TARGET_CONFIG_TEST_SUCCESS = '[Activation Target config] test connection success';
export const TRACKER_TARGET_CONFIG_TEST_FAILED = '[Activation Target config] test connection failed';
export const TRACKER_TARGET_CONFIG_TEST_FORM_ERROR = '[Activation Target config] test connection form error';
export const TRACKER_TARGET_CONFIG_SUBMIT_FORM_ERROR_CLICK = '[Activation Target config] submit form error click';
export const TRACKER_TARGET_CONFIG_SUBMIT_FORM_ERROR_SUPPORT_CLICK = '[Activation Target config] submit form error support click';

export const TRACKER_NEW_TARGET_SUBMIT_CLICK = '[Activation Targets] new target submit click';
export const TRACKER_NEW_TARGET_SUCCESS = '[Activation Targets] new target success';
export const TRACKER_NEW_TARGET_FAILED = '[Activation Targets] new target failed';

export const TRACKER_SOURCE_S3_NEW_CONFIG_ON_USING_IAM_ROLE = '[S3_New] Connection type string';
export const TRACKER_SOURCE_S3_NEW_CONFIG_FILE_FORMAT = '[S3_New] Connection file format type clicked';
