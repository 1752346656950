import {
  FREQUENCY_TYPE_MINUTES,
  FREQUENCY_TYPE_HOURS
} from '../../../../scheduler/ExecutionPolicyDialog/constants';

export const ELIGIBLE_JOB_MODES_STREAMING = ['oplog', 'change_streams', 'wal', 'dynamodb'];

export enum IngestionModes {
  SCHEDULE = 'schedule',
  STREAMING = 'streaming'
}

export const DEFAULT_FREQUENCY_FOR_LOG_BASED = {
  frequencyValue: 30,
  frequencyType: FREQUENCY_TYPE_MINUTES
};

export const STREAMING_FREQUENCY = {
  frequencyValue: 4,
  frequencyType: FREQUENCY_TYPE_MINUTES
};

export const DEFAULT_FREQUENCY = {
  frequencyValue: 6,
  frequencyType: FREQUENCY_TYPE_HOURS
};

export const PIPELINE_SCHEDULER_SUGGESTED_FREQUENCIES = [1800, 3600, 21600, 28800, 43200, 86400];

export const PIPELINE_SCHEDULER_SUGGESTED_FREQUENCIES_FOR_LOG_BASED = [
  1800, 3600, 21600, 28800, 43200
];
