import { Reducer } from 'react';
import { Activation } from '../../../activate/models/activation';
import { Target } from '../../../activate-target/models/target';
import { Workflow } from '../../../workflow/models/workflow';
import { RawDestination } from '../../../destination/model';
import { RawModel } from '../../../transform-model/raw-models';
import { RawSourceType } from '../../../pipeline/create/SelectSourceType/interfaces/source-type';
import { RawPipeline } from '../../../pipeline/model';
import { RawDestinationType } from '../../../destination/create/SelectDestinationType/models';

export enum GlobalSearchReducerActionType {
  BULK_UPDATE = 'BULK_UPDATE',
  GET_ENTITY_DATA = 'GET_ENTITY_DATA',
  UPDATE_ENTITY_DATA = 'UPDATE_ENTITY_DATA',
  FILTER_DATA = 'FILTER_DATA',
  API_ERROR = 'API_ERROR'
}

export enum GlobalSearchEntity {
  PIPELINES = 'pipelines',
  SOURCE_TYPES = 'sourceTypes',
  DESTINATION_TYPES = 'destinationTypes',
  DESTINATIONS = 'destinations',
  MODELS = 'models',
  WORKFLOWS = 'workflows',
  ACTIVATIONS = 'activations',
  TARGETS = 'targets',
  ALGOLIA_SEARCH_RESULTS = 'algoliaSearchResults',
  FILTERS = 'filters'
}

export interface GlobalSearchUIState {
  dataLoadError: any;
  loadingData: boolean;
  initialDataLoaded: boolean;
}

export declare interface GlobalSearchStateEntity<T = any> {
  entities: T[];
  filteredEntities: T[];
  loadingData: boolean;
  apiError?: boolean;
}

export declare interface GlobalSearchFilters {
  search: string;
  entities?: [];
  filteredEntities?: [];
}

export interface GlobalSearchState {
  [GlobalSearchEntity.PIPELINES]: GlobalSearchStateEntity<RawPipeline>;
  [GlobalSearchEntity.MODELS]: GlobalSearchStateEntity<RawModel>;
  [GlobalSearchEntity.DESTINATIONS]: GlobalSearchStateEntity<RawDestination>;
  [GlobalSearchEntity.WORKFLOWS]: GlobalSearchStateEntity<Workflow>;
  [GlobalSearchEntity.ACTIVATIONS]: GlobalSearchStateEntity<Activation>;
  [GlobalSearchEntity.TARGETS]: GlobalSearchStateEntity<Target>;
  [GlobalSearchEntity.SOURCE_TYPES]: GlobalSearchStateEntity<RawSourceType>;
  [GlobalSearchEntity.DESTINATION_TYPES]: GlobalSearchStateEntity<RawDestinationType>;
  [GlobalSearchEntity.ALGOLIA_SEARCH_RESULTS]: GlobalSearchStateEntity<AlgoliaSearchResult>;
  [GlobalSearchEntity.FILTERS]: GlobalSearchFilters;
}

export interface GlobalSearchReducerAction {
  type: GlobalSearchReducerActionType;
  entity?: GlobalSearchEntity;
  data?: object;
}

export declare type GlobalSearchReducerType = Reducer<GlobalSearchState, GlobalSearchReducerAction>;

export interface GlobalSearchReducerActions {
  getEntityData: (entity: GlobalSearchEntity) => void;
  updateEntityData: (entity: GlobalSearchEntity, data: object) => void;
  bulkUpdateState: (data: object) => void;
  filterData: (filters: object) => void;
  apiError: (entity: GlobalSearchEntity) => void;
}

export interface AlgoliaSearchResult {
  id: string;
  title: string;
  displayContent: string;
  url: string;
  icon: string;
  color?: string;
  category: 'docs' | 'product';
}
