import { BehaviorSubject, of, Subject, timer } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { SettingsStorageService } from '../../../containers/core/services/settings-storage.service';

import { SHARE_DOC_DIALOG_SHOWN } from './constants';
import { ShareDialogOptions } from './interface';

export class ShareDocDialogService {
  static deps = [
    SettingsStorageService
  ];

  private _stepErrors$ = new Subject<string>();
  dialogOptions: ShareDialogOptions = {} as ShareDialogOptions;
  isDialogVisible$ = new BehaviorSubject(false);

  constructor(
    private _userSettingsService: SettingsStorageService
  ) {
  }

  show(data: ShareDialogOptions) {
    this.isDialogVisible$.next(true);
    this.dialogOptions = data;

    return of();
  }

  hide() {
    this.isDialogVisible$.next(false);
  }

  onStepError(error: string) {
    this._stepErrors$.next(error);
  }

  showShareDialogOnFailure(data: ShareDialogOptions, maxErrors: number) {
    let count = 0;

    return this._stepErrors$.pipe(
      switchMap(() => {
        count++;

        if (count < maxErrors) {
          return of();
        }

        return timer(2000).pipe(
          switchMap(() => {

            return this._showShareDialogOnce(data);
          })
        );
      })
    );
  }

  private _showShareDialogOnce(data: ShareDialogOptions) {
    const shareDocDialogShown = this._userSettingsService.getSettings(SHARE_DOC_DIALOG_SHOWN);

    if (shareDocDialogShown) {
      return of();
    }

    this._userSettingsService.applySettings(SHARE_DOC_DIALOG_SHOWN, true);

    return this.show(data);
  }

}
