import { KEYMAP } from '../key-map';

export function getDisplayableShortcuts(isWorkFlowsEnabled, showActivation = true): any[] {
  let shortcuts: any[] = [
    KEYMAP.keyMaps.global,
    KEYMAP.keyMaps.pipeline,
    KEYMAP.keyMaps.model,
    KEYMAP.keyMaps.workflow,
    KEYMAP.keyMaps.destination
  ];

  if (!isWorkFlowsEnabled) {
    shortcuts = shortcuts.filter(item => item !== KEYMAP.keyMaps.workflow);
  }

  if (showActivation) {
    shortcuts = [...shortcuts, KEYMAP.keyMaps.activation, KEYMAP.keyMaps.target];
  }
  return shortcuts.map((group: any) => ({ ...group, keys: Object.keys(group.map) }));
}
