import clsx from 'clsx';
import React from 'react';
import { useHistory } from '@useHistory/useHistoryCompat';
import { TRACKER_UPGRADE_PLAN_CLICK } from './constants';
import useAnalyticsTracker from '../../hooks/useAnalyticsTracker';
import { getDataIdGenerator } from '../../utils/generateDataId';
import { getURL } from '../../utils/location';
import RoundedIcon from '../RoundedIcon';
import { HdButton, HdIcon, HdIconButton, HdModal, HdTooltip } from '../UIElements';
import styles from './styles.module.scss';

interface UpgradeToLiveChatDialogProps {
  open: boolean;
  currentPlanTier?: any;
  isCurrentUserOwner?: boolean;
  onClose: () => any;
}

export function UpgradeToLiveChatDialog({ open, ...others }: UpgradeToLiveChatDialogProps) {
  return (
    <HdModal open={open} onClose={others.onClose} styling={{ width: '420px' }} placement='top'>
      <UpgradeToLiveChatDialogInner {...others} />
    </HdModal>
  );
}

function UpgradeToLiveChatDialogInner({
  currentPlanTier,
  isCurrentUserOwner,
  onClose
}: Omit<UpgradeToLiveChatDialogProps, 'open'>) {
  const history = useHistory();
  const { eventTrack } = useAnalyticsTracker();

  const navigateToChangePlan = () => {
    onClose();

    const searchParams = new URLSearchParams([
      ['editing', 'true'],
      ['next', getURL()]
    ]);

    history.push({
      pathname: '/payment/plan-details',
      search: `?${searchParams.toString()}`
    });

    eventTrack({
      action: TRACKER_UPGRADE_PLAN_CLICK,
      properties: {
        'Current Plan': currentPlanTier
      }
    });
  };

  const dataIdGenerator = getDataIdGenerator('upgrade-to-live-chat-dialog');

  return (
    <>
      <div className='dialog-header dialog-header--absolute'>
        <HdIconButton onClick={() => onClose()} dataId={dataIdGenerator('close')}>
          <HdIcon name='close' />
        </HdIconButton>
      </div>

      <div className={clsx('dialog-body', styles.upgradeToLiveChatBox)}>
        <div className='center-flex-row justify-center mb-5'>
          <RoundedIcon iconName='password' />
        </div>

        <div className={clsx('text-bold text-center mt-1', styles.title)}>
          Live Chat is not available in your plan
        </div>

        <div className={clsx('text-secondary mt-2 mb-4 text-center', styles.supportRequestText)}>
          Your plan includes email support. You can send us your queries at{' '}
          <span className='text-primary text-medium'>support@hevodata.com</span> and we will get
          back to you within 2 business days.
        </div>

        <div className='center-flex-row justify-center mt-2'>
          <HdTooltip
            title='Contact your workspace owner to upgrade your plan'
            disabled={isCurrentUserOwner}
          >
            <div className='d-inline-block'>
              <HdButton
                dataId={dataIdGenerator('upgrade')}
                disabled={!isCurrentUserOwner}
                onClick={navigateToChangePlan}
                className={!isCurrentUserOwner && 'disabled-button'}
              >
                Upgrade Your Plan
              </HdButton>
            </div>
          </HdTooltip>
        </div>
      </div>
    </>
  );
}
