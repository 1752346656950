import React, { useEffect, useMemo, useRef, useState } from 'react';
import clsx from 'clsx';
import { FormikProps } from 'formik';
import styles from '../styles.module.scss';
import { HdButton, HdPane } from '../../../components/UIElements';
import Scheduler from '../index';
import { HevoEntity } from '../../core/models/hevo-entity';
import { SchedulerOptions } from '../SchedulerOptions/schedulerOptions';
import useScheduler from '../useScheduler';
import { getPolicyMessage, getPolicyRawData, ExecutionPolicyContext } from '../utils';
import {
  DESTINATION_POLICY_MESSAGE_PREFIX,
  TRACKER_DESTINATION_EXECUTION_POLICY_DIALOG_SCHEDULE_CLICK,
  TRACKER_DESTINATION_EXECUTION_POLICY_DIALOG_SCHEDULE_FAILURE,
  TRACKER_DESTINATION_EXECUTION_POLICY_DIALOG_SCHEDULE_SUCCESS
} from '../ExecutionPolicyDialog/constants';
import ExecutionPolicyMessage from '../ExecutionPolicyMessage';
import useAnalyticsTracker from '../../../hooks/useAnalyticsTracker';
import DestinationsAPI from '../../destination/DestinationsAPI';
import { PolicyTypeEnum } from '../ExecutionPolicyDialog/models';
import { getDataIdGenerator } from '../../../utils/generateDataId';
import { Destination } from '../../destination/models';
import { SchedulerType } from '../models';
import { SchedulerHeader } from '../components/SchedulerHeader';
import { getErrorMessageFromObj } from '../../../legacy-utils/request';

export default function DestinationExecutionPolicyDialog({
  onClose,
  entityDetails
}: {
  onClose: Function;
  entityDetails: Destination;
}) {
  const analyticsTracker = useAnalyticsTracker();
  const formikRef = useRef<FormikProps<{}>>();
  const [savingSchedule, setSavingSchedule] = useState(false);
  const [error, setError] = useState(null);
  const {
    selectedOption,
    policyObj,
    setPolicyObj,
    isCustom,
    handleOptionSelection,
    tooltipData,
    setTooltipData
  } = useScheduler({
    policy: entityDetails.executionPolicy,
    entity: HevoEntity.DESTINATION.value,
    schedulerType: SchedulerType.MultiCronType,
    formikRef
  });

  useEffect(() => {
    if (entityDetails.destType === 'FIREBOLT') {
      setPolicyObj((prev) => ({
        ...prev,
        suggestedFrequencies: prev.suggestedFrequencies.filter(({value}) => (value !== 5 && value !== 15))
      }));
    }
  }, []);

  const handleSubmit = () => {
    trackScheduleClick(TRACKER_DESTINATION_EXECUTION_POLICY_DIALOG_SCHEDULE_CLICK);
    setSavingSchedule(true);
    setError(null);
    DestinationsAPI.schedule(entityDetails.id, getPolicyRawData(policyObj))
      .then(() => {
        onClose(true);
        trackScheduleClick(TRACKER_DESTINATION_EXECUTION_POLICY_DIALOG_SCHEDULE_SUCCESS);
      })
      .catch(error => {
        trackScheduleClick(TRACKER_DESTINATION_EXECUTION_POLICY_DIALOG_SCHEDULE_FAILURE);
        setError(error);
      })
      .finally(() => {
        setSavingSchedule(false);
      });
  };

  const trackScheduleClick = (action: string) => {
    let selectedData = getPolicyRawData(policyObj);
    if (selectedData.type === PolicyTypeEnum.CRON && Array.isArray(selectedData.time_hour_list)) {
      selectedData = {
        ...selectedData,
        time_hour_list: selectedData.time_hour_list.join(','),
        time_hour_list_length: selectedData.time_hour_list.length
      };
    }

    analyticsTracker.eventTrack({
      action,
      properties: {
        ...selectedData,
        id: entityDetails.id
      }
    });
  };

  const policyMessage = useMemo(() => getPolicyMessage(policyObj), [policyObj]);

  const dataIdGenerator = getDataIdGenerator('DestinationExecutionPolicyDialog');

  return (
    <div className={clsx('dialog-content', styles.dialogContent)}>
      <SchedulerHeader
        title='Change the Destination Sync Schedule'
        subTitle='Define the frequency or time(s) at which the Events must be loaded to the
          Destination.'
        dataIdGenerator={dataIdGenerator}
        onClose={onClose}
      />

      <div className={clsx('dialog-body', styles.dialogBody)}>
        <div>
          <div className='text-body-2 mb-6'>
            Select Load frequency
          </div>

          <SchedulerOptions
            entity={HevoEntity.DESTINATION.value}
            options={policyObj.displayedFrequencies}
            onScheduleOptionSelected={handleOptionSelection}
            selectedOption={selectedOption}
          />

          <ExecutionPolicyContext.Provider value={{
            tooltipData,
            setTooltipData
          }}>
            <Scheduler
              schedulerType={SchedulerType.MultiCronType}
              entity={HevoEntity.DESTINATION.value}
              policyObj={policyObj}
              setPolicyObj={setPolicyObj}
              ref={formikRef}
              handleSubmit={handleSubmit}
              isCustom={isCustom}
            />
          </ExecutionPolicyContext.Provider>

          {policyMessage ? (
            <ExecutionPolicyMessage
              className='mb-3 mt-7'
              policyMessage={policyMessage}
              messagePrefix={DESTINATION_POLICY_MESSAGE_PREFIX}
            />
          ) : null}
        </div>

        {error ? (
          <HdPane variant={'error'} icon='error-filled' dataId={dataIdGenerator('')}>
            {getErrorMessageFromObj(error)}
          </HdPane>
        ) : null}
      </div>

      <div className='dialog-footer'>
        <HdButton
          dataId={dataIdGenerator('cancel')}
          variation='outline'
          disabled={savingSchedule}
          onClick={() => onClose()}
        >
          Cancel
        </HdButton>

        <HdButton
          dataId={dataIdGenerator('submit')}
          disabled={savingSchedule}
          showProgress={savingSchedule}
          type='submit'
          onClick={() => formikRef.current.submitForm()}
        >
          Schedule
        </HdButton>
      </div>
    </div>
  );
}
