import { FormikContextType } from 'formik';
import React from 'react';
import { SourceFormData } from '../../../../../components/Node/source-settings-form/source-form-data';

export interface SourceFormFieldDefinition {
  key: string;
  fieldId: string;
  widget: FieldTypes;
  widgetOptions: { [key: string]: any };
  hint?: string;
  docLink?: string;
  externalDocLink?: string;
  sbsDocLink?: string;
  buildParamsKey?: string;
  initialValue?: string | number | boolean;
  validations?: SourceFormFieldValidation[];
  fieldType?: string;
  fieldSize?: number;
  disableEditing?: boolean;
  onChange?: (
    event: React.ChangeEvent<HTMLInputElement>,
    formikProps: FormikContextType<any>
  ) => void;
  getConfigValue?: (rawValue: any) => any;
  getRawValue?: (configValue: any, formData?: any) => any;
  getRenderCondition?: (formMetaData: any, formData?: SourceFormData) => boolean;
}

export enum ValidationType {
  REQUIRED = 'required',
  EMAIL = 'email',
  MATCHES = 'matches',
  POSITIVE = 'positive',
  MAX = 'max',
  MIN = 'min'
}

export enum FieldTypes {
  TEXT_FIELD = 'TEXT_FIELD',
  PASSWORD_FIELD = 'PASSWORD_FIELD',
  SWITCH_FIELD = 'SWITCH_FIELD',
  DROPDOWN_FIELD = 'DROPDOWN_FIELD',
  RADIO_BUTTON_FIELD = 'RADIO_BUTTON_FIELD'
}

export interface SourceFormFieldValidation {
  type: string;
  params?: any[];
}
