import { Enum } from '../core/models/object-enum';
import { EntityStatus } from '../core/models/entity-status';

export class QueryHistoryStatusEnum extends Enum {
  static SUCCESS            = new EntityStatus('Success', 'SUCCESS', 'success');
  static RUNNING            = new EntityStatus('Running', 'RUNNING', 'success');
  static FAILED             = new EntityStatus('Failed', 'FAILED', 'error');
  static QUEUED             = new EntityStatus('Queued', 'QUEUED', 'success');
  static CANCEL_REQUESTED   = new EntityStatus('Cancelling', 'CANCEL_REQUESTED', 'warning');
  static CANCELLED          = new EntityStatus('Cancelled', 'CANCELLED', 'warning');
}
