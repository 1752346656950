import { parse } from 'lossless-json';
import { Observable } from 'rxjs';
import { AppConfig } from '../core/app.config';
import { BACKGROUND_NETWORK_REQ_OPTIONS } from '../core/constants';
import { NetworkRequestOptions } from '../core/models/request';
import { courier, CourierNetworkRequestOptions } from '../core/services/courier.service';
import { createHttpParams } from '../../legacy-utils/request';
import { retainSafeNumber } from '../../utils/lossless-json-utils';
import { QueryHistoryFactory } from './model-factory';

const appConfig = new AppConfig();

const TransformModelAPI = {
  modelsURL: appConfig.getModelsURL(),
  modelsV2Url: appConfig.getModelsV2URL(),

  getModel(modelId: any, showLoading = true) {
    const requestUrl = `${this.modelsURL}/${modelId}`;

    const options: NetworkRequestOptions = {
      uiOptions: {
        showSuccessMsg: false,
        showErrorMsg: false,
        showLoading: showLoading
      }
    };

    return courier.get(requestUrl, options);
  },

  getModels(modelIds?: number[]) {
    const options: NetworkRequestOptions = {
      networkOptions: {
        params: createHttpParams({
          model_id: modelIds
        })
      },
      uiOptions: {
        showSuccessMsg: false,
        showLoading: false
      }
    };

    return courier.get(this.modelsURL, options);
  },

  getModelRunHistory(modelId: any, status?: string, start: number = 0, limit: number = 20) {
    const requestUrl = `${this.modelsURL}/${modelId}/history`;

    const options: NetworkRequestOptions = {
      uiOptions: {
        showLoading: false,
        showErrorMsg: false
      },
      networkOptions: {
        params: createHttpParams({ start, limit, status })
      }
    };

    return courier.get(requestUrl, options).then(res => QueryHistoryFactory(res.data));
  },

  getModelHistoryCount(modelId: any, status?: string) {
    const requestUrl = `${this.modelsURL}/${modelId}/history/count`;

    const options: NetworkRequestOptions = {
      uiOptions: {
        showLoading: false,
        showErrorMsg: false
      },
      networkOptions: {
        params: createHttpParams({ status })
      }
    };

    return courier.get(requestUrl, options).then(res => res.data.count);
  },

  getPrimaryKeySuggestions(modelId: number, query: string) {
    const requestUrl = `${this.modelsV2Url}/query-fields`;
    const params = {
      model_id: modelId,
      query
    };

    return courier.post(requestUrl, BACKGROUND_NETWORK_REQ_OPTIONS, params);
  },

  updateV2Model(
    params: any,
    modelId: any,
    showLoading = true,
    showSuccessMsg = true,
    showErrorMsg = true,
    validateQuery = false,
    abortSignal?: AbortSignal
  ) {
    const requestUrl = this.modelsV2Url + `/${modelId}`;

    const options: CourierNetworkRequestOptions = {
      uiOptions: {
        showLoading: showLoading,
        showSuccessMsg: showSuccessMsg,
        showErrorMsg: showErrorMsg,
        successMsg: 'Model was updated successfully'
      },
      signal: abortSignal
    };

    const body = {
      ...params,
      validate_query: validateQuery
    };

    return courier.put(requestUrl, options, body);
  },

  updateModel(
    params: any,
    modelId: any,
    showLoading = true,
    showSuccessMsg = true,
    showErrorMsg = true,
    abortSignal?: AbortSignal
  ) {
    const requestUrl = `${this.modelsURL}/${modelId}`;

    const options: CourierNetworkRequestOptions = {
      uiOptions: {
        showLoading: showLoading,
        showSuccessMsg: showSuccessMsg,
        showErrorMsg: showErrorMsg,
        successMsg: 'Model was updated successfully'
      },
      signal: abortSignal
    };

    return courier.put(requestUrl, options, params);
  },

  resetModel(modelId: any) {
    const requestUrl = `${this.modelsV2Url}/${modelId}/offsets`;

    const options: NetworkRequestOptions = {
      uiOptions: {
        successMsg: 'Resetting the SQL model. This may take a few moments.'
      }
    };

    return courier.delete(requestUrl, options);
  },

  getQueryExecutionTaskId(modelSourceId: any, query: string) {
    const params = {
      query: query,
      source_destination_id: modelSourceId
    };

    const requestUrl = `${this.modelsURL}/execute`;

    return courier.post(requestUrl, BACKGROUND_NETWORK_REQ_OPTIONS, params).then((res: any) => {
      return res.data.task_id;
    });
  },

  getQueryExecutionTaskResponse(taskId: number) {
    const requestUrl = `${this.modelsURL}/execute/${taskId}`;

    return courier
      .get(requestUrl, {
        ...BACKGROUND_NETWORK_REQ_OPTIONS,
        networkOptions: {
          responseType: 'text'
        }
      })
      .then((res: string) => parse(res, null, retainSafeNumber));
  },

  stopQueryExecution(taskId: number) {
    const requestUrl = `${this.modelsURL}/execution/${taskId}`;

    return courier.delete(requestUrl, BACKGROUND_NETWORK_REQ_OPTIONS);
  },

  getCompatibleDestTypesForModels(destType: string, showLoading = true) {
    const requestUrl = `${this.modelsURL}/destinations/compatible/${destType}`;
    const options: NetworkRequestOptions = {
      uiOptions: {
        showLoading: showLoading
      }
    };

    return courier.get(requestUrl, options);
  },

  schedule(modelId: any, policyRawData: any) {
    const requestUrl = `${this.modelsURL}/${modelId}/schedule`;

    const options: NetworkRequestOptions = {
      uiOptions: {
        showLoading: false
      }
    };

    return courier.put(requestUrl, options, policyRawData);
  },

  getModelsForWarehouse(destinationId: number) {
    const requestUrl = `${this.modelsURL}/short`;

    const options: NetworkRequestOptions = {
      networkOptions: {
        params: createHttpParams({
          destination_ids: destinationId
        })
      },
      uiOptions: {
        showSuccessMsg: false,
        showLoading: false
      }
    };

    return courier.get(requestUrl, options);
  },

  getModelTaskStatus(taskId: number) {
    const requestUrl = `${this.modelsV2Url}/status/${taskId}`;

    const options: NetworkRequestOptions = {
      uiOptions: {
        showSuccessMsg: false,
        showErrorMsg: false
      }
    };

    return courier.get(requestUrl, options);
  },

  deleteSqlModel(modelId: number, options: any = {}) {
    const requestUrl = `${this.modelsURL}/${modelId}`;

    return courier.delete(requestUrl, options);
  },

  getModelBySeqId(seqId: number) {
    const requestUrl = `${this.modelsURL}/seq-id/${seqId}`;

    return courier.get(requestUrl, BACKGROUND_NETWORK_REQ_OPTIONS);
  },

  addModel(data: any) {
    const params = {
      source_destination_id: data.sourceId,
      name: data.name
    };

    return courier.post(this.modelsV2Url, {}, params);
  },

  getDefaultModelName() {
    const requestUrl = `${this.modelsURL}/default-name`;

    return courier.get(requestUrl, BACKGROUND_NETWORK_REQ_OPTIONS).then(res => {
      return res.data ? res.data.default_name : undefined;
    });
  },

  pauseModel(modelId: number) {
    const requestUrl = this.modelsURL + `/${modelId}/pause`;

    const options: NetworkRequestOptions = {
      uiOptions: {
        showSuccessMsg: false
      }
    };

    return courier.put(requestUrl, options);
  },

  resumeModel(modelId: any) {
    const requestUrl = this.modelsURL + `/${modelId}/resume`;

    const options: NetworkRequestOptions = {
      uiOptions: {
        showSuccessMsg: false
      }
    };

    return courier.put(requestUrl, options);
  },

  stopRunningQuery(modelId: number, historyId: number) {
    const requestUrl = this.modelsURL + `/${modelId}/history/${historyId}`;

    return courier.delete(requestUrl);
  }
};

export default TransformModelAPI;
