import { Angulartics2, EventTrack } from '@angularticsCompat';
import { ReplaySubject } from 'rxjs';

import { uuidv4 } from '../../../legacy-utils/uuid';
import { SettingsStorageService } from './settings-storage.service';


export abstract class SessionTrackingAngularticsService {
  static deps: any[] = [
    Angulartics2,
    SettingsStorageService,
    ''
  ];

  eventTrack = new ReplaySubject<Partial<EventTrack>>(10);
  sessionId = this._getStoredSessionId();

  constructor(
    protected _angulartics2: Angulartics2,
    protected _settingsStorageService: SettingsStorageService,
    protected trackingSessionKey: string
  ) {
    this.eventTrack.pipe(
    ).subscribe((data) => {
      if (!this.sessionId) {
        this.startSession();
      }

      this._angulartics2.eventTrack.next({
        action: data.action,
        properties: this.getEventTrackingProperties(data)
      });
    });
  }

  getEventTrackingProperties(data: any) {
    return {
      ...data.properties,
      sessionId: this.sessionId
    };
  }

  startSession() {
    this.sessionId = uuidv4();
    this._storeSessionId();
  }

  startSessionAndTrack(data: any) {
    this.startSession();
    this.eventTrack.next(data);
  }

  endSession() {
    this.sessionId = undefined;
    this._storeSessionId();
  }

  private _storeSessionId() {
    this._settingsStorageService.applySettings(this.trackingSessionKey, this.sessionId);
  }

  private _getStoredSessionId() {
    return this._settingsStorageService.getSettings(this.trackingSessionKey);
  }
}
