import React, { ReactNode } from 'react';
import { Observable } from 'rxjs';
import { BannerColorPalette } from '../interface';

export interface BannerData<T = any> {
  color?: BannerColorPalette;
  bannerTitle?: string;
  message: string | React.ComponentType<any> | ReactNode;
  messageContext?: T;
  canClose?: boolean;
  closeOnAction?: boolean;
  thumbnailSrc?: string;
  thumbnailName?: string;
  actionName?: string;
  actionIcon?: string;
  openCallback?: () => any;
  actionCallback?: () => any;
  closeClickCallback?: () => any;
  closeCallback?: (reason?: BannerCloseReason) => any;
  triggerClose$?: Observable<BannerCloseReason>;
}

export enum BannerCloseReason {
  ACTION,
  DISCARD
}
