/* eslint-disable no-shadow */
import { Typography } from '@mui/material';
import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { JobMode, JobModeEnum } from '../Node/source-type/job-mode';
import {
  TRACKER_SOURCE_CONFIG_JOB_MODES_SEE_MORE_OPTIONS_CLICK,
  TRACKER_SOURCE_CONFIG_UPDATE_JOB_MODE
} from '../Node/tracking';
import useAnalyticsTracker from '../../hooks/useAnalyticsTracker';
import { HdButton, HdRadio, HdRadioGroup } from '../UIElements';
import styles from './styles.module.scss';
import { getDataIdGenerator } from '../../utils/generateDataId';
import { getJobModeValue, getRecommendedJobMode } from './util';
import useTeamSettings from '../../hooks/services/useTeamSettingsService';

export const dataIdGenerator = getDataIdGenerator('job-mode');

interface JobModeSelectionFieldProps {
  jobModes: JobMode[];
  selectedJobMode: JobMode;
  isSeeMoreOptionsVariant?: boolean;
  jobModeChange: (jobMode: JobMode) => void;
  label?: string;
  seeMoreOptionsExpandDefault?: boolean;
}
export default function JobModeSelectionField({
  jobModes,
  selectedJobMode,
  isSeeMoreOptionsVariant = false,
  jobModeChange,
  label,
  seeMoreOptionsExpandDefault = false
}: JobModeSelectionFieldProps) {
  if (!jobModes || jobModes.length === 0) {
    return null;
  }

  return (
    <JobModeSelectionFieldInner
      jobModes={jobModes}
      selectedJobMode={selectedJobMode}
      isSeeMoreOptionsVariant={isSeeMoreOptionsVariant}
      jobModeChange={jobModeChange}
      label={label}
      seeMoreOptionsExpandDefault={seeMoreOptionsExpandDefault}
    />
  );
}

function JobModeSelectionFieldInner({
  jobModes,
  selectedJobMode,
  isSeeMoreOptionsVariant,
  jobModeChange,
  label,
  seeMoreOptionsExpandDefault
}: JobModeSelectionFieldProps) {
  const [expanded, setExpanded] = useState(false);
  const [isRecommendedJobModeAvailable, setIsRecommendedJobModeAvailable] = useState(false);
  const [visibleJobModes, setVisibleJobModes] = useState<JobMode[]>([]);
  const analyticsTracker = useAnalyticsTracker();
  const teamSettingsService = useTeamSettings();

  useEffect(() => {
    const shouldExpand =
      (isSeeMoreOptionsVariant && seeMoreOptionsExpandDefault) ||
      (selectedJobMode && !selectedJobMode.isRecommended);

    if (shouldExpand) {
      setExpanded(true);
    }

    setIsRecommendedJobModeAvailable(!!getRecommendedJobMode(jobModes));

    _setVisibleJobModes();
  }, [isSeeMoreOptionsVariant, seeMoreOptionsExpandDefault]);

  useEffect(() => {
    _setVisibleJobModes();
  }, [expanded]);

  const _setVisibleJobModes = () => {
    if (expanded) {
      if (!teamSettingsService.getTeamSettings()?.['enable_xmin_type']) {
        setVisibleJobModes([...jobModes.filter((jobMode) => {
          return jobMode !== JobModeEnum.XMIN;
        })]);

        return;
      }

      setVisibleJobModes([...jobModes]);
      return;
    }

    const recommendedJobMode = getRecommendedJobMode(jobModes);

    /**
     * Show the recommended option at the top.
     * */
    if (recommendedJobMode) {
      setVisibleJobModes([recommendedJobMode]);
    } else {
      setVisibleJobModes([...jobModes]);
    }
  };

  const updateJobMode = (jobModeValue: string) => {
    const newSelectedJobMode = jobModes.find(mode => {
      if (mode?.virtualValue) {
        return mode.virtualValue === jobModeValue;
      }

      return mode.value === jobModeValue;
    });

    analyticsTracker.eventTrack({
      action: TRACKER_SOURCE_CONFIG_UPDATE_JOB_MODE,
      properties: {
        jobMode: newSelectedJobMode
      }
    });

    jobModeChange(newSelectedJobMode);
  };

  const toggle = () => {
    analyticsTracker.eventTrack({
      action: TRACKER_SOURCE_CONFIG_JOB_MODES_SEE_MORE_OPTIONS_CLICK,
      properties: {
        expanded: !expanded
      }
    });
    setExpanded(prev => !prev);
  };

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {!isSeeMoreOptionsVariant ? (
        <JobModeGroup
          isSeeMoreOptionsVariant={isSeeMoreOptionsVariant}
          jobModes={jobModes}
          updateJobMode={updateJobMode}
          jobMode={selectedJobMode}
          label={label}
        />
      ) : (
        <div
          className={styles.jobModeSelectorBox}
          data-id={dataIdGenerator(`see-more-options-variant`)}
        >
          <div className={`center-flex-row justify-between ${styles.header}`}>
            <div className='text-bold' data-id={dataIdGenerator(`label`)}>
              Select an Ingestion Mode
            </div>

            {visibleJobModes.length && isRecommendedJobModeAvailable ? (
              <HdButton
                variation='flat'
                direction='right'
                className={`text-uppercase text-link ${styles.seeMoreBtn}`}
                icon={expanded ? 'up-arrow' : 'dropdown-arrow'}
                onClick={toggle}
                dataId={dataIdGenerator('expand-toggle')}
              >
                See {expanded ? 'Less' : 'More'}
              </HdButton>
            ) : null}
          </div>

          <JobModeGroup
            isSeeMoreOptionsVariant={isSeeMoreOptionsVariant}
            jobModes={visibleJobModes}
            updateJobMode={updateJobMode}
            jobMode={selectedJobMode}
            label={label}
          />
        </div>
      )}
    </>
  );
}

function JobModeGroup({ isSeeMoreOptionsVariant, jobModes, updateJobMode, jobMode, label }) {
  const radioLabel = isSeeMoreOptionsVariant ? '' : label || 'Select an Ingestion Mode';
  const classNames = clsx({
    [styles.jobModeRadioGroupExperiment]: isSeeMoreOptionsVariant,
    [styles.jobModeRadioGroup]: !isSeeMoreOptionsVariant
  });
  const currentValue = getJobModeValue(jobMode);

  return (
    <HdRadioGroup
      name='jobMode'
      className={classNames}
      label={radioLabel}
      currentValue={currentValue}
      onChange={updateJobMode}
    >
      {jobModes.map(mode => (
        <HdRadio
          key={mode.name}
          dataId={dataIdGenerator(mode.value)}
          value={getJobModeValue(mode)}
          className='center-flex-row p-5 mat-radio-wrap-label'
        >
          <div className='flex-col ml-1 pl-2'>
            <div className='center-flex-row'>
              <Typography variant='body2' data-id={dataIdGenerator(`${mode.value}-title`)}>
                {mode.name}
              </Typography>

              {mode.isRecommended && (
                <span
                  className='badge badge-solid-sm badge-solid info-secondary-badge text-transform-none ml-1'
                  data-id={dataIdGenerator(`${mode.value}-recommended-badge`)}
                >
                  Recommended
                </span>
              )}
            </div>

            <div data-id={dataIdGenerator(`${mode.value}-desc`)} className='text-secondary mt-1'>
              {mode.desc}
            </div>
          </div>
        </HdRadio>
      ))}
    </HdRadioGroup>
  );
}
