import { ActivationSyncBehaviour } from '../models/sync-behaviour';
import { GenericTargetObjectSettings } from '../models/target-object-settings';


export class IntercomTagTargetObjectSettings extends GenericTargetObjectSettings {
  tagIdentifiers?: any[] | any;

  private static _getTagIdAndName(tag: any) {
    return {
      id: tag.id,
      name: tag.name
    };
  }

  setConfigFromRawData(rawData: any): void {
    if (!rawData) {
      return;
    }

    const syncBehaviour = ActivationSyncBehaviour.toArray().find(
      (syncBehaviourType) => syncBehaviourType.value === rawData.sync_mode
    );

    this.targetObject = {
      apiName: rawData.dest_object,
      displayName: rawData.display_object_name || rawData.dest_object
    };
    this.syncBehaviour = syncBehaviour;
    this.uniqueIdentifierKeys = rawData.tag_keys;

    if (ActivationSyncBehaviour.ATTACH_TAG === syncBehaviour) {
      this.tagIdentifiers = [ rawData.tag_identifier ];
    }

    if (ActivationSyncBehaviour.DETACH_TAG === syncBehaviour) {
      this.tagIdentifiers = rawData.tag_identifiers;
    }
  }

  convertConfigToRawData(): any {
    const targetSyncConfig = {
      dest_object: this.targetObject.apiName,
      display_object_name: this.targetObject.displayName,
      sync_mode: this.syncBehaviour?.value,
      tag_keys: this.uniqueIdentifierKeys
    };

    if (ActivationSyncBehaviour.ATTACH_TAG === this.syncBehaviour) {
      const [ tagIdentifier ] = this.tagIdentifiers;
      targetSyncConfig['tag_identifier'] = IntercomTagTargetObjectSettings._getTagIdAndName(tagIdentifier);
    }

    if (ActivationSyncBehaviour.DETACH_TAG === this.syncBehaviour) {
      targetSyncConfig['tag_identifiers'] =
        this.tagIdentifiers.map((tagIdentifier) => IntercomTagTargetObjectSettings._getTagIdAndName(tagIdentifier));
    }

    return targetSyncConfig;
  }

  initialiseConfig(): void {
    super.initialiseConfig();

    this.syncBehaviour = ActivationSyncBehaviour.ATTACH_TAG;
    this.tagIdentifiers = [];
  }
}
