import { SourceTypeConfigHelper } from '../../../../../components/Node/source-settings-form/config-helper';
import PipelinesAPI from '../../../PipelinesAPI';
import {
  MSAdsConfigPayload,
  MSAdsConfigResponse,
  MSAdsFormFields,
  MSAdsFormMetaData,
  MSAdsFormState
} from './models';
import { getSelectedAdProfilesPayload, getSelectedCustomersInclusionListState } from './util';

export class MicrosoftAdsReactConfigHelper implements SourceTypeConfigHelper {
  getDefaultConfig(metaData: MSAdsFormMetaData): Partial<MSAdsFormState> {
    return {
      [MSAdsFormFields.CORE_OBJECTS]: metaData.coreObjects,
      [MSAdsFormFields.REPORTS]: metaData.reports
    };
  }

  getConfigFromRawData(rawData: MSAdsConfigResponse): MSAdsFormState {
    return {
      [MSAdsFormFields.CUSTOMER_ID]: rawData.customer_id,
      [MSAdsFormFields.REPORTS]: rawData.selected_reports,
      [MSAdsFormFields.CORE_OBJECTS]: rawData.selected_core_objects,
      [MSAdsFormFields.SELECTED_CUSTOMERS]: {
        ...getSelectedCustomersInclusionListState(rawData)
      }
    };
  }

  buildConnectionParams(data: MSAdsFormState): MSAdsConfigPayload {
    return {
      customer_id: data[MSAdsFormFields.CUSTOMER_ID],
      selected_core_objects: data[MSAdsFormFields.CORE_OBJECTS],
      selected_reports: data[MSAdsFormFields.REPORTS],
      ...getSelectedAdProfilesPayload(data[MSAdsFormFields.SELECTED_CUSTOMERS])
    };
  }

  async staticDependenciesResolver() {
    try {
      const { data } = await PipelinesAPI.getMSAdsObjects();

      return {
        coreObjects: data.core_objects,
        reports: data.reports
      };
    } catch (e) {
      return {
        coreObjects: [],
        reports: []
      };
    }
  }
}
