import clsx from 'clsx';
import React, { useEffect, useRef } from 'react';
import { TroubleshootGuideService } from '../../../containers/core/services/troubleshoot-guide.service';
import { CommunicationMessageEvents } from '../message-events';
import {
  TRACKER_TROUBLESHOOT_GUIDE_ERRORED,
  TRACKER_TROUBLESHOOT_GUIDE_LOADED
} from './constants';
import useAnalyticsTracker from '../../../hooks/useAnalyticsTracker';
import useService from '../../../hooks/useService';
import { DocsIframe } from '../DocsIframe';
import { useDocs } from '../useDocs';
import styles from './styles.module.scss';

export interface TroubleshootGuideProps {
  className?: string;
  docLink?: string;
  queryParams?: Record<string, string>;
}

export function TroubleshootGuide({
  className,
  docLink,
  queryParams
}: TroubleshootGuideProps) {
  const analyticsTracker = useAnalyticsTracker();
  const iframeRef = useRef(null);
  const troubleshootGuideService = useService(TroubleshootGuideService);

  const onIframeLoadError = () => {
    analyticsTracker.eventTrack({
      action: TRACKER_TROUBLESHOOT_GUIDE_ERRORED,
      properties: {
        setupGuideLink: docLink
      }
    });
  };

  const onIframeLoadSuccess = () => {
    analyticsTracker.eventTrack({
      action: TRACKER_TROUBLESHOOT_GUIDE_LOADED,
      properties: {
        setupGuideLink: docLink
      }
    });
  };

  const {
    error,
    iframeSourceURL,
    isIframeLoading,
    rebuildIframeSourceURL,
    onIframeLoadEvent,
    onIframeLoadErrorEvent,
    sendMessageToIframe
  } = useDocs({
    loadMessage: CommunicationMessageEvents.DASH_LOAD_TROUBLESHOOT,
    loadedMessage: CommunicationMessageEvents.DASH_LOAD_TROUBLESHOOT,
    iframeRef,
    docLink,
    queryParams,
    onIframeLoadError,
    onIframeLoadSuccess
  });

  useEffect(() => {
    const sub = troubleshootGuideService.isTroubleshootVisible$.subscribe((open) => {
      if (open) {
        rebuildIframeSourceURL();
      }
    });

    return () => {
      sub.unsubscribe();
    };
  }, [ rebuildIframeSourceURL ]);

  const iframeSourceURLFinal = iframeSourceURL + '&footerAlternateBg=true';

  return (
    <div
      className={clsx(
        styles.troubleshootGuideContainer,
        className
      )}
    >
      <DocsIframe
        ref={iframeRef}
        className={clsx(
          styles.iframeContainer
        )}
        error={error}
        docLink={docLink}
        iframeSourceURL={iframeSourceURLFinal}
        isIframeLoading={isIframeLoading}
        onIframeLoadEvent={onIframeLoadEvent}
        onIframeLoadErrorEvent={onIframeLoadErrorEvent}
        sendMessageToIframe={sendMessageToIframe}
      />
    </div>
  );
}
