import { SourceTypeConfigHelper } from '../../../../../components/Node/source-settings-form/config-helper';

export class SalesforceMarketingCloudConfigHelper implements SourceTypeConfigHelper {
  getConfigFromRawData(rawData: any) {
    return {
      subDomain: rawData.sub_domain,
      clientId: rawData.client_id,
      clientSecret: rawData.client_secret
    };
  }

  getDefaultConfig() {
  }

  buildConnectionParams(data: any) {
    return {
      sub_domain: data.subDomain,
      client_id: data.clientId,
      client_secret: data.clientSecret
    };
  }
}
