import { KafkaVariations } from './model';
import { GENERIC_VARIATION } from '../../../../../components/Node/nodes-settings/constants';
import { EntityVariationData } from '../../../../../components/Node/nodes-settings/models';


export const KAFKA_VARIATIONS: EntityVariationData[] = [
  {
    name: 'Apache Kafka',
    value: GENERIC_VARIATION,
    description: 'Select this option if you use a generic kafka.',
    displayName: 'Apache Kafka',
    docLink: '/sources/streaming/kafka/apache-kafka/'
  },
  {
    name: 'Confluent Cloud',
    value: KafkaVariations.CONFLUENT_CLOUD,
    description: 'Select this option if you use Kafka Confluent cloud provider.',
    displayName: 'Kafka Confluent Cloud',
    docLink: '/sources/streaming/kafka/kafka-confluent-cloud/'
  }
];
