import React from 'react';
import clsx from 'clsx';
import { HdIcon } from '../../../../components/UIElements';

interface noItemBoxProps {
  className?: string;
  iconName: string;
  title: string;
  children: React.ReactNode;
}
export function NoItemBox({ className = '', iconName, title, children }: noItemBoxProps) {
  return (
    <div className={clsx('no-item-box h-100 my-0', className)} data-id='no-item-box'>
      <div className='no-item-box-icon-container'>
        <HdIcon name={iconName} className='no-item-box-icon' />
      </div>

      <div className='no-item-box-title'>{title}</div>
      {children}
    </div>
  );
}
