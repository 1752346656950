import { DestinationMode } from '../../../containers/core/models/destination-mode';
import { WordDefinition } from '../../../containers/core/models/word-definition';
import { JobMode } from '../source-type/job-mode';
import { SOURCE_TYPES } from '../source-type/source-type';


export function getDestinationModeKeywords(destinationMode: DestinationMode): WordDefinition {
  if (destinationMode === DestinationMode.WAREHOUSE) {
    return {
      singular: 'Warehouse',
      plural: 'Warehouses'
    };
  }

  return {
    singular: 'Destination',
    plural: 'Destinations'
  };
}

export function canSkipNewTables(jobMode: JobMode, sourceType: string) {
  return jobMode ? jobMode.canSkipNewTables : SOURCE_TYPES[sourceType].canSkipNewTables;
}

export function getAuthProviderBasedOnSourceVersion(provider: string, sourceVersion: number) {
  let authProvider = provider;

  if ((authProvider === 'GOOGLE_ANALYTICS_4' || authProvider === 'FIREBASE_ANALYTICS')
    && sourceVersion === 0
  ) {
    authProvider = 'GOOGLE_ANALYTICS';
  }

  return authProvider;
}
