import { Pipeline } from '../../pipeline/models/pipeline';

export enum PostPCSNotificationType {
  DELETE_PIPELINE = 'DELETE_PIPELINE',
  PIPELINE_RUN_NOW = 'PIPELINE_RUN_NOW',
  PAUSE_PIPELINE = 'PAUSE_PIPELINE',
  DELETE_ACCOUNT = 'DELETE_ACCOUNT',
  PIPELINE_FAILED = 'PIPELINE_FAILED',
  SIDELINE_ERRORS = 'SIDELINE_ERRORS',
  RESTART_OBJECT = 'RESTART_OBJECT',
  OBJECT_RUN_NOW = 'OBJECT_RUN_NOW',
  OBJECTS_RUN_NOW_BULK_ACTION = 'OBJECTS_RUN_NOW_BULK_ACTION',
  RESTART_OBJECTS_BULK_ACTION = 'RESTART_OBJECTS_BULK_ACTION',
  REPLAY_SIDELINE_ERRORS = 'REPLAY_SIDELINE_ERRORS',
  INGESTION_DELAY = 'INGESTION_DELAY'
}

export interface PostPCSNotification {
  type: PostPCSNotificationType;
  pipeline?: Pipeline;
  throttle?: boolean;
  throttleTime?: number;
  [key: string]: any;
}
