import { filter, switchMap, take } from 'rxjs/operators';
import { ReactRouterService } from '../../containers/core/services/react-router.service';
import { PriorityQueue } from '../../legacy-utils/priority-queue';
import { BehaviorSubject, Subscription, timer } from 'rxjs';
import { SettingsStorageService } from '../../containers/core/services/settings-storage.service';
import { RecentEntitiesService } from '../../containers/core/services/recent-entities.service';
import { ITour, MAX_TOURS_ALLOWED_PER_SESSION, TOUR_RULES, TourPriority } from './model';
import { ProductTour } from './ProductTour';

const DEBOUNCE_TIME_MS = 2000;

export class TourOrchestratorService {
  static deps = [
    SettingsStorageService,
    RecentEntitiesService,
    ReactRouterService
  ];

  private toursQueue = new PriorityQueue<ITour>(ele => ele.tourId);

  _activeTourId = new BehaviorSubject<ITour>(null);
  activeTourId$ = this._activeTourId.asObservable();

  private isDrawerOpen = false;
  private hasOnePipeline = false;
  private toursShown = 0;
  private isTourShownInThisURL = false;

  private documentClickSub: Subscription;

  constructor(
    private _settingsStorageService: SettingsStorageService,
    private _recentEntitiesService: RecentEntitiesService,
    private _router: ReactRouterService
  ) {
    this.setupListners();
  }

  private setupListners() {
    // RecentEntities subject is already set by the time
    this.hasOnePipeline = !!this._recentEntitiesService.recentMap.PIPELINE;

    this._router.queryParams$.subscribe(params => {
      this.isDrawerOpen = params.get('drawer');
    });

    this._recentEntitiesService.recentEntityUpdate.subscribe(recentEntitesMap => {
      this.hasOnePipeline = !!recentEntitesMap.PIPELINE;
    });

    this._router.listen(() => {
      this.isTourShownInThisURL = false;
    });
  }

  private startOrchestrator() {
    if (this.documentClickSub) {
      return;
    }

    const item = this.toursQueue.peek();
    const delay = item.data.tourScheduleDelay || DEBOUNCE_TIME_MS;

    this.documentClickSub = timer(delay)
      .pipe(
        take(1),
        switchMap(() => timer(1000)),
        filter(() => !this._activeTourId.getValue())
      )
      .subscribe(() => {
        this.startTour();

        this.cleanUpAfterTourStart();
      });
  }

  private canTourBeShown(tour: ITour) {
    if (this.isTourShownInThisURL) {
      return;
    }

    if (!tour.rules) {
      return true;
    }

    for (let i = 0; i < tour.rules.length; i++) {
      switch (tour.rules[i]) {
        case TOUR_RULES.CLOSE_DRAWER_IF_OPEN: {
          if (this.isDrawerOpen) {
            this._router.navigate({
              pathname: this._router.pathname,
              search: `?${this._router.getMergedQueryParams({ drawer: undefined }).toString()}`
            });
          }
          return true;
        }

        case TOUR_RULES.ONLY_AFTER_PIPELINE_CREATION: {
          if (this.hasOnePipeline) {
            return true;
          } else {
            return false;
          }
        }
      }
    }

    return true;
  }

  private startTour() {
    const toursArr = [];

    let tourItem = null;
    while (this.toursQueue.size() > 0) {
      if (this.canTourBeShown(this.toursQueue.peek().data)) {
        tourItem = this.toursQueue.pop();
        break;
      }

      toursArr.push(this.toursQueue.pop());
    }

    toursArr.forEach(item => this.toursQueue.insert(item));

    if (!tourItem) {
      return;
    }

    this.toursShown += 1;

    if (tourItem.data.tourShownIdentifier) {
      this._settingsStorageService.applySettings(tourItem.data.tourShownIdentifier, true);
    }

    this._activeTourId.next(tourItem.data);
    this.isTourShownInThisURL = true;
  }

  private cleanUpAfterTourStart() {
    if (this.toursQueue.size() === 0 && this.documentClickSub) {
      this.documentClickSub.unsubscribe();
      this.documentClickSub = null;
    }
  }

  // Bypass schedule
  runTour(tour: ITour) {
    if (this._activeTourId.getValue()) {
      return;
    }
    this._activeTourId.next(tour);
    this.isTourShownInThisURL = true;
  }

  scheduleTour(tour: ITour, priority: TourPriority) {
    if (
      tour.tourShownIdentifier &&
      this._settingsStorageService.getSettings(tour.tourShownIdentifier)
    ) {
      return;
    }

    if (this.toursShown >= MAX_TOURS_ALLOWED_PER_SESSION) {
      return;
    }

    this.toursQueue.insert({
      data: tour,
      priority
    });

    this.startOrchestrator();
  }

  removeTourFromQueue(tourId: ProductTour) {
    this.toursQueue.remove(tourId);
  }

  endCurrentTour() {
    this._activeTourId.next(null);
  }
}
