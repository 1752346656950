import React, { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { SetupGuideDocs } from '../../../../../components/Docs/SetupGuideDocs';
import {
  HdButton,
  HdDocLink,
  HdLink,
  HdRbacButton,
  HdTooltip
} from '../../../../../components/UIElements';
import {
  EXCERPT_ENTITY_ID_FIELD,
  EXCERPT_HEADER_TEXT,
  GLOBAL_SEARCH_ENTITY_PRODUCT_ROUTES_DATA
} from './constants';
import { EntityStatus } from '../../../../core/models/entity-status';
import styles from './styles.module.scss';
import { getDocLinks, getEntityStatus, getNewEntityData } from './util';
import ProductEntityStats from './ProductEntityStats';
import { NodeLogo } from '../../../../../components/NodeLogo';
import { CreateNewEntityExcerptData, DocLink, ProductEntityRoute } from './models';
import { getEntityTrackingData } from '../../util';
import { GlobalSearchEntity } from '../../GlobalSearchReducer';
import useAnalyticsTracker from '../../../../../hooks/useAnalyticsTracker';
import {
  TRACKER_GLOBAL_SEARCH_ENTITY_DOCS_INTERACTION,
  TRACKER_GLOBAL_SEARCH_SELECT_ITEM
} from '../../tracking';
import { RbacPermissions } from '../../../../core/models/user';
import useHasPermission from '../../../../../hooks/useHasPermission';
import HdPane from '../../../../../components/UIElements/HdPane';
import useService from '../../../../../hooks/useService';
import { PlanChatSupportService } from '../../../../core/services/plan-chat-support.service';
import { PaymentPlanTier } from '../../../../payment/models/payment';
import DefaultLabel from '../../../../../components/UIElements/HdLabel/DefaultLabel';
import { getDataIdGenerator } from '../../../../../utils/generateDataId';

const EXISTING_ENTITIES_EXCERPT = {
  Component: ExistingEntityExcerpt,
  props: {}
};

const ExcerptComponentMap = {
  [GlobalSearchEntity.PIPELINES]: EXISTING_ENTITIES_EXCERPT,
  [GlobalSearchEntity.MODELS]: EXISTING_ENTITIES_EXCERPT,
  [GlobalSearchEntity.WORKFLOWS]: EXISTING_ENTITIES_EXCERPT,
  [GlobalSearchEntity.DESTINATIONS]: EXISTING_ENTITIES_EXCERPT,
  [GlobalSearchEntity.TARGETS]: EXISTING_ENTITIES_EXCERPT,
  [GlobalSearchEntity.ACTIVATIONS]: EXISTING_ENTITIES_EXCERPT,
  [GlobalSearchEntity.SOURCE_TYPES]: {
    Component: CreateNewEntityExcerpt,
    props: {
      rbacPermission: RbacPermissions.PIPELINE_CREATE
    }
  },
  [GlobalSearchEntity.DESTINATION_TYPES]: {
    Component: CreateNewEntityExcerpt,
    props: {
      rbacPermission: RbacPermissions.DESTINATION_CREATE
    }
  }
};

export function GlobalSearchExcerpt({
  entity,
  onEntitySelect,
  navigate,
  docLinksMap,
  uuid,
  dataId
}) {
  const { Component, props } = ExcerptComponentMap[entity.entityType];

  if (Component === null || Component === undefined) {
    return null;
  }

  return (
    <Component
      entity={entity}
      onEntitySelect={onEntitySelect}
      navigate={navigate}
      docLinksMap={docLinksMap}
      uuid={uuid}
      dataId={dataId}
      {...props}
    />
  );
}

function ExistingEntityExcerpt({ navigate, entity, docLinksMap, uuid, dataId }) {
  const [status, setStatus] = useState<EntityStatus>();
  const [docLinks, setDocLinks] = useState<Array<Array<DocLink>>>([]);
  const analyticsTracker = useAnalyticsTracker();

  useEffect(() => {
    const _status = getEntityStatus(entity);
    const _docs = getDocLinks(entity, docLinksMap);

    setStatus(_status);
    setDocLinks(_docs);
  }, [entity]);

  const onOpenEntityClick = () => {
    const { url, trackingData } = getEntityTrackingData(entity, true, {
      cta: `View ${headerText}`,
      path: '',
      section: 'excerpt'
    });

    analyticsTracker.eventTrack({
      action: TRACKER_GLOBAL_SEARCH_SELECT_ITEM,
      properties: {
        ...trackingData,
        uuid
      }
    });

    navigate(url);
  };

  const onQuickNavLinkClick = (route: ProductEntityRoute) => {
    const { url, trackingData } = getEntityTrackingData(entity, false, {
      cta: route.displayName,
      path: route.path,
      section: 'excerpt'
    });

    analyticsTracker.eventTrack({
      action: TRACKER_GLOBAL_SEARCH_SELECT_ITEM,
      properties: {
        ...trackingData,
        uuid
      }
    });

    navigate(url);
  };

  const onDocLinkClick = docLink => {
    analyticsTracker.eventTrack({
      action: TRACKER_GLOBAL_SEARCH_ENTITY_DOCS_INTERACTION,
      properties: {
        entity: entity.entityType,
        exit: docLink.url,
        cta: docLink.title,
        uuid
      }
    });
  };

  let productLinks = GLOBAL_SEARCH_ENTITY_PRODUCT_ROUTES_DATA[entity.entityType];
  if (
    entity.entityType === GlobalSearchEntity.PIPELINES &&
    // eslint-disable-next-line no-prototype-builtins
    entity.hasOwnProperty('dest_valid_for_load_status_page') &&
    !entity?.dest_valid_for_load_status_page
  ) {
    productLinks = productLinks.filter(
      (link: ProductEntityRoute) => link.displayName !== 'Load Status'
    );
  }

  const headerText = EXCERPT_HEADER_TEXT[entity.entityType];
  const renderStatus = !!(status && status.name);
  const isModelEntity = entity.entityType === GlobalSearchEntity.MODELS;
  const isDraftStatus = status?.value === 'DRAFT';
  const showQuickLinks = productLinks.length > 0 && !(isModelEntity && isDraftStatus);
  const showDocLinks = docLinks.length > 0;

  const idField = EXCERPT_ENTITY_ID_FIELD[entity.entityType];
  const idText = entity[idField] || '';

  const dataIdGenerator = getDataIdGenerator(dataId, headerText);

  return (
    <>
      <>
        <div className={`center-flex-row justify-between ${styles.headerSection}`}>
          <span data-id={dataIdGenerator('title')}>
            {headerText} #{idText}
            {renderStatus && !!status ? (
              <span
                className={`ml-2 badge badge-solid-xss badge-solid-faded ${status.colorType}-badge`}
              >
                {status.name}
              </span>
            ) : null}
          </span>

          <HdLink
            direction='right'
            icon='right'
            onClick={onOpenEntityClick}
            dataId={dataIdGenerator('')}
          >
            View {headerText}
          </HdLink>
        </div>

        <ProductEntityStats entity={entity} dataId={dataIdGenerator('')} />
      </>

      {showQuickLinks ? (
        <div className={`${styles.productLinks} border-bottom border-top`}>
          {productLinks.map(product => (
            <HdButton
              key={product.displayName}
              icon={product.icon}
              variation='faded'
              sizwe='sm'
              className={styles.productLinkBtn}
              dataId={dataIdGenerator(product.displayName)}
              onClick={() => onQuickNavLinkClick(product)}
            >
              {product.displayName}
            </HdButton>
          ))}
        </div>
      ) : null}

      {showDocLinks ? (
        <>
          <div className='text-bold mb-1 mt-5'> Read Documentation </div>

          <div className='d-flex'>
            {docLinks.map((col, colIndex) => (
              <ul key={colIndex} className={`w-50 flex-col ${styles.docLinkUlStyle}`}>
                {col.map((docLink, linkIndex) => (
                  <li key={linkIndex} className={styles.docLinkContainer}>
                    <HdDocLink
                      className={`${styles.docLink}`}
                      label={docLink.title}
                      docLink={docLink.url}
                      icon='documentation-open'
                      direction='left'
                      iconClassName={styles.icon}
                      dataId={dataIdGenerator(docLink.title)}
                      onClick={() => onDocLinkClick(docLink)}
                    />
                  </li>
                ))}
              </ul>
            ))}
          </div>
        </>
      ) : null}
    </>
  );
}

function CreateNewEntityExcerpt({ entity, onEntitySelect, rbacPermission, navigate, dataId }) {
  const [data, setData] = useState<CreateNewEntityExcerptData | undefined>();
  const ref = useRef<HTMLDivElement>();
  const { hasPermission } = useHasPermission();
  const isRbacActionEnabled = hasPermission(rbacPermission);

  const planChatSupportService = useService(PlanChatSupportService);

  const isFreePlanCustomer = planChatSupportService.currentPlanTier === PaymentPlanTier.FREE;

  useEffect(() => {
    const _data = getNewEntityData(entity, isFreePlanCustomer);
    setData(_data);
  }, [entity]);

  const onSBSDocDimensionChange = height => {
    if (ref.current) {
      ref.current.style.height = `${30 + height}px`;
    }
  };

  const onCreateEntityClick = () => {
    onEntitySelect(entity, false, { cta: data.cta, section: 'excerpt' });
  };

  const isPaidSource = data?.displayMeta?.showPaidBadge;
  const isUpcomingSource = data?.displayMeta.showUpcomingBadge;
  const isCreateDisabled = data?.isDisabled;

  const createSourceDisabledTooltipText = isCreateDisabled
    ? `${data.displayMeta.displayName} is not available in the Free plan. Upgrade to a paid plan to replicate data from this source`
    : null;

  const dataIdGenerator = getDataIdGenerator(`${dataId}-create-new-${entity.entityType}`);

  return (
    <div ref={ref} data-id={dataIdGenerator('')}>
      {!isRbacActionEnabled ? (
        <HdPane
          icon='warning'
          disableHide
          variant='warning-faded'
          className={styles.rbacWarningPane}
          iconClasses='ml-2'
          dataId={dataIdGenerator('rbac-warning')}
        >
          <span className='text-medium'>Access Restricted!&nbsp;</span>

          <span style={{ fontSize: 16 }}>😢&nbsp;</span>

          <span className='text-default'>Please contact your admin to get access.</span>
        </HdPane>
      ) : null}

      {isCreateDisabled ? (
        <HdPane
          icon='warning'
          disableHide
          variant='warning-faded'
          className={styles.rbacWarningPane}
          iconClasses='ml-2'
          dataId={dataIdGenerator('rbac-warning')}
        >
          <span className='text-medium'>
            {data.displayMeta.displayName} is not available in the Free plan.&nbsp;
          </span>

          <span style={{ fontSize: 16 }}>😢&nbsp;</span>

          <HdLink
            dataId={dataIdGenerator('upgrade-to-paid-plan')}
            onClick={() => navigate('/payment/plan-details')}
          >
            Upgrade to Paid plan
          </HdLink>
        </HdPane>
      ) : null}

      {data ? (
        <>
          <div className='center-flex-row border-bottom pb-3 mb-4'>
            <NodeLogo
              className='mr-2 border-radius-md'
              size='5'
              roundedBorders={false}
              logoURL={data.displayMeta.logoURL}
              darkModeLogoURL={data.displayMeta.darkModeLogoURL}
              primaryColor={data.displayMeta.primaryColor}
              darkModePrimaryColor={data.displayMeta.darkModePrimaryColor}
            />

            <div className='center-flex-row w-100'>
              <div
                className={clsx(
                  {
                    'flex-col': isUpcomingSource || isPaidSource
                  },
                  'w-100'
                )}
              >
                <span className='text-bold w-inherit'>
                  {data.displayMeta.displayName}&nbsp;
                  {isUpcomingSource ? 'will launch soon!' : ''}
                </span>

                {isUpcomingSource || isPaidSource ? (
                  <div className='mt-1 mxw-fit-content'>
                    {isPaidSource ? <DefaultLabel color='success'>Paid</DefaultLabel> : null}

                    {isUpcomingSource ? (
                      <DefaultLabel color='primary-1'>Upcoming</DefaultLabel>
                    ) : null}
                  </div>
                ) : null}
              </div>

              {isCreateDisabled ? (
                <div className='d-flex justify-end w-100'>
                  <HdTooltip title={createSourceDisabledTooltipText} disableInteractive>
                    <div>
                      <HdButton
                        icon={data.icon}
                        variation='faded'
                        size='md'
                        className={styles.productLinkBtn}
                        onClick={onCreateEntityClick}
                        dataId={dataIdGenerator('')}
                        disabled
                      >
                        {data.cta}
                      </HdButton>
                    </div>
                  </HdTooltip>
                </div>
              ) : null}

              {!isUpcomingSource && !isCreateDisabled ? (
                <div className='d-flex justify-end w-100'>
                  <HdRbacButton
                    icon={data.icon}
                    size='md'
                    className={styles.productLinkBtn}
                    rbacPermission={rbacPermission}
                    onClick={onCreateEntityClick}
                    dataId={dataIdGenerator('')}
                    disabled={isCreateDisabled}
                  >
                    {data.cta}
                  </HdRbacButton>
                </div>
              ) : null}
            </div>
          </div>

          {!isUpcomingSource && data?.docLink ? (
            <div className={styles.setupGuideContainer}>
              <SetupGuideDocs
                key={data.id}
                docLink={data.docLink}
                queryParams={{ introOnlyMode: 'true' }}
                iframeClassName={styles.sbsContainer}
                LoadingComponent={SetupGuideDocsShimmer}
                introMode
                onIntroModeLoad={height => onSBSDocDimensionChange(height)}
              />
            </div>
          ) : null}

          {isUpcomingSource ? (
            <div className={styles.setupGuideContainer}>
              Thank you for your interest in {data.displayMeta.displayName} source. We will send an
              invite for exclusive preview once it&apos;s launched.
            </div>
          ) : null}
        </>
      ) : null}
    </div>
  );
}

const SHIMMER_FIELDS = ['sm', 'lg', 'sm'];

function SetupGuideDocsShimmer() {
  return (
    <div className='mt-1'>
      {SHIMMER_FIELDS.map((size, index) => (
        <div className='shimmer shimmer-line mb-2' key={index} />
      ))}
    </div>
  );
}
