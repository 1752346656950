import React, { useEffect, useState } from 'react';
import styles from './styles.module.scss';
import AccountsAPI from '../../../containers/account/AccountsAPI';
import HdIcon from '../../UIElements/HdIcon';
import { EntityUIState } from '../../../containers/core/models/entitiy-ui-state';

interface TFACodeProps {
  onSetQrCodeCallback: Function;
  onSetUiStateCallback?: Function;
  refreshQrCode?: boolean;
  className?: string;
  qrClassName?: string;
}

function TFACode({
  onSetUiStateCallback,
  onSetQrCodeCallback,
  refreshQrCode,
  className = '',
  qrClassName = ''
}: TFACodeProps) {
  const [qrCode, setQrCode] = useState(null);
  const [loading, setLoading] = useState(false);
  const showQr = !loading && qrCode;

  useEffect(() => {
    getTfaQR();
  }, []);

  useEffect(() => {
    if (refreshQrCode) {
      getTfaQR();
    }
  }, [refreshQrCode]);

  useEffect(() => {
    onSetQrCodeCallback(qrCode);
  }, [qrCode]);

  const getTfaQR = () => {
    setLoading(true);
    syncUiState({ state: EntityUIState.LOADING });
    AccountsAPI.getTfaQR()
      .then(({ data }) => {
        setLoading(false);
        setQrCode(data);
        syncUiState({ state: EntityUIState.IDLE });
      })
      .catch(error => {
        setLoading(false);
        syncUiState({ state: EntityUIState.ERRORED, error });
      });
  };

  const syncUiState = data => {
    if (onSetUiStateCallback) {
      onSetUiStateCallback(data);
    }
  };

  return (
    <div className={`${styles.qrWrapper} ${className}`}>
      {showQr ? (
        <figure className={`${styles.qrContainer} ${qrClassName}`}>
          <img src={qrCode.url} alt='QR code' />
        </figure>
      ) : (
        <div className={styles.qrLoading}>
          <HdIcon name='testing' className='mb-5' size={3} />
          Loading QR Code...
        </div>
      )}
    </div>
  );
}

export default TFACode;
