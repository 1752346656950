import { Subject } from 'rxjs';

export class GlobalBlockingLoaderService {
  private _loader$ = new Subject<{ show: boolean }>();
  private _pendingRequests = 0;

  loaderState = this._loader$.asObservable();

  constructor() {
  }

  push() {
    this._pendingRequests++;
    if (this._pendingRequests > 0) {
      this._show();
    }
  }

  pop() {
    if (this._pendingRequests > 0) {
      this._pendingRequests--;
    }

    if (this._pendingRequests === 0) {
      this._hide();
    }
  }

  hasPendingTasks() {
    return this._pendingRequests;
  }

  private _show() {
    this._loader$.next({ show: true });
  }

  private _hide() {
    this._loader$.next({ show: false });
  }
}
