/* eslint-disable import/no-cycle */
/* eslint-disable class-methods-use-this */
import { _sshService } from '../../../../core/services/ssh.service';
import { DEFAULT_FTP_PROTOCOL, FTP_PROTOCOLS_OPTIONS, FTP_FILE_FORMATS_OPTIONS } from './constants';
import { SourceTypeConfigHelper } from '../../../../../components/Node/source-settings-form/config-helper';
import { FTPProtocolType } from './models';

export class FtpConfigHelperReact implements SourceTypeConfigHelper {
  getConfigFromRawData(rawData: any) {
    return {
      ftpHost: rawData.ftp_host,
      ftpPort: rawData.ftp_port,
      user: rawData.ftp_user,
      password: rawData.ftp_password,
      prefix: rawData.path_prefix,
      splitByFolders: !!rawData.split_by_folders,
      sshIp: rawData.ssh_host,
      sshPort: rawData.ssh_port,
      sshUser: rawData.ssh_user,
      useSSH: !!(rawData.ssh_host || rawData.ssh_port || rawData.ssh_user),
      fileFormat: FTP_FILE_FORMATS_OPTIONS.find(format => format.value === rawData.file_format),
      compressedFiles: rawData.process_compressed_files,
      protocolType: FTP_PROTOCOLS_OPTIONS.find(
        (format: FTPProtocolType) => format.value === rawData.type
      ),
      delimiter: rawData.delimiter,
      firstRowColumnHeaders:
        'first_line_as_header' in rawData ? rawData.first_line_as_header : true,
      createEventsFromChildNodes: rawData.file_format === 'XML' ? rawData.xml_depth === 1 : null,
      parseStringsAsDatetime: !!rawData.parse_strings_as_datetime
    };
  }

  getDefaultConfig() {
    return {
      protocolType: { ...DEFAULT_FTP_PROTOCOL, id: DEFAULT_FTP_PROTOCOL.value },
      ftpPort: DEFAULT_FTP_PROTOCOL.defaultPort,
      prefix: '/',
      delimiter: ',',
      firstRowColumnHeaders: true,
      createEventsFromChildNodes: true,
      parseStringsAsDatetime: false
    };
  }

  buildConnectionParams(data: any) {
    const params: any = {
      ftp_host: data.ftpHost,
      ftp_port: data.ftpPort,
      ftp_user: data.user,
      ftp_password: data.password || undefined,
      path_prefix: data.prefix || undefined,
      split_by_folders: !!data.splitByFolders,
      file_format: data.fileFormat?.value,
      process_compressed_files: data.compressedFiles,
      type: data.protocolType?.value,
      first_line_as_header: data.firstRowColumnHeaders,
      parse_strings_as_datetime: data.parseStringsAsDatetime
    };

    if (data.fileFormat?.value === 'XML') {
      params.xml_depth = data.createEventsFromChildNodes ? 1 : 0;
    }

    if (data.fileFormat?.value === 'CSV') {
      params.delimiter = data.delimiter;
    } else if (data.fileFormat?.value === 'TSV') {
      params.delimiter = '\\t';
    } else {
      params.delimiter = null;
    }

    const sshParams = _sshService.getSSHParams(data);
    return { ...params, ...sshParams };
  }
}
