import dayjs from 'dayjs';

const dayjsConstructor = dayjs;

export function getSecondsUntilUpdate(dayjsInstance: dayjs.Dayjs) {
  const howOldDays = Math.abs(dayjsConstructor().diff(dayjsInstance, 'days'));

  if (howOldDays > 0) {
    return 24 * 60 * 60;
  }

  const howOld = Math.abs(dayjsConstructor().diff(dayjsInstance, 'minute'));

  if (howOld < 1) {
    return 1;
  }

  if (howOld < 60) {
    return 30;
  }

  if (howOld < 180) {
    return 300;
  }

  return 3600;
}
