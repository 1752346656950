import { Observable, of, Subject } from 'rxjs';
import { map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { coerceBooleanProperty } from '../../../legacy-utils/coercion';
import { DESTINATION_SCHEMA_PAGE_LIMIT } from '../../destination/constants';
import { SchemaFactory, SchemasFactory } from '../../destination/factory.ts/utils';
import { wrapIntoObservable } from '../../../components/wizard/wizard-native/utils';
import { DestinationConfigHelperService } from '../../../components/Node/destination-settings-form/destination-config-helper.service';
import { DESTINATION_TYPES } from '../../../components/Node/destination-type/model';
import { AppConfig } from '../app.config';
import { BACKGROUND_NETWORK_REQ_UI_OPTION_HANDLE_TIMEOUT, BACKGROUND_NETWORK_REQ_UI_OPTIONS } from '../constants';
import { HevoEntity } from '../models/hevo-entity';
import { NetworkRequestOptions } from '../models/request';
import { createHttpParams } from '../../../legacy-utils/request';
import { GlobalConfigService } from './global-config.service';
import { RecentEntitiesService } from './recent-entities.service';
import { RxRequestService } from './rx-request.service';
import { SSHService } from './ssh.service';


export class DestinationService {
  static deps = [
    AppConfig,
    RxRequestService,
    GlobalConfigService,
    SSHService,
    RecentEntitiesService,
    DestinationConfigHelperService
  ];

  constructor(
    private _appConfig: AppConfig,
    private _rxRequestService: RxRequestService,
    private _globalConfigService: GlobalConfigService,
    private _sshService: SSHService,
    private _recentEntitiesService: RecentEntitiesService,
    private _destinationConfigHelperService: DestinationConfigHelperService
  ) {
  }

  destinationUrl: string = this._appConfig.getDestinationsURL();

  editDestinationSubject = new Subject<void>();

  addDestination(
    data: any,
    destinationType: string,
    flag?: boolean,
    isActivationWarehouse?: boolean,
    draftDestinationId?: any
  ): Observable<any> {
    return this.getDestinationParams(data, destinationType).pipe(
      switchMap((params: any) => {
        const requestUrl = this.destinationUrl + '/' + destinationType;

        const options: NetworkRequestOptions = {
          uiOptions: {
            showLoading: false,
            showErrorMsg: false,
            ...BACKGROUND_NETWORK_REQ_UI_OPTION_HANDLE_TIMEOUT
          },
          networkOptions: {
            params: createHttpParams({
              force: flag === true,
              is_activation: true === isActivationWarehouse,
              draft_destination_id: draftDestinationId
            })
          }
        };

        return this._rxRequestService.post(requestUrl, options, params).pipe(
          tap((res) => {
            this._recentEntitiesService.setRecent(
              HevoEntity.DESTINATION.value,
              res.data
            );
          })
        );
      })
    );
  }

  editDestination(
    data: any,
    destinationType: string,
    flag?: boolean,
    ignoreWarnings?: boolean
  ): Observable<any> {
    return this.getDestinationParams(data, destinationType, true).pipe(
      switchMap((params: any) => {
        const destinationId = data.id;
        const requestUrl = this.destinationUrl + '/' + destinationId;

        const queryParams = {
          force: flag === true
        };

        if (destinationType === 'BIGQUERY') {
          queryParams['ignore_edit_dest_warnings'] = ignoreWarnings || false;
        }

        const options: NetworkRequestOptions = {
          uiOptions: {
            showLoading: false,
            showErrorMsg: false,
            ...BACKGROUND_NETWORK_REQ_UI_OPTION_HANDLE_TIMEOUT
          },
          networkOptions: {
            params: createHttpParams(queryParams)
          }
        };

        return this._rxRequestService.put(requestUrl, options, params);
      })
    );
  }

  testConnection(
    data: any,
    destinationType: any,
    flag?: boolean,
    isActivationWarehouse?: boolean,
    draftDestinationId?: any
  ): Observable<any> {
    const requestUrl = this.destinationUrl + `/${ destinationType }/test`;

    return this.buildConnectionParams(data, destinationType, !!data.id).pipe(
      switchMap((params: any) => {
        const options: NetworkRequestOptions = {
          uiOptions: {...BACKGROUND_NETWORK_REQ_UI_OPTIONS, ...BACKGROUND_NETWORK_REQ_UI_OPTION_HANDLE_TIMEOUT },
          networkOptions: {
            params: createHttpParams({
              'destination_id': data.id || null,
              'force': flag === true,
              'is_activation': isActivationWarehouse === true,
              draft_destination_id: draftDestinationId
            })
          }
        };

        return this._rxRequestService.post(requestUrl, options, params);
      })
    );
  }

  getDestinations(params?: Record<string, any>): Observable<any> {
    const options: NetworkRequestOptions = {
      uiOptions: {
        showSuccessMsg: false,
        showLoading: false
      },
      networkOptions: params ? {
        params: createHttpParams(params)
      } : undefined
    };

    return this._rxRequestService.get(this.destinationUrl, options);
  }

  getDestination(
    id: any,
    destOptions: any,
    showLoading = true,
    currentSchemaList = []
  ): Observable<any> {
    const requestUrl = this.destinationUrl + '/' + id;

    let paramsOption: any = {
      'display-details': true,
      schemas: coerceBooleanProperty(destOptions.schemas),
      relations: coerceBooleanProperty(destOptions.relations)
    };

    if (destOptions.schemas) {
      const cursor = destOptions.cursor || null;

      paramsOption = {
        ...paramsOption,
        limit: DESTINATION_SCHEMA_PAGE_LIMIT,
        cursor: cursor
      };
    }

    const options: NetworkRequestOptions = {
      uiOptions: {
        showSuccessMsg: false,
        showLoading: showLoading
      },
      networkOptions: {
        params: createHttpParams(paramsOption)
      }
    };

    return this._rxRequestService.get(requestUrl, options).pipe(
      withLatestFrom(
        destOptions.filterReserved
          ? this._globalConfigService.getConfig<any[]>(
          'destination_field_mappings_not_allowed_list'
          )
          : of(null)
      ),
      switchMap(([ res, reservedFields ]) => {
        if (!res || !res.data) {
          return res;
        }

        // Append new schema to existing list
        const newSchemaList = res.data?.schemas;
        const updatedSchemaList = currentSchemaList.concat(newSchemaList);

        if (
          destOptions.schemas &&
          true === res.data?.pagination_schema_cursor.has_more
        ) {
          destOptions = {
            ...destOptions,
            cursor: res.data?.pagination_schema_cursor.next_cursor
          };

          return this.getDestination(
            id,
            destOptions,
            showLoading,
            updatedSchemaList
          );
        }

        /*
         * When all the schema is fetched then reset the cache
         * holding the current schema list and send result back
         * */
        const schemas = SchemasFactory(updatedSchemaList);

        if (destOptions.filterReserved) {
          schemas.forEach((schema) => {
            schema.fields = schema.fields.filter((field) => {
              return !reservedFields.includes(field.name);
            });
          });
        }

        return of({
          ...res,
          data: {
            ...res.data,
            schemas: schemas,
            display_config: this._sshService.filterSSHDisplayConfig(
              res.data.display_config
            )
          }
        });
      })
    );
  }

  getDestinationBySeqId(
    seqId: any,
    destOptions: any,
    showLoading = true
  ): Observable<any> {
    const requestUrl = this.destinationUrl + `/by-seq-id/${ seqId }`;

    const options: NetworkRequestOptions = {
      uiOptions: {
        showSuccessMsg: false,
        showLoading: showLoading
      },
      networkOptions: {
        params: createHttpParams({
          'display-details': true,
          schemas: coerceBooleanProperty(destOptions.schemas),
          relations: coerceBooleanProperty(destOptions.relations)
        })
      }
    };

    return this._rxRequestService.get(requestUrl, options).pipe(
      withLatestFrom(
        destOptions.filterReserved
          ? this._globalConfigService.getConfig<any[]>(
          'destination_field_mappings_not_allowed_list'
          )
          : of(null)
      ),
      map(([ res, reservedFields ]) => {
        if (!res || !res.data) {
          return res;
        }

        const schemas = SchemasFactory(res.data.schemas);

        if (destOptions.filterReserved) {
          schemas.forEach((schema) => {
            schema.fields = schema.fields.filter((field) => {
              return !reservedFields.includes(field.name);
            });
          });
        }

        return {
          ...res,
          data: {
            ...res.data,
            schemas: schemas
          }
        };
      })
    );
  }

  getTableSchema(
    destinationId: any,
    schemaName: string,
    filterReserved = false
  ): Observable<any> {
    const requestUrl =
      this.destinationUrl +
      `/${ destinationId }/schema/${ encodeURIComponent(schemaName) }`;
    const options: NetworkRequestOptions = {
      uiOptions: {
        showLoading: false
      }
    };

    return this._rxRequestService.get(requestUrl, options).pipe(
      withLatestFrom(
        filterReserved
          ? this._globalConfigService.getConfig<any[]>(
          'destination_field_mappings_not_allowed_list'
          )
          : of(null)
      ),
      map(([ res, reservedFields ]) => {
        if (!res || !res.data) {
          return res;
        }

        const schema = SchemaFactory(res.data);

        if (filterReserved) {
          schema.fields = schema.fields.filter((field) => {
            return !reservedFields.includes(field.name);
          });
        }

        return {
          ...res,
          data: schema
        };
      })
    );
  }

  getDestinationParams(data: any, destType: string, isUpdateMode = false): any {
    return this.buildConnectionParams(data, destType, isUpdateMode).pipe(
      map((connectionParamsData: any) => {
        return {
          name: data.name,
          config: connectionParamsData
        };
      })
    );
  }

  buildConnectionParams(data: any, destType: string, isUpdateMode = false): Observable<any> {
    let connectionParams: any;
    connectionParams = wrapIntoObservable(
      this._destinationConfigHelperService.getConfigHelper(destType).buildConnectionParams(data, isUpdateMode)
    );

    return connectionParams.pipe(
      map((res: any) => {
        if (DESTINATION_TYPES[destType].populateLoadedAt) {
          res['loaded_at'] = data.loadedAt;
        }

        return res;
      })
    );
  }

  getSampleSettings(): Observable<any> {
    const requestUrl = this.destinationUrl + '/samples';

    const options: NetworkRequestOptions = {
      uiOptions: {
        showLoading: false
      }
    };

    return this._rxRequestService.get(requestUrl, options);
  }
}
