import { DestinationTypeConfigHelper } from '../../../../../components/Node/destination-settings-form/destination-type-config-helper';
import { S3_BUCKET_REGIONS } from '../../../../pipeline/create/ConfigSourceType/S3Base/constants';


export class FireboltConfigHelper implements DestinationTypeConfigHelper {
  getConfigFromRawData(rawData: any) {
    return {
      username: rawData.firebolt_username,
      password: rawData.firebolt_password,
      engine: rawData.engine_id && rawData.engine_name ? {
        name: rawData.engine_name,
        id: rawData.engine_id
      } : undefined,
      database: rawData.db_name && rawData.db_id ? {
        name: rawData.db_name,
        id: rawData.db_id
      } : undefined,
      awsAccessKey: rawData.aws_access_key,
      awsSecretAccessKey: rawData.aws_secret_key,
      bucket: rawData.s3_bucket,
      bucketRegion: S3_BUCKET_REGIONS.find((region: any) => {
        return region.value === rawData.s3_bucket_region;
      })
    };
  }

  getDefaultConfig() {
  }

  buildConnectionParams(data: any) {
    return {
      firebolt_username: data.username,
      firebolt_password: data.password,
      engine_id: data.engine?.id,
      engine_name: data.engine?.name,
      db_name: data.database?.name,
      db_id: data.database?.id,
      aws_access_key: data.awsAccessKey,
      aws_secret_key: data.awsSecretAccessKey,
      s3_bucket: data.bucket,
      s3_bucket_region: data.bucketRegion?.value,
    };
  }
}
