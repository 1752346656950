export enum BannerColorPalette {
  INFO = 'info',
  WARN = 'warning',
  ERROR = 'error',
  DEFERRED = 'deferred',
  ERROR_FADED = 'error-faded',
  WARN_FADED = 'warning-faded'
}

export enum BannerContentColorPalette {
  INFO = 'info',
  WARN = 'warning',
  ERROR = 'error',
  DEFERRED = 'deferred'
}
