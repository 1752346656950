import { REGION_NAMES } from '../../../../../components/Node/constants';

export const S3_BUCKET_REGIONS: any[] = [
  {
    name: REGION_NAMES['us-east-1'],
    value: 'us-east-1'
  },
  {
    name: REGION_NAMES['us-east-2'],
    value: 'us-east-2'
  },
  {
    name: REGION_NAMES['us-west-1'],
    value: 'us-west-1'
  },
  {
    name: REGION_NAMES['us-west-2'],
    value: 'us-west-2'
  },
  {
    name: REGION_NAMES['ca-central-1'],
    value: 'ca-central-1'
  },
  {
    name: REGION_NAMES['ap-northeast-1'],
    value: 'ap-northeast-1'
  },
  {
    name: REGION_NAMES['ap-northeast-2'],
    value: 'ap-northeast-2'
  },
  {
    name: REGION_NAMES['ap-northeast-3'],
    value: 'ap-northeast-3'
  },
  {
    name: REGION_NAMES['ap-southeast-1'],
    value: 'ap-southeast-1'
  },
  {
    name: REGION_NAMES['ap-southeast-2'],
    value: 'ap-southeast-2'
  },
  {
    name: REGION_NAMES['ap-east-1'],
    value: 'ap-east-1'
  },
  {
    name: REGION_NAMES['ap-south-1'],
    value: 'ap-south-1'
  },
  {
    name: REGION_NAMES['cn-north-1'],
    value: 'cn-north-1'
  },
  {
    name: REGION_NAMES['cn-northwest-1'],
    value: 'cn-northwest-1'
  },
  {
    name: REGION_NAMES['eu-central-1'],
    value: 'eu-central-1'
  },
  {
    name: REGION_NAMES['eu-north-1'],
    value: 'eu-north-1'
  },
  {
    name: REGION_NAMES['eu-south-1'],
    value: 'eu-south-1'
  },
  {
    name: REGION_NAMES['eu-west-1'],
    value: 'eu-west-1'
  },
  {
    name: REGION_NAMES['eu-west-2'],
    value: 'eu-west-2'
  },
  {
    name: REGION_NAMES['eu-west-3'],
    value: 'eu-west-3'
  },
  {
    name: REGION_NAMES['sa-east-1'],
    value: 'sa-east-1'
  },
  {
    name: REGION_NAMES['af-south-1'],
    value: 'af-south-1'
  },
  {
    name: REGION_NAMES['me-south-1'],
    value: 'me-south-1'
  },
  {
    name: REGION_NAMES['ap-southeast-3'],
    value: 'ap-southeast-3'
  }
];

export const S3_FILE_FORMATS: any[] = [
  {
    name: 'JSON',
    value: 'JSON'
  },
  {
    name: 'XML',
    value: 'XML'
  },
  {
    name: 'CSV',
    value: 'CSV'
  },
  {
    name: 'AVRO',
    value: 'AVRO'
  },
  {
    name: 'TSV',
    value: 'TSV'
  }
];
