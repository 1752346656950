import { buildFilterOptionsFromSourceTypes } from '../../legacy-utils/integration-node';
import {
  FREQUENCY_TYPE_MINUTES,
  FREQUENCY_TYPE_HOURS
} from '../../containers/scheduler/ExecutionPolicyDialog/constants';
import { PolicyTypeEnum } from '../../containers/scheduler/ExecutionPolicyDialog/models';
import { ROLES_PRIORITY_LIST } from '../../containers/team/factory';

export const getPipelinesWithSuggestedSyncFrequency = pipelines =>
  pipelines.map(pipeline => calculateAutoSuggestedSyncFrequency(pipeline));

const calculateAutoSuggestedSyncFrequency = pipeline => {
  let syncFrequencyPolicy = {};

  const { dest_execution_policy: destExecutionPolicy, execution_policy: executionPolicy } =
    pipeline;

  const destFrequencyType = destExecutionPolicy?.type;
  const sourceFrequencyType = executionPolicy?.type;

  if (
    sourceFrequencyType === PolicyTypeEnum.FREQUENCY &&
    destFrequencyType === PolicyTypeEnum.FREQUENCY
  ) {
    if (destExecutionPolicy.frequency < executionPolicy.frequency) {
      syncFrequencyPolicy = destExecutionPolicy;
    } else {
      syncFrequencyPolicy = executionPolicy;
    }
  } else if (sourceFrequencyType === PolicyTypeEnum.FREQUENCY) {
    syncFrequencyPolicy = executionPolicy;
  } else if (destFrequencyType === PolicyTypeEnum.FREQUENCY) {
    syncFrequencyPolicy = destExecutionPolicy;
  } else {
    syncFrequencyPolicy = executionPolicy || destExecutionPolicy;
  }

  return { ...pipeline, sync_execution_policy: syncFrequencyPolicy };
};

export function getSyncFrequencyDisplayText(executionPolicy) {
  if (!executionPolicy) {
    return 'Real Time';
  }

  let frequencyText = '';

  if (executionPolicy.type === PolicyTypeEnum.FREQUENCY) {
    if (executionPolicy.frequency_type === FREQUENCY_TYPE_HOURS.value) {
      const milliToHr = executionPolicy.frequency / FREQUENCY_TYPE_HOURS.minSeconds;
      frequencyText = `${milliToHr} ${milliToHr > 1 ? 'Hrs' : 'Hr'}`;
    } else {
      const milliToMin = executionPolicy.frequency / FREQUENCY_TYPE_MINUTES.minSeconds;
      frequencyText = `${milliToMin} Mins`;
    }
  } else if (executionPolicy.type === PolicyTypeEnum.CRON) {
    executionPolicy.time_hour_list.forEach(hr => {
      if (frequencyText.length) {
        frequencyText += `,${hr}:00 UTC`;
      } else {
        frequencyText += `${hr}:00 UTC`;
      }
    });
    frequencyText = `Run daily (${frequencyText})`;
  }
  return frequencyText;
}

export const getSourceTypeFilterOptionsFromPipelines = pipelines => {
  const sourceObj = {};
  pipelines.forEach(pipeline => {
    const sourceType = sourceObj[pipeline.source_type];
    if (!sourceType) {
      sourceObj[pipeline.source_type] = pipeline;
    }
  });
  return buildFilterOptionsFromSourceTypes(Object.values(sourceObj));
};

//same func used for audit table permission.
export const isUserEligibleToStartSyncFrequencyMigration = rbacRoles => {
  const userRbacRoles = rbacRoles;
  if (!userRbacRoles.length) {
    return false;
  }

  const eligibleRoles = [
    ROLES_PRIORITY_LIST[0],
    ROLES_PRIORITY_LIST[1],
    ROLES_PRIORITY_LIST[3],
    ROLES_PRIORITY_LIST[4]
  ];

  for (let index = 0; index < userRbacRoles.length; index++) {
    const roleExist = eligibleRoles.includes(userRbacRoles[index]);
    if (roleExist) {
      return true;
    }
  }
  return false;
};
