import { AdrollConfigHelperReact } from '../../../containers/pipeline/create/ConfigSourceType/AdRoll/adroll-config-helper';
import { AmazonAdsConfigHelper } from '../../../containers/pipeline/create/ConfigSourceType/AmazonAds/configHelper';
import { AmplitudeAnalyticsConfigHelper } from '../../../containers/pipeline/create/ConfigSourceType/AmplitudeAnalytics/configHelper';
import { AppleSearchAdsConfigHelper } from '../../../containers/pipeline/create/ConfigSourceType/AppleSearchAds/configHelper';
import { AppsflyerConfigHelper } from '../../../containers/pipeline/create/ConfigSourceType/Appsflyer/appsflyer-config-helper';
import { AsanaConfigHelper } from '../../../containers/pipeline/create/ConfigSourceType/Asana/configHelper';
import { AwsDocumentdbConfigHelperReact } from '../../../containers/pipeline/create/ConfigSourceType/AwsDocumentDb/configHelper';
import { AzureBlobConfigHelperReact } from '../../../containers/pipeline/create/ConfigSourceType/AzureBlob/azure-blob-config-helper';
import { BigQuerySourceConfigHelper } from '../../../containers/pipeline/create/ConfigSourceType/Bigquery/big-query-config-helper';
import {
  BraintreePaymentsConfigHelper
} from '../../../containers/pipeline/create/ConfigSourceType/BraintreePayments/braintree-payments-config-helper';
import { BrazeConfigHelper } from '../../../containers/pipeline/create/ConfigSourceType/Braze/braze-config-helper';
import { ChargebeeConfigHelper } from '../../../containers/pipeline/create/ConfigSourceType/Chargebee/chargebee-config-helper';
import { ClevertapConfigHelper } from '../../../containers/pipeline/create/ConfigSourceType/Clevertap/clevertap-config-helper';
import { CriteoConfigHelper } from '../../../containers/pipeline/create/ConfigSourceType/Criteo/criteo-config-helper';
import { DynamodbConfigHelper } from '../../../containers/pipeline/create/ConfigSourceType/DynamoDb/configHelper';
import { ElasticSearchConfigHelper } from '../../../containers/pipeline/create/ConfigSourceType/ElasticSearch/configHelper';
import { FbaConfigHelper } from '../../../containers/pipeline/create/ConfigSourceType/FacebookAds/configHelper';
import { FbInsightsConfigHelperReact } from '../../../containers/pipeline/create/ConfigSourceType/FacebookPages/configHelper';
import { FirebaseAnalyticsConfigHelperReact } from '../../../containers/pipeline/create/ConfigSourceType/FireBaseAnalytics/configHelper';
import { FrontConfigHelper } from '../../../containers/pipeline/create/ConfigSourceType/Front/configHelper';
import { FtpConfigHelperReact } from '../../../containers/pipeline/create/ConfigSourceType/FTP/configHelper';
import { GcsConfigHelper } from '../../../containers/pipeline/create/ConfigSourceType/Gcs/configHelper';
import { GoogleAdsReactConfigHelper } from '../../../containers/pipeline/create/ConfigSourceType/GoogleAds/configHelper';
import { GoogleAnalyticsConfigHelperReact } from '../../../containers/pipeline/create/ConfigSourceType/GoogleAnalytics/configHelper';
import { GoogleAnalytics360ConfigHelper } from '../../../containers/pipeline/create/ConfigSourceType/GoogleAnalytics360/configHelper';
import { GoogleAnalytics4ConfigHelperReact } from '../../../containers/pipeline/create/ConfigSourceType/GoogleAnalytics4/configHelper';
import {
  GoogleCampaignManagerConfigHelperReact
} from '../../../containers/pipeline/create/ConfigSourceType/GoogleCampaignManager/configHelper';
import { DriveConfigHelperReact } from '../../../containers/pipeline/create/ConfigSourceType/GoogleDrive/configHelper';
import { GooglePlayConfigHelper } from '../../../containers/pipeline/create/ConfigSourceType/GooglePlay/configHelper';
import {
  GoogleSearchConsoleConfigHelperReact
} from '../../../containers/pipeline/create/ConfigSourceType/GoogleSearchConsole/configHelper';
import { GoogleSheetsReactConfigHelper } from '../../../containers/pipeline/create/ConfigSourceType/GoogleSheets/configHelper';
import {
  InstagramBusinessConfigHelper
} from '../../../containers/pipeline/create/ConfigSourceType/InstagramBusiness/instagram-business-config-helper';
import { JiraConfigHelper } from '../../../containers/pipeline/create/ConfigSourceType/Jira/jira-config-helper';
import { KafkaConfigHelper } from '../../../containers/pipeline/create/ConfigSourceType/Kafka/configHelper';
import { LinkedinAdsConfigHelper } from '../../../containers/pipeline/create/ConfigSourceType/LinkedInAds/config-helper';
import { MarketoConfigHelper } from '../../../containers/pipeline/create/ConfigSourceType/Marketo/marketo-config-helper';
import { MicrosoftAdsReactConfigHelper } from '../../../containers/pipeline/create/ConfigSourceType/MicrosoftAds/configHelper';
import { MixpanelConfigHelper } from '../../../containers/pipeline/create/ConfigSourceType/Mixpanel/configHelper';
import { MockLoadConfigHelper } from '../../../containers/pipeline/create/ConfigSourceType/MockLoad/config-helper';
import { MongodbConfigHelper } from '../../../containers/pipeline/create/ConfigSourceType/MongoDb/configHelper';
import { OutbrainConfigHelper } from '../../../containers/pipeline/create/ConfigSourceType/Outbrain/config-helper';
import { PardotConfigHelper } from '../../../containers/pipeline/create/ConfigSourceType/Pardot/configHelper';
import { PendoConfigHelper } from '../../../containers/pipeline/create/ConfigSourceType/Pendo/pendo-config-helper';
import { PinterestAdsConfigHelper } from '../../../containers/pipeline/create/ConfigSourceType/PinterestAds/pinterest-ads-config-helper';
import { RecurlyConfigHelper } from '../../../containers/pipeline/create/ConfigSourceType/Recurly/recurly-config-helper';
import { RestApiReactConfigHelper } from '../../../containers/pipeline/create/ConfigSourceType/RestApi/rest-api-config-helper';
import { S3ConfigHelperReact } from '../../../containers/pipeline/create/ConfigSourceType/S3/configHelper';
import {
  SalesforceMarketingCloudConfigHelper
} from '../../../containers/pipeline/create/ConfigSourceType/SalesforceMarketingCloud/salesforce-marketing-cloud-config-helper';
import { SendgridConfigHelper } from '../../../containers/pipeline/create/ConfigSourceType/SendGrid/sendgrid-config-helper';
import { ShopifyConfigHelper } from '../../../containers/pipeline/create/ConfigSourceType/Shopify/config-helper';
import { SnapchatAdsConfigHelper } from '../../../containers/pipeline/create/ConfigSourceType/Snapchat/config-helper';
import { SqlConfigHelperReact } from '../../../containers/pipeline/create/ConfigSourceType/SQL/configHelper';
import { StripeConfigHelper } from '../../../containers/pipeline/create/ConfigSourceType/Stripe/stripe-config-helper';
import { TaboolaConfigHelperReact } from '../../../containers/pipeline/create/ConfigSourceType/taboola/configHelper';
import { TiktokAdsConfigHelper } from '../../../containers/pipeline/create/ConfigSourceType/TiktokAds/tiktok-ads-config-helper';
import { TwilioConfigHelper } from '../../../containers/pipeline/create/ConfigSourceType/Twilio/twilio-config-helper';
import { TwitterAdsConfigHelper } from '../../../containers/pipeline/create/ConfigSourceType/TwitterAds/configHelper';
import { TypeformConfigHelper } from '../../../containers/pipeline/create/ConfigSourceType/Typeform/typeform-config-helper';
import { WebhookConfigHelper } from '../../../containers/pipeline/create/ConfigSourceType/Webhook/webhook-config-helper';
import { XeroConfigHelper } from '../../../containers/pipeline/create/ConfigSourceType/Xero/xero-config-helper';
import { YoutubeAnalyticsConfigHelper } from '../../../containers/pipeline/create/ConfigSourceType/YoutubeAnalytics/configHelper';

export const SOURCE_TYPE_CONFIG_HELPERS = {
  'REDSHIFT': SqlConfigHelperReact,
  'POSTGRES': SqlConfigHelperReact,
  'AURORA': SqlConfigHelperReact,
  'MS_SQL': SqlConfigHelperReact,
  'AZURE_SQL': SqlConfigHelperReact,
  'ORACLE': SqlConfigHelperReact,
  'MYSQL': SqlConfigHelperReact,
  'MARIA_DB': SqlConfigHelperReact,
  'MONGODB': MongodbConfigHelper,
  'ATLAS_MONGODB': MongodbConfigHelper,
  'MAILCHIMP_WEBHOOK': WebhookConfigHelper,
  'GITHUB': WebhookConfigHelper,
  'INTERCOM_WEBHOOK': WebhookConfigHelper,
  'SHOPIFY_WEBHOOK': WebhookConfigHelper,
  'SEGMENT': WebhookConfigHelper,
  'SENDGRID_WEBHOOK': WebhookConfigHelper,
  'ANDROID_SDK': WebhookConfigHelper,
  'WEBHOOK': WebhookConfigHelper,
  'KAFKA': KafkaConfigHelper,
  'MIXPANEL': MixpanelConfigHelper,
  'GOOGLE_SHEETS': GoogleSheetsReactConfigHelper,
  'DRIVE': DriveConfigHelperReact,
  'CLEVERTAP': ClevertapConfigHelper,
  'S3': S3ConfigHelperReact,
  'GCS': GcsConfigHelper,
  'GOOGLE_SEARCH_CONSOLE': GoogleSearchConsoleConfigHelperReact,
  'GOOGLE_PLAY_CONSOLE': GooglePlayConfigHelper,
  'APPSFLYER': AppsflyerConfigHelper,
  'GOOGLE_ANALYTICS': GoogleAnalyticsConfigHelperReact,
  'GOOGLE_ANALYTICS_V2': GoogleAnalyticsConfigHelperReact,
  'MICROSOFT_ADS': MicrosoftAdsReactConfigHelper,
  'FACEBOOK_ADS': FbaConfigHelper,
  'ELASTICSEARCH': ElasticSearchConfigHelper,
  'FTP': FtpConfigHelperReact,
  'RESTAPI': RestApiReactConfigHelper,
  'DYNAMODB': DynamodbConfigHelper,
  'SENDGRID': SendgridConfigHelper,
  'TWITTER_ADS': TwitterAdsConfigHelper,
  'MARKETO': MarketoConfigHelper,
  'SHOPIFY': ShopifyConfigHelper,
  'STRIPE': StripeConfigHelper,
  'JIRA_CLOUD': JiraConfigHelper,
  'AMPLITUDE_ANALYTICS': AmplitudeAnalyticsConfigHelper,
  'GOOGLE_CM': GoogleCampaignManagerConfigHelperReact,
  'FACEBOOK_PAGES': FbInsightsConfigHelperReact,
  'MOCK_LOAD': MockLoadConfigHelper,
  'OUTBRAIN': OutbrainConfigHelper,
  'ADROLL': AdrollConfigHelperReact,
  'LINKEDIN_ADS': LinkedinAdsConfigHelper,
  'PINTEREST_ADS': PinterestAdsConfigHelper,
  'CRITEO': CriteoConfigHelper,
  'AWS_RDS_MYSQL': SqlConfigHelperReact,
  'GCP_MYSQL': SqlConfigHelperReact,
  'AZURE_MYSQL': SqlConfigHelperReact,
  'ORACLE_MYSQL': SqlConfigHelperReact,
  'TOKU_DB_MYSQL': SqlConfigHelperReact,
  'AWS_RDS_MARIA_DB': SqlConfigHelperReact,
  'AZURE_MARIA_DB': SqlConfigHelperReact,
  'AWS_RDS_POSTGRES': SqlConfigHelperReact,
  'AWS_AURORA_POSTGRES': SqlConfigHelperReact,
  'GCP_POSTGRES': SqlConfigHelperReact,
  'AZURE_POSTGRES': SqlConfigHelperReact,
  'SF_HEROKU_POSTGRES': SqlConfigHelperReact,
  'AWS_RDS_ORACLE': SqlConfigHelperReact,
  'AWS_RDS_MSSQL': SqlConfigHelperReact,
  'GCP_MS_SQL': SqlConfigHelperReact,
  'CONFLUENT_CLOUD': KafkaConfigHelper,
  'YOUTUBE_ANALYTICS': YoutubeAnalyticsConfigHelper,
  'PARDOT': PardotConfigHelper,
  'SALESFORCE_MARKETING_CLOUD': SalesforceMarketingCloudConfigHelper,
  'APPLE_SEARCH_ADS': AppleSearchAdsConfigHelper,
  'AWS_ELASTICSEARCH': ElasticSearchConfigHelper,
  'GOOGLE_ANALYTICS_360': GoogleAnalytics360ConfigHelper,
  'INSTAGRAM_BUSINESS': InstagramBusinessConfigHelper,
  'BIGQUERY': BigQuerySourceConfigHelper,
  'XERO': XeroConfigHelper,
  'GOOGLE_ANALYTICS_4': GoogleAnalytics4ConfigHelperReact,
  'TABOOLA': TaboolaConfigHelperReact,
  'FIREBASE_ANALYTICS': FirebaseAnalyticsConfigHelperReact,
  'ASANA': AsanaConfigHelper,
  'WORDPRESS_VIA_MYSQL': SqlConfigHelperReact,
  'WOOCOMMERCE_VIA_MYSQL': SqlConfigHelperReact,
  'MAGENTO_VIA_MYSQL': SqlConfigHelperReact,
  'PRESTASHOP_VIA_MYSQL': SqlConfigHelperReact,
  'GOOGLE_ADS': GoogleAdsReactConfigHelper,
  'SNAPCHAT_ADS': SnapchatAdsConfigHelper,
  'CHARGEBEE': ChargebeeConfigHelper,
  'BRAINTREE_PAYMENTS': BraintreePaymentsConfigHelper,
  'RECURLY': RecurlyConfigHelper,
  'FRONT': FrontConfigHelper,
  'TYPEFORM': TypeformConfigHelper,
  'PENDO': PendoConfigHelper,
  'TWILIO': TwilioConfigHelper,
  'TIKTOK_ADS': TiktokAdsConfigHelper,
  'AWS_DOCUMENT_DB': AwsDocumentdbConfigHelperReact,
  'BRAZE': BrazeConfigHelper,
  'AZURE_BLOB': AzureBlobConfigHelperReact,
  'AMAZON_ADS': AmazonAdsConfigHelper,
  'AWS_OPENSEARCH': ElasticSearchConfigHelper
};
