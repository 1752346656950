import { Field, Formik, FormikProps } from 'formik';
import React, { useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useHistory } from '@useHistory/useHistoryCompat';
import * as Yup from 'yup';
import { EntityUIState, EntityUIStateWrapper } from '../../core/models/entitiy-ui-state';
import { getErrorMessageFromObj } from '../../../legacy-utils/request';
import { HdFormikTextField } from '../../../components/FormikElements';
import RetryApiAlert from '../../../components/RetryApiAlert';
import { HdResolvedComponent } from '../../../components/Routing/HdResolvedComponent';
import TFACode from '../../../components/TFAElements/TFACode';
import { HdIcon, HdLink, HdPane } from '../../../components/UIElements';
import HdButton from '../../../components/UIElements/HdButton';
import HdFormControl from '../../../components/UIElements/HdFormControl';
import ErrorFocus from '../../../utils/ErrorFocus';
import { getDataIdGenerator } from '../../../utils/generateDataId';
import AccountsAPI from '../../account/AccountsAPI';
import { HdAuth } from '../HdAuth';
import { HdAuthRequestType } from '../HdAuth/models';
import { FeaturedCustomers } from '../TestimonialSection';
import { useFeaturedCustomers } from '../useFeaturedCustomer';
import { useLoginGuard } from '../useLoginGuard';

export interface TfaSetupPageProps {
  featuredCustomers: FeaturedCustomers;
}

const initialValues = {
  token: null
};

const validationSchema = Yup.object({
  token: Yup.number().required()
});

export function TfaSetupPage() {
  const { getFeaturedCustomers } = useFeaturedCustomers();
  const { executeLoginGuard } = useLoginGuard();

  return (
    <HdResolvedComponent
      Component={TfaSetupPageInner}
      resolve={{
        featuredCustomers: getFeaturedCustomers
      }}
      canActivate={[ executeLoginGuard ]}
    />
  );
}

export function TfaSetupPageInner({
  featuredCustomers
}: TfaSetupPageProps) {
  const [ isSubmitting, setIsSubmitting ] = useState(false);
  const [qrCode, setQrCode] = useState(null);
  const [refreshQrCode, setRefreshQrCode] = useState(null);
  const [uiState, setUiState] = useState<EntityUIStateWrapper>({ state: EntityUIState.NEW });
  const [ formError, setFormError ] = useState(null);
  const errored = uiState.state === EntityUIState.ERRORED;

  const formikRef = useRef<FormikProps<{}>>();
  const history = useHistory();
  const { search } = useLocation();

  const onSetUiStateCallback = data => {
    setUiState(data);
    setRefreshQrCode(false);
  };

  const goBack = () => {
    history.push(`/login${search}`);
  };

  const showRecoveryCodes = () => {
    history.push(`/tfa/recovery-codes${search}`);
  };

  const onRetry = () => {
    setUiState({ state: EntityUIState.NEW });
    setRefreshQrCode(true);
  };

  const handleSubmit = (values) => {
    if (!formikRef.current.isSubmitting) {
      return;
    }

    setIsSubmitting(true);
    setFormError(null);

    AccountsAPI.validateTfaToken(
      {
        otp: values.token,
        secret: qrCode.secret
      },
      false,
      false
    ).then(({ data }) => {
      setIsSubmitting(false);
      showRecoveryCodes();
    }, (error) => {
      setIsSubmitting(false);

      const errorMessage = getErrorMessageFromObj(error);
      setFormError(errorMessage);

      if (error.status === 401) {
        history.push(`/login${search}`);
      }
    });
  };

  const dataIdGenerator = getDataIdGenerator('tfa-setup-page');

  return (
    <HdAuth selectedAuthType={HdAuthRequestType.SETUP_TFA} featuredCustomers={featuredCustomers}>
      {!errored ? (<>
        <TFACode
          className='mb-8'
          qrClassName='border'
          onSetQrCodeCallback={setQrCode}
          onSetUiStateCallback={onSetUiStateCallback}
          refreshQrCode={refreshQrCode}
        />

        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={values => handleSubmit(values)}
          validateOnMount
          innerRef={formikRef}
        >
          {props => (
            <form
              noValidate
              onSubmit={props.handleSubmit}
            >
              <ErrorFocus formik={props} onFormError={() => {}} />

              <HdFormControl className='mb-0'>
                <Field
                  name='token'
                  label='Verification Code'
                  component={HdFormikTextField}
                  startAdornment={<HdIcon name='key' />}
                  required
                />
              </HdFormControl>

              {!!formError && (
                <HdPane
                  dataId={dataIdGenerator('form-error')}
                  className='mb-1 w-100'
                  variant='error-faded'
                  icon='error-filled'
                  iconClasses='text-error'
                  disableHide
                >
                  <div className='text-default'>
                    {formError}
                  </div>
                </HdPane>
              )}

              <HdButton
                dataId={dataIdGenerator('submit')}
                type='submit'
                size='lg'
                className='w-100 mt-4'
                showProgress={isSubmitting}
                disabled={!props.isValid || isSubmitting}
              >
                {isSubmitting ? 'Activating' : 'Activate'}
              </HdButton>
            </form>
            )}
        </Formik>

        <HdLink
          dataId={dataIdGenerator('back')}
          tag='button'
          icon='back'
          direction='left'
          className='mt-4'
          onClick={goBack}
        >
          Go Back
        </HdLink>
      </>) : (
        <RetryApiAlert
          dataId={dataIdGenerator('')}
          actionHandler={onRetry}
          error={uiState.error}
        />
      )}
    </HdAuth>
  );
}
