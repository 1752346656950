import { HevoCustomer, HevoTestimonial } from '../../core/models/customers';

export const CLIENTS: HevoCustomer[] = [
  {
    name: 'Cure.Fit',
    logoWhite: 'https://res.cloudinary.com/hevo/image/upload/v1/hevo-website/customer/logo-white/7197aa1d962f41e4a4c9468d8c7bb5c7_niyaql'
  },
  {
    name: 'Swiggy',
    logoWhite: 'https://res.cloudinary.com/hevo/image/upload/v1/hevo-website/customer/logo-white/TypeWhite_lk0r0y'
  },
  {
    name: 'FarEye',
    logoWhite: 'https://res.cloudinary.com/hevo/image/upload/v1/hevo-website/customer/logo-white/8083eebb1e9145d1a03614a51dee74f6_eyjgxd'
  },
  {
    name: 'Edureka',
    logoWhite: 'https://res.cloudinary.com/hevo/image/upload/v1/hevo-website/customer/logo-white/df35d4cba0044516b3d61d91e73a19a4_v06j2h'
  },
  {
    name: 'Fave',
    logoWhite: 'https://res.cloudinary.com/hevo/image/upload/v1/hevo-website/customer/logo-white/ccac17d2777a4e02815c8a797317cecf_csoy9e'
  },
  {
    name: 'Gimlet',
    logoWhite: 'https://res.cloudinary.com/hevo/image/upload/v1/hevo-website/customer/logo-white/9874643369654280b4a8ab45e8839ca6_gszncz'
  },
  {
    name: 'Meesho',
    logoWhite: 'https://res.cloudinary.com/hevo/image/upload/v1/hevo-website/customer/logo-white/38ee61b2b9944468bbfa6c923cdee999_z6uzga'
  },
  {
    name: 'Pharmeasy',
    logoWhite: 'https://res.cloudinary.com/hevo/image/upload/v1/hevo-website/customer/logo-white/9114423761f94032a3d85c57a3d32fb4_ddbs5a'
  },
  {
    name: 'Vedantu',
    logoWhite: 'https://res.cloudinary.com/hevo/image/upload/v1/hevo-website/customer/logo-white/8fa7dc05e5484bbe8f9ab3f7e85691ef_ymieah'
  }
];

export const TESTIMONIALS: HevoTestimonial[] = [
  {
    desc: 'With Hevo, the process of bringing data, no matter what Source or format, has become ' +
      'simpler and error-free. Our analysts are now busy building Models and deriving insights instead ' +
      'of worrying about data availability.',
    author: 'Vivek Garg',
    company: 'Swiggy'
  },
  {
    desc: 'Hevo solved one of my core needs - getting complex data transformations done on the fly with ease. '
      + 'Quick integrations with complete flexibility and control makes Hevo a perfect complement to our data engineering team.',
    author: 'Swati Singhi',
    company: 'Cure.Fit'
  },
  {
    desc: 'Hevo is super quick to get going. Within minutes the data was flowing from a couple of Sources to Redshift. '
      + 'Extensive documentation and a prompt support makes going live a breeze.',
    author: 'Gaurav Gupta',
    company: 'Fareye'
  },
  {
    desc: 'We had data in a variety of places like MySQL, Drive, MongoDB. Hevo helped us swiftly migrate this data '
      + 'into Redshift at lightening speed. Moreover, Hevo\'s Models feature allowed us to quickly create materialized '
      + 'views and data models over our data.',
    author: 'Ajith Reddy',
    company: 'Vedantu'
  }
];
